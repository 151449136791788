import React from 'react'
import { AppRouter } from '../../routes/AppRouter'

import style from './Main.module.css'
import { Dashboard } from './Dashboard'
import { Footer } from '../../components/footer/Footer'

export function Main() {
  const mainContainer = React.useRef<HTMLDivElement>(null)

  return (
    <div className={style.mainContainer} id="mainContainer">
      <AppRouter />
      <Footer />
    </div>
  )
}
