import generic from '../../../../assets/generic.module.css'
import { Box } from '@mui/material'
import React from 'react'

export const CVS311 = () => {
  return (
    <>
      <Box className={generic.pageContainer} style={{ backgroundColor: 'white' }}>
        <Box className={generic.pageContainer} display="flex">
          <h2>
            <b>3.1 Lesión Medular - Función vesical y síntomas</b>
          </h2>
        </Box>
        <Box className={generic.pageContainer}>
          <div>
            <b>Síntomas</b>
            <br />
            <br />
            Una LM por encima de T12 normalmente provocaría un intestino reflejo con los siguientes
            síntomas:
            <br />
            <br />
            &nbsp;&nbsp;&nbsp;&nbsp;•&nbsp;&nbsp;&nbsp;&nbsp; Pérdida de sensación de cuando el
            intestino está lleno. No se recibe el mensaje entre el intestino y el sistema nervioso
            central (SNC) que suele indicar al intestino que está lleno.
            <br />
            <br />
            &nbsp;&nbsp;&nbsp;&nbsp;•&nbsp;&nbsp;&nbsp;&nbsp; Continuará vaciando cuando se estimule
            <br />
            <br />
            &nbsp;&nbsp;&nbsp;&nbsp;•&nbsp;&nbsp;&nbsp;&nbsp; Una presión rectal alta
            <br />
            <br />
            &nbsp;&nbsp;&nbsp;&nbsp;•&nbsp;&nbsp;&nbsp;&nbsp; El músculo que controla la apertura y
            el cierre del ano permanece tenso
            <br />
            <br />
            &nbsp;&nbsp;&nbsp;&nbsp;•&nbsp;&nbsp;&nbsp;&nbsp; Cuando el intestino se llena, se vacía
            automáticamente, es decir, se predispone a realizar un vaciado inadecuado
            <br />
            <br />
            Una LM por debajo de T12 normalmente provocaría un intestino flácido con los siguientes
            síntomas:
            <br />
            <br />
            &nbsp;&nbsp;&nbsp;&nbsp;•&nbsp;&nbsp;&nbsp;&nbsp; Pérdida de sensación de cuando el
            intestino está lleno
            <br />
            <br />
            &nbsp;&nbsp;&nbsp;&nbsp;•&nbsp;&nbsp;&nbsp;&nbsp; No se vaciará por completo, incluso
            cuando esté estimulado. Esto se debe a que la LM ha dañado las vías desde el intestino
            hasta el centro reflejo de la columna, es decir, no puede haber ninguna acción refleja
            <br />
            <br />
            &nbsp;&nbsp;&nbsp;&nbsp;•&nbsp;&nbsp;&nbsp;&nbsp; La presión rectal es baja
            <br />
            <br />
            &nbsp;&nbsp;&nbsp;&nbsp;•&nbsp;&nbsp;&nbsp;&nbsp; Predispone a sufrir manchado fecal, es
            decir, incontinencia fecal
            <br />
            <br />
            <br />
            Dada la proximidad de la vejiga y el intestino, la interacción funcional es inevitable.
            Por lo tanto, es importante recordar que algunos de los síntomas de la vejiga y/o del
            intestino pueden tener una correlación, por ejemplo, pérdida de orina debido al
            estreñimiento.
            <br />
            <br />
            Puesto que existe una conexión tan estrecha entre la vejiga y el intestino y los
            problemas que surgen en un área también podrían afectar a la otra, cabe recordar que se
            deben abordar ambos para mejorar la calidad de vida de la persona afectada.
            <br />
            <br />
            El impacto emocional y psicológico de los problemas intestinales con la lesión medular
            puede ser devastador. Las terapias deben tener como objetivo comprender los mecanismos
            fisiológicos y adaptar los tratamientos para la gestión de los síntomas.
            <br />
            <br />
          </div>
        </Box>
      </Box>
    </>
  )
}
