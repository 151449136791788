import { Box, TextField } from '@mui/material'
import styles from './Editor.module.css'
import { useTranslation } from 'react-i18next'
import { emptyUserDTO, User, UserDTO } from '../../modules/users/models/User'
import React, { ChangeEvent, FormEvent, useEffect, useState } from 'react'
import { getUserContainer } from '../../container/user-module'
import { IUserService, USER_SERVICE_KEY } from '../../modules/users'
import { AppButton, ButtonTheme } from '../../components/app-button/AppButton'
import { navigate } from '@reach/router'
import { ROUTE_HOME } from '../../routes/routes-constants'
import { Alert } from '@material-ui/lab'

const userContainer = getUserContainer()
const userService = userContainer.get<IUserService>(USER_SERVICE_KEY)

type ProfileProps = {
  user: User | undefined
  role: string
}

export const Profile = (props: ProfileProps) => {
  const { t } = useTranslation()
  const [userToEdit, setUserToEdit] = useState<UserDTO>(emptyUserDTO())
  const [errorMessage, setErrorMessage] = useState<string>('')

  useEffect(() => {
    props.user && setUserToEdit(props.user.toDTO())
  }, [])

  useEffect(() => {
    setErrorMessage('')
  }, [userToEdit])

  const handleInput = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) =>
    setUserToEdit(Object.assign({ ...userToEdit }, { [e.target.name]: e.target.value }))

  const handleSave = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault()

    let correct = true

    if (userToEdit.password) {
      if (userToEdit.password != userToEdit.repeatPassword) {
        correct = false
        setErrorMessage(t('passwordsNotMatch'))
      }
    }

    if (correct)
      userService.update(userToEdit).subscribe(() => setErrorMessage(t('changesSavedSuccessfully')))
  }

  const handleBack = () => navigate(ROUTE_HOME).then

  return (
    <>
      <Box className={styles.profileContainer}>
        {errorMessage && (
          <Box mb={3}>
            <Alert severity={errorMessage === t('passwordsNotMatch') ? 'error' : 'success'}>
              {errorMessage}
            </Alert>
          </Box>
        )}
        <Box className={styles.profileHeader}>
          <p style={{ color: '#000', fontFamily: 'Open-sans, sans-serif' }}>{t('profile')}</p>
        </Box>
        <Box className={styles.fieldsContainer}>
          <h4 style={{ color: '#000', fontFamily: 'Open-sans, sans-serif' }}>
            {t('generalInformation')}
          </h4>
          <Box display="flex" flexDirection="row">
            <Box className={styles.textFieldBox}>
              <h4 style={{ color: '#000', fontFamily: 'Open-sans, sans-serif' }}>
                {t('name') + ' *'}
              </h4>
              <TextField
                sx={{
                  /*'.MuiOutlinedInput-notchedOutline': {
                      borderColor: '#000',
                    },*/
                  '.css-1n4twyu-MuiInputBase-input-MuiOutlinedInput-input': {
                    color: '#000',
                    fontFamily: 'Open-sans, sans-serif',
                  },
                }}
                id={'firstName'}
                key={'firstName'}
                name="firstName"
                className={styles.textField}
                value={userToEdit.firstName}
                type="name"
                required={true}
                variant={'outlined'}
                size={'small'}
                onChange={handleInput}
              />
            </Box>
            <Box>
              <h4 style={{ color: '#000', fontFamily: 'Open-sans, sans-serif' }}>
                {t('lastName') + ' *'}
              </h4>
              <TextField
                id={'lastName'}
                key={'lastName'}
                name="lastName"
                className={styles.textField}
                sx={{
                  /*'.MuiOutlinedInput-notchedOutline': {
                    borderColor: '#000',
                  },*/
                  '.css-1n4twyu-MuiInputBase-input-MuiOutlinedInput-input': {
                    color: '#000',
                    fontFamily: 'Open-sans, sans-serif',
                  },
                }}
                value={userToEdit.lastName}
                type="name"
                required={true}
                variant={'outlined'}
                size={'small'}
                onChange={handleInput}
              />
            </Box>
          </Box>
          <Box display="flex" flexDirection="row">
            <Box className={styles.textFieldBox}>
              <h4 style={{ color: '#000', fontFamily: 'Open-sans, sans-serif' }}>
                {t('phone') + ' *'}
              </h4>
              <TextField
                id={'phone'}
                key={'phone'}
                name="phone"
                className={styles.textField}
                sx={{
                  /*'.MuiOutlinedInput-notchedOutline': {
                    borderColor: '#000',
                  },*/
                  '.css-1n4twyu-MuiInputBase-input-MuiOutlinedInput-input': {
                    color: '#000',
                    fontFamily: 'Open-sans, sans-serif',
                  },
                }}
                value={userToEdit.phone}
                type="name"
                required={true}
                variant={'outlined'}
                size={'small'}
                onChange={handleInput}
              />
            </Box>
            <Box>
              <h4 style={{ color: '#000', fontFamily: 'Open-sans, sans-serif' }}>
                {t('email') + ' *'}
              </h4>
              <TextField
                id={'email'}
                key={'email'}
                name="email"
                className={styles.textField}
                sx={{
                  /*'.MuiOutlinedInput-notchedOutline': {
                    borderColor: '#000',
                  },*/
                  '.css-1n4twyu-MuiInputBase-input-MuiOutlinedInput-input': {
                    color: '#000',
                    fontFamily: 'Open-sans, sans-serif',
                  },
                }}
                value={userToEdit.email}
                type="name"
                required={true}
                variant={'outlined'}
                size={'small'}
                onChange={handleInput}
              />
            </Box>
          </Box>
          <Box display="flex" flexDirection="row">
            <Box className={styles.textFieldBox}>
              <h4 style={{ color: '#000', fontFamily: 'Open-sans, sans-serif' }}>
                {t('center') + ' *'}
              </h4>
              <TextField
                id={'center'}
                key={'center'}
                name="center"
                className={styles.textField}
                sx={{
                  /*'.MuiOutlinedInput-notchedOutline': {
                    borderColor: '#000',
                  },*/
                  '.css-1n4twyu-MuiInputBase-input-MuiOutlinedInput-input': {
                    color: '#000',
                    fontFamily: 'Open-sans, sans-serif',
                  },
                }}
                value={userToEdit.centerID}
                type="name"
                required={true}
                variant={'outlined'}
                size={'small'}
                onChange={handleInput}
              />
            </Box>
          </Box>
          <Box display="flex" flexDirection="row">
            <Box className={styles.textFieldBox}>
              <h4 style={{ color: '#000', fontFamily: 'Open-sans, sans-serif' }}>
                {t('role') + ' *'}
              </h4>
              <TextField
                id={'role'}
                key={'role'}
                name="role"
                className={styles.textField}
                sx={{
                  /*'.MuiOutlinedInput-notchedOutline': {
                    borderColor: '#000',
                  },*/
                  '.css-1n4twyu-MuiInputBase-input-MuiOutlinedInput-input': {
                    color: '#000',
                    fontFamily: 'Open-sans, sans-serif',
                  },
                }}
                value={props.role}
                type="name"
                variant={'outlined'}
                size={'small'}
                disabled={true}
              />
            </Box>
          </Box>
          <h4 style={{ color: '#000', fontFamily: 'Open-sans, sans-serif' }}>{t('user')}</h4>
          <Box display="flex" flexDirection="row">
            <Box className={styles.textFieldBox}>
              <h4 style={{ color: '#000', fontFamily: 'Open-sans, sans-serif' }}>
                {t('userMail') + ' *'}
              </h4>
              <TextField
                id={'userMail'}
                key={'userMail'}
                name="userMail"
                className={styles.textField}
                sx={{
                  /*'.MuiOutlinedInput-notchedOutline': {
                    borderColor: '#000',
                  },*/
                  '.css-1n4twyu-MuiInputBase-input-MuiOutlinedInput-input': {
                    color: '#000',
                    fontFamily: 'Open-sans, sans-serif',
                  },
                }}
                value={''}
                type="name"
                required={true}
                variant={'outlined'}
                size={'small'}
                onChange={handleInput}
              />
            </Box>
          </Box>
          <Box display="flex" flexDirection="row">
            <Box className={styles.textFieldBox}>
              <h4 style={{ color: '#000', fontFamily: 'Open-sans, sans-serif' }}>
                {t('password') + ' *'}
              </h4>
              <TextField
                id={'password'}
                key={'password'}
                name="password"
                className={styles.textField}
                sx={{
                  /*'.MuiOutlinedInput-notchedOutline': {
                    borderColor: '#000',
                  },*/
                  '.css-1n4twyu-MuiInputBase-input-MuiOutlinedInput-input': {
                    color: '#000',
                    fontFamily: 'Open-sans, sans-serif',
                  },
                }}
                value={userToEdit.password}
                type="password"
                required={true}
                variant={'outlined'}
                size={'small'}
                onChange={handleInput}
              />
            </Box>
            <Box>
              <h4 style={{ color: '#000', fontFamily: 'Open-sans, sans-serif' }}>
                {t('passwordRepeat') + ' *'}
              </h4>
              <TextField
                id={'repeatPassword'}
                key={'repeatPassword'}
                name="repeatPassword"
                className={styles.textField}
                sx={{
                  /*'.MuiOutlinedInput-notchedOutline': {
                    borderColor: '#000',
                  },*/
                  '.css-1n4twyu-MuiInputBase-input-MuiOutlinedInput-input': {
                    color: '#000',
                    fontFamily: 'Open-sans, sans-serif',
                  },
                }}
                value={userToEdit.repeatPassword}
                type="password"
                required={true}
                variant={'outlined'}
                size={'small'}
                onChange={handleInput}
              />
            </Box>
          </Box>
        </Box>
        <Box className={styles.buttonContainer}>
          <AppButton
            theme={ButtonTheme.BasicTransparentBlue}
            type={'button'}
            label={t('cancel')}
            handler={handleBack}
          />
          <AppButton
            theme={ButtonTheme.NewPrimaryLight}
            type={'button'}
            label={t('save')}
            handler={handleSave}
          />
        </Box>
      </Box>
    </>
  )
}
