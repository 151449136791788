import { emptyList, ItemList } from '../../../common/models/ItemList'
import { Observable, of } from 'rxjs'
import { Container, IInit } from '../../../common/container/Container'
import { HTTP_CLIENT_KEY, IHTTPClient } from '../../../common/api/HTTPClient'
import { IStatusService } from '../../../common/status/StatusService'
import { STATUS_SERVICE_KEY } from '../../../container/app'
import { Query } from '../../../common/api/Query'
import { Patient, PatientQuery } from '../models/Patient'
import { PatientContainerConfig } from '../container'
import { prepareURL } from '../../../common/api/http-helpers'
import { catchError, map } from 'rxjs/operators'
import { Problem, ProblemQuery } from '../models/Problem'
import { ProblemDTO, toModel } from '../models/ProblemDTO'

export interface IProblemApi extends IInit {
  getByID(id: string | undefined): Observable<Problem>
  getFilteredList(q: Query<ProblemQuery>): Observable<ItemList<Problem>>
  add(e: ProblemDTO): Observable<Problem | undefined>
  update(e: ProblemDTO): Observable<Problem | undefined>
  delete(id: string | undefined): Observable<boolean>
}

export class ProblemApi implements IProblemApi {
  private _container!: Container
  private _httpClient!: IHTTPClient
  private _url!: string
  private _statusService!: IStatusService

  init(c: Container) {
    this._container = c
    this._httpClient = this._container.get<IHTTPClient>(HTTP_CLIENT_KEY)
    this._statusService = this._container.get<IStatusService>(STATUS_SERVICE_KEY)
    this._url = (this._container.config as PatientContainerConfig).moduleFullUrl
  }

  add(e: ProblemDTO): Observable<Problem | undefined> {
    return this._httpClient
      .post<Problem>({ url: this._url, body: e })
      .pipe(
        map<ProblemDTO, Problem>((d) => toModel(d)),
        catchError((err) => {
          this._statusService.sendStatus({ variant: 'error', error: err })
          return of(undefined)
        })
      )
  }

  delete(id: string | undefined): Observable<boolean> {
    return this._httpClient.delete({ url: this._url + '/' + id }).pipe(
      catchError((err) => {
        this._statusService.sendStatus({ variant: 'error', error: err })
        return of(false)
      })
    )
  }

  getByID(id: string | undefined): Observable<Problem> {
    return this._httpClient
      .get<Problem>({ url: `${this._url}/symptoms/${id}` })
      .pipe(
        map<ProblemDTO, Problem>((d) => toModel(d))
      )
  }

  getFilteredList(q: Query<ProblemQuery>): Observable<ItemList<Problem>> {
    return this._httpClient
      .post<ItemList<Problem>>({ url: this._url + '/problems', body: q })
      .pipe(
        map<ItemList<ProblemDTO>, ItemList<Problem>>((dto) => {
          const itemList = emptyList<Problem>()
          itemList.count = dto.count
          itemList.items = dto.items.map((d) => toModel(d))
          return itemList
        }),
        catchError((err) => {
          this._statusService.sendStatus({ variant: 'error', error: err })
          return of(emptyList<Problem>())
        })
      )
  }

  update(e: ProblemDTO): Observable<Problem | undefined> {
    return this._httpClient
      .put<Problem>({ url: this._url, body: e })
      .pipe(
        map<ProblemDTO, Problem>((d) => toModel(d)),
        catchError((err) => {
          //this._statusService.sendStatus({ variant: 'error', error: err })
          return of(undefined)
        })
      )
  }
}
