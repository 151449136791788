import generic from '../../../../assets/generic.module.css'
import { Box, Button, Divider, Modal } from '@mui/material'
import React, { useState } from 'react'
import image from './vejiga.jpg'
import { useTranslation } from 'react-i18next'

export const CVS111 = () => {
  const { t } = useTranslation()
  const [openModal, setOpenModal] = useState<boolean>(false)

  const handleOpenModal = () => setOpenModal(true)

  const handleCloseModal = () => setOpenModal(false)

  return (
    <>
      <Box className={generic.pageContainer} style={{ backgroundColor: 'white', display: 'flex' }}>
        <Box className={generic.pageContainer} style={{ width: '50%' }}>
          <h2>
            <b>1.1 Disfunción y trastornos del tracto urinario</b>
          </h2>

          <h1 style={{ color: 'dodgerblue' }}>
            <b>Infecciones del tracto urinario (ITU)</b>
          </h1>

          <div>
            Las infecciones del tracto urinario (ITU) se producen cuando las bacterias tienen la
            posibilidad de multiplicarse y adherirse a la membrana mucosa de la vejiga; el término
            médico es cistitis. Cuando una infección del tracto urinario afecta al tracto urinario
            superior, los riñones y/o la pelvis renal, la infección generalmente se conoce como
            pielonefritis.
            <br />
            <br />
            Como cualquier otro tipo de infección, cuanto más tiempo esté sin tratar, más graves
            pueden ser las complicaciones. Una ITU sintomática sin tratamiento puede derivar
            finalmente en daños renales.
            <br />
            <br />
            Las infecciones del tracto urinario (ITU) se producen cuando las bacterias tienen la
            oportunidad de crecer en la uretra y la vejiga.
            <br />
            <br />
            Como cualquier otro tipo de infección, cuanto más tiempo pase sin tratarse, más graves
            pueden ser las complicaciones.
            <br />
            <br />
            Una infección del tracto urinario generalmente implica un cambio en su patrón de
            vaciado.
            <br />
            <br />
            <b>Los signos de una ITU incluyen:</b> <br />
            <br />
            &nbsp;&nbsp;&nbsp;&nbsp;•&nbsp;&nbsp;&nbsp;&nbsp; Necesidad frecuente de orinar
            <br />
            <br />
            &nbsp;&nbsp;&nbsp;&nbsp;•&nbsp;&nbsp;&nbsp;&nbsp; Incontinencia
            <br />
            <br />
            &nbsp;&nbsp;&nbsp;&nbsp;•&nbsp;&nbsp;&nbsp;&nbsp; Sensación de ardor al vaciar la vejiga
            <br />
            <br />
            &nbsp;&nbsp;&nbsp;&nbsp;•&nbsp;&nbsp;&nbsp;&nbsp; Sangre en la orina
            <br />
            <br />
            &nbsp;&nbsp;&nbsp;&nbsp;•&nbsp;&nbsp;&nbsp;&nbsp; Orina turbia, descolorida u olorosa
            <br />
            <br />
            &nbsp;&nbsp;&nbsp;&nbsp;•&nbsp;&nbsp;&nbsp;&nbsp; Dolor de espalda y abdominal
            <br />
            <br />
            &nbsp;&nbsp;&nbsp;&nbsp;•&nbsp;&nbsp;&nbsp;&nbsp; Fiebre
            <br />
            <br />
            &nbsp;&nbsp;&nbsp;&nbsp;•&nbsp;&nbsp;&nbsp;&nbsp; Malestar general
            <br />
            <br />
          </div>
        </Box>
        <Box>
          {/*TODO INSERTAR IMAGEN*/}
          <img
            src={image}
            onClick={handleOpenModal}
            style={{
              marginTop: '20%',
              marginLeft: '20%',
              width: '70%',
              display: 'flex',
              justifyContent: 'center',
              borderStyle: 'ridge',
              borderColor: 'dodgerblue',
            }}
          />
        </Box>
        <Modal open={openModal} onClose={handleCloseModal}>
          <Box
            style={{
              position: 'absolute',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
              backgroundColor: 'white',
              padding: '10px',
            }}>
            <h1 style={{ marginLeft: '25%' }}>
              <b>1.1 Disfunción y trastornos del tracto unirario</b>
            </h1>
            <Divider variant={'middle'} />
            <img
              src={image}
              style={{
                justifyContent: 'center',
                display: 'flex',
                width: '99%',
              }}
            />
            <Button
              variant={'outlined'}
              style={{ marginLeft: '45%', marginTop: '1%', justifyContent: 'end', display: 'flex' }}
              onClick={handleCloseModal}>
              {t('close')}
            </Button>
          </Box>
        </Modal>
      </Box>
    </>
  )
}
