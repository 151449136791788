import { QuestionnairePatientDTO } from './QuestionnairePatientDTO'

export class QuestionnairePatient {
  private readonly _id: string
  private readonly _questionnaireID: string
  private readonly _assignedAt: Date
  private readonly _finished: boolean
  private readonly _score: number
  private readonly _scoreText: string
  private readonly _title: string
  private readonly _patientID: string
  private readonly _finishedAt: Date

  constructor(q: QuestionnairePatientDTO) {
    this._id = q.id
    this._questionnaireID = q.questionnaireID
    this._assignedAt = q.assignedAt
    this._finished = q.finished
    this._score = q.score
    this._title = q.title
    this._patientID = q.patientID
    this._finishedAt = q.finishedAt
    this._scoreText = q.scoreText
  }

  get id(): string {
    return this._id
  }

  get scoreText(): string {
    return this._scoreText
  }

  get title(): string {
    return this._title
  }

  get questionnaireID(): string {
    return this._questionnaireID
  }

  get assignedAt(): Date {
    return this._assignedAt
  }

  get finished(): boolean {
    return this._finished
  }

  get score(): number {
    return this._score
  }

  get patientID(): string {
    return this._patientID
  }

  get finishedAt(): Date {
    return this._finishedAt
  }
}

export function toModel(d: QuestionnairePatientDTO): QuestionnairePatient {
  return new QuestionnairePatient(d)
}

export interface QuestionnairePatientQuery {
  id: string
  ids: string[]
  assignedAt: Date
  finished: boolean
  score: number
}
