import { Lesson } from './Lesson'
import { v4 as uuidv4 } from 'uuid'

export interface LessonDTO {
  id: string
  title: string
  educationalResourceID: string
  video: boolean
  routeName: string
  exercise: boolean
  pathImage: string
  index: number
}

export function emptyLessonDTO(creator: string | undefined): LessonDTO {
  return {
    id: uuidv4(),
    title: '',
    educationalResourceID: '',
    video: false,
    routeName: '',
    exercise: false,
    pathImage: '',
    index: 0,
  }
}

export function fromModel(f: Lesson): LessonDTO {
  return {
    id: f.id,
    title: f.title,
    educationalResourceID: f.educationalResourceID,
    video: f.video,
    routeName: f.routeName,
    exercise: f.exercise,
    pathImage: f.pathImage,
    index: f.index,
  }
}

export function toModel(d: LessonDTO): Lesson {
  return new Lesson(d)
}
