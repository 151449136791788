import { Container, ContainerItem } from '../common/container/Container'
import { ContactService } from '../modules/messenger/services/ContactService'
import { ConversationService } from '../modules/messenger/services/ConversationService'
import { getAppContainer } from './app'
import { moduleURLs } from '../config/api'
import { ConversationApi } from '../modules/messenger/api/ConversationApi'
import { ContactApi } from '../modules/messenger/api/ContactApi'
import { NotificationMessageApi } from '../modules/messenger/api/NotificationMessageApi'
import {
  CONTACT_API_KEY,
  CONTACT_SERVICE_KEY,
  CONVERSATION_API_KEY,
  CONVERSATION_SERVICE_KEY,
  MESSENGER_MODULE,
  MessengerProps,
  NOTIFICATION_MESSAGE_API_KEY,
  NOTIFICATION_MESSAGE_SERVICE_KEY,
} from '../modules/messenger/container'
import { NotificationMessageService } from '../modules/messenger/services/NotificationMessageService'

let container: Container

function init(p: MessengerProps) {
  const items = new Map<symbol, ContainerItem>()

  items.set(CONTACT_API_KEY, new ContainerItem({ instance: new ContactApi() }))
  items.set(CONVERSATION_API_KEY, new ContainerItem({ instance: new ConversationApi() }))

  items.set(
    CONTACT_SERVICE_KEY,
    new ContainerItem({ instance: new ContactService({ apiKey: CONTACT_API_KEY }) })
  )
  items.set(
    CONVERSATION_SERVICE_KEY,
    new ContainerItem({ instance: new ConversationService({ apiKey: CONVERSATION_API_KEY }) })
  )

  items.set(
    NOTIFICATION_MESSAGE_API_KEY,
    new ContainerItem({ instance: new NotificationMessageApi() })
  )

  items.set(
    NOTIFICATION_MESSAGE_SERVICE_KEY,
    new ContainerItem({
      instance: new NotificationMessageService({ apiKey: NOTIFICATION_MESSAGE_API_KEY }),
    })
  )

  container = new Container(items, p.parentContainer, p.config)
}

export function getMessengerContainer(): Container {
  if (!container) {
    init({
      parentContainer: getAppContainer(),
      config: {
        moduleFullUrl: moduleURLs[MESSENGER_MODULE],
      },
    })
  }

  return container
}
