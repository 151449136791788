import React from 'react'
import { Resources as R } from '../../features/resource'
import { CurrentNavHeader } from '../../components/current-nav-header/CurrentNavHeader'
import { RouteProps } from '../../routes/AppRouter'

type ResourcesProps = {
  breadcrumbUrl?: Map<string, string>
} & RouteProps

export function Resources(props: ResourcesProps) {
  const title = props.title || ''

  return (
    <>
      <CurrentNavHeader title={title} />
      <R />
    </>
  )
}
