import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date'
import style from './Patients.module.css'
import { Box, TextField } from '@mui/material'
import styles from '../user-profile/Editor.module.css'
import { KeyboardDatePicker } from '@material-ui/pickers'
import { useTranslation } from 'react-i18next'
import { ChangeEvent } from 'react'
import { NoteDTO } from '../../modules/patients/models/NoteDTO'
import { getUserContainer } from 'container/user-module'
import { ILoggedUserService } from 'modules/users/services/LoggedUserService'
import { LOGGED_USER_SERVICE_KEY } from 'modules/users'

interface NotesViewProps {
  note: NoteDTO
  handleNoteInput: (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void
  handleDate: (e: MaterialUiPickersDate, name: string) => void
}

const loggedUserService = getUserContainer().get<ILoggedUserService>(LOGGED_USER_SERVICE_KEY)

export const NotesView = (p: NotesViewProps) => {
  const { t } = useTranslation()
  const { innerWidth } = window

  return (
    <>
      <Box className={style.generalContainer}>
        <Box>
          <h3>{t('internNotes')}</h3>
        </Box>
        <Box display="flex" flexDirection="row">
          <Box className={styles.textFieldBox}>
            <h4>{t('noteDate')}</h4>
            <KeyboardDatePicker
              style={{ width: '233px' }}
              InputProps={{ style: { fontFamily: 'Open-sans, sans-serif' } }}
              key={'date'}
              id={'date'}
              autoOk
              variant="inline"
              inputVariant={'outlined'}
              label={''}
              format="DD/MM/YYYY"
              value={p.note.date}
              onChange={(e) => p.handleDate(e, 'noteDate')}
              size={'small'}
            />
          </Box>
        </Box>
        <Box display="flex" flexDirection="row">
          <Box className={styles.textFieldBox}>
            <Box display="flex" flexDirection="row" justifyContent={'space-between'}>
              <h4>{t('notes')}</h4>
              {loggedUserService.get()?.language == 1 ? (
                <h5>
                  {new Date(p.note.date).getDate() +
                    '/' +
                    (new Date(p.note.date).getMonth() + 1) +
                    '/' +
                    new Date(p.note.date).getFullYear().toString().substring(2, 4)}
                </h5>
              ) : (
                <h5>{t('lastUpdate') + ': ' + new Date(p.note.date).toLocaleDateString()}</h5>
              )}
            </Box>
            <TextField
              id={'content'}
              key={'content'}
              name="content"
              multiline={true}
              rows={6}
              className={innerWidth < 900 ? styles.doubleRowNotesTablet : styles.doubleRowNotes}
              value={p.note.content}
              type="name"
              variant={'outlined'}
              size={'small'}
              onChange={p.handleNoteInput}
            />
          </Box>
        </Box>
      </Box>
    </>
  )
}
