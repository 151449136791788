import { RouteProps } from '../../routes/AppRouter'
import { Box, FormControlLabel, MenuItem, Select, SelectChangeEvent } from '@mui/material'
import generic from '../../assets/generic.module.css'
import { FormCard } from '../../components/form-card/FormCard'
import styles from '../professionals/Professionals.module.css'
import { AppButton, ButtonTheme } from '../../components/app-button/AppButton'
import { useTranslation } from 'react-i18next'
import { ChangeEvent, FormEvent, useEffect, useState } from 'react'
import { fromModel, UserDTO } from '../../modules/users/models/User'
import { Role } from '../../modules/users/models/Role'
import { getUserContainer } from '../../container/user-module'
import {
  IRolesService,
  IUserService,
  ROLES_SERVICE_KEY,
  USER_SERVICE_KEY,
} from '../../modules/users'
import { TextFieldItem } from '../../components/form-card/TextFieldItem'
import { ROUTE_PROFESSIONALS } from '../../routes/routes-constants'
import { navigate } from '@reach/router'
import { Switch } from '@material-ui/core'

const userContainer = getUserContainer()
const userService = userContainer.get<IUserService>(USER_SERVICE_KEY)
const roleService = userContainer.get<IRolesService>(ROLES_SERVICE_KEY)

export function UserEdit(props: RouteProps) {
  const { t } = useTranslation()
  const [professionalData, setProfessionalData] = useState<UserDTO>()
  const [professionalRoles, setProfessionalRoles] = useState<Role[]>()
  const [checked, setChecked] = useState<boolean>()
  const [role, setRole] = useState<number>(0)
  const [isLoading, setIsLoading] = useState<boolean>(false)

  useEffect(() => {
    if (professionalData) {
      setChecked(professionalData.active)
      roleService.getProfessionals().subscribe((res) => {
        setProfessionalRoles(res)
        let r = res.filter((item) => item.id.toString() == professionalData.roleID)
        if (r.length > 0) {
          setRole(r[0].id)
        }
      })
    }
  }, [professionalData])

  useEffect(() => {
    userService.getByID(props.id || '').subscribe((res) => {
      res && setProfessionalData(fromModel(res))
    })
  }, [])

  useEffect(() => {
    setChecked(!checked)
  }, [isLoading])

  const handleInput = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    professionalData &&
      setProfessionalData(
        Object.assign({ ...professionalData }, { [e.target.name]: e.target.value })
      )
  }

  const saveUser = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault()
  }

  const handleSave = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault()

    professionalData &&
      userService.updateByAdmin(professionalData).subscribe((res) => {
        navigate(ROUTE_PROFESSIONALS)
      })
  }

  const handleSelectRole = (e: SelectChangeEvent) => {
    professionalData &&
      setProfessionalData(
        Object.assign({ ...professionalData }, { [e.target.name]: e.target.value })
      )
  }

  const formCard = () => (
    <FormCard>
      <form onSubmit={(e) => saveUser(e)}>
        <Box className={styles.fieldsContainer}>
          <Box
            display="flex"
            flexDirection="row"
            style={{ marginTop: '1%', fontFamily: 'Open-sans, sans-serif' }}>
            <Box className={styles.textFieldBox}>
              <TextFieldItem
                field="firstName"
                value={professionalData?.firstName || ''}
                type={'string'}
                handleChange={handleInput}
                required={true}
                label={t('firstName')}
              />
            </Box>
            <Box
              className={styles.textFieldBox}
              style={{ marginLeft: '3%', fontFamily: 'Open-sans, sans-serif' }}>
              <TextFieldItem
                field="lastName"
                value={professionalData?.lastName || ''}
                type={'string'}
                handleChange={handleInput}
                required={true}
                label={t('lastName')}
              />
            </Box>
          </Box>

          <Box
            display="flex"
            flexDirection="row"
            style={{ marginTop: '3%', fontFamily: 'Open-sans, sans-serif' }}>
            <Box className={styles.textFieldBox}>
              <TextFieldItem
                field="phone"
                value={professionalData?.phone || ''}
                type={'string'}
                handleChange={handleInput}
                required={true}
                label={t('phone')}
              />
            </Box>
            <Box
              className={styles.textFieldBox}
              style={{ marginLeft: '3%', fontFamily: 'Open-sans, sans-serif' }}>
              <TextFieldItem
                field="email"
                value={professionalData?.email || ''}
                type={'string'}
                handleChange={handleInput}
                required={true}
                label={t('email')}
              />
            </Box>
          </Box>

          <Box
            display="flex"
            flexDirection="row"
            style={{ marginTop: '3%', fontFamily: 'Open-sans, sans-serif' }}>
            <Box className={styles.textFieldBox}>
              <TextFieldItem
                field="centerID"
                value={professionalData?.centerID || ''}
                type={'string'}
                handleChange={handleInput}
                required={true}
                label={t('center')}
              />
            </Box>
            <Box
              className={styles.textFieldBox}
              style={{ marginLeft: '3%', fontFamily: 'Open-sans, sans-serif' }}>
              <Select
                /*sx={{
                  '.MuiOutlinedInput-notchedOutline': {
                    borderColor: '#000',
                  },
                }}*/
                sx={{ fontFamily: 'Open-sans, sans-serif' }}
                value={role.toString()}
                label={t('role')}
                style={{ height: '100%', width: '230px' }}
                className={styles.selectField}
                id="roleID"
                name="roleID"
                onChange={handleSelectRole}>
                {professionalRoles?.map((item) => (
                  <MenuItem value={item.id}>{item.role}</MenuItem>
                ))}
              </Select>
            </Box>
          </Box>
        </Box>
        <Box
          display="flex"
          flexDirection="row"
          style={{
            marginLeft: '1%',
            marginTop: '3%',
            maxWidth: '526px',
            display: 'flex',
            justifyContent: 'flex-end',
            fontFamily: 'Open-sans, sans-serif',
          }}>
          <FormControlLabel
            sx={{
              '.css-ahj2mt-MuiTypography-root': {
                fontFamily: 'Open-sans, sans-serif',
              },
            }}
            className={styles.toggle}
            control={<Switch />}
            label="Activar"
            checked={professionalData?.active || false}
            onChange={() => {
              if (professionalData) {
                let auxProfessional: UserDTO = professionalData
                auxProfessional.active = !professionalData.active
                setProfessionalData(auxProfessional)
                setIsLoading(!isLoading)
              }
            }}
          />
        </Box>

        <Box className={styles.buttonContainer} style={{ paddingBottom: '2.5%' }}>
          <AppButton
            theme={ButtonTheme.NewPrimaryLight}
            type={'button'}
            label={t('save')}
            handler={handleSave}
          />
        </Box>
      </form>
    </FormCard>
  )

  return (
    <>
      <Box className={generic.pageContainer}>{formCard()}</Box>
    </>
  )
}
