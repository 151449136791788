import { PatientSymptomDTO } from './PatientSymptomDTO'

export class PatientSymptom {
  private readonly _id: string
  private _createdAt: Date
  private _symptomID: string
  private _patientID: string
  private _other: string

  constructor(p: PatientSymptomDTO) {
    this._id = p.id
    this._createdAt = p.createdAt
    this._symptomID = p.symptomID
    this._patientID = p.patientID
    this._other = p.other
  }

  get id(): string {
    return this._id
  }

  get other(): string {
    return this._other
  }

  get createdAt(): Date {
    return this._createdAt
  }

  get symptomID(): string {
    return this._symptomID
  }

  get patientID(): string {
    return this._patientID
  }
}

export interface PatientSymptomQuery {
  id: string
  createdAt: Date
  symptomID: string
  patientID: string
}
