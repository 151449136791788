import { Box, List, ListItem } from '@mui/material'
import { Avatar, IconButton, Typography } from '@material-ui/core'
import viewIcon from '../../../../assets/higea/ico-view.svg'
import React from 'react'
import { navigate } from '@reach/router'
import {
  ROUTE_EDUCATIONALVESICAL11,
  ROUTE_EDUCATIONALVESICAL12,
  ROUTE_EDUCATIONALVESICAL13,
  ROUTE_EDUCATIONALVESICAL14,
  ROUTE_EDUCATIONALVESICAL21,
  ROUTE_EDUCATIONALVESICAL22,
  ROUTE_EDUCATIONALVESICAL23,
} from '../../../../routes/routes-constants'
import styles from '../../EducationalProfessional.module.css'

export const Module1Lessons = () => {
  const handleVesical11 = () => navigate(ROUTE_EDUCATIONALVESICAL11).then()
  const handleVesical12 = () => navigate(ROUTE_EDUCATIONALVESICAL12).then()
  const handleVesical13 = () => navigate(ROUTE_EDUCATIONALVESICAL13).then()
  const handleVesical14 = () => navigate(ROUTE_EDUCATIONALVESICAL14).then()

  return (
    <List>
      <ListItem>
        <Box className={styles.lessonItem}>
          <Box display={'flex'} justifyContent={'space-between'} alignItems={'center'}>
            <ListItem id={'idLesson'}>
              <Typography style={{ color: 'blue' }}>
                1.1 Disfunción y trastornos del tracto urinario
              </Typography>
            </ListItem>
            <IconButton>
              <Avatar src={viewIcon} style={{ width: 29, height: 25 }} onClick={handleVesical11} />
            </IconButton>
          </Box>
        </Box>
      </ListItem>
      <ListItem>
        <Box className={styles.lessonItem}>
          <Box display={'flex'} justifyContent={'space-between'} alignItems={'center'}>
            <ListItem id={'idLesson'}>
              <Typography style={{ color: 'blue' }}>1.2 Incontinencia</Typography>
            </ListItem>
            <IconButton>
              <Avatar src={viewIcon} style={{ width: 29, height: 25 }} onClick={handleVesical12} />
            </IconButton>
          </Box>
        </Box>
      </ListItem>
      <ListItem>
        <Box className={styles.lessonItem}>
          <Box display={'flex'} justifyContent={'space-between'} alignItems={'center'}>
            <ListItem id={'idLesson'}>
              <Typography style={{ color: 'blue' }}>1.3 Vejiga hiperactiva</Typography>
            </ListItem>
            <IconButton>
              <Avatar src={viewIcon} style={{ width: 29, height: 25 }} onClick={handleVesical13} />
            </IconButton>
          </Box>
        </Box>
      </ListItem>
      <ListItem>
        <Box className={styles.lessonItem}>
          <Box display={'flex'} justifyContent={'space-between'} alignItems={'center'}>
            <ListItem id={'idLesson'}>
              <Typography style={{ color: 'blue' }}>1.4 Vejiga hipoactiva</Typography>
            </ListItem>
            <IconButton>
              <Avatar src={viewIcon} style={{ width: 29, height: 25 }} onClick={handleVesical14} />
            </IconButton>
          </Box>
        </Box>
      </ListItem>
    </List>
  )
}
