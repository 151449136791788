import React, { useState, useEffect } from 'react'
import { getUserContainer } from './container/user-module'
import { IUserService, LOGGED_USER_SERVICE_KEY, USER_SERVICE_KEY } from './modules/users'
import { Box } from '@material-ui/core'
import styles from './Tfa.module.css'
import Button from '@mui/material/Button'
import ReactCodeInput from './components/tfa/ReactCodeInput'
import { LoggedUserDTO } from 'modules/users/models/LoggedUserDTO'
import { ILoggedUserService } from 'modules/users/services/LoggedUserService'
import { useTranslation } from 'react-i18next'

type TFAProps = {
  userToLogin: LoggedUserDTO | undefined
  goToLogin: () => void
  backToLogin: () => void
}

const userService = getUserContainer().get<IUserService>(USER_SERVICE_KEY)
const loggedUserService = getUserContainer().get<ILoggedUserService>(LOGGED_USER_SERVICE_KEY)

export default function Tfa(props: TFAProps) {
  const [failure, setFailure] = useState<boolean>(false)
  const [success, setSucces] = useState<boolean>(false)
  const [clear, setClear] = useState<boolean>(false)
  const { t } = useTranslation()

  const verifyToken = (t: string) => {
    userService.verifyToken(props.userToLogin?.id || '', t).subscribe((res) => {
      if (res) {
        setSucces(true)
      } else {
        setFailure(true)
        setTimeout(() => {
          setFailure(false)
          setClear(true)
        }, 2500)
      }
    })
  }

  return (
    <Box className={styles.background}>
      <Box className={styles.card} style={{ maxWidth: 510 }}>
        <Box className={styles.titleCard}>
          <p className={styles.titleText} style={{ fontSize: 24 }}>
            {t('tfa1')}
          </p>
          <p className={styles.titleText} style={{ marginTop: '-6%', fontSize: 24 }}>
            {t('tfa2')}
          </p>
        </Box>
        <Box style={{ paddingLeft: 40, paddingRight: 30, marginTop: '-3%' }}>
          <p>{t('tf3')}</p>
        </Box>
        <Box className={styles.componentStyle}>
          <ReactCodeInput
            failure={failure}
            success={success}
            onComplete={(t: string) => verifyToken(t)}
            clear={clear}
            id="inputID"
          />
        </Box>
        <Box className={styles.boxButton}>
          <Button
            style={{ marginTop: '1%', color: 'rgb(26, 150, 255)' }}
            onClick={() => props.backToLogin()}>
            {t('backToLogin')}
          </Button>
          <Button
            style={{ marginTop: '1%', color: 'rgb(26, 150, 255)' }}
            onClick={() => {
              if (!success) return
              props.userToLogin && loggedUserService.storeUser(props.userToLogin)
              props.goToLogin()
            }}>
            {t('access')}
          </Button>
        </Box>
      </Box>
    </Box>
  )
}
