import { Container, ContainerItem } from '../common/container/Container'
import { getAppContainer } from './app'
import { moduleURLs } from '../config/api'
import { GoalApi } from '../modules/goals/api/GoalApi'
import { GoalService } from '../modules/goals/services/GoalService'
import { GOAL_API_KEY, GOAL_SERVICE_KEY, GOALS_MODULE } from '../modules/goals'
import { ContentProps } from '../modules/goals/container'

let container: Container

function init(p: ContentProps) {
  const items = new Map<symbol, ContainerItem>()

  items.set(GOAL_API_KEY, new ContainerItem({ instance: new GoalApi() }))

  items.set(
    GOAL_SERVICE_KEY,
    new ContainerItem({ instance: new GoalService({ apiKey: GOAL_API_KEY }) })
  )

  container = new Container(items, p.parentContainer, p.config)
}

export function getGoalContainer(): Container {
  if (!container) {
    init({
      parentContainer: getAppContainer(),
      config: {
        moduleFullUrl: moduleURLs[GOALS_MODULE],
      },
    })
  }

  return container
}
