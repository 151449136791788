import generic from '../../../../assets/generic.module.css'
import { Box } from '@mui/material'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import image from './inervation.png'

export const CVS231 = () => {
  const { t } = useTranslation()
  const [openModal, setOpenModal] = useState<boolean>(false)
  const { innerWidth } = window

  const handleOpenModal = () => setOpenModal(true)

  const handleCloseModal = () => setOpenModal(false)

  return (
    <>
      <Box className={generic.pageContainer} style={{ backgroundColor: 'white' }}>
        <Box className={generic.pageContainer} display="flex">
          <h2>
            <b>2.3. Función de la vejiga y ciclo miccional</b>
          </h2>
        </Box>
        <Box>
          <h1 style={{ color: 'dodgerblue' }}>
            <b>Inervación</b>
          </h1>
        </Box>
        <Box
          className={generic.pageContainer}
          style={{ display: 'flex', width: innerWidth < 900 ? '80%' : '82%' }}>
          <Box>
            <div>
              El sistema nervioso central controla la función de la vejiga. Los músculos lisos están
              controlados por las dos divisiones diferentes del sistema nervioso autónomo. Las
              entradas parasimpáticas estimulan la contracción del músculo detrusor. Las entradas
              simpáticas estimulan la contracción del esfínter uretral interno. Las neuronas
              eferentes somáticas estimular la contracción del esfínter uretral externo, que es el
              músculo esquelético.
              <br />
              <br />
              Las paredes de la vejiga se estiran a medida que la vejiga se expande durante el
              llenado de la vejiga. Esto lo detectan las neuronas aferentes de la vejiga cuyas
              dendritas sensoriales se encuentran en la pared de la vejiga. Las aferentes de la
              vejiga se proyectan a la médula espinal y a varias regiones del cerebro que son
              responsables de coordinar la salida eferente a la vejiga y la uretra.
              <br />
              <br />
            </div>
          </Box>
          <Box>
            <img
              src={image}
              onClick={handleOpenModal}
              style={{
                width: innerWidth < 900 ? '190%' : '160%',
                marginLeft: innerWidth < 900 ? '8%' : '15%',
                borderStyle: 'ridge',
                borderColor: 'dodgerblue',
              }}
            />
          </Box>
        </Box>
        <Box className={generic.pageContainer}>
          <div>
            Existen dos modos de funcionamiento de la vejiga, que se describen en la tabla anterior:
            almacenamiento de orina o micción.
            <br />
            <br />
            Mientras la vejiga llena, cuando la orina se almacena en ella, se inhibe la entrada
            parasimpática al músculo detrusor y se activan las vías eferentes simpáticas y
            somáticas, lo que hace que los dos esfínteres uretrales se contraigan.
            <br />
            <br />
            La información aferente de la vejiga proporciona una sensación de llenado de la vejiga y
            ganas de orinar.
            <br />
            <br />
            Cuando se da el momento y el lugar apropiados para orinar, los eferentes parasimpáticos
            se activan y provocan la contracción del músculo detrusor. Al mismo tiempo, existe una
            inhibición coordinada de los eferentes simpáticos y los eferentes somáticos para que los
            esfínteres uretrales internos y externos se relajen.
            <br />
            <br />
          </div>
        </Box>
      </Box>
    </>
  )
}
