import generic from '../../../../assets/generic.module.css'
import { Box } from '@mui/material'
import image from './intestino.svg'
import React from 'react'

export const CIS320 = () => {
  return (
    <>
      <Box className={generic.pageContainer} style={{ backgroundColor: 'white', display: 'flex' }}>
        <Box style={{ marginRight: '5%', marginLeft: '1%' }}>
          <Box className={generic.pageContainer} display="flex">
            <h2>
              <b>3.2 Síndrome de resección anterior baja (LARS)</b>
            </h2>
          </Box>
          <Box className={generic.pageContainer}>
            <div>
              La Resección Anterior Baja es una cirugía para extirpar el cáncer de recto. La parte
              del recto que contiene el tumor se extrae y la parte restante del recto se vuelve a
              conectar al colon. El procedimiento quirúrgico salva el esfínter hasta en un 90 % de
              las cirugías y, de este modo, se evita la colostomía permanente.
              <br />
              <br />
              Esto puede hacer que el paciente piense que su función intestinal no se verá afectada
              y también la información disponible en Internet afirma que: "podrá evacuar de la forma
              habitual"
              <br />
              <br />
              Sin embargo, hasta el 80 % de los pacientes experimentarán Síndrome de Resección
              Anterior Baja (LARS). El LARS es una variedad de síntomas que incluyen incontinencia,
              frecuencia, urgencia o sensación de vaciado incompleto. Algunos pacientes pueden
              experimentar uno de los síntomas y, otros, varios o todos los síntomas.
              <br />
              <br />
              El LARS tiene un impacto importante en la calidad de vida. Los mecanismos
              fisiopatológicos del LARS son probablemente multifactoriales: disfunción del esfínter
              anal interno (EAI), disminución de la sensación del canal anal, desaparición del
              reflejo inhibidor rectoanal (RIR), alteración de los reflejos locales entre el ano y
              el neorrecto (el neorrecto es el recto artificial creado por una parte del colon
              después de la resección del recto) y reducción de la capacidad y la distensibilidad
              del reservorio rectal.
              <br />
              <br />
            </div>
          </Box>
          <Box className={generic.pageContainer} display="flex">
            <h4 style={{ color: 'dodgerblue' }}>
              <b>Tratamiento del SRAB</b>
            </h4>
          </Box>

          <Box className={generic.pageContainer} display="flex">
            <div>
              Se ha demostrado que los métodos no invasivos como el biofeedback y los antagonistas
              de los receptores de serotonina mejoran los síntomas, pero si estos no ayudan, se ha
              demostrado que la ITA es una forma eficaz de mejorar la función en pacientes con LARS
              y también de mejorar la calidad de vida.
              <br />
              <br />
              Existen algunas técnicas de reconstrucción quirúrgica diferentes para reducir el LARS.
              Sin embargo, el colon distal y el recto normalmente los controlan el sistema nervioso
              autónomo, parasimpático, simpático y entérico actuando juntos y, durante una resección
              estándar para el cáncer rectal, se seccionan las fibras parasimpáticas y simpáticas.
              Esto deja el control del intestino restante y del neorrecto al sistema nervioso
              entérico, lo que afectará a su funcionalidad. En algunos estudios se ha demostrado que
              la estimulación del nervio sacro reduce los episodios de incontinencia fecal y mejora
              la calidad de vida de los pacientes con LARS.
              <br />
              <br />
            </div>
          </Box>

          <Box className={generic.pageContainer} display="flex">
            <h4 style={{ color: 'dodgerblue' }}>
              <b>Irrigación transanal para el LARS</b>
            </h4>
          </Box>
          <Box className={generic.pageContainer} display="flex">
            <div>
              Existen distintos estudios que respaldan el uso de la ITA para mejorar los síntomas
              del LARS. Se recomienda utilizar un cono rectal en lugar de una sonda rectal para los
              pacientes con LARS para no presionar excesivamente la anastomosis colónica. También se
              recomienda comenzar con un volumen bajo de líquido de irrigación y luego aumentar
              progresivamente el volumen si la respuesta no es adecuada.
              <br />
              <br />
            </div>
          </Box>
        </Box>
      </Box>
    </>
  )
}
