import { StringMap } from 'i18next'
import { PatientGoal } from './PatientGoal'
import { v4 as uuidv4 } from 'uuid'

export interface PatientGoalDTO {
  patientID: string
  goalID: string
  createdAt: Date
  creatorID: string
  achieved: boolean
  title: string
  id: string
  achievedAt: Date | undefined
}

export function emptyPatientGoal(): PatientGoalDTO {
  return {
    id: uuidv4(),
    patientID: '',
    goalID: '',
    createdAt: new Date(),
    creatorID: '',
    achieved: false,
    title: '',
    achievedAt: undefined,
  }
}

export function toModel(p: PatientGoalDTO): PatientGoal {
  return new PatientGoal(p)
}

export function fromModel(p: PatientGoal): PatientGoalDTO {
  return {
    id: p.id,
    patientID: p.patientID,
    goalID: p.goalID,
    createdAt: p.createdAt,
    creatorID: p.creatorID,
    achieved: p.achieved,
    title: p.title,
    achievedAt: p.achievedAt
  }
}
