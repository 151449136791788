import generic from '../../assets/generic.module.css'
import styles from './Professionals.module.css'
import { Box, MenuItem, Select, SelectChangeEvent, TextField, Modal } from '@mui/material'
import { FormCard } from 'components/form-card/FormCard'
import { FormEvent, ChangeEvent, useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { getUserContainer } from '../../container/user-module'
import { IRolesService, IUserService } from 'modules/users'
import { ROLES_SERVICE_KEY, USER_SERVICE_KEY } from '../../modules/users/container'
import { Role } from '../../modules/users/models/Role'
import { AppButton, ButtonTheme } from '../../components/app-button/AppButton'
import { UserDTO, emptyUserDTO } from '../../modules/users/models/User'
import { navigate } from '@reach/router'
import { ROUTE_PROFESSIONALS } from '../../routes/routes-constants'
import { LegalTermsModal } from '../../components/modal/LegalTermsModal'
import Autocomplete from '@mui/material/Autocomplete'
import { communities, provinces } from './communities'

const userContainer = getUserContainer()
const userService = userContainer.get<IUserService>(USER_SERVICE_KEY)
const roleService = userContainer.get<IRolesService>(ROLES_SERVICE_KEY)

export function Editor() {
  const { t } = useTranslation()
  const [professionalData, setProfessionalData] = useState<UserDTO>(emptyUserDTO())
  const [professionalRoles, setProfessionalRoles] = useState<Role[]>()
  const [legalTermsAccepted, setLegalTermsAccepted] = useState<boolean>(false)
  const [modalOpened, setModalOpened] = useState<boolean>(false)

  useEffect(() => {
    roleService.getProfessionals().subscribe((res) => {
      setProfessionalRoles(res)
    })
  }, [])

  const handleInput = (e: ChangeEvent<HTMLInputElement>) => {
    setProfessionalData(Object.assign({ ...professionalData }, { [e.target.name]: e.target.value }))
  }

  const saveProfessional = (e: FormEvent<HTMLFormElement>) => {}

  const handleSave = () => {
    userService.add(professionalData).subscribe((res) => {
      navigate(ROUTE_PROFESSIONALS)
    })
  }

  const handleSelectRole = (e: SelectChangeEvent) => {
    setProfessionalData(Object.assign({ ...professionalData }, { [e.target.name]: e.target.value }))
  }

  const handleConfirm = () => {
    if (legalTermsAccepted) {
      setModalOpened(false)
      legalTermsAccepted && handleSave()
    }
  }

  return (
    <>
      <Box className={generic.pageContainer}>
        <FormCard>
          <form onSubmit={(e) => saveProfessional(e)}>
            <Box className={styles.fieldsContainer}>
              <Box display="flex" flexDirection="row">
                <Box className={styles.textFieldBox}>
                  <h4>{t('name') + ' *'}</h4>
                  <TextField
                    id={'firstName'}
                    key={'firstName'}
                    name="firstName"
                    className={styles.textField}
                    required={true}
                    variant="outlined"
                    size={'small'}
                    onChange={handleInput}
                  />
                </Box>
                <Box className={styles.textFieldBox}>
                  <h4>{t('lastName') + ' *'}</h4>
                  <TextField
                    id={'lastName'}
                    key={'lastName'}
                    name="lastName"
                    className={styles.textField}
                    required={true}
                    variant="outlined"
                    size={'small'}
                    onChange={handleInput}
                  />
                </Box>
              </Box>

              <Box display="flex" flexDirection="row">
                <Box className={styles.textFieldBox}>
                  <h4>{t('phone') + ' *'}</h4>
                  <TextField
                    id={'phone'}
                    key={'phone'}
                    name="phone"
                    className={styles.textField}
                    required={true}
                    variant="outlined"
                    size={'small'}
                    onChange={handleInput}
                  />
                </Box>
                <Box className={styles.textFieldBox}>
                  <h4>{t('email') + ' *'}</h4>
                  <TextField
                    id={'email'}
                    key={'email'}
                    name="email"
                    className={styles.textField}
                    required={true}
                    variant="outlined"
                    size={'small'}
                    onChange={handleInput}
                  />
                </Box>
              </Box>

              <Box display="flex" flexDirection="row">
                <Box className={styles.textFieldBox}>
                  <h4>{t('center')}</h4>
                  <TextField
                    id={'centerID'}
                    key={'centerID'}
                    name="centerID"
                    className={styles.textField}
                    variant="outlined"
                    size={'small'}
                    onChange={handleInput}
                  />
                </Box>
                <Box className={styles.textFieldBox}>
                  <h4>{t('role') + ' *'}</h4>
                  <Select
                    className={styles.selectField}
                    id="roleID"
                    key="roleID"
                    name="roleID"
                    onChange={handleSelectRole}>
                    {professionalRoles?.map((item) => (
                      <MenuItem value={item.id}>{item.role}</MenuItem>
                    ))}
                  </Select>
                </Box>
              </Box>

              <Box display="flex" flexDirection="row">
                <Box className={styles.textFieldBox}>
                  <h4>{t('autonomousCommunity')}*</h4>
                  <Autocomplete
                    disablePortal
                    id="combo-box-demo"
                    options={communities}
                    sx={{ width: 230 }}
                    onChange={(_, b) =>
                      setProfessionalData(
                        Object.assign(
                          { ...professionalData },
                          { autonomousCommunity: b?.label || '' }
                        )
                      )
                    }
                    renderInput={(params) => <TextField {...params} size="small" />}
                  />
                </Box>
                <Box className={styles.textFieldBox} style={{ marginLeft: 20 }}>
                  <h4>{t('province') + ' *'}</h4>
                  <Autocomplete
                    disablePortal
                    id="combo-box-demo"
                    options={provinces}
                    sx={{ width: 230 }}
                    onChange={(_, b) =>
                      setProfessionalData(
                        Object.assign({ ...professionalData }, { province: b?.label || '' })
                      )
                    }
                    renderInput={(params) => <TextField {...params} size="small" />}
                  />
                </Box>
              </Box>
            </Box>
            <Box className={styles.buttonContainer}>
              <AppButton
                theme={ButtonTheme.NewPrimary}
                type={'button'}
                label={t('save')}
                handler={() => setModalOpened(true)}
              />
            </Box>
          </form>
        </FormCard>
        <Modal
          open={modalOpened}
          onClose={() => {
            setLegalTermsAccepted(false)
            setModalOpened(false)
          }}>
          <LegalTermsModal
            handleChangeCheck={() => setLegalTermsAccepted(!legalTermsAccepted)}
            title={t('privacyPoliticProfesionalRegister')}
            warningText={t('professionalRegisterCheck')}
            handleClose={() => {
              setLegalTermsAccepted(false)
              setModalOpened(false)
            }}
            handleSave={handleConfirm}
          />
        </Modal>
      </Box>
    </>
  )
}
