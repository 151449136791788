import { emptyList, ItemList } from '../../../common/models/ItemList'
import { Observable, of } from 'rxjs'
import { Container, IInit } from '../../../common/container/Container'
import { HTTP_CLIENT_KEY, IHTTPClient } from '../../../common/api/HTTPClient'
import { IStatusService } from '../../../common/status/StatusService'
import { STATUS_SERVICE_KEY } from '../../../container/app'
import { Query } from '../../../common/api/Query'
import { PatientContainerConfig } from '../container'
import { catchError, map } from 'rxjs/operators'
import { Diagnostic, DiagnosticQuery } from '../models/Diagnostic'
import { DiagnosticDTO, toModel } from '../models/DiagnosticDTO'

export interface IDiagnosticApi extends IInit {
  getByID(id: string | undefined): Observable<Diagnostic>

  getFilteredList(q: Query<DiagnosticQuery>): Observable<ItemList<Diagnostic>>

  add(e: DiagnosticDTO): Observable<Diagnostic | undefined>

  update(e: DiagnosticDTO): Observable<Diagnostic | undefined>

  delete(id: string | undefined): Observable<boolean>

  getByName(name: string | undefined): Observable<Diagnostic>
}

export class DiagnosticApi implements IDiagnosticApi {
  private _container!: Container
  private _httpClient!: IHTTPClient
  private _url!: string
  private _statusService!: IStatusService

  init(c: Container) {
    this._container = c
    this._httpClient = this._container.get<IHTTPClient>(HTTP_CLIENT_KEY)
    this._statusService = this._container.get<IStatusService>(STATUS_SERVICE_KEY)
    this._url = (this._container.config as PatientContainerConfig).moduleFullUrl
  }

  add(e: DiagnosticDTO): Observable<Diagnostic | undefined> {
    return this._httpClient
      .post<Diagnostic>({ url: this._url, body: e })
      .pipe(
        map<DiagnosticDTO, Diagnostic>((d) => toModel(d)),
        catchError((err) => {
          this._statusService.sendStatus({ variant: 'error', error: err })
          return of(undefined)
        })
      )
  }

  delete(id: string | undefined): Observable<boolean> {
    return this._httpClient.delete({ url: this._url + '/' + id }).pipe(
      catchError((err) => {
        this._statusService.sendStatus({ variant: 'error', error: err })
        return of(false)
      })
    )
  }

  getByID(id: string | undefined): Observable<Diagnostic> {
    return this._httpClient
      .get<Diagnostic>({ url: `${this._url}/diagnostic/${id}` })
      .pipe(
        map<DiagnosticDTO, Diagnostic>((d) => toModel(d))
      )
  }

  getFilteredList(q: Query<DiagnosticQuery>): Observable<ItemList<Diagnostic>> {
    return this._httpClient
      .post<ItemList<Diagnostic>>({ url: this._url + '/diagnosticsList', body: q })
      .pipe(
        map<ItemList<DiagnosticDTO>, ItemList<Diagnostic>>((dto) => {
          const itemList = emptyList<Diagnostic>()
          itemList.count = dto.count
          itemList.items = dto.items.map((d) => toModel(d))
          return itemList
        }),
        catchError((err) => {
          this._statusService.sendStatus({ variant: 'error', error: err })
          return of(emptyList<Diagnostic>())
        })
      )
  }

  update(e: DiagnosticDTO): Observable<Diagnostic | undefined> {
    return this._httpClient
      .put<Diagnostic>({ url: this._url, body: e })
      .pipe(
        map<DiagnosticDTO, Diagnostic>((d) => toModel(d)),
        catchError((err) => {
          this._statusService.sendStatus({ variant: 'error', error: err })
          return of(undefined)
        })
      )
  }

  getByName(name: string | undefined): Observable<Diagnostic> {
    return this._httpClient
      .post<Diagnostic>({ url: `${this._url}/diagnostic/${name}` })
      .pipe(
        map<DiagnosticDTO, Diagnostic>((d) => toModel(d))
      )
  }
}
