import { Container } from '../../common/container/Container'

export type QuestionnaireContainerConfig = {
  moduleFullUrl: string
}

export type QuestionnaireProps = {
  parentContainer: Container
  config: QuestionnaireContainerConfig
}

export const QUESTIONNAIRES_MODULE = Symbol('QUESTIONNAIRES_MODULE')
export const QUESTIONNAIRE_API_KEY = Symbol('QUESTIONNAIRE_API_KEY')
export const QUESTIONNAIRE_SERVICE_KEY = Symbol('QUESTIONNAIRE_SERVICE_KEY')
export const QUESTIONNAIRE_PATIENT_ANSWER_API_KEY = Symbol('QUESTIONNAIRE_PATIENT_ANSWER_API_KEY')
export const QUESTIONNAIRE_PATIENT_ANSWER_SERVICE_KEY = Symbol(
  'QUESTIONNAIRE_PATIENT_ANSWER_SERVICE_KEY'
)
