import React from 'react'
import { makeStyles, TablePagination } from '@material-ui/core'
import { Pager } from './types'
import { useTranslation } from 'react-i18next'

const useStyles = makeStyles({
  root: {
    fontFamily: 'Open-sans, sans-serif',
    border: '0',
    fontSize: '13px',
    color: '#515151',
    fontWeight: 'normal',
    '& .MuiSelect-icon': {
      color: 'rgb(26, 150, 255)',
    },
  },
  caption: {
    fontFamily: 'Open-sans, sans-serif',
  },
  toolbar: {
    '& > p:nth-of-type(2)': {
      fontFamily: 'Open-sans, sans-serif',
    },
  },
})

const rowsPerPageOptions = [2, 10, 20, 50, 100]

export function Pagination(props: Pager) {
  const { t } = useTranslation()
  const classes = useStyles()

  const [rowsPerPage, setRowsPerPage] = React.useState<number>(
    props.rowsPerPage || rowsPerPageOptions[0]
  )

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>): void => {
    if (props.handleChangeRowsPerPage) {
      setRowsPerPage(parseInt(event.target.value))
      props.handleChangeRowsPerPage(event)
    }
  }

  return (
    <TablePagination
      labelDisplayedRows={({ from, to, count }) => {
        return ''
      }}
      nextIconButtonText={t('Página siguiente')}
      backIconButtonText={t('Página anterior')}
      labelRowsPerPage={t('Resultados por página')}
      count={props.count}
      onPageChange={props.handleChangePage}
      page={props.page}
      rowsPerPage={rowsPerPage}
      onRowsPerPageChange={handleChangeRowsPerPage}
      rowsPerPageOptions={props.rowsPerPage ? rowsPerPageOptions : []}
      style={{ fontFamily: 'Open-sans, sans-serif' }}
      SelectProps={{
        style: {
          border: '1px solid rgb(26, 150, 255)',
          color: 'rgb(26, 150, 255)',
          borderRadius: '3px',
          fontFamily: 'Open-sans, sans-serif',
        },
      }}
      classes={{
        root: classes.root,
        caption: classes.caption,
        toolbar: classes.toolbar,
      }}
      backIconButtonProps={{
        style: {
          color: props.page === 0 ? '#b5b8c4' : '#7cb5ec',
          //border: '1px solid #D1D1D1',
          borderRadius: '3px',
          width: '30px',
          height: '30px',
          fontFamily: 'Open-sans, sans-serif',
        },
      }}
      nextIconButtonProps={{
        style: {
          color:
            props.count - (props.page + 1) * rowsPerPage <= 0 ? '#b5b8c4' : 'rgb(26, 150, 255)',
          //border: '1px solid #D1D1D1',
          borderRadius: '3px',
          width: '30px',
          height: '30px',
          fontFamily: 'Open-sans, sans-serif',
        },
      }}
    />
  )
}
