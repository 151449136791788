import { PriorityLevel } from '../enums/PriorityLevel'
import { DeliveryStatus } from '../enums/DeliveryStatus'
import { TransportType } from '../../../common/enums/TransportType'

export type NotificationParams = {
  id?: string
  priorityLevel?: PriorityLevel
  recipientID: string
  deliveryStatus: DeliveryStatus
  transportType: TransportType
  title: number
  description: string
  createdAt: Date
  sendAt: Date
  readed: boolean
  idObject: string
}

export class Notification {
  private readonly _id: string | undefined
  private readonly _recipientID: string
  private readonly _transportType: TransportType
  private readonly _description: string
  private readonly _title: number
  private readonly _createdAt: Date
  private readonly _sendAt: Date
  private readonly _readed: boolean
  private readonly _idObject: string

  private _priorityLevel: PriorityLevel
  private _deliveryStatus: DeliveryStatus

  constructor(p: NotificationParams) {
    this._id = p.id
    this._priorityLevel = p.priorityLevel || PriorityLevel.Normal
    this._title = p.title
    this._description = p.description
    this._recipientID = p.recipientID
    this._deliveryStatus = p.deliveryStatus
    this._transportType = p.transportType
    this._createdAt = new Date()
    this._sendAt = p.sendAt
    this._readed = p.readed
    this._idObject = p.idObject
  }

  get id(): string | undefined {
    return this._id
  }

  get recipientID(): string {
    return this._recipientID
  }

  get idObject(): string {
    return this._idObject
  }

  get title(): number {
    return this._title
  }

  get description(): string {
    return this._description
  }

  get priorityLevel(): PriorityLevel {
    return this._priorityLevel
  }

  set priorityLevel(value: PriorityLevel) {
    this._priorityLevel = value
  }

  get deliveryStatus(): DeliveryStatus {
    return this._deliveryStatus
  }

  set deliveryStatus(value: DeliveryStatus) {
    this._deliveryStatus = value
  }

  get transportType(): TransportType {
    return this._transportType
  }

  get createdAt(): Date {
    return this._createdAt
  }

  get sendAt(): Date {
    return this._sendAt
  }

  get readed(): boolean {
    return this._readed
  }
}

export interface NotificationQuery {
  recipientID: string
  title: number
}
