import generic from '../../../../assets/generic.module.css'
import { Box } from '@mui/material'
import React from 'react'

export const CVS420 = () => {
  return (
    <>
      <Box className={generic.pageContainer} style={{ backgroundColor: 'white' }}>
        <Box className={generic.pageContainer} display="flex">
          <h2>
            <b>4.2 Indicaciones para el cateterismo intermitente</b>
          </h2>
        </Box>
        <Box className={generic.pageContainer}>
          <div>
            El CI solo debe realizarse en presencia de un volumen residual de orina y de síntomas o
            complicaciones que surjan como resultado de dicho volumen residual. El vaciado
            incompleto de la vejiga por lo general se debe a una de tres categorías de disfunción
            del tracto urinario inferior:
            <br />
            <br />
            &nbsp;&nbsp;&nbsp;&nbsp;•&nbsp;&nbsp;&nbsp;&nbsp; <b>Disfunción del detrusor:</b>un
            detrusor hipoactivo o atónico que no se contrae con la duración o magnitud suficiente
            para vaciar por completo la vejiga.
            <br />
            <br />
            &nbsp;&nbsp;&nbsp;&nbsp;•&nbsp;&nbsp;&nbsp;&nbsp;{' '}
            <b>Obstrucción de la salida de la vejiga:</b>las causas más habituales son hiperplasia
            de próstata, cuello vesical alto o estenosis uretral (en mujeres). En los hombres, las
            estenosis uretrales pueden obstruir el flujo de salida de la vejiga y a menudo surgen
            después de la instrumentación quirúrgica, como la prostatectomía radical.
            <br />
            <br />
            &nbsp;&nbsp;&nbsp;&nbsp;•&nbsp;&nbsp;&nbsp;&nbsp; <b>Después de la cirugía:</b>la
            cirugía para restaurar la continencia puede perjudicar el vaciado de la vejiga y esta
            técnica puede producir retención urinaria aguda. Los procedimientos para reducir la
            incontinencia urinaria de esfuerzo introducen un grado de obstrucción en la salida de la
            vejiga, mientras que los procedimientos para resolver la incontinencia urinaria de
            urgencia tienen como objetivo reducir la presión intravesical y aumentar la capacidad
            funcional de la vejiga. Ambos pueden afectar a la capacidad de la vejiga para vaciarse,
            lo que posiblemente genere un volumen residual.
            <br />
            <br />
            <br />
            <br />
          </div>
        </Box>
      </Box>
    </>
  )
}
