import { Box, TextField, Typography } from '@mui/material'
import styles from '../user-profile/Editor.module.css'
import style from '../patients/Patients.module.css'
import React, { ChangeEvent, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { NoteDTO } from 'modules/patients/models/NoteDTO'
import { getUserContainer } from 'container/user-module'
import { ILoggedUserService } from 'modules/users/services/LoggedUserService'
import { LOGGED_USER_SERVICE_KEY } from 'modules/users'
import { KeyboardDatePicker } from '@material-ui/pickers'
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date'

interface NotesViewProps {
  note: NoteDTO
  handleNoteInput: (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void
  handleNoteDate: (e: MaterialUiPickersDate) => void
}

const loggedUserService = getUserContainer().get<ILoggedUserService>(LOGGED_USER_SERVICE_KEY)

export function PatientDetailNotes(p: NotesViewProps) {
  const { t } = useTranslation()
  const [note, setNote] = useState<string>(p.note.content)
  const [noteDate, setNoteDate] = useState<Date>(p.note.date)
  const { innerWidth } = window

  return (
    <>
      <Box className={style.generalContainer}>
        <Box>
          <h3 style={{ marginBottom: 0, color: '#000' }}>{t('internNotes')}</h3>
        </Box>
        <Box display="flex" flexDirection="row">
          <Box className={styles.textFieldBox} width={'100%'}>
            <Box>
              <h4 style={{ marginBottom: 0, color: '#000' }}>{t('noteDate')}</h4>
              <KeyboardDatePicker
                style={{ width: '233px' }}
                InputProps={{ style: { fontFamily: 'Open-sans, sans-serif' } }}
                key={'noteDate'}
                id={'noteDate'}
                autoOk
                variant="inline"
                inputVariant={'outlined'}
                label={''}
                format="DD/MM/YYYY"
                value={noteDate}
                onChange={(e) => {
                  setNoteDate((e as unknown) as Date)
                  p.handleNoteDate(e)
                }}
                size={'small'}
              />
            </Box>
            <Box
              display="flex"
              flexDirection="row"
              justifyContent={'space-between'}
              style={{ marginTop: 10 }}>
              <h4 style={{ marginBottom: 0, color: '#000' }}>{t('notes')}</h4>
              {loggedUserService.get()?.language == 1 ? (
                <Typography
                  style={{ fontSize: 20, marginBottom: 0, fontFamily: 'Open-sans, sans-serif' }}>
                  {t('lastUpdate') +
                    ': ' +
                    new Date(p.note.date).getDate() +
                    '/' +
                    (new Date(p.note.date).getMonth() + 1) +
                    '/' +
                    new Date(p.note.date).getFullYear().toString().substring(2, 4)}
                </Typography>
              ) : (
                <Typography style={{ fontSize: 20 }}>
                  {t('lastUpdate') + ': ' + new Date(p.note.date).toLocaleDateString()}
                </Typography>
              )}
            </Box>
            <TextField
              id={'content'}
              key={'content'}
              name="content"
              multiline={true}
              rows={6}
              fullWidth={true}
              //className={innerWidth < 900 ? styles.doubleRowNotesTablet : styles.doubleRowNotes}
              value={note}
              type="name"
              required={true}
              variant={'outlined'}
              size={'small'}
              onChange={(e) => {
                setNote(e.target.value)
                p.handleNoteInput(e)
              }}
            />
          </Box>
        </Box>
      </Box>
    </>
  )
}
