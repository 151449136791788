import { Observable } from 'rxjs'
import { Container, IInit } from '../../../common/container/Container'
import { Query } from '../../../common/api/Query'
import { IStatusService } from '../../../common/status/StatusService'
import { STATUS_SERVICE_KEY } from '../../../container/app'
import { ItemList } from '../../../common/models/ItemList'
import { PatientDiagnostic } from '../models/PatientDiagnostic'
import { PatientDiagnosticDTO } from '../models/PatientDiagnosticDTO'
import { IPatientDiagnosticApi } from '../api/PatientDiagnosticApi'
import { Diagnostic } from '../models/Diagnostic'
import { PatientFile } from '../models/PatientFile'
import { PatientFileDTO } from '../models/PatientFileDTO'
import { FileDTO } from '../../files/models/FileDTO'
import { File } from '../../files/models/File'
import { IPatientFileApi } from '../api/PatientFileApi'

type Props = {
  apiKey: symbol
}

export interface IPatientFileService extends IInit {
  getByID(id: string | null): Observable<PatientFile | undefined>

  getFilteredItems(q: Query<PatientFile>): Observable<ItemList<PatientFile>>

  add(e: PatientFileDTO): Observable<PatientFile | undefined>

  update(e: PatientFileDTO): Observable<PatientFile | undefined>

  delete(id: string): Observable<boolean>

  getPatientFilesByPatientID(patientID: string): Observable<ItemList<PatientFile>>

  getPatientFile(patientID: string): Observable<File | undefined>
}

export class PatientFileService implements IPatientFileApi {
  private readonly _apiKey: symbol
  private _container!: Container
  private _api!: IPatientFileApi
  private _statusService!: IStatusService

  constructor(p: Props) {
    this._apiKey = p.apiKey
  }

  init(c: Container) {
    this._container = c
    this._api = this._container.get<IPatientFileApi>(this._apiKey)
    this._statusService = this._container.get<IStatusService>(STATUS_SERVICE_KEY)
  }

  getByID(id: string | null): Observable<PatientFile | undefined> {
    return this._api.getByID(id)
  }

  getFilteredItems(q: Query<PatientFile>): Observable<ItemList<PatientFile>> {
    return this._api.getFilteredItems(q).pipe()
  }

  add(e: PatientFileDTO): Observable<PatientFile | undefined> {
    return this._api.add(e)
  }

  update(e: PatientFileDTO): Observable<PatientFile | undefined> {
    return this._api.update(e)
  }

  delete(id: string): Observable<boolean> {
    return this._api.delete(id)
  }

  getPatientFilesByPatientID(patientID: string): Observable<ItemList<PatientFile>> {
    return this._api.getPatientFilesByPatientID(patientID)
  }

  getPatientFile(patientID: string): Observable<File | undefined> {
    return this._api.getPatientFile(patientID)
  }
}
