import React, { useEffect, useState } from 'react'
import { Educational as EducationalModel } from '../../modules/educational/models/Educational'
import { PATIENTLESSON_SERVICE_KEY } from '../../modules/educational'
import { getEducationalContainer } from '../../container/educational-module'
import { Box, Button, Checkbox, Grid, List, ListItem, Paper } from '@mui/material'
import { useTranslation } from 'react-i18next'
import {
  Avatar,
  IconButton,
  ListItemIcon,
  Typography,
  Modal,
  Fade,
  Backdrop,
} from '@material-ui/core'
import learningIcon from '../../assets/higea/icon-aprendizaje.svg'
import moduleActiveIcon from '../../assets/higea/Group-5.svg'
import { getPatientContainer } from '../../container/patient-module'
import { PatientService } from '../../modules/patients/services/PatientService'
import { PATIENT_SERVICE_KEY } from '../../modules/patients'
import { Lesson } from '../../modules/educational/models/Lesson'
import viewIcon from '../../assets/higea/View-icon-Copy-2.svg'
import intestinal11 from '../../assets/images/lessonsMobile/intestinal/1-1intestinal.jpeg'
import intestinal12 from '../../assets/images/lessonsMobile/intestinal/1-2intestinal.jpeg'
import intestinal13 from '../../assets/images/lessonsMobile/intestinal/1-3intestinal.png'
import intestinal14 from '../../assets/images/lessonsMobile/intestinal/1-4intestinal.png'
import intestinalejercicio1 from '../../assets/images/lessonsMobile/intestinal/1ejercicio1intestinal.jpeg'
import intestinal21 from '../../assets/images/lessonsMobile/intestinal/2-1Intestinal.png'
import intestinal22 from '../../assets/images/lessonsMobile/intestinal/2-2intestinal.png'
import intestinal23 from '../../assets/images/lessonsMobile/intestinal/2-3intestinalsonda.png'
import intestinal234 from '../../assets/images/lessonsMobile/intestinal/2-3intestinalsonda2.png'
import intestinal24 from '../../assets/images/lessonsMobile/intestinal/2-4intestinal.png'
import intestinal25 from '../../assets/images/lessonsMobile/intestinal/2-5intestinal.jpeg'
import intestinal26 from '../../assets/images/lessonsMobile/intestinal/2-6intestinal.jpeg'
import intestinalejercicio2 from '../../assets/images/lessonsMobile/intestinal/2ejercicio1intestinal.jpeg'
import intestinal31 from '../../assets/images/lessonsMobile/intestinal/3-1intestinal.jpeg'
import intestinal32 from '../../assets/images/lessonsMobile/intestinal/3-2intestinal.jpeg'
import intestinal41 from '../../assets/images/lessonsMobile/intestinal/4-1intestinal.png'
import vesical11 from '../../assets/images/lessonsMobile/vesical/1-1vesical.jpeg'
import vesical12 from '../../assets/images/lessonsMobile/vesical/1-2vesical.jpeg'
import vesical13 from '../../assets/images/lessonsMobile/vesical/1-3vesical.png'
import vesical14 from '../../assets/images/lessonsMobile/vesical/1-4vesical.png'
import vesical15 from '../../assets/images/lessonsMobile/vesical/1-5vesical.png'
import vesicalejercicio1 from '../../assets/images/lessonsMobile/vesical/1ejercicio1vesical.png'
import vesical21 from '../../assets/images/lessonsMobile/vesical/2-1vesical.jpeg'
import vesical22 from '../../assets/images/lessonsMobile/vesical/2-2vesical.png'
import vesical23 from '../../assets/images/lessonsMobile/vesical/2-3vesical.png'
import vesical23Video from '../../assets/images/lessonsMobile/vesical/2-3vesicalVideo.jpeg'
import vesicalejercicio21 from '../../assets/images/lessonsMobile/vesical/2ejercicio1vesical.png'
import vesicalejercicio22 from '../../assets/images/lessonsMobile/vesical/2ejercicio2vesical.png'
import vesicalejercicio23 from '../../assets/images/lessonsMobile/vesical/2ejercicio3vesical.jpeg'
import vesical31 from '../../assets/images/lessonsMobile/vesical/3-1vesical.png'
import checkIcon from '../../assets/higea/ico-hecho.svg'
import CloseIcon from '@material-ui/icons/Close'
import { PatientLessonService } from '../../modules/educational/services/PatientLessonService'
import { v4 as uuidv4 } from 'uuid'
import { PatientLesson, PatientLessonQuery } from '../../modules/educational/models/PatientLesson'
import { Patient } from '../../modules/patients/models/Patient'
import { AppButton } from 'components/app-button/AppButton'
import { navigate } from '@reach/router'
import { ROUTE_EDUCATIONAL, ROUTE_PATIENTS_ID } from 'routes/routes-constants'
import { ButtonTheme } from '../../components/app-button/AppButton'
import style from './Educational.module.css'
import { Query, QueryParam } from 'common/api/Query'
import DoneOutlineIcon from '@mui/icons-material/DoneOutline'
import educationalIcon from '../../assets/higea/Ico-asignar-nuevo-aprendizaje.svg'

type PatientDataProps = {
  id: string | undefined
  userID: string | undefined
}

const patientService = getPatientContainer().get<PatientService>(PATIENT_SERVICE_KEY)
const patientLessonService =
  getEducationalContainer().get<PatientLessonService>(PATIENTLESSON_SERVICE_KEY)

export const Educational = (props: PatientDataProps, userID: string | undefined) => {
  const { t } = useTranslation()
  const [isLoading, setIsLoading] = useState<boolean>(true)
  const [open, setOpen] = useState<boolean>(false)
  const [checkedLesson, setCheckedLesson] = useState<Lesson[]>([])
  const [unCheckedLesson, setUncheckedLesson] = useState<Lesson[]>([])
  const [patientLessons, setPatientLessons] = useState<PatientLesson[]>([])
  const [patient, setPatient] = useState<Patient>()
  const [pathToShow, setPathToShow] = useState<string>('')
  const [modulesAndLessonsAssigned, setModulesAndLessonsAssigned] = useState<EducationalModel[]>([])
  const [modulesAndLessonsAvailable, setModulesAndLessonsAvailable] = useState<EducationalModel[]>(
    []
  )
  const { innerWidth } = window
  const [patientLessons2, setPatientLessons2] = useState<PatientLesson[]>([])

  const handleToggleDeleteModule = (em: EducationalModel) => {
    let input = document.getElementById(`checkbox2-${em.name}`) as HTMLInputElement | null
    if (input) {
      if (input.checked) {
        let aux: Lesson[] = [...unCheckedLesson]
        aux.push(...em.lessons)
        setUncheckedLesson(aux)
      } else {
        let aux: Lesson[] = []
        unCheckedLesson.forEach((checked) => {
          let includes = em.lessons.filter((l) => l.id == checked.id)
          if (includes.length == 0) {
            aux.push(checked)
          }
        })
        setUncheckedLesson(aux)
      }
    }
  }

  const addModule = (em: EducationalModel) => {
    let input = document.getElementById(`checkbox-${em.name}`) as HTMLInputElement | null
    if (input) {
      if (input.checked) {
        let aux: Lesson[] = [...checkedLesson]
        aux.push(...em.lessons)
        setCheckedLesson(aux)
      } else {
        let aux: Lesson[] = []
        checkedLesson.forEach((checked) => {
          let includes = em.lessons.filter((l) => l.id == checked.id)
          if (includes.length == 0) {
            aux.push(checked)
          }
        })
        setCheckedLesson(aux)
      }
    }
  }

  const addPatientLessons = () => {
    if (checkedLesson.length <= 0) return
    checkedLesson.forEach((lesson, i) => {
      patientLessonService
        .add({
          id: uuidv4(),
          patientID: props.id || '',
          lessonID: lesson.id,
          creatorID: props.userID || '',
          started: false,
          finished: false,
          createdAt: new Date(),
          index: lesson.index,
        })
        .subscribe(() => {
          if (i == checkedLesson.length - 1) {
            setIsLoading(true)
          }
        })
    })
    setCheckedLesson([])
  }

  const deletePatientLessons = () => {
    if (unCheckedLesson.length <= 0) return
    patientLessons.forEach((pl, i) => {
      unCheckedLesson.forEach((lesson) => {
        let plID = pl.lessonID
        let lID = lesson.id
        if (plID === lID) {
          patientLessonService.delete(pl.id).subscribe(() => {
            setIsLoading(true)
          })
        }
      })
    })
    setUncheckedLesson([])
  }

  const handleToggleAdd = (lesson: Lesson) => () => {
    const currentIndex = checkedLesson.indexOf(lesson)
    const newChecked = [...checkedLesson]

    if (currentIndex === -1) {
      newChecked.push(lesson)
    } else {
      newChecked.splice(currentIndex, 1)
    }
    setCheckedLesson(newChecked)
  }

  const handleToggleDelete = (lesson: Lesson) => () => {
    const currentIndex = unCheckedLesson.indexOf(lesson)
    const newChecked = [...unCheckedLesson]

    if (currentIndex === -1) {
      newChecked.push(lesson)
    } else {
      newChecked.splice(currentIndex, 1)
    }
    setUncheckedLesson(newChecked)
  }

  const handleOpen = (l: Lesson) => () => {
    setPathToShow(l.pathImage)
    setOpen(true)
  }

  const handleClose = () => setOpen(false)

  useEffect(() => {
    patientService.getByID(props.id).subscribe((res) => {
      setPatient(res)
    })
  }, [])

  useEffect(() => {
    getAllAssigned()
    const eduAux: EducationalModel[] = []

    patientLessonService
      .getFilteredItems(
        new Query({
          query: [new QueryParam<PatientLessonQuery>('patientID', props.id || '')],
        })
      )
      .subscribe((res) => {
        setPatientLessons2(res.items.filter((i) => i.patientID == props.id))
      })

    patientLessonService
      .getLessonsAndModulesAssignedByIDPatient(props.id || '')
      .subscribe((res) => {
        res.items.forEach((module) => {
          eduAux.push(module)
        })

        setModulesAndLessonsAssigned(eduAux)
      })
  }, [modulesAndLessonsAvailable, isLoading])

  useEffect(() => {
    if (patient && isLoading) {
      const eduAuxA: EducationalModel[] = []
      patientLessonService
        .getLessonsAndModulesAvailableByIDPatient(props.id || '')
        .subscribe((res) => {
          let items = []
          if (patient?.gender == 1) {
            items = res.items.filter((i) => i.gender == 'Both' || i.gender == 'Male')
          } else {
            items = res.items.filter((i) => i.gender == 'Both' || i.gender == 'Female')
          }

          items.forEach((item) => {
            eduAuxA.push(item)
          })

          setModulesAndLessonsAvailable(eduAuxA)
        })
      setIsLoading(!isLoading)
    }
  }, [isLoading, patient])

  useEffect(() => {
    const auxArr: PatientLesson[] = []
    patientLessonService.getPatientLessonsByPatientID(props.id || '').subscribe((res) => {
      res.items.forEach((patientLesson) => {
        auxArr.push(patientLesson)
      })
      setPatientLessons(auxArr)
    })
  }, [isLoading])

  const eduAux: EducationalModel[] = []
  const getAllAssigned = () => {
    patientLessonService
      .getLessonsAndModulesAssignedByIDPatient(props.id || '')
      .subscribe((res) => {
        res.items.forEach((module) => {
          eduAux.push(module)
        })
      })
    setModulesAndLessonsAssigned(eduAux)
  }

  const handlerAddEducational = () => navigate(ROUTE_EDUCATIONAL.replace(':id', `${props.id}`))

  const handleImage = (path: string) => {
    switch (path) {
      case 'intestinal11':
        return intestinal11
      case 'intestinal12':
        return intestinal12
      case 'intestinal13':
        return intestinal13
      case 'intestinal14':
        return intestinal14
      case 'intestinalejercicio1':
        return intestinalejercicio1
      case 'intestinal21':
        return intestinal21
      case 'intestinal22':
        return intestinal22
      case 'intestinal23':
        return intestinal23
      case 'intestinal234':
        return intestinal234
      case 'intestinal24':
        return intestinal24
      case 'intestinal25':
        return intestinal25
      case 'intestinal26':
        return intestinal26
      case 'intestinalejercicio2':
        return intestinalejercicio2
      case 'intestinal31':
        return intestinal31
      case 'intestinal32':
        return intestinal32
      case 'intestinal41':
        return intestinal41
      case 'vesical11':
        return vesical11
      case 'vesical12':
        return vesical12
      case 'vesical13':
        return vesical13
      case 'vesical14':
        return vesical14
      case 'vesical15':
        return vesical15
      case 'vesicalejercicio1':
        return vesicalejercicio1
      case 'vesical21':
        return vesical21
      case 'vesical22':
        return vesical22
      case 'vesical23':
        return vesical23
      case 'vesical23Video':
        return vesical23Video
      case 'vesicalejercicio21':
        return vesicalejercicio21
      case 'vesicalejercicio22':
        return vesicalejercicio22
      case 'vesicalejercicio23':
        return vesicalejercicio23
      case 'vesical31':
        return vesical31
      default:
        return ''
    }
  }
  const moduleListAvailable = (items: EducationalModel[]) => (
    <Paper
      sx={{
        height: window.innerWidth > 900 ? 850 : 550,
        marginBottom: 4,
        overflow: 'auto',
        borderRadius: '10px',
        overflowX: 'hidden',
      }}
    >
      <List dense component="div" role="list">
        {items.map((i) => {
          return (
            <List key={i.id + uuidv4() + 'List1' || ''}>
              <Box
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'flex-end',
                  marginRight: 10,
                  fontFamily: 'Open-sans, sans-serif',
                }}
              >
                {/*<input
                    type="checkbox"
                    id={`checkbox-${i.name}`}
                    style={{ zoom: 1.8, marginLeft: '2%', borderColor:'#c1c1c1 !important' }}
                    onChange={(e) => addModule(i)}
                />*/}
                <Checkbox
                  id={`checkbox-${i.name}`}
                  sx={{ color: '#c1c1c1' }}
                  onChange={(e) => addModule(i)}
                />
                <p style={{ color: '#000', fontFamily: 'Open-sans, sans-serif' }}>
                  {t('checkAll')}
                </p>
              </Box>
              <List key={i.id + uuidv4() + 'List2' || ''} role="listitem">
                <Grid item>
                  <ListItem
                    key={i.id + uuidv4() + 'ListItem1' || ''}
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'flex-start',
                      marginLeft: window.innerWidth > 900 ? '30px' : '10px',
                      fontFamily: 'Open-sans, sans-serif',
                    }}
                  >
                    <ListItemIcon>
                      <Avatar
                        src={moduleActiveIcon}
                        style={{
                          width: window.innerWidth > 900 ? 65 : 45,
                          height: window.innerWidth > 900 ? 65 : 45,
                        }}
                      />
                    </ListItemIcon>
                    <Typography
                      style={{
                        marginLeft: window.innerWidth > 900 ? 10 : 0,
                        fontWeight: 'bold',
                        color: '#000',
                        fontSize: window.innerWidth > 900 ? '16px' : '14px',
                        fontFamily: 'Open-sans, sans-serif',
                      }}
                      variant="h6"
                    >
                      {i.name}
                    </Typography>
                  </ListItem>
                </Grid>
                <Grid>
                  {i.lessons.map((l) => (
                    <ListItem
                      key={l.id + uuidv4() + 'ListItem2' || ''}
                      style={{
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        alignItems: 'center',
                        fontFamily: 'Open-sans, sans-serif',
                      }}
                    >
                      <Box
                        style={
                          checkedLesson.includes(l)
                            ? {
                                backgroundColor: '#d6eaff',
                                borderColor: '#d6eaff',
                                borderStyle: 'solid',
                                borderWidth: '2px',
                                borderRadius: '10px',
                                width: window.innerWidth > 900 ? 400 : 260,
                                padding: '10px',
                                fontFamily: 'Open-sans, sans-serif',
                              }
                            : {
                                borderColor: '#c1c1c1',
                                borderStyle: 'solid',
                                borderWidth: '2px',
                                borderRadius: '10px',
                                width: window.innerWidth > 900 ? 400 : 260,
                                padding: '10px',
                                fontFamily: 'Open-sans, sans-serif',
                              }
                        }
                      >
                        <Box
                          display={'flex'}
                          justifyContent={'space-between'}
                          alignItems={'center'}
                          style={{ cursor: 'pointer' }}
                        >
                          <ListItem
                            key={l.id + uuidv4() + 'ListItem3'}
                            id={'idLesson'}
                            onClick={handleToggleAdd(l)}
                          >
                            <Typography
                              style={{
                                fontSize: window.innerWidth > 900 ? '' : '12px',
                                color: '#000',
                                fontFamily: 'Open-sans, sans-serif',
                              }}
                              key={l.id + uuidv4() + 'ListItem4'}
                            >{`${l.title}`}</Typography>
                          </ListItem>
                          <IconButton
                            key={l.id + uuidv4() + 'IconButton'}
                            id={'idLesson'}
                            onClick={handleOpen(l)}
                          >
                            <Avatar
                              style={{ width: 'auto', height: 'auto' }}
                              imgProps={{ style: { width: 25, height: 15 } }}
                              src={viewIcon}
                            />
                          </IconButton>
                        </Box>
                      </Box>
                    </ListItem>
                  ))}
                </Grid>
              </List>
              <Modal
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  fontFamily: 'Open-sans, sans-serif',
                }}
                open={open}
                onClose={handleClose}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                  timeout: 500,
                }}
              >
                <Fade
                  in={open}
                  timeout={300}
                  style={{
                    height: '100%',
                    width: '100%',
                    display: 'flex',
                    justifyContent: 'center',
                    marginTop: '10%',
                  }}
                >
                  <div>
                    <img
                      src={handleImage(pathToShow)}
                      alt="asd"
                      style={{ maxHeight: '85%', maxWidth: '85%' }}
                    />
                    <CloseIcon
                      color="primary"
                      style={{
                        marginLeft: '-0.7%',
                        marginTop: '-0.6%',
                        backgroundColor: 'grey',
                        color: 'white',
                        borderRadius: 12,
                        cursor: 'pointer',
                      }}
                      onClick={() => setOpen(false)}
                    />
                  </div>
                </Fade>
              </Modal>
            </List>
          )
        })}
        <ListItem />
      </List>
    </Paper>
  )

  const moduleListAssigned = (items: EducationalModel[]) => (
    <Paper
      sx={{
        height: window.innerWidth > 900 ? 850 : 550,
        overflow: 'scroll',
        marginBottom: 4,
        borderRadius: '10px',
        overflowX: 'hidden',
      }}
    >
      <List dense component="div" role="list">
        {items.map((i) => {
          return (
            <List key={i.id + uuidv4() + 'List6' || ''}>
              <Box
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'flex-end',
                  marginRight: 10,
                  fontFamily: 'Open-sans, sans-serif',
                }}
              >
                {/*<input
                    type="checkbox"
                    id={`checkbox-${i.name}`}
                    style={{ zoom: 1.8, marginLeft: '2%', borderColor:'#c1c1c1 !important' }}
                    onChange={(e) => addModule(i)}
                />*/}
                <Checkbox
                  id={`checkbox2-${i.name}`}
                  sx={{ color: '#c1c1c1' }}
                  onChange={(e) => {
                    handleToggleDeleteModule(i)
                  }}
                />
                <p style={{ color: '#000', fontFamily: 'Open-sans, sans-serif' }}>
                  {t('checkAll')}
                </p>
              </Box>
              <List key={i.id + uuidv4() + 'List7' || ''} role="listitem">
                <Grid item>
                  <ListItem
                    key={i.id + uuidv4() + 'List8' || ''}
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'flex-start',
                      marginLeft: window.innerWidth > 900 ? '30px' : '10px',
                      fontFamily: 'Open-sans, sans-serif',
                    }}
                  >
                    <ListItemIcon>
                      <Avatar
                        src={moduleActiveIcon}
                        style={{
                          width: window.innerWidth > 900 ? 65 : 45,
                          height: window.innerWidth > 900 ? 65 : 45,
                        }}
                      />
                    </ListItemIcon>
                    <Typography
                      style={{
                        marginLeft: window.innerWidth > 900 ? 10 : 0,
                        fontWeight: 'bold',
                        color: '#000',
                        fontSize: window.innerWidth > 900 ? '16px' : '14px',
                        fontFamily: 'Open-sans, sans-serif',
                      }}
                      variant="h6"
                    >
                      {i.name}
                    </Typography>
                  </ListItem>
                </Grid>
                <Grid>
                  {i.lessons.map((l) => (
                    <ListItem
                      key={l.id + uuidv4() + 'List9' || ''}
                      style={{
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        alignItems: 'center',
                        fontFamily: 'Open-sans, sans-serif',
                      }}
                    >
                      <Box
                        style={
                          unCheckedLesson.includes(l)
                            ? {
                                backgroundColor: '#d6eaff',
                                borderColor: '#d6eaff',
                                borderWidth: '2px',
                                borderRadius: '10px',
                                width: window.innerWidth > 900 ? 400 : 260,
                                padding: '10px',
                                fontFamily: 'Open-sans, sans-serif',
                              }
                            : {
                                borderColor:
                                  patientLessons2?.length > 0 &&
                                  patientLessons2?.filter((pt) => pt.lessonID == l.id)[0]?.finished
                                    ? '#3daa32'
                                    : '#c1c1c1',
                                borderStyle: 'solid',
                                borderWidth: '2px',
                                borderRadius: '10px',
                                width: window.innerWidth > 900 ? 400 : 260,
                                padding: '10px',
                                fontFamily: 'Open-sans, sans-serif',
                              }
                        }
                      >
                        <Box
                          display={'flex'}
                          justifyContent={'space-between'}
                          alignItems={'center'}
                          style={{ cursor: 'pointer' }}
                        >
                          <ListItem
                            key={l.id + uuidv4() + 'List10'}
                            id={'idLesson'}
                            onClick={handleToggleDelete(l)}
                          >
                            <Typography
                              style={{
                                color:
                                  patientLessons2?.length > 0 &&
                                  patientLessons2?.filter((pt) => pt.lessonID == l.id)[0]?.finished
                                    ? '#3daa32'
                                    : '#000',
                                fontSize: window.innerWidth > 900 ? '' : '12px',
                                fontFamily: 'Open-sans, sans-serif',
                              }}
                              key={l.id + uuidv4() + 'List11'}
                            >{`${l.title}`}</Typography>
                          </ListItem>
                          {patientLessons2?.length > 0 &&
                            patientLessons2?.filter((pt) => pt.lessonID == l.id)[0]?.finished && (
                              <p style={{ marginRight: '2%' }}>
                                <img
                                  src={checkIcon}
                                  style={{ marginTop: '1%', width: '20px', height: '20px' }}
                                />
                                {/*<DoneOutlineIcon style={{ marginTop: '1%' }} color="success" />*/}
                              </p>
                            )}
                          <IconButton onClick={handleOpen(l)}>
                            <Avatar
                              src={viewIcon}
                              style={{ width: 'auto', height: 'auto' }}
                              imgProps={{ style: { width: 25, height: 15 } }}
                            />
                          </IconButton>
                        </Box>
                      </Box>
                    </ListItem>
                  ))}
                </Grid>
              </List>
            </List>
          )
        })}
        <ListItem />
      </List>
    </Paper>
  )

  return (
    <>
      <Box className={style.buttonContainer}>
        {window.innerWidth > 900 ? (
          <Box style={{ width: '220px' }}>
            <AppButton
              theme={ButtonTheme.NewPrimaryLightWithoutWidth}
              type={'button'}
              label={t('returnToPatient')}
              handler={() => navigate(ROUTE_PATIENTS_ID.replace(':id', `${props.id}`))}
            />
          </Box>
        ) : (
          <AppButton
            theme={ButtonTheme.NewPrimaryLightWithoutWidth}
            type={'button'}
            label={t('returnToPatient')}
            handler={() => navigate(ROUTE_PATIENTS_ID.replace(':id', `${props.id}`))}
          />
        )}
      </Box>
      <Grid
        container
        justifyContent={innerWidth < 400 ? 'center' : ''}
        style={{ marginBottom: '1%' }}
      >
        <Grid item xs={12} sm={4} md={3} lg={3}>
          <Box
            style={{ backgroundColor: '#2b55b4', color: 'white', fontWeight: 'bold' }}
            className={innerWidth < 900 ? style.gridButtonTablet2 : style.gridButton2}
            onClick={handlerAddEducational}
          >
            {innerWidth < 900 ? (
              <img
                src={educationalIcon}
                alt="reports"
                style={{ marginRight: 10 }}
                width={40}
                height={40}
              />
            ) : (
              <img
                src={educationalIcon}
                alt="reports"
                style={{ marginRight: 10 }}
                width={50}
                height={50}
              />
            )}
            {t('assingNewLearning')}
          </Box>
        </Grid>
      </Grid>
      <Grid container spacing={2} justifyContent={innerWidth < 900 ? 'center' : ''}>
        <Grid item xs={12} sm={5.5} md={5.5} lg={5}>
          <ListItem>
            <Typography
              style={{
                fontSize: window.innerWidth > 900 ? '' : '18px',
                fontWeight: 'bold',
                color: '#000',
                fontFamily: 'Open-sans, sans-serif',
              }}
              variant="h6"
            >
              {t('modulesAvailable')}
            </Typography>
          </ListItem>
          {!isLoading ? moduleListAvailable(modulesAndLessonsAvailable) : null}
        </Grid>
        <Grid item xs={12} sm={1} md={1} lg={1.5}>
          <Box
            style={{
              marginTop: 70,
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
            <Button
              style={{
                backgroundColor: 'rgb(26, 150, 255)',
                textTransform: 'none',
                fontWeight: 'bold',
                fontSize: window.innerWidth > 900 ? '' : '10px',
                fontFamily: 'Open-sans, sans-serif',
              }}
              sx={{ my: 0.5, width: window.innerWidth > 900 ? 90 : 20 }}
              variant="contained"
              size="medium"
              onClick={addPatientLessons}
              aria-label="move all right"
            >
              {t('add')}
            </Button>
            <Button
              style={{
                color: 'rgb(26, 150, 255)',
                backgroundColor: 'white',
                textTransform: 'none',
                fontWeight: 'bold',
                fontSize: window.innerWidth > 900 ? '' : '10px',
                fontFamily: 'Open-sans, sans-serif',
              }}
              sx={{ my: 0.5, width: window.innerWidth > 900 ? 90 : 20 }}
              variant="outlined"
              size="medium"
              onClick={deletePatientLessons}
              aria-label="move all right"
            >
              {t('delete')}
            </Button>
          </Box>
        </Grid>

        <Grid item xs={12} sm={5.5} md={5.5} lg={5}>
          <ListItem>
            <Typography
              style={{
                fontSize: window.innerWidth > 900 ? '' : '18px',
                fontWeight: 'bold',
                color: '#000',
                fontFamily: 'Open-sans, sans-serif',
              }}
              variant="h6"
            >
              {t('modulesAssigned')}
            </Typography>
          </ListItem>
          {!isLoading ? moduleListAssigned(modulesAndLessonsAssigned) : null}
        </Grid>
      </Grid>
    </>
  )
}
