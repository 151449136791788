import generic from '../../../../assets/generic.module.css'
import React, { useState } from 'react'
import image from './sondaTypes.png'
import { Box, Button, Divider, Modal } from '@mui/material'
import { useTranslation } from 'react-i18next'

export const CVS430 = () => {
  const [openModal, setOpenModal] = useState<boolean>(false)
  const handleOpenModal = () => setOpenModal(true)
  const handleCloseModal = () => setOpenModal(false)
  const { t } = useTranslation()
  const { innerWidth } = window

  return (
    <>
      <Box className={generic.pageContainer} style={{ backgroundColor: 'white' }}>
        <Box className={generic.pageContainer} display="flex">
          <h2>
            <b>4.3 Tipos de sonda</b>
          </h2>
        </Box>
        <Box
          className={generic.pageContainer}
          style={{ display: 'flex', justifyContent: 'center' }}>
          <img
            src={image}
            onClick={handleOpenModal}
            style={{
              justifyContent: 'center',
              display: 'flex',
              borderStyle: 'ridge',
              borderColor: 'dodgerblue',
              width: innerWidth < 900 ? '470px' : '750px',
            }}
          />
        </Box>
        <Modal open={openModal} onClose={handleCloseModal}>
          <Box
            style={{
              position: 'absolute',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
              backgroundColor: 'white',
              padding: '10px',
            }}>
            <h1 style={{ marginLeft: '25%' }}>
              <b>Masculina</b>
            </h1>
            <Divider variant={'middle'} />
            <img
              src={image}
              style={{
                width: '80%',
                marginTop: '1%',
                marginLeft: '10%',
                justifyContent: 'center',
                display: 'flex',
              }}
            />
            <Button
              variant={'outlined'}
              style={{ marginLeft: '45%', marginTop: '1%', justifyContent: 'end', display: 'flex' }}
              onClick={handleCloseModal}>
              {t('close')}
            </Button>
          </Box>
        </Modal>
      </Box>
    </>
  )
}
