import { Observable } from 'rxjs'
import { Query } from '../../../common/api/Query'
import { ItemList } from '../../../common/models/ItemList'
import { Container, IInit } from '../../../common/container/Container'
import { IStatusService } from '../../../common/status/StatusService'
import { IPatientApi } from '../api/PatientApi'
import { STATUS_SERVICE_KEY } from '../../../container/app'
import { SymptomDTO } from '../models/SymptomDTO'
import { Symptom, SymptomQuery } from '../models/Symptom'
import { ISymptomApi } from '../api/SymptomApi'

type Props = {
  apiKey: symbol
}

export interface ISymptomService extends IInit {
  getByID(id: string): Observable<Symptom>
  getFilteredList(q: Query<SymptomQuery>): Observable<ItemList<Symptom>>
  add(e: SymptomDTO): Observable<Symptom | undefined>
  update(e: SymptomDTO): Observable<Symptom | undefined>

  delete(id: string | undefined): Observable<boolean>

}

export class SymptomService implements ISymptomService {
  private readonly _apiKey: symbol
  private _container!: Container
  private _api!: ISymptomApi
  private _statusService!: IStatusService

  constructor(p: Props) {
    this._apiKey = p.apiKey
  }

  init(c: Container) {
    this._container = c
    this._api = this._container.get<ISymptomApi>(this._apiKey)
    this._statusService = this._container.get<IStatusService>(STATUS_SERVICE_KEY)
  }

  getByID(id: string | undefined): Observable<Symptom> {
    return this._api.getByID(id)
  }

  getFilteredList(q: Query<SymptomQuery>): Observable<ItemList<Symptom>> {
    return this._api.getFilteredList(q).pipe()
  }

  add(e: SymptomDTO): Observable<Symptom | undefined> {
    return this._api.add(e)
  }

  delete(id: string | undefined): Observable<boolean> {
    return this._api.delete(id)
  }

  update(e: SymptomDTO): Observable<Symptom | undefined> {
    return this._api.update(e)
  }
}
