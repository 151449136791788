import React from 'react'
import { Box, TableBody, TableCell, TableRow } from '@material-ui/core'
import { Actions, Field } from './types'
import { GenericTooltip } from '../generic-tooltip'
import { useTranslation } from 'react-i18next'
import { makeStyles } from '@material-ui/core/styles'

export type BodyProps<T> = {
  readonly fields: Field<T>[]
  readonly items: T[]
  readonly rowKeyField: keyof T
  readonly actions?: Actions<T>
  readonly dashboardStyles?: boolean
  groupBy?: string
}

export function Body<T extends { [key: string]: any }>(props: BodyProps<T>) {
  const { t } = useTranslation()
  const groupByField = props.groupBy
  const categories = groupByField
    ? Array.from(new Set(props.items.map((item) => item[groupByField])))
    : []

  const useStyles = makeStyles((_) => ({
    body: {
      fontSize: '14px',
      fontFamily: 'Open-sans',
      border: '1px solid #D1D1D1',
      borderLeft: 'none',
      color: '#000',
      borderBottom: props.dashboardStyles ? 'none' : '',
      padding: '0 auto 0 auto',
      '&:last-child': {
        borderRight: props.dashboardStyles ? 'none' : '',
      },
    },
    actionBody: {
      fontSize: '14px',
      fontFamily: 'Open-sans, sans-serif',
      border: '1px solid #D1D1D1',
      borderLeft: 'none',
      borderRight: 'none',
      borderBottom: props.dashboardStyles ? 'none' : '',
      padding: '0 auto 0 auto',
      '&:last-child tr': {
        borderRight: 0,
      },
    },
    icon: {
      verticalAlign: 'middle',
      cursor: 'pointer',
      paddingTop: '0',
      paddingBottom: '0',
      marginRight: 8,
    },
  }))

  const classes = useStyles()
  const Rows = () => {
    return (
      <>
        {groupByField
          ? categories.map((category) => {
              const itemsInCategory = props.items.filter((item) => item[groupByField] === category)
              return (
                <React.Fragment key={category}>
                  <TableRow>
                    <TableCell
                      colSpan={Object.keys(props.fields).length}
                      style={{ fontWeight: 'bold' }}
                    >
                      {category}
                    </TableCell>
                  </TableRow>
                  {itemsInCategory.map((item) => {
                    const actionStyle = props.actions?.styleFunc
                      ? props.actions.styleFunc(item)
                      : ''
                    return (
                      <TableRow key={item[props.rowKeyField]}>
                        {Object.values(props.fields).map((field, i) => {
                          const style = field.styleFunc ? field.styleFunc(field, item) : ''
                          return (
                            <TableCell
                              style={{ color: '#000' }}
                              className={classes.body + ' ' + style}
                              key={`${item[props.rowKeyField]}-cell` + i}
                            >
                              {field.renderFunc
                                ? field.renderFunc(field, item)
                                : item[field.name].toString()}
                            </TableCell>
                          )
                        })}

                        {props.actions && props.actions.items?.length > 0 && (
                          <TableCell
                            className={classes.actionBody + ' ' + actionStyle}
                            key={item[props.rowKeyField] + 'cell2'}
                          >
                            <Box display="flex" justifyContent="vaseline">
                              {props.actions.items.map((a, i) => {
                                if (a.hidden && a.hidden(item)) {
                                  return
                                }
                                return (
                                  <GenericTooltip
                                    key={`${item[props.rowKeyField]}-genericToolTip` + i}
                                    values={[t(a.label || a.icon)]}
                                    icon={
                                      <img
                                        src={a.icon}
                                        className={classes.icon}
                                        key={item[props.rowKeyField]}
                                        onClick={() => a.handler(item)}
                                      />
                                    }
                                    noUseFab={true}
                                  />
                                )
                              })}
                            </Box>
                          </TableCell>
                        )}
                      </TableRow>
                    )
                  })}
                </React.Fragment>
              )
            })
          : props.items.map((item) => {
              const actionStyle = props.actions?.styleFunc ? props.actions.styleFunc(item) : ''
              return (
                <TableRow key={item[props.rowKeyField]}>
                  {Object.values(props.fields).map((field, i) => {
                    const style = field.styleFunc ? field.styleFunc(field, item) : ''
                    return (
                      <TableCell
                        style={{ color: '#000' }}
                        className={classes.body + ' ' + style}
                        key={`${item[props.rowKeyField]}-cell` + i}
                      >
                        {field.renderFunc
                          ? field.renderFunc(field, item)
                          : item[field.name].toString()}
                      </TableCell>
                    )
                  })}

                  {props.actions && props.actions.items?.length > 0 && (
                    <TableCell
                      className={classes.actionBody + ' ' + actionStyle}
                      key={item[props.rowKeyField] + 'cell2'}
                    >
                      <Box display="flex" justifyContent="vaseline">
                        {props.actions.items.map((a, i) => {
                          if (a.hidden && a.hidden(item)) {
                            return
                          }
                          return (
                            <GenericTooltip
                              key={`${item[props.rowKeyField]}-genericToolTip` + i}
                              values={[t(a.label || a.icon)]}
                              icon={
                                <img
                                  src={a.icon}
                                  className={classes.icon}
                                  key={item[props.rowKeyField]}
                                  onClick={() => a.handler(item)}
                                />
                              }
                              noUseFab={true}
                            />
                          )
                        })}
                      </Box>
                    </TableCell>
                  )}
                </TableRow>
              )
            })}
      </>
    )
  }

  return (
    <TableBody>
      <Rows />
    </TableBody>
  )
}
