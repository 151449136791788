import { PatientLessonDTO } from './PatientLessonDTO'

export class PatientLesson {
  private readonly _id: string
  private _patientID: string
  private _lessonID: string
  private _createdAt: Date
  private _creatorID: string
  private _started: boolean
  private _finished: boolean
  private _index: number

  constructor(p: PatientLessonDTO) {
    this._id = p.id
    this._patientID = p.patientID
    this._lessonID = p.lessonID
    this._createdAt = p.createdAt
    this._started = p.started
    this._finished = p.finished
    this._creatorID = p.creatorID
    this._index = p.index
  }

  get id(): string {
    return this._id
  }

  get patientID(): string {
    return this._patientID
  }

  get lessonID(): string {
    return this._lessonID
  }

  get index(): number {
    return this._index
  }

  get createdAt(): Date {
    return this._createdAt
  }

  get started(): boolean {
    return this._started
  }

  get finished(): boolean {
    return this._finished
  }

  get creatorID(): string {
    return this._creatorID
  }
}

export interface PatientLessonQuery {
  id: string
  patientID: string
  lessonID: string
}
