import generic from '../../../../assets/generic.module.css'
import { Box } from '@mui/material'

export const CVS233 = () => {
  const { innerWidth } = window

  return (
    <>
      <Box className={generic.pageContainer} style={{ backgroundColor: 'white' }}>
        <Box className={generic.pageContainer} display="flex">
          <h2>
            <b>2.3 Función de la vejiga y ciclo miccional</b>
          </h2>
        </Box>
        <Box
          className={generic.pageContainer}
          style={{ display: 'flex', justifyContent: 'center' }}
        >
          <video
            width={innerWidth < 900 ? '720' : '750'}
            height={innerWidth < 900 ? '450' : '500'}
            controls
          >
            <source src={'https://videos.higeawithyou.com/videoVesical.mp4'} type="video/mp4" />
          </video>
        </Box>
      </Box>
    </>
  )
}
