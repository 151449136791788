import generic from '../../../../assets/generic.module.css'
import { Box } from '@mui/material'
import image from './intestino.svg'
import React from 'react'

export const CIS232 = () => {
  return (
    <>
      <Box className={generic.pageContainer} style={{ backgroundColor: 'white', display: 'flex' }}>
        <Box style={{ marginRight: '5%', marginLeft: '1%' }}>
          <Box className={generic.pageContainer} display="flex">
            <h2>
              <b>2.3 Estreñimiento</b>
            </h2>
          </Box>
          <Box className={generic.pageContainer} display="flex">
            <h4 style={{ color: 'dodgerblue' }}>
              <b>Estreñimiento de tránsito lento, ETL</b>
            </h4>
          </Box>

          <Box className={generic.pageContainer} display="flex">
            <div>
              El estreñimiento de tránsito lento o la inercia colónica se define como estreñimiento
              funcional grave sin ninguna disfunción del suelo pélvico. El ETL se diagnostica junto
              con pruebas radiográficas de tránsito retardado con ausencia fisiológica de actividad
              motora colónica y sin respuesta a la estimulación farmacológica durante el registro de
              la motilidad del colon. Entre el 15 y el 30 % de los pacientes con estreñimiento
              tienen estreñimiento de tránsito lento. Se produce esencialmente porque la peristalsis
              dentro del colon se reduce en gran medida y se ralentiza la capacidad de las heces
              para moverse a lo largo del intestino. El motivo de la reducción del movimiento, es
              decir, del tránsito lento, no se conoce con certeza. Algunas teorías indican una falta
              de fibra en la dieta, neuropatía autónoma y trastornos tanto del sistema nervioso
              entérico como del sistema endocrino. Dos estudios han demostrado que los pacientes con
              estreñimiento por tránsito lento del colon tienen menos células intersticiales de
              Cajal, las células que marcan el ritmo del colon, que son necesarias para la motilidad
              colónica normal y ayudan en la transferencia de señales entre los nervios y los
              músculos.
              <br />
              <br />
              <br />
            </div>
          </Box>

          <Box className={generic.pageContainer} display="flex">
            <h4 style={{ color: 'dodgerblue' }}>
              <b>Tratamiento del ETL</b>
            </h4>
          </Box>
          <Box className={generic.pageContainer} display="flex">
            <div>
              &nbsp;&nbsp;&nbsp;&nbsp;•&nbsp;&nbsp;&nbsp;&nbsp;{' '}
              <b>Modificaciones en el estilo de vida y tratamientos farmacéuticos:</b>Los pacientes
              con ETL no responden al tratamiento médico. Sin embargo, el manejo del ETL por lo
              general empieza con una gestión conservadora, como dieta, actividad física y continúa
              con medicamentos como laxantes. El diagnóstico del ETL con frecuencia no se determina
              hasta que los pacientes no responden a los tratamientos iniciales.
              <br />
              <br />
              &nbsp;&nbsp;&nbsp;&nbsp;•&nbsp;&nbsp;&nbsp;&nbsp;{' '}
              <b>Los tratamientos no farmacológicos</b>, incluido el biofeedback, la irrigación
              transanal y la acupuntura, han demostrado tener éxito, pero solo se han probado en
              estudios pequeños. En aquellos pacientes que no responden a los tratamientos
              mínimamente invasivos, existe una variedad de enfoques quirúrgicos para el ETL.
              <br />
              <br />
              &nbsp;&nbsp;&nbsp;&nbsp;•&nbsp;&nbsp;&nbsp;&nbsp;{' '}
              <b>Irrigación transanal para ETL:</b>Se ha demostrado que tiene éxito en pacientes con
              ETL, aunque podría existir el riesgo de que la pared del intestino grueso sea menos
              propensa a responder a los estímulos en este grupo de pacientes con estreñimiento
              grave. Se recomienda utilizar ITA con un gran volumen de líquido de irrigación para
              lograr una evacuación suficiente.
              <br />
              <br />
              <br />
            </div>
          </Box>
        </Box>
      </Box>
    </>
  )
}
