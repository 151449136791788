import generic from '../../../../assets/generic.module.css'
import { Box } from '@mui/material'
import React, { useState } from 'react'
import image from './rinones.jpg'

export const CVS410 = () => {
  const [openModal, setOpenModal] = useState<boolean>(false)
  const { innerWidth } = window
  const handleOpenModal = () => setOpenModal(true)

  const handleCloseModal = () => setOpenModal(false)
  return (
    <>
      <Box className={generic.pageContainer} style={{ backgroundColor: 'white' }}>
        <Box className={generic.pageContainer} display="flex">
          <h2>
            <b>4.1 ¿En qué consiste el cateterismo intermitente (CI)?</b>
          </h2>
        </Box>
        <Box className={generic.pageContainer}>
          <Box style={{ display: 'flex' }}>
            <Box>
              <div>
                El cateterismo es quizás uno de los procedimientos urológicos más antiguos, ya que
                se remonta a hace miles de años. El uso de una sonda vesical es necesario cuando
                queda orina en la vejiga que no es posible vaciar con la micción normal.
                <br />
                <br />
                El CI es un método seguro y eficaz con el que se vacía la vejiga de forma regular
                mediante una sonda vesical de un solo uso y lo puede realizar el propio paciente, o
                bien con la ayuda de otra persona. Durante la década de 1970, el Dr. Jack Lapides
                estableció la importancia y la eficacia de vaciar la vejiga de manera regular e
                intermitente utilizando una técnica limpia, en lugar de una estéril. Introdujo el
                término cateterismo intermitente limpio (denominado en adelante CI), que ahora se
                considera la recomendación estándar para el manejo de la retención urinaria.
                <br />
                <br />
              </div>
              <div>
                <b>
                  <i style={{ color: 'dodgerblue' }}>
                    "El autocateterismo intermitente limpio (CIL) es un método alternativo, seguro y
                    eficaz para vaciar la vejiga"
                  </i>
                  , J. Lapides
                </b>
              </div>
            </Box>
            <Box>
              <img
                src={image}
                onClick={handleOpenModal}
                style={{
                  width: innerWidth < 900 ? '130%' : '90%',
                  marginRight: '10%',
                  justifyContent: 'center',
                  display: 'flex',
                }}
              />
            </Box>
          </Box>
          <Box>
            <div>
              <b>¿Por qué es importante vaciar la vejiga por completo?</b>
              <br />
              <br />
              &nbsp;&nbsp;&nbsp;&nbsp;•&nbsp;&nbsp;&nbsp;&nbsp; El vaciado incompleto de la vejiga
              puede suponer un riesgo grave para la salud que puede provocar infecciones y deterioro
              de la función renal.
              <br />
              <br />
              &nbsp;&nbsp;&nbsp;&nbsp;•&nbsp;&nbsp;&nbsp;&nbsp; La retención de orina también puede
              causar molestias como resultado de la incontinencia y síntomas como la urgencia y la
              frecuencia.
              <br />
              <br />
              &nbsp;&nbsp;&nbsp;&nbsp;•&nbsp;&nbsp;&nbsp;&nbsp; El objetivo del tratamiento es
              vaciar la orina y conservar la función de la vejiga y los riñones.
              <br />
              <br />
              El cateterismo intermitente tiene un gran impacto psicológico y emocional para el
              usuario, como mejorar su autoestima y autoconfianza, mejorar su calidad de vida con
              menos incontinencia/urgencia, mejorar el sueño, poder realizar actividades físicas y
              sufrir menos dolor y molestias.
              <br />
              <br />
              Entre algunas de las condiciones en las que el CI puede marcar una auténtica
              diferencia se encuentran las lesiones medulares (LM), los trastornos como la
              esclerosis múltiple (EM) y la hiperplasia benigna de próstata (HBP). También puede ser
              fundamental para afecciones en las que el CI puede suponer una verdadera diferencia,
              como las lesiones medulares (LM), trastornos como la esclerosis múltiple (EM) y la
              hiperplasia benigna de próstata (HBP). También puede ser fundamental para distintos
              tipos de problemas de incontinencia.
              <br />
              <br />
            </div>
          </Box>
        </Box>
      </Box>
    </>
  )
}
