import { PatientGoalDTO } from './PatientGoalDTO'

export class PatientGoal {
  private readonly _id: string
  private readonly _patientID: string
  private readonly _goalID: string
  private readonly _createdAt: Date
  private readonly _creatorID: string
  private _achieved: boolean
  private _achievedAt: Date | undefined
  private readonly _title: string

  constructor(p: PatientGoalDTO) {
    this._id = p.id
    this._patientID = p.patientID
    this._goalID = p.goalID
    this._createdAt = p.createdAt
    this._creatorID = p.creatorID
    this._achieved = p.achieved
    this._title = p.title
    this._achievedAt = p.achievedAt
  }

  get id(): string {
    return this._id
  }

  get achievedAt(): Date | undefined {
    return this._achievedAt
  }

  get patientID(): string {
    return this._patientID
  }

  get achieved(): boolean {
    return this._achieved
  }

  set achieved(a: boolean) {
    this._achieved = a
  }

  set achievedAt(a: Date | undefined) {
    this._achievedAt = a
  }

  get goalID(): string {
    return this._goalID
  }

  get createdAt(): Date {
    return this._createdAt
  }

  get creatorID(): string {
    return this._creatorID
  }

  get title(): string {
    return this._title
  }
}

export interface PatientGoalQuery {
  patientsIDs: string[]
  patientId: string
}

export function toModel(d: PatientGoalDTO): PatientGoal {
  return new PatientGoal(d)
}
