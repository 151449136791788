import { Role } from '../models/Role'
import { Container, IInit } from '../../../common/container/Container'
import { Observable } from 'rxjs'
import { IStatusService } from '../../../common/status/StatusService'
import { IRoleApi } from '../api/RoleApi'
import { STATUS_SERVICE_KEY } from '../../../container/app'
import { ThreeSixty } from '@material-ui/icons'

type Props = {
  apiKey: symbol
}

export interface IRolesService extends IInit {
  getAll(): Observable<Role[]>

  getProfessionals(): Observable<Role[]>

  getByID(id: string): Observable<Role | undefined>
}

export class RolesService implements IRolesService {
  private readonly _apiKey: symbol
  private _container!: Container
  private _api!: IRoleApi
  private _statusService!: IStatusService

  constructor(p: Props) {
    this._apiKey = p.apiKey
  }

  init(c: Container) {
    this._container = c
    this._api = this._container.get<IRoleApi>(this._apiKey)
    this._statusService = this._container.get<IStatusService>(STATUS_SERVICE_KEY)
  }

  getAll(): Observable<Role[]> {
    return this._api.getAll()
  }

  getProfessionals(): Observable<Role[]> {
    return this._api.getProfessionals()
  }

  getByID(id: string): Observable<Role | undefined> {
    return this._api.getByID(id)
  }
}
