import { useTranslation } from 'react-i18next'
import generic from '../../assets/generic.module.css'
import { Box } from '@material-ui/core'
import style from './Professionals.module.css'
import { navigate } from '@reach/router'
import { ROUTE_USERS_EDIT } from 'routes/routes-constants'
import { AppTable, Field } from 'components/table'
import React, { useEffect, useState } from 'react'
import { Actions, Pager, Search, SearchValue, Sort } from '../../components/table/types'
import { User, UserQuery } from 'modules/users/models/User'
import { getUserContainer } from '../../container/user-module'
import { UserService } from '../../modules/users/services/UserService'
import { LOGGED_USER_SERVICE_KEY, USER_SERVICE_KEY } from '../../modules/users'
import { Query, QueryParam, QueryParamN, SortParam } from '../../common/api/Query'
import editIcon from '../../assets/table_icons/ico-edit.svg'
import deleteIcon from '../../assets/table_icons/ico_eliminar_copy.svg'
import { RoleType } from '../../common/enums/RoleType'
import { ILoggedUserService } from 'modules/users/services/LoggedUserService'

const userService = getUserContainer().get<UserService>(USER_SERVICE_KEY)
const loggedUserService = getUserContainer().get<ILoggedUserService>(LOGGED_USER_SERVICE_KEY)

const searcherQuery = (
  svs: SearchValue<UserQuery>[]
): QueryParam<UserQuery>[] | QueryParamN<UserQuery>[] =>
  svs.filter((sv) => sv.value).map((sv) => ({ name: sv.name, value: sv.value as string }))

interface UserTableParams {
  actions: Actions<User>
  search: Search<UserQuery>
  items: User[]
  pager: Pager | undefined
  fields: Field<User>[]
  sort: Sort<User>
}

const UserTable = (props: UserTableParams) => {
  return (
    <Box className={style.tableContainer}>
      <AppTable
        actions={props.actions}
        search={props.search}
        items={props.items}
        pager={props.pager}
        fields={props.fields}
        rowKeyField={'id'}
        sort={props.sort}
        widthSearcher={true}
      />
    </Box>
  )
}

export function Table() {
  const { t } = useTranslation()
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [items, setItems] = useState<User[]>([])
  const [pager, setPager] = useState<Pager>()
  const [page, setPage] = useState<number>(0)
  const [count, setCount] = useState<number>(0)
  const [rowsPerPage, setRowsPerPage] = useState<number>(10)
  const [sort, setSort] = useState<SortParam<User>>({
    field: 'firstName',
    desc: true,
  })
  const [searcher, setSearcher] = useState<SearchValue<UserQuery>[]>([
    {
      name: 'firstName',
      label: t('search') + ' ' + t('byFirstName'),
      width: '250px',
    },
    {
      name: 'autonomousCommunity',
      label: t('search') + ' ' + t('byAutonomousCommunity'),
      width: '250px',
    },
    {
      name: 'province',
      label: t('search') + ' ' + t('byProvince'),
      width: '250px',
    },
  ])

  useEffect(() => {
    if (!isLoading) {
      return
    }

    userService
      .getFilteredList(
        new Query<UserQuery>({
          pager: { offset: page * rowsPerPage, limit: rowsPerPage },
          query: [
            ...searcherQuery(searcher),
            new QueryParam<UserQuery>('professionalRole', 'professionalRole'),
          ],
          sort: [{ field: sort.field, desc: sort.desc }],
        })
      )
      .subscribe((res) => {
        setItems(res.items)
        setCount(res.count)
        setIsLoading(!isLoading)
      })
  }, [isLoading])

  useEffect(() => {
    if (!isLoading) return
  })

  useEffect(() => {
    setIsLoading(!isLoading)
    setPager({
      page,
      count,
      handleChangePage,
      rowsPerPage,
      handleChangeRowsPerPage,
    })
  }, [page, count, rowsPerPage])

  const handleChangePage = (event: unknown, value: number) => setPage(value)

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) =>
    setRowsPerPage(Number.parseInt(event.target.value) || 10)

  const fields: Field<User>[] = [
    {
      searchable: true,
      sortable: true,
      label: t('firstName'),
      name: 'firstName',
      renderFunc: (f, i) => i.firstName || '',
    },
    {
      searchable: true,
      sortable: true,
      label: t('lastName'),
      name: 'lastName',
      renderFunc: (f, i) => i.lastName || '',
    },
    {
      searchable: true,
      sortable: true,
      label: t('center'),
      name: 'centerID',
      renderFunc: (f, i) => i.centerID,
    },
    {
      searchable: true,
      sortable: true,
      label: t('role'),
      name: 'roleID',
      renderFunc: (f, i) => t(RoleType[Number(i.roleID)]),
    },
    {
      searchable: true,
      sortable: true,
      label: t('phone'),
      name: 'phone',
      renderFunc: (f, i) => i.phone || '',
    },
    {
      searchable: true,
      sortable: true,
      label: t('registerDate'),
      name: 'createdAt',
      renderFunc: (f, i) =>
        loggedUserService.get()?.language == 1
          ? (new Date(i.createdAt).getDate() <= 9 ? '0' : '') +
            new Date(i.createdAt).getDate() +
            '/' +
            (new Date(i.createdAt).getMonth() <= 9 ? '0' : '') +
            (new Date(i.createdAt).getMonth() + 1) +
            '/' +
            new Date(i.createdAt).getFullYear().toString()
          : new Date(i.createdAt).toLocaleDateString(),
    },
    {
      searchable: true,
      sortable: true,
      label: t('Email'),
      name: 'email',
      renderFunc: (f, i) => i.email || '',
    },
    {
      searchable: true,
      sortable: true,
      label: t('active'),
      name: 'active',
      renderFunc: (f, i) => {
        return i.active ? t('yes') : t('no')
      },
    },
  ]

  const search: Search<UserQuery> = {
    searchValues: searcher,
    handleSearch: (svs: SearchValue<UserQuery>[]) => {
      svs.forEach((s) => {
        if (s.value && s.value != '') {
          const auxiliar = Array.from(s.value)
          auxiliar[0] = auxiliar[0].toUpperCase()
          s.value = auxiliar.join('')
        } else {
          s.value = ''
        }
      })
      setSearcher(svs)
      setIsLoading(true)
    },
  }

  const sortable: Sort<User> = {
    name: sort.field,
    direction: sort.desc ? 'desc' : 'asc',
    handleSort: (field) => {
      setSort({ field: field, desc: sort.field === field ? !sort.desc : true })
      setIsLoading(true)
    },
  }

  const deleteUser = (u: User) => userService.delete(u.id).subscribe(() => setIsLoading(true))

  const editUser = (u: User) => navigate(ROUTE_USERS_EDIT.replace(':id', `${u.id}`))

  const actions: Actions<User> = {
    actionsColumn: t('Actions'),
    items: [
      {
        handler: editUser,
        icon: editIcon,
        label: t('edit'),
      },
      {
        handler: deleteUser,
        icon: deleteIcon,
        label: t('delete'),
      },
    ],
  }

  return (
    <>
      <Box className={generic.pageContainer}>
        <Box className={style.buttonContainer}></Box>
        <UserTable
          actions={actions}
          search={search}
          items={items}
          pager={pager}
          fields={fields}
          sort={sortable}
        />
      </Box>
    </>
  )
}
