import { QuestionnairePatientAnswerDTO } from './QuestionnariePatientAnswerDTO'

export class QuestionnairePatientAnswer {
  private readonly _id: string
  private readonly _answer: string
  private readonly _questPatientID: string
  private readonly _response: string
  private readonly _score: number
  private readonly _resultText: string
  private readonly _index: number

  constructor(q: QuestionnairePatientAnswerDTO) {
    this._id = q.id
    this._answer = q.answer
    this._questPatientID = q.questPatientID
    this._response = q.response
    this._score = q.score
    this._resultText = q.resultText
    this._index = q.index
  }

  get id(): string {
    return this._id
  }

  get index(): number {
    return this._index
  }

  get answer(): string {
    return this._answer
  }

  get response(): string {
    return this._response
  }

  get score(): number {
    return this._score
  }

  get resultText(): string {
    return this._resultText
  }
  get questPatientID(): string {
    return this._questPatientID
  }
}

export interface QuestionnairePatientAnswerQuery {
  questPatientID: string
}
