import { Questionnaire } from './Questionnaire'

export interface QuestionnaireDTO {
  id: string
  title: string
}

export function toModel(d: QuestionnaireDTO): Questionnaire {
  return new Questionnaire(d)
}
