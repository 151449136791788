import generic from '../../../../assets/generic.module.css'
import { Box, Button, Divider, Modal } from '@mui/material'
import React, { useState } from 'react'
import image from '../vesical/urisystem.png'
import image2 from '../vesical/perfilVaron.jpg'
import { useTranslation } from 'react-i18next'

export const CVS220 = () => {
  const { t } = useTranslation()
  const [openModal, setOpenModal] = useState<boolean>(false)
  const { innerWidth } = window

  const handleOpenModal = () => setOpenModal(true)

  const handleCloseModal = () => setOpenModal(false)

  const [openModal2, setOpenModal2] = useState<boolean>(false)

  const handleOpenModal2 = () => setOpenModal2(true)

  const handleCloseModal2 = () => setOpenModal2(false)

  return (
    <>
      <Box className={generic.pageContainer} style={{ backgroundColor: 'white' }}>
        <Box className={generic.pageContainer} display="flex">
          <h2>
            <b>2.2. Anatomía y fisiología</b>
            <br />
            <br />
          </h2>
        </Box>
        <Box className={generic.pageContainer} style={{ display: 'flex' }}>
          <Box style={{ marginLeft: '5%' }}>
            • El cuerpo humano está compuesto por un 65% de agua
            <br />
            <br />
            • Producimos alrededor de 1,5 litros de orina al día
            <br />
            <br />
            • Nuestro sistema digestivo utiliza 5 litros de agua al día
            <br />
            <br />
          </Box>
          <Box style={{ marginLeft: '20%' }}>
            • Se pierden más de 500 ml al día por la respiración y el sudor
            <br />
            <br />
            • En total, perdemos unos 2 litros al día
            <br />
            <br />
            • Debemos mantener el equilibrio de líquidos bebiendo
            <br />
            <br />
          </Box>
        </Box>
        <Box
          className={generic.pageContainer}
          style={{
            display: 'flex',
            justifyContent: 'center',
            borderStyle: 'ridge',
            borderColor: 'dodgerblue',
          }}
          onClick={handleOpenModal}
          sx={{
            '&:hover': {
              cursor: 'pointer',
            },
          }}>
          <img
            src={image}
            style={{
              width: innerWidth < 900 ? '35%' : '28%',
              height: innerWidth < 900 ? '200px' : '350px',
              alignSelf: 'center',
            }}
          />
          <img
            src={image2}
            style={{
              width: innerWidth < 900 ? '30%' : '20%',
              marginLeft: '23%',
            }}
          />
        </Box>
        <Modal open={openModal} onClose={handleCloseModal}>
          <Box
            style={{
              position: 'absolute',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
              backgroundColor: 'white',
              padding: '10px',
            }}>
            <h1 style={{ marginLeft: '25%' }}>
              <b>Sistema urinario</b>
            </h1>
            <Divider variant={'middle'} />
            <Box display="flex" flexDirection={'row'}>
              <img
                src={image}
                style={{
                  width: '500px',
                  height: '370px',
                  marginTop: '100px',
                }}
              />
              <img
                src={image2}
                style={{
                  width: '350px',
                  marginLeft: '40px',
                }}
              />
            </Box>
            <Button
              variant={'outlined'}
              style={{ marginLeft: '45%', marginTop: '1%', justifyContent: 'end', display: 'flex' }}
              onClick={handleCloseModal}>
              {t('close')}
            </Button>
          </Box>
        </Modal>
      </Box>
    </>
  )
}
