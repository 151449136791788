import { QUESTIONNAIRE_PATIENT_ANSWER_SERVICE_KEY } from '../../modules/questionnaires/container'
import { useTranslation } from 'react-i18next'
import style from './Questionnaires.module.css'
import {
  Box,
  TableCell,
  TableContainer,
  TableRow,
  TableHead,
  Table,
  TableBody,
} from '@material-ui/core'
import { Header } from 'components/header/Header'
import close from '../../assets/table_icons/ico-eliminar.svg'
import { getQuestionnaireContainer } from '../../container/questionnaire-module'
import { useEffect, useState } from 'react'
import {
  QuestionnairePatientAnswer,
  QuestionnairePatientAnswerQuery,
} from '../../modules/questionnaires/models/QuestionnariePatientAnswer'
import { QuestionnairesPatientAnswerService } from '../../modules/questionnaires/services/QuestionnairePatientAnswerService'
import { makeStyles } from '@material-ui/core/styles'
import { Query, QueryParam } from 'common/api/Query'

interface PQInterface {
  id: string
  questionnaireID: string
  assignedAt: Date
  finished: boolean
  score: string
  scoreText: string
  title: string
  patientID: string
  finishedAt: Date
}

type questionnaireResProps = {
  title: string
  subtitle: string
  q: PQInterface | undefined
  handleClose: () => void
  score: string
  scoreText: string
}

const questionnaireService = getQuestionnaireContainer().get<QuestionnairesPatientAnswerService>(
  QUESTIONNAIRE_PATIENT_ANSWER_SERVICE_KEY
)

export function QuestionairesResultModal(props: questionnaireResProps) {
  const { t } = useTranslation()
  const [items, setItems] = useState<QuestionnairePatientAnswer[]>([])

  const useStyles = makeStyles((_) => ({
    head: {
      fontWeight: 'bold',
      backgroundColor: '#FFFFFF',
      color: '#515151',
      fontSize: '14px',
      borderBottom: '3px solid #68B3E0',
      fontFamily: 'Open-sans, sans-serif',
    },
    actions: {
      fontWeight: 'bold',
      backgroundColor: '#FFFFFF',
      color: '#515151',
      fontSize: '14px',
      borderBottom: '3px solid #68B3E0',
    },
    body: {
      fontSize: '14px',
      borderBottom: '1px solid #D1D1D1',
      padding: '0 auto 0 auto',
      fontFamily: 'Open-sans, sans-serif',
    },
  }))

  const classes = useStyles()

  useEffect(() => {
    props.q?.id &&
      questionnaireService
        .getList(
          new Query({
            query: [new QueryParam<QuestionnairePatientAnswerQuery>('questPatientID', props.q.id)],
          })
        )
        .subscribe((res) => {
          if (!res) return
          setItems(
            res.items
              .filter((i) => i.questPatientID == props.q?.id)
              .sort(function (a, b) {
                return a.index - b.index
              })
          )
        })
  }, [props.q?.id])

  return (
    <div>
      <div
        className={style.questionnaireContainer}
        style={{ marginTop: props.subtitle == 'Control de la vejiga' ? '-19%' : '-10%' }}>
        <Box className={style.containerResultsInside}>
          <Header label={props.title} icon={close} onClick={props.handleClose} />
          <h2 className={style.subtitle}>{props.subtitle}</h2>

          <TableContainer style={{ maxHeight: 350, marginTop: '2%' }}>
            <Table stickyHeader>
              <TableHead>
                <TableRow
                  style={{
                    backgroundColor: '#f5f5f5',
                    height: '35px',
                    fontFamily: 'Open-sans, sans-serif',
                  }}>
                  <TableCell className={classes.head}>{t('ask')}</TableCell>
                  <TableCell className={classes.head}>{t('answer')}</TableCell>
                  <TableCell className={classes.head}>{t('score')}</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {items.map((n) => {
                  return (
                    <TableRow key={n.id}>
                      <TableCell>{n.answer}</TableCell>
                      <TableCell>{n.response}</TableCell>
                      <TableCell>{n.score}</TableCell>
                    </TableRow>
                  )
                })}
              </TableBody>
            </Table>
          </TableContainer>
          <Box className={style.scoreContainer}>
            {props.subtitle == 'Control de la vejiga' ? (
              <>
                <Box className={style.score}>
                  TOTAL PUNTUACIÓN SÍNTOMAS: {Array.from(props.score)[0]}
                </Box>
                <Box className={style.score} style={{ marginTop: '1%' }}>
                  TOTAL PUNTUACIÓN MOLESTIAS:{' '}
                  {Array.from(props.score)[1] != undefined ? Array.from(props.score)[1] : '0'}
                </Box>
                <Box className={style.scoreText}>
                  RESULTADO SÍNTOMAS: {props.scoreText.substring(0, props.scoreText.indexOf('|'))}
                </Box>
                <Box className={style.scoreText}>
                  RESULTADO MOLESTIAS:{' '}
                  {props.scoreText.substring(
                    props.scoreText.indexOf('|') + 1,
                    props.scoreText.length
                  )}
                </Box>
              </>
            ) : (
              <>
                <Box className={style.score}>TOTAL PUNTUACIÓN: {props.score}</Box>
                <Box className={style.scoreText}>RESULTADO: {props.scoreText}</Box>
              </>
            )}
          </Box>
        </Box>
      </div>
    </div>
  )
}
