import { Container, IInit } from 'common/container/Container'
import { IStatusService } from 'common/status/StatusService'
import { STATUS_SERVICE_KEY } from 'container/app'
import { IPatientNoteApi } from '../api/PatientNoteApi'
import { NoteDTO } from '../models/NoteDTO'
import { Observable } from 'rxjs'
import { Note } from '../models/Note'

type Props = {
  apiKey: symbol
}

export interface IPatientNoteService extends IInit {
  add(n: NoteDTO): Observable<Note | undefined>
  getNotesByPatientID(id: string): Observable<Note[]>
  update(e: NoteDTO): Observable<Note | undefined>
}

export class PatientNoteService implements IPatientNoteService {
  private readonly _apiKey: symbol
  private _container!: Container
  private _api!: IPatientNoteApi
  private _statusService!: IStatusService

  constructor(p: Props) {
    this._apiKey = p.apiKey
  }

  init(c: Container) {
    this._container = c
    this._api = this._container.get<IPatientNoteApi>(this._apiKey)
    this._statusService = this._container.get<IStatusService>(STATUS_SERVICE_KEY)
  }

  add(n: NoteDTO): Observable<Note | undefined> {
    return this._api.add(n)
  }

  getNotesByPatientID(id: string): Observable<Note[]> {
    return this._api.getNotesByPatientID(id)
  }

  update(e: NoteDTO): Observable<Note | undefined> {
    return this._api.update(e)
  }
}
