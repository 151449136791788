import generic from '../../../../assets/generic.module.css'
import { Box } from '@mui/material'
import style from './Table.module.css'

export const CVS431 = () => {
  const { innerWidth } = window
  return (
    <>
      <Box className={generic.pageContainer} style={{ backgroundColor: 'white' }}>
        <Box className={generic.pageContainer} display="flex">
          <h2>
            <b>4.3 Tipos de sonda</b>
          </h2>
        </Box>
        <Box className={generic.pageContainer}>
          <div>
            <b>Diámetro y longitud</b>
            <br />
            <br />
            Las sondas intermitentes se encuentran disponibles en longitudes masculinas y femeninas,
            de alrededor de 40 cm y de entre 7 cm y 22 cm, respectivamente.
            <br />
            <br />
            El diámetro externo se mide en milímetros (escala de Charrière: Ch, CH), o bien se puede
            usar la circunferencia (escala francesa: F, FR, FG) y los tamaños oscilan entre 6 y 24.
            <br />
            <br />
            Los tamaños típicos para mujeres adultas son de 10 a 14 y, para hombres, de 12 a 14. Se
            pueden usar tamaños más grandes para tratar estenosis. Los tamaños están codificador por
            colores que se corresponden con los conectores (indicados a continuación).
            <br />
            <br />
            <table className={style.table}>
              <tr className={style.tr}>
                <th>Tamaño sonda</th>
                <th>Color</th>
                <th></th>
                <th>Diámetro tubo (mm)</th>
              </tr>
              <tr className={style.td}>
                <td>8</td>
                <td>Azul</td>
                <td>
                  <div
                    style={{
                      padding: 10,
                      margin: 20,
                      backgroundColor: '#50ACF9',
                      borderRadius: '50%',
                      marginLeft: innerWidth < 900 ? '-20%' : '-43%',
                      width: 20,
                      height: 20,
                      left: 0,
                      top: 0,
                      borderWidth: 1,
                      borderColor: 'red',
                    }}
                  />
                </td>
                <td>2.7</td>
              </tr>
              <tr className={style.td}>
                <td>10</td>
                <td>Negro</td>
                <td>
                  <div
                    style={{
                      padding: 10,
                      margin: 20,
                      backgroundColor: 'black',
                      borderRadius: '50%',
                      marginLeft: innerWidth < 900 ? '-20%' : '-43%',
                      width: 20,
                      height: 20,
                      left: 0,
                      top: 0,
                      borderWidth: 1,
                      borderColor: 'red',
                    }}
                  />
                </td>
                <td>3.3</td>
              </tr>
              <tr className={style.td}>
                <td>12</td>
                <td>Blanco</td>
                <td>
                  <div
                    style={{
                      border: 'solid #000',
                      borderWidth: '1px',
                      padding: 10,
                      margin: 20,
                      backgroundColor: 'white',
                      borderRadius: '50%',
                      marginLeft: innerWidth < 900 ? '-20%' : '-43%',
                      width: 15,
                      height: 15,
                      left: 0,
                      top: 0,
                    }}
                  />
                </td>
                <td>4</td>
              </tr>
              <tr className={style.td}>
                <td>14</td>
                <td>Verde</td>
                <td>
                  <div
                    style={{
                      padding: 10,
                      margin: 20,
                      backgroundColor: 'green',
                      borderRadius: '50%',
                      marginLeft: innerWidth < 900 ? '-20%' : '-43%',
                      width: 20,
                      height: 20,
                      left: 0,
                      top: 0,
                    }}
                  />
                </td>
                <td>4.7</td>
              </tr>
              <tr className={style.td}>
                <td>16</td>
                <td>Naranja</td>
                <td>
                  <div
                    style={{
                      padding: 10,
                      margin: 20,
                      backgroundColor: 'orange',
                      borderRadius: '50%',
                      marginLeft: innerWidth < 900 ? '-20%' : '-43%',
                      width: 20,
                      height: 20,
                      left: 0,
                      top: 0,
                    }}
                  />
                </td>
                <td>5.3</td>
              </tr>
              <tr className={style.td}>
                <td>18</td>
                <td>Rojo</td>
                <td>
                  <div
                    style={{
                      padding: 10,
                      margin: 20,
                      backgroundColor: 'red',
                      borderRadius: '50%',
                      marginLeft: innerWidth < 900 ? '-20%' : '-43%',
                      width: 20,
                      height: 20,
                      left: 0,
                      top: 0,
                    }}
                  />
                </td>
                <td>6</td>
              </tr>
              <tr className={style.td}>
                <td>20</td>
                <td>Amarillo</td>
                <td>
                  <div
                    style={{
                      padding: 10,
                      margin: 20,
                      backgroundColor: '#ebeb1a',
                      borderRadius: '50%',
                      marginLeft: innerWidth < 900 ? '-20%' : '-43%',
                      width: 20,
                      height: 20,
                      left: 0,
                      top: 0,
                    }}
                  />
                </td>
                <td>6.7</td>
              </tr>
            </table>
          </div>
        </Box>
      </Box>
    </>
  )
}
