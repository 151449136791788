import { Container, IInit } from '../../../common/container/Container'
import { EducationalContainerConfig } from '../container'
import { Observable, of } from 'rxjs'
import { catchError, map } from 'rxjs/operators'
import { Query } from '../../../common/api/Query'
import { HTTP_CLIENT_KEY, IHTTPClient } from '../../../common/api/HTTPClient'
import { IStatusService } from '../../../common/status/StatusService'
import { STATUS_SERVICE_KEY } from '../../../container/app'
import { emptyList, ItemList } from 'common/models/ItemList'
import { PatientLesson } from '../models/PatientLesson'
import { PatientLessonDTO, toModel } from '../models/PatientLessonDTO'
import { Lesson } from '../models/Lesson'
import { LessonDTO, toModel as tmdl } from '../models/LessonDTO'
import { Educational } from '../models/Educational'
import { EducationalDTO, toModel as toModelEdu } from '../models/EducationalDTO'

export interface IPatientLessonApi extends IInit {
  getByID(id: string | null): Observable<PatientLesson | undefined>

  getFilteredItems(q: Query<PatientLesson>): Observable<ItemList<PatientLesson>>

  add(e: PatientLessonDTO): Observable<PatientLesson | undefined>

  update(e: PatientLessonDTO): Observable<PatientLesson | undefined>

  delete(id: string): Observable<boolean>

  getLessonsByIDPatient(id: string): Observable<ItemList<Lesson>>

  getLessonsAndModulesAssignedByIDPatient(id: string): Observable<ItemList<Educational>>

  getLessonsAndModulesAvailableByIDPatient(id: string): Observable<ItemList<Educational>>

  deleteByPatientAndLessonID(patientID: string, lessonID: string): Observable<boolean>

  getPatientLessonsByPatientID(patientID: string): Observable<ItemList<PatientLesson>>

  getLessonByID(lessonID: string): Observable<Lesson | undefined>
}

export class PatientLessonApi implements IPatientLessonApi {
  private _container!: Container
  private _httpClient!: IHTTPClient
  private _url!: string
  private _statusService!: IStatusService

  init(c: Container) {
    this._container = c
    this._httpClient = this._container.get<IHTTPClient>(HTTP_CLIENT_KEY)
    this._statusService = this._container.get<IStatusService>(STATUS_SERVICE_KEY)
    this._url = (this._container.config as EducationalContainerConfig).moduleFullUrl
  }

  getByID(id: string): Observable<PatientLesson | undefined> {
    return this._httpClient
      .get<PatientLesson>({ url: this._url + '/' + id })
      .pipe(
        map<PatientLessonDTO, PatientLesson>((d) => toModel(d)),
        catchError((err) => {
          this._statusService.sendStatus({ variant: 'error', error: err })
          return of(undefined)
        })
      )
  }

  getFilteredItems(q: Query<PatientLesson>): Observable<ItemList<PatientLesson>> {
    return this._httpClient
      .post<ItemList<PatientLesson>>({ url: this._url + '/patientlessons/all', body: q })
      .pipe(
        map<ItemList<PatientLessonDTO>, ItemList<PatientLesson>>((dto) => {
          const itemList = emptyList<PatientLesson>()
          itemList.items = dto.items.map((d) => toModel(d))
          itemList.count = dto.count
          return itemList
        }),
        catchError((err) => {
          this._statusService.sendStatus({ variant: 'error', error: err })
          return of(emptyList<PatientLesson>())
        })
      )
  }

  add(e: PatientLessonDTO): Observable<PatientLesson | undefined> {
    return this._httpClient
      .post<PatientLesson>({ url: this._url + '/patientlessons', body: e })
      .pipe(
        map<PatientLessonDTO, PatientLesson>((d) => {
          return toModel(d)
        }),
        catchError((err) => {
          this._statusService.sendStatus({ variant: 'error', error: err })
          return of(undefined)
        })
      )
  }

  update(e: PatientLessonDTO): Observable<PatientLesson | undefined> {
    return this._httpClient
      .put<PatientLesson>({ url: this._url + '/patientlessons', body: e })
      .pipe(
        map<PatientLessonDTO, PatientLesson>((d) => {
          this._statusService.sendStatus({ variant: 'success' })
          return toModel(d)
        }),
        catchError((err) => {
          this._statusService.sendStatus({ variant: 'error', error: err })
          return of(undefined)
        })
      )
  }

  delete(id: string): Observable<boolean> {
    return this._httpClient.delete({ url: this._url + '/patientlessons/' + id }).pipe(
      catchError((err) => {
        this._statusService.sendStatus({ variant: 'error', error: err })
        return of(false)
      })
    )
  }

  getLessonsByIDPatient(id: string): Observable<ItemList<Lesson>> {
    return this._httpClient
      .get<ItemList<Lesson>>({ url: this._url + '/patientlessons/lessons/' + id })
      .pipe(
        map<ItemList<LessonDTO>, ItemList<Lesson>>((dto) => {
          const itemList = emptyList<Lesson>()
          itemList.items = dto.items.map((d) => tmdl(d))
          itemList.count = dto.count
          return itemList
        }),
        catchError((err) => {
          this._statusService.sendStatus({ variant: 'error', error: err })
          return of(emptyList<Lesson>())
        })
      )
  }

  getLessonsAndModulesAssignedByIDPatient(id: string): Observable<ItemList<Educational>> {
    return this._httpClient
      .get<ItemList<Educational>>({
        url: this._url + '/patientlessons/modulesandlessonsassigned/' + id,
      })
      .pipe(
        map<ItemList<EducationalDTO>, ItemList<Educational>>((dto) => {
          const itemList = emptyList<Educational>()
          itemList.items = dto.items.map((d) => toModelEdu(d))
          itemList.count = dto.count
          return itemList
        }),
        catchError((err) => {
          this._statusService.sendStatus({ variant: 'error', error: err })
          return of(emptyList<Educational>())
        })
      )
  }

  getLessonsAndModulesAvailableByIDPatient(id: string): Observable<ItemList<Educational>> {
    return this._httpClient
      .get<ItemList<Educational>>({
        url: this._url + '/patientlessons/modulesandlessonsavailable/' + id,
      })
      .pipe(
        map<ItemList<EducationalDTO>, ItemList<Educational>>((dto) => {
          const itemList = emptyList<Educational>()
          itemList.items = dto.items.map((d) => toModelEdu(d))
          itemList.count = dto.count
          return itemList
        }),
        catchError((err) => {
          this._statusService.sendStatus({ variant: 'error', error: err })
          return of(emptyList<Educational>())
        })
      )
  }

  deleteByPatientAndLessonID(patientID: string, lessonID: string): Observable<boolean> {
    return this._httpClient
      .delete({ url: this._url + '/patientlessons/' + patientID + '/' + lessonID })
      .pipe(
        catchError((err) => {
          this._statusService.sendStatus({ variant: 'error', error: err })
          return of(false)
        })
      )
  }

  getPatientLessonsByPatientID(patientID: string): Observable<ItemList<PatientLesson>> {
    return this._httpClient
      .get<ItemList<PatientLesson>>({ url: this._url + '/patientlessonsList/' + patientID })
      .pipe(
        map<ItemList<PatientLessonDTO>, ItemList<PatientLesson>>((dto) => {
          const itemList = emptyList<PatientLesson>()
          itemList.items = dto.items.map((d) => toModel(d))
          itemList.count = dto.count
          return itemList
        }),
        catchError((err) => {
          this._statusService.sendStatus({ variant: 'error', error: err })
          return of(emptyList<PatientLesson>())
        })
      )
  }

  getLessonByID(lessonID: string): Observable<Lesson | undefined> {
    return this._httpClient
      .get<Lesson>({ url: `${this._url}/lesson/${lessonID}` })
      .pipe(
        map<LessonDTO, Lesson>((d) => tmdl(d)),
        catchError((err) => {
          this._statusService.sendStatus({ variant: 'error', error: err })
          return of(undefined)
        })
      )
  }
}
