import { Box, InputLabel, TextField } from '@material-ui/core'
import { RouteProps } from 'routes/AppRouter'
import genericStyle from '../../common/utils/generic.module.css'
import { AppButton, ButtonTheme } from '../../components/app-button/AppButton'
import { FormCard } from '../../components/form-card/FormCard'
import { useTranslation } from 'react-i18next'
import React, { ChangeEvent, useState, FormEvent } from 'react'
import { navigate } from '@reach/router'
import { ROUTE_USERS } from '../../routes/routes-constants'
import styles from './Editor.module.css'

export function Editor(props: RouteProps) {
  const { t } = useTranslation()
  const [participantType, setParticipantType] = useState<String>()
  const [currentEmail, setCurrentEmail] = useState<string>()

  const handleEmail = (event: ChangeEvent<{ value: unknown }>) => {
    setCurrentEmail(event.target.value as string)
  }
  const handleBack = () => navigate(ROUTE_USERS)

  const validateForm = () => {
    if (currentEmail === undefined || participantType === undefined) {
      return false
    }
    return true
  }

  const handleSave = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    if (!validateForm()) {
      return
    }

    navigate(ROUTE_USERS).then()
  }

  return (
    <>
      <Box className={genericStyle.pageContainer}>
        <Box mb={3} className={styles.emailBox}>
          <TextField
            id="email"
            onChange={(e) => handleEmail(e)}
            label="Email"
            variant="outlined"
            required={true}
          />
        </Box>
        <FormCard>
          <form onSubmit={handleSave}>
            <Box mb={3} className={styles.participantBox}>
              <InputLabel id="select-participant-label" style={{ marginBottom: '0.5%' }}>
                {t('participantType')}
              </InputLabel>
            </Box>

            <Box mb={3} display="flex" justifyContent="space-between">
              <AppButton
                theme={ButtonTheme.NewSecondary}
                type={'button'}
                label={t('back')}
                handler={handleBack}
              />
              <AppButton
                theme={ButtonTheme.NewPrimary}
                type={'submit'}
                label={t('add')}
                handler={(e) => handleSave(e)}
              />
            </Box>
          </form>
        </FormCard>
      </Box>
    </>
  )
}
