import { RouteProps } from '../../routes/AppRouter'
import { CurrentNavHeader } from '../../components/current-nav-header/CurrentNavHeader'
import { Editor as CP } from 'features/professionals/Editor'

export function CreateProfessional(props: RouteProps) {
  const title = props.title || ''

  return (
    <>
      <CurrentNavHeader title={title} />
      <CP />
    </>
  )
}
