import { v4 as uuidv4 } from 'uuid'
import { Note } from './Note'

export interface NoteDTO {
  id: string | undefined
  patientID: string
  content: string
  date: Date
}

export function emptyNoteDTO(): NoteDTO {
  return {
    id: uuidv4(),
    patientID: '',
    content: '',
    date: new Date(),
  }
}

export function toModel(n: NoteDTO): Note {
  return new Note(n)
}

export function fromModel(p: Note): NoteDTO {
  return {
    id: p.id,
    patientID: p.patientID,
    content: p.content,
    date: p.date,
  }
}
