import { ViewProps } from './Statistics'
import { Box } from '@mui/material'
import { useTranslation } from 'react-i18next'
import style from './Stats.module.css'
import { GenericChart, GenericChartFecal } from '../../components/generic-chart/GenericChart'
import { useEffect, useState } from 'react'
import dayjs from 'dayjs'
import { DataType } from './MiccionalView'

export const SymptomsView = (props: ViewProps) => {
  const { t } = useTranslation()
  const [labelX, setLabelX] = useState<string[]>()
  const [isLoading, setIsLoading] = useState<boolean>(true)
  const [symptomsChart, setSymptomChart] = useState<DataType[]>([])
  const { innerWidth } = window

  useEffect(() => {
    const labelXTmp: string[] = []

    let chart: DataType[] = [
      {
        label: t('urineVisibleParticles'),
        data: [],
        backgroundColor: ['rgb(54, 162, 235)'],
      },
      {
        label: t('urineVisibleBlood'),
        data: [],
        backgroundColor: ['rgb(255, 99, 132)'],
      },
      {
        label: t('scumSmell'),
        data: [],
        backgroundColor: ['rgb(255, 205, 86)'],
      },
      {
        label: t('fever'),
        data: [],
        backgroundColor: ['rgb(103,103,103)'],
      },
      {
        label: t('urineSmell'),
        data: [],
        backgroundColor: ['rgb(122,210,79)'],
      },
      {
        label: t('scumBlood'),
        data: [],
        backgroundColor: ['#cf350a'],
      },
      {
        label: t('diarrhea'),
        data: [],
        backgroundColor: ['#9135b0'],
      },
      {
        label: t('stoolPassagePain'),
        data: [],
        backgroundColor: ['#994208'],
      },
      {
        label: t('abdominalNoises'),
        data: [],
        backgroundColor: ['#169614'],
      },
      {
        label: t('eliminationPatternChange'),
        data: [],
        backgroundColor: ['#12a1a3'],
      },
      {
        label: t('itch'),
        data: [],
        backgroundColor: ['#10568f'],
      },
      {
        label: t('frequentUrination'),
        data: [],
        backgroundColor: ['#a3e0f7'],
      },
      {
        label: t('painfulUrination'),
        data: [],
        backgroundColor: ['#d7db5e'],
      },
      {
        label: t('other'),
        data: [],
        backgroundColor: ['#f59c2f'],
      },
    ]

    props.symptoms?.forEach((u) => {
      let d = new Date(u.createdAt)
      if (!props.finishDate) return
      if (
        new Date(d.getFullYear(), d.getMonth(), d.getDate()) >= props.startDate &&
        new Date(d.getFullYear(), d.getMonth(), d.getDate()) <= props.finishDate
      ) {
        if (!labelXTmp.includes(dayjs(u.createdAt).format('DD/MM/YYYY'))) {
          labelXTmp.push(dayjs(u.createdAt).format('DD/MM/YYYY'))
        }

        chart.forEach((c) => {
          if (c.label === t(u.name)) {
            c.data.push({ x: dayjs(u.createdAt).format('DD/MM/YYYY'), y: 1 })
          }
        })
      }
    })

    setSymptomChart(chart)
    setLabelX(labelXTmp)
    setIsLoading(false)
  }, [isLoading])

  return (
    <>
      <Box className={style.chartsContainer} style={{ margin: '62px' }}>
        <Box
          display="flex"
          justifyContent="center"
          maxWidth={innerWidth < 900 ? '700px' : '800px'}
          style={{ marginBottom: '62px' }}
        >
          {!isLoading && (
            <GenericChartFecal
              title={t('symptoms')}
              type="bar"
              data={symptomsChart}
              labelX={labelX ? labelX : ['']}
              xAxisLabel={''}
              yAxisLabel={''}
              showLegend={true}
              showTitle={true}
              showTooltip={false}
            />
          )}
        </Box>
      </Box>
    </>
  )
}
