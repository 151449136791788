export function reduceString(text: string, textLimit: number): string {
  const finalChars = '...'

  return text.length > textLimit
    ? text.slice(0, textLimit - 1 - finalChars.length).concat(finalChars)
    : text
}

export function camelize(str: string): string {
  return str
    .replace(/^\w|[A-Z]|\b\w/g, function (word, index) {
      return index === 0 ? word.toLowerCase() : word.toUpperCase()
    })
    .replace(/\s+/g, '')
}

export function capitalize(string: string): string {
  return string.charAt(0).toUpperCase() + string.slice(1)
}

export function getFirstWord(str: string): string {
  let spaceIndex = str.indexOf(' ')
  return spaceIndex === -1 ? str : str.substr(0, spaceIndex)
}
