import { Container, IInit } from '../../../common/container/Container'
import { PatientContainerConfig } from '../container'
import { Observable, of } from 'rxjs'
import { catchError, map } from 'rxjs/operators'
import { Query } from '../../../common/api/Query'
import { HTTP_CLIENT_KEY, IHTTPClient } from '../../../common/api/HTTPClient'
import { IStatusService } from '../../../common/status/StatusService'
import { STATUS_SERVICE_KEY } from '../../../container/app'
import { emptyList, ItemList } from 'common/models/ItemList'
import { PatientSymptom } from '../models/PatientSymptom'
import { PatientSymptomDTO, toModel } from '../models/PatientSymptomDTO'
import { prepareURL } from '../../../common/api/http-helpers'

export interface IPatientSymptomApi extends IInit {
  getByID(id: string | null): Observable<PatientSymptom | undefined>

  getFilteredItems(q: Query<PatientSymptom>): Observable<ItemList<PatientSymptom>>

  add(e: PatientSymptomDTO): Observable<PatientSymptom | undefined>

  update(e: PatientSymptomDTO): Observable<PatientSymptom | undefined>

  delete(id: string): Observable<boolean>

  getPatientSymptomsByPatientID(patientID: string): Observable<ItemList<PatientSymptom>>

  getLastDocument(id: string): Observable<PatientSymptom | undefined>
}

export class PatientSymptomApi implements IPatientSymptomApi {
  private _container!: Container
  private _httpClient!: IHTTPClient
  private _url!: string
  private _statusService!: IStatusService

  init(c: Container) {
    this._container = c
    this._httpClient = this._container.get<IHTTPClient>(HTTP_CLIENT_KEY)
    this._statusService = this._container.get<IStatusService>(STATUS_SERVICE_KEY)
    this._url = (this._container.config as PatientContainerConfig).moduleFullUrl
  }

  getByID(id: string): Observable<PatientSymptom | undefined> {
    return this._httpClient
      .get<PatientSymptom>({ url: this._url + '/' + id })
      .pipe(
        map<PatientSymptomDTO, PatientSymptom>((d) => toModel(d)),
        catchError((err) => {
          this._statusService.sendStatus({ variant: 'error', error: err })
          return of(undefined)
        })
      )
  }

  getFilteredItems(q: Query<PatientSymptom>): Observable<ItemList<PatientSymptom>> {
    return this._httpClient
      .get<ItemList<PatientSymptom>>({ url: prepareURL(this._url + '/patientsymptom/list/paginatedList', q) })
      .pipe(
        map<ItemList<PatientSymptomDTO>, ItemList<PatientSymptom>>((dto) => {
          const itemList = emptyList<PatientSymptom>()
          itemList.items = dto.items.map((d) => toModel(d))
          itemList.count = dto.count
          return itemList
        }),
        catchError((err) => {
          this._statusService.sendStatus({ variant: 'error', error: err })
          return of(emptyList<PatientSymptom>())
        })
      )
  }

  add(e: PatientSymptomDTO): Observable<PatientSymptom | undefined> {
    return this._httpClient
      .post<PatientSymptom>({ url: this._url + '/patientsymptom', body: e })
      .pipe(
        map<PatientSymptomDTO, PatientSymptom>((d) => {
          this._statusService.sendStatus({ variant: 'success' })
          return toModel(d)
        }),
        catchError((err) => {
          this._statusService.sendStatus({ variant: 'error', error: err })
          return of(undefined)
        })
      )
  }

  update(e: PatientSymptomDTO): Observable<PatientSymptom | undefined> {
    return this._httpClient
      .put<PatientSymptom>({ url: this._url + '/patientsymptom', body: e })
      .pipe(
        map<PatientSymptomDTO, PatientSymptom>((d) => {
          this._statusService.sendStatus({ variant: 'success' })
          return toModel(d)
        }),
        catchError((err) => {
          this._statusService.sendStatus({ variant: 'error', error: err })
          return of(undefined)
        })
      )
  }

  delete(id: string): Observable<boolean> {
    return this._httpClient.delete({ url: this._url + '/patientsymptom/' + id }).pipe(
      catchError((err) => {
        this._statusService.sendStatus({ variant: 'error', error: err })
        return of(false)
      })
    )
  }

  getPatientSymptomsByPatientID(patientID: string): Observable<ItemList<PatientSymptom>> {
    return this._httpClient
      .get<ItemList<PatientSymptom>>({ url: this._url + '/patientsymptomList/' + patientID })
      .pipe(
        map<ItemList<PatientSymptomDTO>, ItemList<PatientSymptom>>((dto) => {
          const itemList = emptyList<PatientSymptom>()
          itemList.items = dto.items.map((d) => toModel(d))
          itemList.count = dto.count
          return itemList
        }),
        catchError((err) => {
          this._statusService.sendStatus({ variant: 'error', error: err })
          return of(emptyList<PatientSymptom>())
        })
      )
  }

  getLastDocument(id: string): Observable<PatientSymptom | undefined> {
    return this._httpClient
      .get<PatientSymptom>({ url: `${this._url}/getLastDocument/${id}` })
      .pipe(
        map<PatientSymptomDTO, PatientSymptom>((d) => toModel(d)),
        catchError((err) => {
          this._statusService.sendStatus({ variant: 'error', error: err })
          return of(undefined)
        })
      )
  }
}
