import { Observable } from 'rxjs'
import { Container, IInit } from '../../../common/container/Container'
import { Query } from '../../../common/api/Query'
import { IStatusService } from '../../../common/status/StatusService'
import { STATUS_SERVICE_KEY } from '../../../container/app'
import { ItemList } from '../../../common/models/ItemList'
import { PatientIntervention } from '../models/PatientIntervention'
import { PatientInterventionDTO } from '../models/PatientInterventionDTO'
import { IPatientInterventionApi } from '../api/PatientInterventionApi'

type Props = {
  apiKey: symbol
}

export interface IPatientInterventionService extends IInit {
  getByID(id: string | null): Observable<PatientIntervention | undefined>

  getFilteredItems(q: Query<PatientIntervention>): Observable<ItemList<PatientIntervention>>

  add(e: PatientInterventionDTO): Observable<PatientIntervention | undefined>

  update(e: PatientInterventionDTO): Observable<PatientIntervention | undefined>

  delete(id: string): Observable<boolean>

  getPatientInterventionsByPatientID(patientID: string): Observable<PatientIntervention | undefined>
}

export class PatientInterventionService implements IPatientInterventionApi {
  private readonly _apiKey: symbol
  private _container!: Container
  private _api!: IPatientInterventionApi
  private _statusService!: IStatusService

  constructor(p: Props) {
    this._apiKey = p.apiKey
  }

  init(c: Container) {
    this._container = c
    this._api = this._container.get<IPatientInterventionApi>(this._apiKey)
    this._statusService = this._container.get<IStatusService>(STATUS_SERVICE_KEY)
  }

  getByID(id: string | null): Observable<PatientIntervention | undefined> {
    return this._api.getByID(id)
  }

  getFilteredItems(q: Query<PatientIntervention>): Observable<ItemList<PatientIntervention>> {
    return this._api.getFilteredItems(q).pipe()
  }

  add(e: PatientInterventionDTO): Observable<PatientIntervention | undefined> {
    return this._api.add(e)
  }

  update(e: PatientInterventionDTO): Observable<PatientIntervention | undefined> {
    return this._api.update(e)
  }

  delete(id: string): Observable<boolean> {
    return this._api.delete(id)
  }

  getPatientInterventionByPatientID(
    patientID: string
  ): Observable<PatientIntervention | undefined> {
    return this._api.getPatientInterventionByPatientID(patientID)
  }
}
