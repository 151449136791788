import generic from '../../../../assets/generic.module.css'
import { Box } from '@mui/material'
import React from 'react'
import image from './enfermera2.jpg'
import image2 from './enfermera1.jpg'
import { useTranslation } from 'react-i18next'

export const CVS114 = () => {
  const { t } = useTranslation()
  return (
    <>
      <Box className={generic.pageContainer} style={{ backgroundColor: 'white' }}>
        <Box style={{ display: 'flex' }}>
          <Box>
            <Box className={generic.pageContainer} style={{ width: '50%' }}>
              <h2>
                <b>1.1 Disfunción y trastornos del tracto urinario</b>
              </h2>
            </Box>
            <Box>
              <h1 style={{ color: 'dodgerblue' }}>
                <b>Mujeres y las ITU</b>
              </h1>
            </Box>
            <Box className={generic.pageContainer}>
              <div>
                <b>Síntomas del tracto urinario inferior:</b>síntomas que afectarán a la mayoría de
                las mujeres en algún momento de su vida, e incluyen síntomas como la frecuencia, la
                urgencia, el escaso chorro y el goteo de orina.
                <br />
                <br />
                <br />
                &nbsp;&nbsp;&nbsp;&nbsp;•&nbsp;&nbsp;&nbsp;&nbsp; La incontinencia urinaria es de
                2,5 a 3 veces más frecuente entre las mujeres
                <br />
                <br />
                &nbsp;&nbsp;&nbsp;&nbsp;•&nbsp;&nbsp;&nbsp;&nbsp; El riesgo de infecciones urinarias
                es de 3 a 4 veces mayor en las mujeres
                <br />
                <br />
                &nbsp;&nbsp;&nbsp;&nbsp;•&nbsp;&nbsp;&nbsp;&nbsp; La vejiga hiperactiva es más común
                en las mujeres
                <br />
                <br />
                <h1 style={{ color: 'dodgerblue' }}>
                  <b>Hombres y las ITU</b>
                </h1>
                <div>
                  <b>Gestionar las causas de los síntomas miccionales</b>
                  <br />
                  <br />
                  1. &nbsp;&nbsp;Vigilancia activa
                  <br />
                  <br />
                  2. &nbsp;&nbsp;Manejo conservador
                  <br />
                  <br />
                  &nbsp;&nbsp;&nbsp;&nbsp;•&nbsp;&nbsp;&nbsp;&nbsp; Síntomas de la próstata -
                  tamaño, sensibilidad, nódulos - PSA
                  <br />
                  <br />
                  &nbsp;&nbsp;&nbsp;&nbsp;•&nbsp;&nbsp;&nbsp;&nbsp; Chorro de orina escaso
                  <br />
                  <br />
                  &nbsp;&nbsp;&nbsp;&nbsp;•&nbsp;&nbsp;&nbsp;&nbsp; Goteo después de la micción
                  <br />
                  <br />
                </div>
              </div>
            </Box>
          </Box>
          <Box>
            {/*TODO INSERTAR IMAGEN*/}
            <img
              src={image}
              style={{
                width: '350px',
                marginTop: '10%',
                marginLeft: '5%',
                display: 'flex',
                justifyContent: 'center',
              }}
            />
            <img
              src={image2}
              style={{
                width: '350px',
                marginTop: '6%',
                marginLeft: '5%',
                display: 'flex',
                justifyContent: 'center',
              }}
            />
          </Box>
        </Box>
      </Box>
    </>
  )
}
