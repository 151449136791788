import { UserGender } from '../../users/enums/UserGender'
import { PatientDTO } from './PatientDTO'
import { Diagnosis } from '../enums/Diagnosis'
import { Procedure } from '../enums/Procedure'
import { Treatment } from '../enums/Treatment'
import { LastActivity } from '../enums/LastActivity'

export class Patient {
  private readonly _id: string
  private readonly _userID: string | undefined
  private _internalID: string | undefined
  private readonly _firstName: string
  private readonly _lastName: string
  private readonly _gender: UserGender
  private readonly _dob: Date
  private readonly _contactPhone: string
  private readonly _mobilePhone: string
  private readonly _email: string
  private _address: string | undefined
  private _city: string
  private _zip: string | undefined
  private _country: string
  private _createdAt: Date
  private _createdBy: string
  private _carerID: string
  private _diagnosis: Diagnosis
  private _diagnosisDate: Date
  private _procedure: Procedure
  private _procedureDate: Date | undefined
  private _complications: string | undefined
  private _currentTreatment: Treatment
  private _brand: string | undefined
  private _diet: string | undefined
  private _medicine: string | undefined
  private _observations: string | undefined
  private _dischargeDate: Date | undefined
  private _notifications: number | undefined
  private _autonomousCommunity: string
  private _province: string
  private _lastActivity: LastActivity | undefined

  constructor(p: PatientDTO) {
    this._id = p.id
    this._userID = p.userID
    this._internalID = p.internalID
    this._firstName = p.firstName
    this._lastName = p.lastName
    this._gender = p.gender
    this._dob = p.dob
    this._contactPhone = p.contactPhone
    this._mobilePhone = p.mobilePhone
    this._email = p.email
    this._address = p.address
    this._city = p.city
    this._zip = p.zip
    this._country = p.country
    this._createdAt = p.createdAt
    this._createdBy = p.createdBy
    this._carerID = p.carerID
    this._diagnosis = p.diagnosis
    this._diagnosisDate = p.diagnosisDate
    this._procedure = p.procedure
    this._procedureDate = p.procedureDate
    this._complications = p.complications
    this._currentTreatment = p.currentTreatment
    this._brand = p.brand
    this._diet = p.diet
    this._medicine = p.medicine
    this._observations = p.observations
    this._dischargeDate = p.dischargeDate
    this._notifications = p.notifications
    this._autonomousCommunity = p.autonomousCommunity
    this._province = p.province
    this._lastActivity = p.lastActivity
  }

  get id(): string {
    return this._id
  }

  get userID(): string | undefined {
    return this._userID
  }

  get autonomousCommunity(): string {
    return this._autonomousCommunity
  }

  get province(): string {
    return this._province
  }

  get internalID(): string | undefined {
    return this._internalID
  }

  set internalID(value: string | undefined) {
    this._internalID = value
  }

  get firstName(): string {
    return this._firstName
  }

  get lastName(): string {
    return this._lastName
  }

  get gender(): UserGender {
    return this._gender
  }

  get dob(): Date {
    return this._dob
  }

  get contactPhone(): string {
    return this._contactPhone
  }

  get mobilePhone(): string {
    return this._mobilePhone
  }

  get email(): string {
    return this._email
  }

  get address(): string | undefined {
    return this._address
  }

  set address(value: string | undefined) {
    this._address = value
  }

  get city(): string {
    return this._city
  }

  set city(value: string) {
    this._city = value
  }

  get zip(): string | undefined {
    return this._zip
  }

  set zip(value: string | undefined) {
    this._zip = value
  }

  get country(): string {
    return this._country
  }

  set country(value: string) {
    this._country = value
  }

  get createdAt(): Date {
    return this._createdAt
  }

  set createdAt(value: Date) {
    this._createdAt = value
  }

  get createdBy(): string {
    return this._createdBy
  }

  set createdBy(value: string) {
    this._createdBy = value
  }

  get carerID(): string {
    return this._carerID
  }

  set carerID(value: string) {
    this._carerID = value
  }

  get diagnosis(): Diagnosis {
    return this._diagnosis
  }

  set diagnosis(value: Diagnosis) {
    this._diagnosis = value
  }

  get diagnosisDate(): Date {
    return this._diagnosisDate
  }

  set diagnosisDate(value: Date) {
    this._diagnosisDate = value
  }

  get procedure(): Procedure {
    return this._procedure
  }

  set procedure(value: Procedure) {
    this._procedure = value
  }

  get procedureDate(): Date | undefined {
    return this._procedureDate
  }

  set procedureDate(value: Date | undefined) {
    this._procedureDate = value
  }

  get complications(): string | undefined {
    return this._complications
  }

  set complications(value: string | undefined) {
    this._complications = value
  }

  get currentTreatment(): Treatment {
    return this._currentTreatment
  }

  set currentTreatment(value: Treatment) {
    this._currentTreatment = value
  }

  get brand(): string | undefined {
    return this._brand
  }

  set brand(value: string | undefined) {
    this._brand = value
  }

  get diet(): string | undefined {
    return this._diet
  }

  set diet(value: string | undefined) {
    this._diet = value
  }

  get medicine(): string | undefined {
    return this._medicine
  }

  set medicine(value: string | undefined) {
    this._medicine = value
  }

  get observations(): string | undefined {
    return this._observations
  }

  set observations(value: string | undefined) {
    this._observations = value
  }

  get dischargeDate(): Date | undefined {
    return this._dischargeDate
  }

  set dischargeDate(value: Date | undefined) {
    this._dischargeDate = value
  }

  get notifications(): number | undefined {
    return this._notifications
  }

  set notifications(value: number | undefined) {
    this._notifications = value
  }

  get lastActivity(): LastActivity | undefined {
    return this._lastActivity
  }

  set lastActivity(value: LastActivity | undefined) {
    this._lastActivity = value
  }
}

export interface PatientQuery {
  id: string
  ids: string[]
  firstName: string
  diagnosis: Diagnosis
  procedure: Procedure
  treatment: Treatment
  carerID: string
  dischargeDate: Date
  userID: string
  patientID: string
  createdBy: string
}
