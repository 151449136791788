import React from 'react'
import { Box, Table, TableContainer } from '@material-ui/core'
import { Head } from './Head'
import { Pagination } from './Pagination'
import { Body } from './Body'
import { Actions, Field, Pager, Search, Sort } from './types'
import { Searcher } from './Searcher'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import { ListTable } from './ListTable'
import generic from '../../assets/generic.module.css'

const DEFAULT_ROW_KEY = 'id'

export type TableProps<T, Q> = {
  readonly fields: Field<T>[]
  readonly items: T[]
  readonly rowKeyField: keyof T
  readonly actions?: Actions<T>
  readonly search?: Search<Q>
  readonly sort?: Sort<T>
  readonly pager?: Pager
  readonly notMarginTop?: boolean
  readonly dashboardStyles?: boolean
  readonly searchWithIcon?: boolean
  readonly widthSearcher?: boolean
  readonly groupBy?: string
}

export function AppTable<T extends { [key: string]: any }, Q extends { [key: string]: any }>(
  props: TableProps<T, Q>
) {
  return (
    <>
      <Box
        className={generic.tableContainer}
        style={{ padding: props.dashboardStyles ? '0' : '18px' }}
      >
        <Box
          display={'flex'}
          justifyContent={'flex-start'}
          paddingBottom={'20px'}
          margin={!props.notMarginTop && '20px 0 0 20px'}
        >
          {props.search && (
            <Searcher
              searchWithIcon={props.searchWithIcon}
              widthSearcher={props.widthSearcher}
              search={props.search}
            />
          )}
        </Box>
        <TableContainer>
          {useMediaQuery('(min-width:599px)') ? (
            <Table style={{ border: 'none' }}>
              <Head
                fields={props.fields}
                sort={props.sort}
                actions={props.actions}
                dashboardStyles={props.dashboardStyles}
              />
              <Body
                actions={props.actions}
                fields={props.fields}
                items={props.items}
                rowKeyField={props.rowKeyField || DEFAULT_ROW_KEY}
                dashboardStyles={props.dashboardStyles}
                groupBy={props.groupBy}
              />
            </Table>
          ) : (
            <ListTable
              actions={props.actions}
              fields={props.fields}
              items={props.items}
              rowKeyField={props.rowKeyField || DEFAULT_ROW_KEY}
            />
          )}
        </TableContainer>
        <Box display={'flex'} justifyContent={'flex-end'}>
          {props.pager && <Pagination {...props.pager} />}
        </Box>
      </Box>
    </>
  )
}
