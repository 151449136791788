import { Observable } from 'rxjs'
import { Container, IInit } from '../../../common/container/Container'
import { Query } from '../../../common/api/Query'
import { IStatusService } from '../../../common/status/StatusService'
import { STATUS_SERVICE_KEY } from '../../../container/app'
import { ItemList } from '../../../common/models/ItemList'
import { PatientPoint } from '../models/PatientPoint'
import { PatientPointDTO } from '../models/PatientPointDTO'
import { IPatientPointApi } from '../api/PatientPointApi'

type Props = {
  apiKey: symbol
}

export interface IPatientPointService extends IInit {
  getByID(id: string | null): Observable<PatientPoint | undefined>

  getFilteredItems(q: Query<PatientPoint>): Observable<ItemList<PatientPoint>>

  add(e: PatientPointDTO): Observable<PatientPoint | undefined>

  update(e: PatientPointDTO): Observable<PatientPoint | undefined>

  delete(id: string): Observable<boolean>

  getPatientPointsByPatientID(patientID: string): Observable<ItemList<PatientPoint>>

  getStarsByPatientID(patientID: string): Observable<number | undefined>

  getLevelFromPoints(points: number): number
}

export class PatientPointService implements IPatientPointApi {
  private readonly _apiKey: symbol
  private _container!: Container
  private _api!: IPatientPointApi
  private _statusService!: IStatusService

  constructor(p: Props) {
    this._apiKey = p.apiKey
  }

  init(c: Container) {
    this._container = c
    this._api = this._container.get<IPatientPointApi>(this._apiKey)
    this._statusService = this._container.get<IStatusService>(STATUS_SERVICE_KEY)
  }

  getByID(id: string | null): Observable<PatientPoint | undefined> {
    return this._api.getByID(id)
  }

  getFilteredItems(q: Query<PatientPoint>): Observable<ItemList<PatientPoint>> {
    return this._api.getFilteredItems(q).pipe()
  }

  add(e: PatientPointDTO): Observable<PatientPoint | undefined> {
    return this._api.add(e)
  }

  update(e: PatientPointDTO): Observable<PatientPoint | undefined> {
    return this._api.update(e)
  }

  delete(id: string): Observable<boolean> {
    return this._api.delete(id)
  }

  getPatientPointsByPatientID(patientID: string): Observable<ItemList<PatientPoint>> {
    return this._api.getPatientPointsByPatientID(patientID)
  }

  getStarsByPatientID(patientID: string): Observable<number | undefined> {
    return this._api.getStarsByPatientID(patientID)
  }

  getLevelFromPoints(points: number): number {
    switch (true) {
      case points >= 0 && points <= 120:
        return 1
      case points >= 121 && points <= 300:
        return 2
      case points >= 301 && points <= 480:
        return 3
      case points >= 481 && points <= 600:
        return 4
      case points >= 601:
        return 5
      default:
        return 0
    }
  }
}
