export enum UserGender {
  Male = 1,
  Female,
  NoneOfTheAbove,
}

export const userGenders = (): Record<UserGender, string> => ({
  [UserGender.Female]: 'Female',
  [UserGender.Male]: 'Male',
  [UserGender.NoneOfTheAbove]: 'None of the above',
})

export const toUserGenders = (): Record<string, UserGender> => ({
  ['Female']: UserGender.Female,
  ['Male']: UserGender.Male,
  ['None of the above']: UserGender.NoneOfTheAbove,
})
