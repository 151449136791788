import { PatientDiagnostic } from './PatientDiagnostic'

export interface PatientDiagnosticDTO {
  id: string
  createdAt: Date
  diagnosticID: string
  patientID: string
  text: string
}

export function emptyPatientDiagnosticDTO(): PatientDiagnosticDTO {
  return {
    id: '',
    createdAt: new Date(),
    diagnosticID: '',
    patientID: '',
    text: '',
  }
}

export function toModel(p: PatientDiagnosticDTO): PatientDiagnostic {
  return new PatientDiagnostic(p)
}

export function fromModel(p: PatientDiagnostic): PatientDiagnosticDTO {
  return {
    id: p.id,
    createdAt: p.createdAt,
    diagnosticID: p.diagnosticID,
    patientID: p.patientID,
    text: p.text,
  }
}
