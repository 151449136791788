import { Box, TextField, Select, MenuItem, Grid } from '@mui/material'
import { PatientDTO } from 'modules/patients/models/PatientDTO'
import styles from '../user-profile/Editor.module.css'
import style from '../patients/Patients.module.css'
import { useTranslation } from 'react-i18next'
import { KeyboardDatePicker } from '@material-ui/pickers'
import { Diagnostic } from 'modules/patients/models/Diagnostic'
import { ChangeEvent, useEffect, useState } from 'react'

interface MedicalViewProps {
  patient: PatientDTO
  diagnostics: Diagnostic[]
  patientDiagnostic: PatientDiagnosticInterface
  handlePatientInput: (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void
  handleChangePatientTreatment: (t: PatientTreatmentInterface[]) => void
  allPatientTreatmentsInter: PatientTreatmentInterface[]
  problemsInterface: PatientProblemInterface[]
  handleChangeDiagnostic: (diagnostic: PatientDiagnosticInterface) => void
  handleChangeProblems: (problems: PatientProblemInterface[]) => void
}

interface PatientTreatmentInterface {
  id: string
  patientID: string
  treatmentID: string
  brand: string
  name: string
  checked: boolean
}

interface PatientDiagnosticInterface {
  diagnosticID: string
  patientID: string
  name: string
  text: string
  date: Date
}

interface PatientProblemInterface {
  id: string
  problemID: string
  patientID: string
  name: string
  checked: boolean
}

export function PatientDetailMedical(p: MedicalViewProps) {
  const { t } = useTranslation()
  const [diagnosis, setDiagnostic] = useState<PatientDiagnosticInterface>(p.patientDiagnostic)
  const [interType, setInterType] = useState('')
  const [actualTreatment, setActualTreatment] = useState('')
  const { innerWidth } = window

  useEffect(() => {
    p.problemsInterface.forEach((item) => {
      if (item.checked) {
        let input = document.getElementById(item.name) as HTMLInputElement | null
        if (input) {
          input.checked = true
        }
      }
    })
  }, [])

  useEffect(() => {
    p.allPatientTreatmentsInter.forEach((item) => {
      let element = document.getElementById('brandName' + item.name) as HTMLInputElement | null

      if (item.checked) {
        let input = document.getElementById(item.name) as HTMLInputElement | null
        if (input) {
          input.checked = true
        }

        if (element) {
          element.value = item.brand
        }
      } else {
        element?.setAttribute('disabled', '')
      }
    })
  }, [])

  const changeChecked = (e: any, i: number) => {
    let aux = p.allPatientTreatmentsInter
    aux[i].checked = e.target.checked
    p.handleChangePatientTreatment(aux)

    let element = document.getElementById(
      'brandName' + p.allPatientTreatmentsInter[i].name
    ) as HTMLInputElement | null

    if (e.target.checked) {
      element?.removeAttribute('disabled')
    } else {
      if (element) {
        element.value = ''
      }
      element?.setAttribute('disabled', '')
    }
    let input = document.getElementById(
      p.allPatientTreatmentsInter[i].name
    ) as HTMLInputElement | null

    if (input) {
      input.checked = e.target.checked
    }
  }

  const handleChangeBrandName = (name: string, i: number) => {
    let aux = p.allPatientTreatmentsInter
    aux[i].brand = name
    p.handleChangePatientTreatment(aux)

    let element = document.getElementById(
      'brandName' + p.allPatientTreatmentsInter[i].name
    ) as HTMLInputElement | null
    if (element) {
      element.value = name
    }
  }

  const handleCheckProblem = (e: boolean, i: number) => {
    let aux = p.problemsInterface
    aux[i].checked = e
    p.handleChangeProblems(aux)

    let input = document.getElementById(p.problemsInterface[i].name) as HTMLInputElement | null

    if (input) {
      input.checked = e
    }
  }

  return (
    <>
      <Box className={style.generalContainer}>
        <Box display="flex" flexDirection="row">
          <Box className={styles.textFieldBox}>
            <h4 style={{ marginBottom: 0, color: '#000' }}>{t('condition') + ' * '}</h4>
            <Select
              value={diagnosis.name}
              onChange={(e) => {
                setDiagnostic(Object.assign({ ...diagnosis }, { ['name']: e.target.value }))
                let diag = p.diagnostics.filter((d) => d.name === e.target.value)
                if (diag.length > 0) {
                  let aux = p.patientDiagnostic
                  aux.name = e.target.value
                  aux.diagnosticID = diag[0].id
                  p.handleChangeDiagnostic(aux)
                }
              }}
              name="diagnosis"
              inputProps={{ 'aria-label': 'Gender' }}
              style={{
                width: '233px',
                marginRight: '20px',
                minHeight: '40px',
                height: '40px',
                fontFamily: 'Open-sans, sans-serif',
              }}>
              {p.diagnostics.map((d, y) => {
                return (
                  <MenuItem key={d.id} value={d.name}>
                    {d.name}
                  </MenuItem>
                )
              })}
            </Select>
          </Box>
          {(p.patientDiagnostic.name == 'No neurógeno' || p.patientDiagnostic.name == 'Otro') && (
            <Box>
              <h4 style={{ marginBottom: 0, color: '#000' }}>{t('description')}</h4>
              <TextField
                id={'diagnosisText'}
                key={'diagnosisText'}
                name="diagnosisText"
                className={styles.textField}
                value={p.patientDiagnostic.text}
                type="text"
                required={true}
                variant={'outlined'}
                size={'small'}
                onChange={(e) => {
                  setDiagnostic(Object.assign({ ...diagnosis }, { ['text']: e.target.value }))
                  let aux = p.patientDiagnostic
                  aux.text = e.target.value
                  p.handleChangeDiagnostic(aux)
                }}
                style={{ width: '233px', marginRight: '20px', minHeight: '20px', height: '20px' }}
              />
            </Box>
          )}
          <Box>
            <h4 style={{ marginBottom: 0, color: '#000' }}>{t('diagnosisDate')}</h4>
            <KeyboardDatePicker
              style={{ width: '233px' }}
              InputProps={{ style: { fontFamily: 'Open-sans, sans-serif' } }}
              key={'diagnosisDate'}
              id={'diagnosisDate'}
              autoOk
              variant="inline"
              inputVariant={'outlined'}
              label={''}
              format="DD/MM/YYYY"
              value={diagnosis.date}
              onChange={(e) => {
                if (e) {
                  setDiagnostic(Object.assign({ ...diagnosis }, { ['date']: new Date(e.toDate()) }))
                  let aux = p.patientDiagnostic
                  aux.date = new Date(e.toDate())
                  p.handleChangeDiagnostic(aux)
                }
              }}
              size={'small'}
            />
          </Box>
        </Box>

        <Box display="flex" flexDirection="row">
          <Box className={styles.textFieldBox}>
            <h4>{t('problem')}</h4>
            <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
              {p.problemsInterface.map((problem, i) => {
                return (
                  <>
                    <Grid item xs={1}>
                      <input
                        style={{ transform: 'scale(1.5)' }}
                        type="checkbox"
                        id={problem.name}
                        name="check"
                        onChange={(e) => handleCheckProblem(e.target.checked, i)}
                      />
                    </Grid>
                    <Grid item xs={5}>
                      <p style={{ marginTop: '-1.5%' }}>{t(problem.name)}</p>
                    </Grid>
                  </>
                )
              })}
            </Grid>
          </Box>
          {/*<Box>
            <h4 style={{ marginBottom: 0 }}>{t('interventionDate')}</h4>
            <KeyboardDatePicker
              style={{ width: '233px' }}
              key={'interventionDate'}
              id={'interventionDate'}
              autoOk
              variant="inline"
              inputVariant={'outlined'}
              label={''}
              format="DD/MM/YYYY"
              value={diagnosis.date}
              onChange={(e) => {
                if (e) {
                  setDiagnostic(Object.assign({ ...diagnosis }, { ['date']: new Date(e.toDate()) }))
                  let aux = p.patientDiagnostic
                  aux.date = new Date(e.toDate())
                  p.handleChangeDiagnostic(aux)
                }
              }}
              size={'small'}
            />
          </Box>*/}
        </Box>
        {/*<Box display="flex" flexDirection="row">
          <Box>
            <h4 style={{ marginBottom: 0 }}>{t('interventionComplications')}</h4>
            <TextField
              id={'interventionComplications'}
              key={'interventionComplications'}
              name="interventionComplications"
              multiline={true}
              rows={2}
              className={styles.doubleRow}
              value={p.patient.complications}
              type="name"
              required={true}
              variant={'outlined'}
              size={'small'}
              onChange={p.handlePatientInput}
            />
          </Box>
        </Box>*/}
        <Box display="flex" flexDirection="row">
          <Box className={styles.textFieldBox}>
            <Grid container rowSpacing={1} columnSpacing={{ xs: 5, sm: 2, md: 3 }}>
              <Grid item md={10}>
                <h4 style={{ color: '#000' }}>{t('treatmentType')}</h4>
              </Grid>
              <Grid item md={2}>
                <h4 style={{ color: '#000' }}>{t('brand')}</h4>{' '}
              </Grid>
            </Grid>
            {p.allPatientTreatmentsInter.map((treatment, i) => {
              return (
                <Grid
                  container
                  rowSpacing={1}
                  columnSpacing={{ xs: 1, sm: 2, md: 3 }}
                  style={{ marginTop: i > 0 ? '10%' : '0%' }}>
                  <Grid item xs={1}>
                    <input
                      style={{ transform: 'scale(1.2)' }}
                      type="checkbox"
                      id={treatment.name}
                      name="topping"
                      onChange={(e) => {
                        changeChecked(e, i)
                      }}
                    />
                  </Grid>
                  <Grid item md={6} style={{ marginTop: '-4%' }}>
                    <p style={{ color: '#000' }}>{treatment.name}</p>
                  </Grid>
                  <Grid item md={1} style={{ marginTop: '-0.5%', marginLeft: '10%' }}>
                    <TextField
                      id={'brandName' + treatment.name}
                      key={'diagnosisText'}
                      name="diagnosisText"
                      className={styles.textField}
                      type="text"
                      required={true}
                      variant={'outlined'}
                      size={'small'}
                      onChange={(e) => {
                        handleChangeBrandName(e.target.value, i)
                      }}
                      style={{
                        width: '233px',
                        marginRight: '20px',
                        minHeight: '20px',
                        height: '20px',
                      }}
                    />
                  </Grid>
                </Grid>
              )
            })}
          </Box>
        </Box>
        <Box
          display="flex"
          flexDirection="row"
          style={{ marginTop: innerWidth < 900 ? '3.5%' : '' }}>
          <Box>
            <h4 style={{ marginBottom: 0, color: '#000' }}>{t('diet')}</h4>
            <TextField
              id={'diet'}
              key={'diet'}
              name="diet"
              className={styles.doubleRow}
              value={p.patient.diet}
              type="name"
              required={true}
              variant={'outlined'}
              size={'small'}
              onChange={p.handlePatientInput}
            />
          </Box>
        </Box>
        <Box display="flex" flexDirection="row">
          <Box>
            <h4 style={{ marginBottom: 0, color: '#000' }}>{t('pharmaceuticals')}</h4>
            <TextField
              id={'medicine'}
              key={'medicine'}
              name="medicine"
              className={styles.doubleRow}
              value={p.patient.medicine}
              type="name"
              required={true}
              variant={'outlined'}
              size={'small'}
              onChange={p.handlePatientInput}
            />
          </Box>
        </Box>
        <Box display="flex" flexDirection="row">
          <Box>
            <h4 style={{ marginBottom: 0, color: '#000' }}>{t('observations')}</h4>
            <TextField
              id={'observations'}
              key={'observations'}
              name="observations"
              multiline={true}
              rows={2}
              className={styles.doubleRow}
              value={p.patient.observations}
              type="name"
              required={true}
              variant={'outlined'}
              size={'small'}
              onChange={p.handlePatientInput}
            />
          </Box>
        </Box>
      </Box>
    </>
  )
}
