import { emptyList, ItemList } from '../../../common/models/ItemList'
import { Observable, of } from 'rxjs'
import { Container, IInit } from '../../../common/container/Container'
import { HTTP_CLIENT_KEY, IHTTPClient } from '../../../common/api/HTTPClient'
import { IStatusService } from '../../../common/status/StatusService'
import { STATUS_SERVICE_KEY } from '../../../container/app'
import { Query } from '../../../common/api/Query'
import { PatientContainerConfig } from '../container'
import { catchError, map } from 'rxjs/operators'
import { SymptomDTO } from '../models/SymptomDTO'
import { Treatment, TreatmentQuery } from '../models/Treatment'
import { toModel, TreatmentDTO } from '../models/TreatmentDTO'

export interface ITreatmentApi extends IInit {
  getByID(id: string | undefined): Observable<Treatment>

  getFilteredList(q: Query<TreatmentQuery>): Observable<ItemList<Treatment>>

  add(e: TreatmentDTO): Observable<Treatment | undefined>

  update(e: TreatmentDTO): Observable<Treatment | undefined>

  delete(id: string | undefined): Observable<boolean>

  getByName(name: string | undefined): Observable<Treatment>

  getPatientTreatment(patientID: string): Observable<Treatment | undefined>
}

export class TreatmentApi implements ITreatmentApi {
  private _container!: Container
  private _httpClient!: IHTTPClient
  private _url!: string
  private _statusService!: IStatusService

  init(c: Container) {
    this._container = c
    this._httpClient = this._container.get<IHTTPClient>(HTTP_CLIENT_KEY)
    this._statusService = this._container.get<IStatusService>(STATUS_SERVICE_KEY)
    this._url = (this._container.config as PatientContainerConfig).moduleFullUrl
  }

  add(e: TreatmentDTO): Observable<Treatment | undefined> {
    return this._httpClient
      .post<Treatment>({ url: this._url, body: e })
      .pipe(
        map<TreatmentDTO, Treatment>((d) => toModel(d)),
        catchError((err) => {
          this._statusService.sendStatus({ variant: 'error', error: err })
          return of(undefined)
        })
      )
  }

  delete(id: string | undefined): Observable<boolean> {
    return this._httpClient.delete({ url: this._url + '/' + id }).pipe(
      catchError((err) => {
        this._statusService.sendStatus({ variant: 'error', error: err })
        return of(false)
      })
    )
  }

  getByID(id: string | undefined): Observable<Treatment> {
    return this._httpClient
      .get<Treatment>({ url: `${this._url}/treatment/${id}` })
      .pipe(
        map<TreatmentDTO, Treatment>((d) => toModel(d))
      )
  }

  getFilteredList(q: Query<TreatmentQuery>): Observable<ItemList<Treatment>> {
    return this._httpClient
      .post<ItemList<Treatment>>({ url: this._url + '/treatmentsList', body: q })
      .pipe(
        map<ItemList<TreatmentDTO>, ItemList<Treatment>>((dto) => {
          const itemList = emptyList<Treatment>()
          itemList.count = dto.count
          itemList.items = dto.items.map((d) => toModel(d))
          return itemList
        }),
        catchError((err) => {
          this._statusService.sendStatus({ variant: 'error', error: err })
          return of(emptyList<Treatment>())
        })
      )
  }

  update(e: SymptomDTO): Observable<Treatment | undefined> {
    return this._httpClient
      .put<Treatment>({ url: this._url, body: e })
      .pipe(
        map<TreatmentDTO, Treatment>((d) => toModel(d)),
        catchError((err) => {
          this._statusService.sendStatus({ variant: 'error', error: err })
          return of(undefined)
        })
      )
  }

  getByName(name: string | undefined): Observable<Treatment> {
    return this._httpClient
      .post<Treatment>({ url: `${this._url}/treatment/${name}` })
      .pipe(
        map<TreatmentDTO, Treatment>((d) => toModel(d))
      )
  }

  getPatientTreatment(patientID: string): Observable<Treatment | undefined> {
    return this._httpClient
      .get<Treatment>({ url: `${this._url}/treatmentbypatient/${patientID}` })
      .pipe(
        map<TreatmentDTO, Treatment>((d) => toModel(d)),
        catchError((err) => {
          this._statusService.sendStatus({ variant: 'error', error: err })
          return of(undefined)
        })
      )
  }
}
