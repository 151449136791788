import { Container } from '../../common/container/Container'

export type PatientContainerConfig = {
  moduleFullUrl: string
}

export type PatientProps = {
  parentContainer: Container
  config: PatientContainerConfig
}

export const PATIENT_API_KEY = Symbol('PATIENT_API_KEY')
export const PATIENT_SERVICE_KEY = Symbol('PATIENT_SERVICE_KEY')
export const PATIENTS_MODULE = Symbol('PATIENTS_MODULE')

export const PATIENT_PARAM_API_KEY = Symbol('PATIENT_PARAM_API_KEY')
export const PATIENT_PARAM_SERVICE_KEY = Symbol('PATIENT_PARAM_SERVICE_KEY')
export const PATIENT_PARAMS_MODULE = Symbol('PATIENT_PARAMS_MODULE')

export const PATIENT_GOAL_API_KEY = Symbol('PATIENT_GOAL_API_KEY')
export const PATIENT_GOAL_SERVICE_KEY = Symbol('PATIENT_GOAL_SERVICE_KEY')

export const SYMPTOM_API_KEY = Symbol('SYMPTOM_API_KEY')
export const SYMPTOM_SERVICE_KEY = Symbol('SYMPTOM_SERVICE_KEY')

export const PATIENT_SYMPTOM_API_KEY = Symbol('PATIENT_SYMPTOM_API_KEY')
export const PATIENT_SYMPTOM_SERVICE_KEY = Symbol('PATIENT_SYMPTOM_SERVICE_KEY')

export const DIAGNOSTIC_API_KEY = Symbol('DIAGNOSTIC_API_KEY')
export const DIAGNOSTIC_SERVICE_KEY = Symbol('DIAGNOSTIC_SERVICE_KEY')

export const PATIENT_DIAGNOSTIC_API_KEY = Symbol('PATIENT_DIAGNOSTIC_API_KEY')
export const PATIENT_DIAGNOSTIC_SERVICE_KEY = Symbol('PATIENT_DIAGNOSTIC_SERVICE_KEY')

export const INTERVENTION_API_KEY = Symbol('INTERVENTION_API_KEY')
export const INTERVENTION_SERVICE_KEY = Symbol('INTERVENTION_SERVICE_KEY')

export const PATIENT_INTERVENTION_API_KEY = Symbol('PATIENT_INTERVENTION_API_KEY')
export const PATIENT_INTERVENTION_SERVICE_KEY = Symbol('PATIENT_INTERVENTION_SERVICE_KEY')

export const TREATMENT_API_KEY = Symbol('TREATMENT_API_KEY')
export const TREATMENT_SERVICE_KEY = Symbol('TREATMENT_SERVICE_KEY')

export const PATIENT_TREATMENT_API_KEY = Symbol('PATIENT_TREATMENT_API_KEY')
export const PATIENT_TREATMENT_SERVICE_KEY = Symbol('PATIENT_TREATMENT_SERVICE_KEY')

export const PATIENT_POINT_API_KEY = Symbol('PATIENT_POINT_API_KEY')
export const PATIENT_POINT_SERVICE_KEY = Symbol('PATIENT_POINT_SERVICE_KEY')

export const PATIENT_FILE_API_KEY = Symbol('PATIENT_FILE_API_KEY')
export const PATIENT_FILE_SERVICE_KEY = Symbol('PATIENT_FILE_SERVICE_KEY')

export const PATIENT_NOTES_API_KEY = Symbol('PATIENT_NOTES_API_KEY')
export const PATIENT_NOTES_SERVICE_KEY = Symbol('PATIENT_NOTES_SERVICE_KEY')

export const PROBLEM_API_KEY = Symbol('PROBLEM_API_KEY')
export const PROBLEM_SERVICE_KEY = Symbol('PROBLEM_SERVICE_KEY')

export const PATIENT_PROBLEM_API_KEY = Symbol('PATIENT_PROBLEM_API_KEY')
export const PATIENT_PROBLEM_SERVICE_KEY = Symbol('PATIENT_PROBLEM_SERVICE_KEY')
