import React, { useEffect, useState } from 'react'
import { navigate } from '@reach/router'
import { ROUTE_ARTICLES, ROUTE_ARTICLES_FORM } from '../../routes/routes-constants'
import { Article } from '../../modules/content/models/Article'
import { Query } from '../../common/api/Query'
import { Actions, Pager } from '../../components/table/types'
import { getContentContainer } from '../../container/content-module'
import { ArticleService } from '../../modules/content/services/ArticleService'
import { ARTICLE_SERVICE_KEY } from '../../modules/content'
import { AppTable, Field } from '../../components/table'
import { useTranslation } from 'react-i18next'
import seeIcon from '../../assets/table_icons/ico-ver.svg'
import editIcon from '../../assets/table_icons/ico-edit.svg'
import deleteIcon from '../../assets/table_icons/ico-eliminar.svg'
import { getUserContainer } from '../../container/user-module'
import { IUserService, LOGGED_USER_SERVICE_KEY, USER_SERVICE_KEY } from '../../modules/users'
import { LoggedUserService } from '../../modules/users/services/LoggedUserService'
import genericStyle from '../../common/utils/generic.module.css'
import { Box } from '@material-ui/core'

const userContainer = getUserContainer()
const loggedUserService = userContainer.get<LoggedUserService>(LOGGED_USER_SERVICE_KEY)
const userService = userContainer.get<IUserService>(USER_SERVICE_KEY)

const contentContainer = getContentContainer()
const articlesService = contentContainer.get<ArticleService>(ARTICLE_SERVICE_KEY)

export function Table() {
  const { t } = useTranslation()

  const loggedUser = loggedUserService.get()

  const [isLoading, setIsLoading] = useState<boolean>(true)
  const [articles, setArticles] = useState<Article[]>([])
  const [count, setCount] = useState<number>(0)
  const [page, setPage] = useState<number>(0)
  const [articlesPerPage, setArticlesPerPage] = useState<number>(10)
  const [pager, setPager] = useState<Pager>()
  const [creators, setCreators] = useState<Map<string, string>>(new Map())

  useEffect(() => {
    if (!isLoading) {
      return
    }
    articlesService
      .getFilteredList(
        new Query({
          sort: [{ field: 'createdAt', desc: true }],
          pager: { offset: page * articlesPerPage, limit: articlesPerPage },
        })
      )
      .subscribe((res) => {
        setIsLoading(false)
        setArticles(res.items)
        setCount(res.count)
      })
  }, [isLoading])

  useEffect(() => {
    if (!articles.length) {
      return
    }

    const ids = articles.map((u) => u.creatorID || '')
    userService
      .getFilteredList(
        new Query({
          pager: { offset: 0, limit: ids.length },
          query: [
            {
              name: 'ids',
              value: ids,
            },
          ],
        })
      )
      .subscribe((res) =>
        setCreators(new Map(res.items.map((u) => [u.id, u.firstName] as [string, string])))
      )
  }, [articles])

  useEffect(() => {
    setIsLoading(true)
    setPager({
      page,
      count,
      handleChangePage: handlePaginationChange,
      rowsPerPage: articlesPerPage,
      handleChangeRowsPerPage,
    })
  }, [page, count, articlesPerPage])

  const handlePaginationChange = (event: unknown, value: number) => setPage(value)

  const handleSee = (article: Article) => navigate(`${ROUTE_ARTICLES}/${article.id}`)

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) =>
    setArticlesPerPage(Number.parseInt(event.target.value) || 10)

  const editArticle = (a: Article) => navigate(`${ROUTE_ARTICLES_FORM}/${a.id}`)

  const removeArticle = (a: Article) =>
    a?.id && articlesService.delete(a.id).subscribe(() => setIsLoading(true))

  const isCreator = (a: Article) => a.creatorID === loggedUser?.id

  const fields: Field<Article>[] = [
    {
      name: 'ask',
      label: t('title'),
    },
    {
      name: 'createdAt',
      label: t('creationDate'),
      renderFunc: (f, i) =>
        loggedUserService.get()?.language == 1
          ? new Date(i.createdAt).getDate() +
            '/' +
            (new Date(i.createdAt).getMonth() + 1) +
            '/' +
            new Date(i.createdAt).getFullYear().toString().substring(2, 4)
          : new Date(i.createdAt).toLocaleDateString(),
    },
    {
      name: 'creatorID',
      label: t('creator'),
      renderFunc: (f, i) => creators.get(i.creatorID || '') || '',
    },
  ]

  const actions: Actions<Article> = {
    actionsColumn: t('Actions'),
    items: [
      {
        handler: handleSee,
        icon: seeIcon,
        label: 'see',
      },
      {
        handler: editArticle,
        icon: editIcon,
        label: 'edit',
        hidden: (a) => !isCreator(a),
      },
      {
        handler: removeArticle,
        icon: deleteIcon,
        label: 'delete',
        hidden: (a) => !isCreator(a),
      },
    ],
  }

  return (
    <Box className={genericStyle.pageContainer}>
      <AppTable items={articles} rowKeyField="id" fields={fields} actions={actions} pager={pager} />
    </Box>
  )
}
