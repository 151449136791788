import { Box, List, ListItem } from '@mui/material'
import { Avatar, IconButton, Typography } from '@material-ui/core'
import viewIcon from '../../../../assets/higea/ico-view.svg'
import React from 'react'
import { navigate } from '@reach/router'
import {
  ROUTE_EDUCATIONALVESICAL11,
  ROUTE_EDUCATIONALVESICAL12,
  ROUTE_EDUCATIONALVESICAL13,
  ROUTE_EDUCATIONALVESICAL31,
  ROUTE_EDUCATIONALVESICAL32,
  ROUTE_EDUCATIONALVESICAL33,
} from '../../../../routes/routes-constants'
import styles from '../../EducationalProfessional.module.css'

export const Module3Lessons = () => {
  const handleVesical31 = () => navigate(ROUTE_EDUCATIONALVESICAL31).then()
  const handleVesical32 = () => navigate(ROUTE_EDUCATIONALVESICAL32).then()
  const handleVesical33 = () => navigate(ROUTE_EDUCATIONALVESICAL33).then()

  return (
    <List>
      <ListItem>
        <Box className={styles.lessonItem}>
          <Box display={'flex'} justifyContent={'space-between'} alignItems={'center'}>
            <ListItem id={'idLesson'}>
              <Typography style={{ color: 'blue' }}>
                3.1 Lesión Medular - Función Vesical y síntomas
              </Typography>
            </ListItem>
            <IconButton>
              <Avatar src={viewIcon} style={{ width: 29, height: 25 }} onClick={handleVesical31} />
            </IconButton>
          </Box>
        </Box>
      </ListItem>
      <ListItem>
        <Box className={styles.lessonItem}>
          <Box display={'flex'} justifyContent={'space-between'} alignItems={'center'}>
            <ListItem id={'idLesson'}>
              <Typography style={{ color: 'blue' }}>
                3.2 Esclerosis Múltiple Función Vesical y problemas vesicales
              </Typography>
            </ListItem>
            <IconButton>
              <Avatar src={viewIcon} style={{ width: 29, height: 25 }} onClick={handleVesical32} />
            </IconButton>
          </Box>
        </Box>
      </ListItem>
      <ListItem>
        <Box className={styles.lessonItem}>
          <Box display={'flex'} justifyContent={'space-between'} alignItems={'center'}>
            <ListItem id={'idLesson'}>
              <Typography style={{ color: 'blue' }}>
                3.3 Vejiga neurógena - Disfunción vesical, manejo y tratamiento
              </Typography>
            </ListItem>
            <IconButton>
              <Avatar src={viewIcon} style={{ width: 29, height: 25 }} onClick={handleVesical33} />
            </IconButton>
          </Box>
        </Box>
      </ListItem>
    </List>
  )
}
