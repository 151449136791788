import { Box, List, ListItem } from '@mui/material'
import { Avatar, IconButton, Typography } from '@material-ui/core'
import viewIcon from '../../../../assets/higea/ico-view.svg'
import React from 'react'
import { navigate } from '@reach/router'
import {
  ROUTE_EDUCATIONALINTESTINAL11,
  ROUTE_EDUCATIONALINTESTINAL21,
  ROUTE_EDUCATIONALINTESTINAL22,
  ROUTE_EDUCATIONALINTESTINAL23,
  ROUTE_EDUCATIONALINTESTINAL24,
} from '../../../../routes/routes-constants'
import styles from '../../EducationalProfessional.module.css'

export const Module2LessonsIns = () => {
  const handleIntestinal21 = () => navigate(ROUTE_EDUCATIONALINTESTINAL21).then()
  const handleIntestinal22 = () => navigate(ROUTE_EDUCATIONALINTESTINAL22).then()
  const handleIntestinal23 = () => navigate(ROUTE_EDUCATIONALINTESTINAL23).then()
  const handleIntestinal24 = () => navigate(ROUTE_EDUCATIONALINTESTINAL24).then()

  return (
    <List>
      <ListItem>
        <Box className={styles.lessonItem}>
          <Box display={'flex'} justifyContent={'space-between'} alignItems={'center'}>
            <ListItem id={'idLesson'}>
              <Typography style={{ color: 'blue' }}>
                2.1 Descripción general de la incontinencia fecal (IF)
              </Typography>
            </ListItem>
            <IconButton>
              <Avatar
                src={viewIcon}
                style={{ width: 29, height: 25 }}
                onClick={handleIntestinal21}
              />
            </IconButton>
          </Box>
        </Box>
      </ListItem>
      <ListItem>
        <Box className={styles.lessonItem}>
          <Box display={'flex'} justifyContent={'space-between'} alignItems={'center'}>
            <ListItem id={'idLesson'}>
              <Typography style={{ color: 'blue' }}>2.2 Disfunción intestinal</Typography>
            </ListItem>
            <IconButton>
              <Avatar
                src={viewIcon}
                style={{ width: 29, height: 25 }}
                onClick={handleIntestinal22}
              />
            </IconButton>
          </Box>
        </Box>
      </ListItem>
      <ListItem>
        <Box className={styles.lessonItem}>
          <Box display={'flex'} justifyContent={'space-between'} alignItems={'center'}>
            <ListItem id={'idLesson'}>
              <Typography style={{ color: 'blue' }}>2.3 Estreñimiento</Typography>
            </ListItem>
            <IconButton>
              <Avatar
                src={viewIcon}
                style={{ width: 29, height: 25 }}
                onClick={handleIntestinal23}
              />
            </IconButton>
          </Box>
        </Box>
      </ListItem>
      <ListItem>
        <Box className={styles.lessonItem}>
          <Box display={'flex'} justifyContent={'space-between'} alignItems={'center'}>
            <ListItem id={'idLesson'}>
              <Typography style={{ color: 'blue' }}>2.4 Evaluación intestinal</Typography>
            </ListItem>
            <IconButton>
              <Avatar
                src={viewIcon}
                style={{ width: 29, height: 25 }}
                onClick={handleIntestinal24}
              />
            </IconButton>
          </Box>
        </Box>
      </ListItem>
    </List>
  )
}
