import { Box, List, ListItem } from '@mui/material'
import generic from '../../assets/generic.module.css'
import { Avatar, ListItemIcon, Typography } from '@material-ui/core'
import moduleActiveIcon from '../../assets/higea/modulo-activo.svg'
import React, { useState } from 'react'
import { Module1LessonsIns } from './lessonsComponents/intestinalLessons/Module1'
import { Module2LessonsIns } from './lessonsComponents/intestinalLessons/Module2'
import { Module3LessonsIns } from './lessonsComponents/intestinalLessons/Module3'
import { Module4LessonsIns } from './lessonsComponents/intestinalLessons/Module4'
import styles from './EducationalProfessional.module.css'

export const IntestinalModule = () => {
  const [acordeon1, setAcordeon1] = useState<boolean>(false)
  const [acordeon2, setAcordeon2] = useState<boolean>(false)
  const [acordeon3, setAcordeon3] = useState<boolean>(false)
  const [acordeon4, setAcordeon4] = useState<boolean>(false)

  const handleClick1 = () => {
    setAcordeon1(!acordeon1)
  }
  const handleClick2 = () => {
    setAcordeon2(!acordeon2)
  }
  const handleClick3 = () => {
    setAcordeon3(!acordeon3)
  }
  const handleClick4 = () => {
    setAcordeon4(!acordeon4)
  }

  return (
    <List>
      <Box
        className={generic.pageContainer}
        style={{
          backgroundColor: '#0050c4',
          marginTop: '2%',
          marginLeft: '2%',
          marginRight: '2%',
          borderRadius: '10px',
        }}>
        <ListItem>
          <ListItemIcon>
            <Avatar src={moduleActiveIcon} style={{ width: 55, height: 55 }} />
          </ListItemIcon>
          <Typography style={{ marginLeft: 10, color: 'white' }} variant="h6">
            INTESTINAL
          </Typography>
        </ListItem>
      </Box>
      <ListItem>
        <Box className={styles.lessonContainer}>
          <Box display={'flex'} justifyContent={'space-between'} alignItems={'center'}>
            <ListItem id={'idLesson'} button onClick={handleClick1}>
              <Typography style={{ color: 'blue' }}>Módulo 1. Introducción</Typography>
            </ListItem>
          </Box>
        </Box>
      </ListItem>
      {acordeon1 && <Module1LessonsIns />}
      <ListItem>
        <Box className={styles.lessonContainer}>
          <Box display={'flex'} justifyContent={'space-between'} alignItems={'center'}>
            <ListItem id={'idLesson'} button onClick={handleClick2}>
              <Typography style={{ color: 'blue' }}>Módulo 2. Incontinencia fecal</Typography>
            </ListItem>
          </Box>
        </Box>
      </ListItem>
      {acordeon2 && <Module2LessonsIns />}
      <ListItem>
        <Box className={styles.lessonContainer}>
          <Box display={'flex'} justifyContent={'space-between'} alignItems={'center'}>
            <ListItem id={'idLesson'} button onClick={handleClick3}>
              <Typography style={{ color: 'blue' }}>Módulo 3. Patologías</Typography>
            </ListItem>
          </Box>
        </Box>
      </ListItem>
      {acordeon3 && <Module3LessonsIns />}
      <ListItem>
        <Box className={styles.lessonContainer}>
          <Box display={'flex'} justifyContent={'space-between'} alignItems={'center'}>
            <ListItem id={'idLesson'} button onClick={handleClick4}>
              <Typography style={{ color: 'blue' }}>Módulo 4. Irrigación transanal</Typography>
            </ListItem>
          </Box>
        </Box>
      </ListItem>
      {acordeon4 && <Module4LessonsIns />}
      {/** 
      <ListItem>
        <Box className={styles.lessonContainer}>
          <Box display={'flex'} justifyContent={'space-between'} alignItems={'center'}>
            <ListItem id={'idLesson'} button onClick={handleClick5}>
              <Typography style={{ color: 'blue' }}>
                Módulo 5. Reeducación de suelo pélvico
              </Typography>
            </ListItem>
          </Box>
        </Box>
      </ListItem>
      {acordeon5 && <Module5LessonsIns />}*/}
    </List>
  )
}
