import { QuestionnaireRangeDTO } from './QuestionnaireRangeDTO';

export class QuestionnaireRange {
    private readonly _score: number
    private readonly _title: string

    constructor(q: QuestionnaireRangeDTO) {
        this._score = q.score;
        this._title = q.title;
    }

    get score(): number {
        return this._score;
    }

    get title(): string {
        return this._title
    }
}

export function toModel(d: QuestionnaireRangeDTO): QuestionnaireRange {
    return new QuestionnaireRange(d)
}