import { Box, List, ListItem } from '@mui/material'
import { Avatar, IconButton, Typography } from '@material-ui/core'
import viewIcon from '../../../../assets/higea/ico-view.svg'
import styles from '../../EducationalProfessional.module.css'
import { navigate } from '@reach/router'
import { ROUTE_EDUCATIONALINTESTINAL33 } from 'routes/routes-constants'

export const Module4LessonsIns = () => {
  return (
    <List>
      <ListItem>
        <Box className={styles.lessonItem}>
          <Box display={'flex'} justifyContent={'space-between'} alignItems={'center'}>
            <ListItem id={'idLesson'}>
              <Typography style={{ color: 'blue' }}>4.1 ¿Qué es la ITA?</Typography>
            </ListItem>
            <IconButton>
              <Avatar
                src={viewIcon}
                style={{ width: 29, height: 25 }}
                onClick={() => navigate(ROUTE_EDUCATIONALINTESTINAL33).then()}
              />
            </IconButton>
          </Box>
        </Box>
      </ListItem>
    </List>
  )
}
