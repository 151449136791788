import React, { useState } from 'react'
import { Search, SearchValue } from './types'
import { Box, makeStyles, TextField } from '@material-ui/core'
import searchIcon from '../../assets/resource_icons/search_icon.svg'
import InputAdornment from '@mui/material/InputAdornment'
import styles from '../../Login.module.css'
import { v4 as uuidv4 } from 'uuid'
import icoUser from '../../assets/higea/Ico-usuario.svg'

type SearchProps<Q> = {
  readonly search: Search<Q>
  readonly searchWithIcon?: boolean
  readonly widthSearcher?: boolean
}

export function Searcher<Q extends { [key: string]: any }>(props: SearchProps<Q>) {
  const [values, setValues] = useState<SearchValue<Q>[]>(props.search.searchValues)

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    values.find((sv) => e.target.name === sv.name)!.value = e.target.value
    props.search.handleSearch([...values])
    setValues([...values])
  }

  const useStyles = makeStyles({
    input: {
      '&:-webkit-autofill': {
        WebkitBoxShadow: '0 0 0 1000px white inset',
        fontSize: '14px',
        fontStyle: 'Open-sans, sans-serif',
      },
      '&::placeholder': {
        color: '#c1c1c1',
        fontStyle: 'Open-sans, sans-serif',
        fontSize: 16,
        //fontWeight: 'bold',
        opacity: 1,
      },
    },
  })

  const classes = useStyles()
  return (
    <form>
      <Box marginBottom="5px">
        <Box display="flex" flexWrap="wrap">
          {values.map((v, i) => (
            <>
              {props.searchWithIcon ? (
                <Box
                  key={v.name as string}
                  width={!props.widthSearcher ? '50vw' : v.width}
                  className={styles.boxInputSearchWithIcon}
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    fontStyle: 'Open-sans, sans-serif',
                  }}
                >
                  <img
                    src={searchIcon}
                    alt={'User'}
                    style={{ width: '20px', marginLeft: 8, marginRight: 8 }}
                  />
                  <TextField
                    name={v.name as string}
                    //label={v.label}
                    placeholder={v.label}
                    onChange={handleChange}
                    type={v.type || 'text'}
                    value={v.value || ''}
                    variant="standard"
                    size="small"
                    style={{ width: '100%', top: '-5px', paddingTop: '15px' }}
                    InputProps={{ disableUnderline: true, classes: { input: classes.input } }}
                  />
                </Box>
              ) : (
                <Box mr={2} width={!props.widthSearcher ? '50vw' : v.width} key={v.name as string}>
                  <TextField
                    name={v.name as string}
                    label={v.label}
                    onChange={handleChange}
                    type={v.type || 'text'}
                    value={v.value || ''}
                    variant="outlined"
                    size="small"
                    style={{ width: '100%', color: '#000', fontStyle: 'Open-sans, sans-serif' }}
                  />
                </Box>
              )}
            </>
          ))}
        </Box>
      </Box>
    </form>
  )
}
