import i18n from '../../i18n'

export enum LangType {
  Spanish = 1,
  English,
}

export const langTypes = (): Record<LangType, string> => ({
  [LangType.Spanish]: i18n.t('Spanish'),
  [LangType.English]: i18n.t('English'),
})
