import generic from '../../../../assets/generic.module.css'
import { Box } from '@mui/material'
import React from 'react'

export const CVS211 = () => {
  return (
    <>
      <Box className={generic.pageContainer} style={{ backgroundColor: 'white' }}>
        <Box className={generic.pageContainer} display="flex">
          <h2>
            <b>2.1 ¿Qué es y como funciona?</b>
          </h2>
        </Box>
        <Box>
          <h1 style={{ color: 'dodgerblue' }}>
            <b>¿Cuáles son las distintas funciones de cada parte del sistema urinario?</b>
          </h1>
        </Box>
        <Box className={generic.pageContainer}>
          <div>
            &nbsp;&nbsp;&nbsp;&nbsp;• &nbsp;&nbsp;&nbsp;&nbsp;<b>Los riñones:</b>son órganos grandes
            con forma de alubia situados hacia la parte posterior del abdomen, a ambos lados de la
            columna vertebral. Eliminan los productos de desecho mediante la generación de orina.
            Tienen un sistema especial que consiste en una pequeña red de tubos (nefrones) que
            permiten filtrar las sustancias. Los riñones regulan la cantidad de agua en el cuerpo y
            los humanos producen alrededor de 1,5 litros de orina al día.
            <br />
            <br />
            &nbsp;&nbsp;&nbsp;&nbsp;• &nbsp;&nbsp;&nbsp;&nbsp;<b>Los uréteres:</b>vacían la orina
            desde los riñones hasta la vejiga. Son tubos de entre 25 y 30 cm de largo recubiertos
            conmúsculo uniforme. El tejido muscular realiza la peristalsis para desplazar la orina
            hacia abajo. Los uréteres acceden a la parte posterior de la vejiga a través del área
            trígono y una válvula evita el reflujo de orina de regreso a los riñones.
            <br />
            <br />
            &nbsp;&nbsp;&nbsp;&nbsp;• &nbsp;&nbsp;&nbsp;&nbsp;<b>La vejiga:</b>es un órgano hueco
            que almacena la orina y está ubicado en la pelvis por encima y detrás del hueso pélvico.
            La pared elástica y muscular de la vejiga se llama detrusor y se estirará para contener
            un volumen medio de 400 a 600 ml de orina. El interior de la vejiga está cubierto por
            varias capas de epitelio (mucosa). La mucosa se pliega cuando la vejiga está vacía, pero
            se estira a medida que la vejiga se llena. Es normal orinar de 4 a 8 veces al día (de 0
            a 1 durante la noche), normalmente entre 200 y 400 ml cada vez.
            <br />
            <br />
            &nbsp;&nbsp;&nbsp;&nbsp;• &nbsp;&nbsp;&nbsp;&nbsp;<b>Trígono de la vejiga:</b>El trígono
            es un área dentro de la vejiga; el área triangular entre los orificios ureterales y la
            abertura de la uretra interna. El área sufre pocos cambios de tamaño durante el llenado
            de la vejiga. Es muy sensible al estiramiento, ya que contiene una gran cantidad de
            terminaciones nerviosas sensoriales.
            <br />
            <br />
            &nbsp;&nbsp;&nbsp;&nbsp;• &nbsp;&nbsp;&nbsp;&nbsp;<b>Esfínter interno:</b>Una banda del
            músculo detrusor rodea la uretra en la unión de la vejiga desde el esfínter uretral
            interno.
            <br />
            <br />
            &nbsp;&nbsp;&nbsp;&nbsp;• &nbsp;&nbsp;&nbsp;&nbsp;<b>Uretra y esfínter externo:</b>La
            uretra es el tubo a través del cual pasa la orina desde la vejiga hasta el exterior del
            cuerpo. La uretra femenina mide entre 3 y 5 cm de largo y es casi recta. En hombres, la
            uretra mide alrededor de 20-27 cm y tiene forma de S. La uretra también es un órgano del
            sistema reproductor masculino, ya que transporta los espermatozoides fuera del cuerpo a
            través del pene. Un esfínter externo está en el extremo más alejado de la uretra y tiene
            dos funciones importantes: mantener la orina en la vejiga, pero también relajarse al
            vaciarse la vejiga.
            <br />
            <br />
          </div>
        </Box>
        <Box>
          <h1 style={{ color: 'dodgerblue' }}>
            <b>¿Cuál es la diferencia entre el esfínter interno y el externo?</b>
          </h1>
        </Box>
        <Box className={generic.pageContainer}>
          <div>
            A diferencia del esfínter interno, el esfínter externo puede controlarse
            voluntariamente. El esfínter externo se encuentra bajo la influencia del sistema
            nervioso autónomo (involuntario).
          </div>
        </Box>
      </Box>
    </>
  )
}
