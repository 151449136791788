import { Container, IInit } from '../../../common/container/Container'
import { Observable } from 'rxjs'
import { Query } from '../../../common/api/Query'
import { ItemList } from '../../../common/models/ItemList'
import { IStatusService } from '../../../common/status/StatusService'
import { STATUS_SERVICE_KEY } from '../../../container/app'
import { Fecal } from '../models/Fecal'
import { FecalDTO } from '../models/FecalDTO'
import { IFecalApi } from '../api/FecalApi'
import { Urination } from '../../urination/models/Urination'

type Props = {
  apiKey: symbol
}

export interface IFecalService extends IInit {
  getByID(id: string): Observable<Fecal | undefined>

  getFilteredList(q: Query<Fecal>): Observable<ItemList<Fecal>>

  add(e: FecalDTO): Observable<Fecal | undefined>

  update(e: FecalDTO): Observable<Fecal | undefined>

  delete(id: string): Observable<boolean>

  getDiariesLastWeek(q: Query<Fecal>): Observable<ItemList<Fecal>>

  getLastDocument(id: string): Observable<Fecal | undefined>

  getExcelFromFecalDiaries(urinations: FecalDTO[], language: string): Observable<Blob | undefined>
}

export class FecalService implements IFecalService {
  private readonly _apiKey: symbol
  private _container!: Container
  private _api!: IFecalApi
  private _statusService!: IStatusService

  constructor(p: Props) {
    this._apiKey = p.apiKey
  }

  init(c: Container) {
    this._container = c
    this._api = this._container.get<IFecalApi>(this._apiKey)
    this._statusService = this._container.get<IStatusService>(STATUS_SERVICE_KEY)
  }

  add(e: FecalDTO): Observable<Fecal | undefined> {
    return this._api.add(e)
  }

  delete(id: string): Observable<boolean> {
    return this._api.delete(id)
  }

  getByID(id: string): Observable<Fecal | undefined> {
    return this._api.getByID(id)
  }

  getFilteredList(q: Query<Fecal>): Observable<ItemList<Fecal>> {
    return this._api.getFilteredList(q).pipe()
  }

  update(e: FecalDTO): Observable<Fecal | undefined> {
    return this._api.update(e)
  }

  getDiariesLastWeek(q: Query<Fecal>): Observable<ItemList<Fecal>> {
    return this._api.getDiariesLastWeek(q).pipe()
  }

  getLastDocument(id: string): Observable<Fecal | undefined> {
    return this._api.getLastDocument(id)
  }

  getExcelFromFecalDiaries(fecals: FecalDTO[], language: string): Observable<Blob | undefined> {
    return this._api.getExcelFromFecalDiaries(fecals, language)
  }
}
