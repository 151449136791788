import { getFileContainer } from '../../container/file-module'
import { FileService } from '../../modules/files/services/FileService'
import { FILE_SERVICE_KEY } from '../../modules/files'
import React, { useEffect, useState } from 'react'
import { File as F } from '../../modules/files/models/File'
import { Box } from '@material-ui/core'
import { navigate } from '@reach/router'
import { ROUTE_CREATE, ROUTE_RESOURCES_FORM } from '../../routes/routes-constants'
import style from '../generic/GenericTable.module.css'
import { LoadingSpinner } from '../../components/loading-spinner/LoadingSpinner'
import loaderStyles from '../../components/loading-spinner/LoadingSpinner.module.css'
import createFile from '../../assets/resource_icons/ico-file-crear.svg'
import { LOGGED_USER_SERVICE_KEY, USER_SERVICE_KEY } from '../../modules/users'
import { getUserContainer } from '../../container/user-module'
import { AppButton, ButtonTheme } from '../../components/app-button/AppButton'
import { useTranslation } from 'react-i18next'
import genericStyle from '../../common/utils/generic.module.css'
import { downloadFile } from '../../common/utils/file'
import { AppTable, Field } from '../../components/table'
import { Actions, Pager } from '../../components/table/types'
import { UserService } from '../../modules/users/services/UserService'
import { ILoggedUserService } from '../../modules/users/services/LoggedUserService'
import editIcon from '../../assets/table_icons/ico-edit.svg'
import downloadIcon from '../../assets/table_icons/ico-download.svg'
import deleteIcon from '../../assets/table_icons/ico-eliminar.svg'
import { Query } from '../../common/api/Query'

const userContainer = getUserContainer()
const loggedUserService = userContainer.get<ILoggedUserService>(LOGGED_USER_SERVICE_KEY)
const userService = userContainer.get<UserService>(USER_SERVICE_KEY)

const fileContainer = getFileContainer()
const filesService = fileContainer.get<FileService>(FILE_SERVICE_KEY)

export function Table(): JSX.Element {
  const { t } = useTranslation('translation')

  const loggedUser = loggedUserService.get()

  const [resources, setResources] = useState<F[]>([])
  const [isLoading, setIsLoading] = useState<boolean>(true)
  const [count, setCount] = useState<number>(0)
  const [page, setPage] = useState<number>(0)
  const [pager, setPager] = useState<Pager>()
  const [rowsPerPage, setRowsPerPage] = useState<number>(10)
  const [userNames, setUserNames] = useState<Map<string, string>>(new Map())

  useEffect(() => {
    if (!isLoading) {
      return
    }
    filesService
      .getFilteredItems(
        new Query({
          pager: { offset: page * rowsPerPage, limit: rowsPerPage },
          sort: [{ field: 'name' }],
        })
      )
      .subscribe((res) => {
        setIsLoading(false)
        setResources(res.items)
        setCount(res.count)
      })
  }, [isLoading])

  useEffect(() => {
    if (!resources.length) {
      return
    }

    const ids = resources.map((u) => u.ownerID)
    userService
      .getFilteredList(
        new Query({
          pager: { offset: 0, limit: ids.length },
          query: [
            {
              name: 'ids',
              value: ids,
            },
          ],
        })
      )
      .subscribe((res) =>
        setUserNames(new Map(res.items.map((u) => [u.id, u.firstName] as [string, string])))
      )
  }, [resources])

  useEffect(() => {
    setIsLoading(true)
    setPager({
      page,
      count,
      handleChangePage,
      rowsPerPage,
      handleChangeRowsPerPage,
    })
  }, [page, count, rowsPerPage])

  const createResource = () => navigate(`${ROUTE_RESOURCES_FORM}/${ROUTE_CREATE}`)

  const editResource = (f: F) => navigate(`${ROUTE_RESOURCES_FORM}/${f.id}`)

  const downloadResource = (f: F) =>
    f.id &&
    filesService
      .getByID(f.id)
      .subscribe((res) => res && downloadFile(res.name, res.mimeType, res.data))

  const removeResource = (f: F) =>
    f.id && filesService.delete(f.id).subscribe(() => setIsLoading(true))

  const handleChangePage = (event: unknown, value: number) => setPage(value)

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (Number.isNaN(event.target.value)) {
      setRowsPerPage(10)
      return
    }
    setRowsPerPage(Number.parseInt(event.target.value))
  }

  const isCreator = (r: F) => r.ownerID === loggedUser?.id

  const fields: Field<F>[] = [
    {
      name: 'name',
      label: t('title'),
    },
    {
      name: 'createdAt',
      label: t('createdAt'),
      renderFunc: (f, i) =>
        loggedUserService.get()?.language == 1
          ? new Date(i.createdAt).getDate() +
            '/' +
            (new Date(i.createdAt).getMonth() + 1) +
            '/' +
            new Date(i.createdAt).getFullYear().toString().substring(2, 4)
          : new Date(i.createdAt).toLocaleDateString(),
    },
    {
      name: 'ownerID',
      label: t('creator'),
      renderFunc: (f, i) => userNames.get(i.ownerID) || '',
    },
  ]

  const actions: Actions<F> = {
    actionsColumn: t('Actions'),
    items: [
      {
        handler: downloadResource,
        icon: downloadIcon,
        label: 'download',
      },
      {
        handler: editResource,
        icon: editIcon,
        label: 'edit',
        hidden: (r) => !isCreator(r),
      },
      {
        handler: removeResource,
        icon: deleteIcon,
        label: 'delete',
        hidden: (r) => !isCreator(r),
      },
    ],
  }

  const isMobile = () => window.screen.width >= 400

  return (
    <>
      {!isLoading ? (
        <Box className={genericStyle.pageContainer}>
          <Box className={style.buttonContainer}>
            <AppButton
              theme={ButtonTheme.NewPrimary}
              type={'button'}
              label={isMobile() ? t('createResource') : ''}
              startIcon={createFile}
              handler={createResource}
            />
          </Box>
          <Box mt={3}>
            <AppTable
              items={resources}
              rowKeyField="id"
              fields={fields}
              actions={actions}
              pager={pager}
            />
          </Box>
        </Box>
      ) : (
        <LoadingSpinner className={loaderStyles.loadingSpinner} />
      )}
    </>
  )
}
