import { NoteDTO } from './NoteDTO'

export class Note {
  private readonly _id: string | undefined
  private _patientID: string
  private _content: string
  private _date: Date

  constructor(n: NoteDTO) {
    this._id = n.id
    this._patientID = n.patientID
    this._content = n.content
    this._date = n.date
  }

  get id(): string | undefined {
    return this._id
  }

  get patientID(): string {
    return this._patientID
  }

  set patientID(value: string) {
    this._patientID = value
  }

  get content(): string {
    return this._content
  }

  set content(value: string) {
    this._content = value
  }

  get date(): Date {
    return this._date
  }

  set date(value: Date) {
    this._date = value
  }
}

export interface NoteQuery {
  ids: string[]
  id: string
  patientID: string
  PatientsID: string[]
}
