import { Diagnostic } from './Diagnostic'

export interface DiagnosticDTO {
  id: string
  name: string
}

export function emptyDiagnosticDTO(): DiagnosticDTO {
  return {
    id: '',
    name: '',
  }
}

export function toModel(p: DiagnosticDTO): Diagnostic {
  return new Diagnostic(p)
}

export function fromModel(p: Diagnostic): DiagnosticDTO {
  return {
    id: p.id,
    name: p.name,
  }
}
