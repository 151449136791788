import { Container, IInit } from '../../../common/container/Container'
import { Observable } from 'rxjs'
import { Query } from '../../../common/api/Query'
import { ItemList } from '../../../common/models/ItemList'
import { IStatusService } from '../../../common/status/StatusService'
import { STATUS_SERVICE_KEY } from '../../../container/app'
import { Urination } from '../models/Urination'
import { UrinationDTO } from '../models/UrinationDTO'
import { IUrinationApi } from '../api/UrinationApi'

type Props = {
  apiKey: symbol
}

export interface IUrinationService extends IInit {
  getByID(id: string): Observable<Urination | undefined>

  getFilteredList(q: Query<Urination>): Observable<ItemList<Urination>>

  getDiariesLastWeek(q: Query<Urination>): Observable<ItemList<Urination>>

  add(e: UrinationDTO): Observable<Urination | undefined>

  update(e: UrinationDTO): Observable<Urination | undefined>

  delete(id: string): Observable<boolean>

  getLastDocument(id: string): Observable<Urination | undefined>

  getExcelFromUrinationDiaries(
    urinations: UrinationDTO[],
    language: string
  ): Observable<Blob | undefined>
}

export class UrinationService implements IUrinationService {
  private readonly _apiKey: symbol
  private _container!: Container
  private _api!: IUrinationApi
  private _statusService!: IStatusService

  constructor(p: Props) {
    this._apiKey = p.apiKey
  }

  init(c: Container) {
    this._container = c
    this._api = this._container.get<IUrinationApi>(this._apiKey)
    this._statusService = this._container.get<IStatusService>(STATUS_SERVICE_KEY)
  }

  add(e: UrinationDTO): Observable<Urination | undefined> {
    return this._api.add(e)
  }

  delete(id: string): Observable<boolean> {
    return this._api.delete(id)
  }

  getByID(id: string): Observable<Urination | undefined> {
    return this._api.getByID(id)
  }

  getFilteredList(q: Query<Urination>): Observable<ItemList<Urination>> {
    return this._api.getFilteredList(q).pipe()
  }

  update(e: UrinationDTO): Observable<Urination | undefined> {
    return this._api.update(e)
  }

  getDiariesLastWeek(q: Query<Urination>): Observable<ItemList<Urination>> {
    return this._api.getDiariesLastWeek(q).pipe()
  }

  getLastDocument(id: string): Observable<Urination | undefined> {
    return this._api.getLastDocument(id)
  }

  getExcelFromUrinationDiaries(
    urinations: UrinationDTO[],
    language: string
  ): Observable<Blob | undefined> {
    return this._api.getExcelFromUrinationDiaries(urinations, language)
  }
}
