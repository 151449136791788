import React, { useEffect, useState } from 'react'
import { RouteProps } from '../../routes/AppRouter'
import { CurrentNavHeader } from '../../components/current-nav-header/CurrentNavHeader'
import { Educational as E } from '../../features/educational'
import { getUserContainer } from '../../container/user-module'
import { LOGGED_USER_SERVICE_KEY } from '../../modules/users'
import { LoggedUserService } from '../../modules/users/services/LoggedUserService'
import { PatientService } from '../../modules/patients/services/PatientService'
import { PATIENT_SERVICE_KEY } from '../../modules/patients'
import { getPatientContainer } from '../../container/patient-module'
import { useTranslation } from 'react-i18next'

const userService = getUserContainer().get<LoggedUserService>(LOGGED_USER_SERVICE_KEY)
const patientService = getPatientContainer().get<PatientService>(PATIENT_SERVICE_KEY)

export function Educationals(props: RouteProps) {
  const { t } = useTranslation()
  const [patient, setPatient] = useState<string>('')
  const [title, setTitle] = useState<string>('')
  const [isLoading, setIsLoading] = useState<boolean>(true)

  useEffect(() => {
    patientService.getByID(props.id || '').subscribe((res) => {
      setTitle(`${t('patient')} ${' '} ${res.firstName} ${' '}  ${res.lastName}`)
      setIsLoading(false)
    })
  }, [])

  return (
    <>
      {!isLoading && (
        <>
          <CurrentNavHeader title={title} />
          <E id={props.id} userID={userService.get()?.id} />
        </>
      )}
    </>
  )
}
