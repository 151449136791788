import { Box } from '@material-ui/core'
import { AppButton, ButtonTheme } from 'components/app-button/AppButton'
import generic from '../../assets/generic.module.css'
import style from './Questionnaires.module.css'
import { useTranslation } from 'react-i18next'
import AddIcon from '../../assets/higea/plus-button.svg'
import React, { useState, useEffect } from 'react'
import { QuestionnairesModal } from './QuestionnairesModal'
import { getQuestionnaireContainer } from '../../container/questionnaire-module'
import { QuestionnairesService } from '../../modules/questionnaires/services/QuestionnaireService'
import {
  QUESTIONNAIRE_PATIENT_ANSWER_SERVICE_KEY,
  QUESTIONNAIRE_SERVICE_KEY,
} from '../../modules/questionnaires/container'
import { Modal } from '@mui/material'
import { AppTable } from 'components/table'
import { Field, Pager, SearchValue, Actions } from '../../components/table/types'
import { Query } from 'common/api/Query'
import { QuestionnairePatientQuery } from '../../modules/questionnaires/models/QuestionnairePatient'
import { QueryParam, QueryParamN } from '../../common/api/Query'
import deleteIcon from '../../assets/table_icons/ico_eliminar_copy.svg'
import downloadIcon from '../../assets/table_icons/ico-download-black.svg'
import seeIcon from '../../assets/table_icons/View-icon-Copy-3.svg'
import { QuestionairesResultModal } from './QuestionnairesResultsModal'
import { navigate } from '@reach/router'
import { ROUTE_PATIENTS_ID } from 'routes/routes-constants'
import { LoggedUserService } from 'modules/users/services/LoggedUserService'
import { getUserContainer } from 'container/user-module'
import { LOGGED_USER_SERVICE_KEY } from 'modules/users'
import {
  IQuestionnairePatientAnswer,
  QuestionnairesPatientAnswerService,
} from '../../modules/questionnaires/services/QuestionnairePatientAnswerService'
import { QuestionnairePatientAnswerQuery } from '../../modules/questionnaires/models/QuestionnariePatientAnswer'

type QuestionnairesDataProps = {
  id: string
}

const searcherQuery = (
  svs: SearchValue<QuestionnairePatientQuery>[]
): QueryParam<QuestionnairePatientQuery>[] | QueryParamN<QuestionnairePatientQuery>[] =>
  svs.filter((sv) => sv.value).map((sv) => ({ name: sv.name, value: sv.value as string }))

const questionnaireService =
  getQuestionnaireContainer().get<QuestionnairesService>(QUESTIONNAIRE_SERVICE_KEY)

const loggedUserService = getUserContainer().get<LoggedUserService>(LOGGED_USER_SERVICE_KEY)
const questionnairesPatientService = getQuestionnaireContainer().get<IQuestionnairePatientAnswer>(
  QUESTIONNAIRE_PATIENT_ANSWER_SERVICE_KEY
)

interface patientQuestion {
  id: string
  questionnaireID: string
  assignedAt: Date
  finished: boolean
  score: string
  scoreText: string
  title: string
  patientID: string
  finishedAt: Date
}

export function Questionnaires(props: QuestionnairesDataProps) {
  const { t } = useTranslation()
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [openModal, setOpenModal] = useState<boolean>(false)
  const [openResults, setOpenResults] = useState<boolean>(false)
  const [items, setItems] = useState<patientQuestion[]>([])
  const [pager, setPager] = useState<Pager>()
  const [page, setPage] = useState<number>(0)
  const [count, setCount] = useState<number>(0)
  const [rowsPerPage, setRowsPerPage] = useState<number>(10)
  const [resultSubtitle, setResultSubtitle] = useState<string>('')
  const [questSelected, setQuestSelected] = useState<patientQuestion>()
  const [currentScore, setCurrentScore] = useState<string>('')
  const [currentScoreText, setCurrentScoreText] = useState<string>('')
  const { innerWidth } = window
  const [searcher, setSearcher] = useState<SearchValue<QuestionnairePatientQuery>[]>([
    {
      name: 'assignedAt',
      label: t('search') + ' ' + t('date'),
    },
  ])

  const assignQuestionnaire = () => {
    setOpenModal(true)
  }

  const handleCloseModal = () => setOpenModal(false)

  const handleCloseResults = () => setOpenResults(false)

  const handleSave = (ids: string[]) => {
    questionnaireService.add(ids, props.id).subscribe((res) => {
      if (res) {
        setOpenModal(false)
        setIsLoading(!isLoading)
      }
    })
  }

  useEffect(() => {
    if (!isLoading) {
      return
    }
    questionnaireService
      .getPatientQuestionnaireList(
        new Query<QuestionnairePatientQuery>({
          pager: { offset: page * rowsPerPage, limit: rowsPerPage },
          query: [...searcherQuery(searcher)],
        }),
        props.id
      )
      .subscribe((res) => {
        setCount(res.count)
        let aux: patientQuestion[] = []
        res.items.forEach((item) => {
          aux.push({
            id: item.id,
            questionnaireID: item.questionnaireID,
            assignedAt: item.assignedAt,
            finished: item.finished,
            score: item.score.toString(),
            scoreText: item.scoreText,
            title: item.title,
            patientID: item.patientID,
            finishedAt: new Date(item.finishedAt),
          })
        })
        setItems(aux)
        setIsLoading(!isLoading)
      })
  }, [isLoading])

  useEffect(() => {
    setIsLoading(!isLoading)
    setPager({
      page,
      count,
      handleChangePage,
      rowsPerPage,
      handleChangeRowsPerPage,
    })
  }, [page, count, rowsPerPage])

  const handleChangePage = (event: unknown, value: number) => setPage(value)

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) =>
    setRowsPerPage(Number.parseInt(event.target.value) || 10)

  const fields: Field<patientQuestion>[] = [
    {
      searchable: true,
      sortable: true,
      label: t('date'),
      name: 'assignedAt',
      renderFunc: (f, i) =>
        loggedUserService.get()?.language == 1
          ? (new Date(i.assignedAt).getDate() <= 9 ? '0' : '') +
            new Date(i.assignedAt).getDate() +
            '/' +
            (new Date(i.assignedAt).getMonth() <= 9 ? '0' : '') +
            (new Date(i.assignedAt).getMonth() + 1) +
            '/' +
            new Date(i.assignedAt).getFullYear().toString()
          : new Date(i.assignedAt).toLocaleDateString(),
    },
    {
      searchable: true,
      sortable: true,
      label: t('questionnaire'),
      name: 'title',
    },
    {
      searchable: true,
      sortable: true,
      label: t('score'),
      name: 'score',
      renderFunc: (f, i) =>
        i.title == 'Control de la vejiga'
          ? Array.from(i.score)[0] +
            '   ' +
            (Array.from(i.score)[1] != undefined ? Array.from(i.score)[1] : '  0')
          : i.score,
    },
    {
      searchable: true,
      sortable: true,
      label: t('status'),
      name: 'finished',
      renderFunc: (f, i) => statusQuestionnaireElem(i.finished),
    },
  ]

  const statusQuestionnaireElem = (finished: boolean) => {
    if (finished) {
      return <span style={{ color: 'black' }}>{t('completed')}</span>
    } else {
      return <span style={{ color: 'red' }}>{t('pending')}</span>
    }
  }

  const removeQuestionnaire = (q: patientQuestion) => {
    q?.id &&
      questionnaireService
        .deletePatientQuestionnaire(q.id)
        .subscribe(() => setIsLoading(!isLoading))
  }

  const showQuestionnaireResults = (q: patientQuestion) => {
    setCurrentScoreText(q.scoreText)
    setCurrentScore(q.score)
    setResultSubtitle(q.title)
    setQuestSelected(q)
    setOpenResults(true)
  }

  const downloadResults = (q: patientQuestion) => {
    const language = navigator.language.split('-')[0].toUpperCase()
    questionnairesPatientService.getPdfFromAnswers(q.id, language).subscribe((res) => {
      console.log(res)
      if (res) {
        const url = window.URL.createObjectURL(new Blob([res]))
        const link = document.createElement('a')
        link.href = url
        link.setAttribute('download', 'file.pdf')
        document.body.appendChild(link)
        link.click()
      }
    })
  }

  const actions: Actions<patientQuestion> = {
    actionsColumn: t('Actions'),
    items: [
      {
        handler: showQuestionnaireResults,
        icon: seeIcon,
        label: 'show',
      },
      {
        handler: removeQuestionnaire,
        icon: deleteIcon,
        label: 'delete',
      },
      {
        handler: downloadResults,
        icon: downloadIcon,
        label: 'download',
      },
    ],
  }

  return (
    <>
      <Box className={style.buttonBack}>
        <AppButton
          theme={ButtonTheme.NewPrimaryLightWithoutWidth}
          type={'button'}
          label={t('returnToPatient')}
          handler={() => navigate(ROUTE_PATIENTS_ID.replace(':id', `${props.id}`))}
        />
      </Box>
      <Box className={style.titleBox} style={{ marginTop: '-1%' }}>
        <h3 className={style.title}>{t('questionnaires')}</h3>
      </Box>
      <Box className={generic.pageContainer}>
        <Box
          className={style.buttonContainer}
          style={{ marginBottom: innerWidth < 1025 ? '4%' : '2.2%', marginTop: '-2.8%' }}
        >
          <AppButton
            theme={ButtonTheme.NewPrimaryLightWithoutWidth}
            type={'button'}
            label={t('assignNew')}
            handler={assignQuestionnaire}
            startIcon={AddIcon}
          />
        </Box>
        <Box className={style.tableContainer}>
          <AppTable
            rowKeyField={'id'}
            fields={fields}
            items={items}
            pager={pager}
            actions={actions}
          />
        </Box>
      </Box>
      <Modal open={openModal} onClose={handleCloseModal}>
        <QuestionnairesModal
          title={t('assignQuestionarie')}
          handleClose={handleCloseModal}
          handleSave={handleSave}
          patientID={props.id}
        />
      </Modal>
      <Modal open={openResults} onClose={handleCloseResults}>
        <QuestionairesResultModal
          title={t('questionnaireResult')}
          q={questSelected}
          subtitle={resultSubtitle}
          handleClose={() => setOpenResults(false)}
          score={currentScore}
          scoreText={currentScoreText}
        />
      </Modal>
    </>
  )
}
