import { ArticleTable as AT } from '../../features/article'
import { RouteProps } from '../../routes/AppRouter'
import { CurrentNavHeader } from '../../components/current-nav-header/CurrentNavHeader'
import React from 'react'

export function ArticlesTable(props: RouteProps) {
  const title = props.title || ''

  return (
    <>
      <CurrentNavHeader title={title} />
      <AT />
    </>
  )
}
