import { Box, TextField } from '@mui/material'
import generic from '../../assets/generic.module.css'
import { AppTable, Field } from '../../components/table'
import React, { ChangeEvent, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Actions, Pager, Search, SearchValue, Sort } from '../../components/table/types'
import { Query, QueryParam, QueryParamN, SortParam } from '../../common/api/Query'
import deleteIcon from '../../assets/higea/ico_eliminar-copy.svg'
import styles from './Goals.module.css'
import { AppButton, ButtonTheme } from '../../components/app-button/AppButton'
import { getGoalContainer } from '../../container/goal-module'
import { GOAL_SERVICE_KEY } from '../../modules/goals'
import { GoalService } from '../../modules/goals/services/GoalService'
import { Goal, GoalQuery } from '../../modules/goals/models/Goal'
import { ILoggedUserService } from '../../modules/users/services/LoggedUserService'
import { LOGGED_USER_SERVICE_KEY } from '../../modules/users'
import { getUserContainer } from '../../container/user-module'
import { v4 as uuidv4 } from 'uuid'

const userContainer = getUserContainer()
const goalService = getGoalContainer().get<GoalService>(GOAL_SERVICE_KEY)
const loggedUserService = userContainer.get<ILoggedUserService>(LOGGED_USER_SERVICE_KEY)

const searcherQuery = (
  svs: SearchValue<GoalQuery>[]
): QueryParam<GoalQuery>[] | QueryParamN<GoalQuery>[] =>
  svs.filter((sv) => sv.value).map((sv) => ({ name: sv.name, value: sv.value as string }))

export const Goals = () => {
  const { t } = useTranslation()
  const loggedUser = loggedUserService.get()
  const [goal, setGoal] = useState<string>('')
  const [parentTitle, setParentTitle] = useState<string>('')
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [items, setItems] = useState<Goal[]>([])
  const [pager, setPager] = useState<Pager>()
  const [page, setPage] = useState<number>(0)
  const [count, setCount] = useState<number>(0)
  const [rowsPerPage, setRowsPerPage] = useState<number>(10)
  const [sort, setSort] = useState<SortParam<Goal>>({
    field: 'parentTitle',
    desc: true,
  })
  const [searcher, setSearcher] = useState<SearchValue<GoalQuery>[]>([
    {
      name: 'title',
      label: t('search') + ' ' + t('title'),
    },
  ])

  useEffect(() => {
    if (!isLoading) return
    goalService
      .getFilteredList(
        new Query({
          pager: { offset: page * rowsPerPage, limit: rowsPerPage },
          query: [...searcherQuery(searcher)],
          sort: [{ field: sort.field, desc: sort.desc }],
        })
      )
      .subscribe((res) => {
        setItems(res.items)
        setCount(res.count)
        setIsLoading(!isLoading)
      })
  }, [isLoading])

  useEffect(() => {
    setIsLoading(!isLoading)
    setPager({
      page,
      count,
      handleChangePage,
      rowsPerPage,
      handleChangeRowsPerPage,
    })
  }, [page, count, rowsPerPage])

  const removeGoal = (g: Goal) =>
    g?.id && goalService.delete(g.id).subscribe(() => setIsLoading(!isLoading))

  const handleChangePage = (event: unknown, value: number) => setPage(value)

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) =>
    setRowsPerPage(Number.parseInt(event.target.value) || 10)

  const fields: Field<Goal>[] = [
    {
      searchable: true,
      sortable: true,
      label: t('goalParentTitle'),
      name: 'parentTitle',
    },
    {
      searchable: true,
      sortable: true,
      label: t('objective'),
      name: 'title',
    },
    {
      searchable: true,
      sortable: true,
      label: t('dateOfAdd'),
      name: 'createdAt',
      renderFunc: (f, i) =>
        loggedUserService.get()?.language == 1
          ? (new Date(i.createdAt).getDate() <= 9 ? '0' : '') +
            new Date(i.createdAt).getDate() +
            '/' +
            (new Date(i.createdAt).getMonth() <= 9 ? '0' : '') +
            (new Date(i.createdAt).getMonth() + 1) +
            '/' +
            new Date(i.createdAt).getFullYear().toString()
          : new Date(i.createdAt).toLocaleDateString(),
    },
  ]

  const actions: Actions<Goal> = {
    actionsColumn: t('Actions'),
    items: [
      {
        handler: removeGoal,
        icon: deleteIcon,
        label: 'delete',
      },
    ],
  }

  const search: Search<any> = {
    searchValues: searcher,
    handleSearch: (svs: SearchValue<any>[]) => {
      let auxiliar
      const result: SearchValue<any>[] = svs.map((s) => {
        if (s.type === 'date' && s.value) {
          return Object.assign({ ...s }, { value: new Date('' + s.value).toISOString() })
        }

        if (s.value) {
          auxiliar = Array.from(s.value)
          if (auxiliar[0] == auxiliar[0].toUpperCase()) {
            auxiliar[0] = auxiliar[0].toLowerCase()
            auxiliar = auxiliar.join('')
          } else {
            auxiliar[0] = auxiliar[0].toUpperCase()
            auxiliar = auxiliar.join('')
          }
        }

        return s
      })

      result.push({
        label: '',
        name: 'title2',
        value: auxiliar,
      })

      setSearcher(result)
      setIsLoading(true)
    },
  }

  const sortable: Sort<Goal> = {
    name: sort.field,
    direction: sort.desc ? 'desc' : 'asc',
    handleSort: (field) => {
      setSort({ field: field, desc: sort.field === field ? !sort.desc : true })
      setIsLoading(true)
    },
  }

  const handleGoal = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) =>
    setGoal(e.currentTarget.value)

  const handleParentTitle = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) =>
    setParentTitle(e.currentTarget.value)

  const handleSave = () => {
    if (!loggedUser?.id) return
    goalService
      .add({
        id: uuidv4(),
        title: goal,
        parentTitle: parentTitle,
        createdAt: new Date(),
        creatorID: loggedUser.id,
      })
      .subscribe(() => setIsLoading(!isLoading))
  }

  return (
    <>
      <Box className={generic.pageContainer}>
        <Box className={styles.addGoalsContainer}>
          <h4 className={styles.addGoalsTitle}>{t('addGoal')}</h4>
          <Box display="flex" flexDirection="row">
            <Box display="flex" flexDirection="column" style={{ width: '46%' }}>
              <h5 style={{ marginBottom: 0, fontSize: '16px', color: '#000' }}>
                {t('goalParentTitle')}
              </h5>
              <TextField
                id={'parentTitle'}
                key={'parentTitle'}
                name="parentTitle"
                className={styles.textField}
                value={parentTitle}
                type="name"
                required={true}
                variant={'outlined'}
                size={'small'}
                onChange={handleParentTitle}
              />
            </Box>

            <Box display="flex" flexDirection="column" style={{ width: '46%', marginLeft: '30px' }}>
              <h5 style={{ marginBottom: 0, fontSize: '16px', color: '#000' }}>{t('objective')}</h5>
              <TextField
                id={'goal'}
                key={'goal'}
                name="goal"
                className={styles.textField}
                value={goal}
                type="name"
                required={true}
                variant={'outlined'}
                size={'small'}
                onChange={handleGoal}
              />
            </Box>
            <Box
              display="flex"
              flexDirection="column"
              style={{ justifyContent: 'flex-end', marginLeft: '30px', marginBottom: 4 }}
            >
              <AppButton
                theme={ButtonTheme.NewPrimaryLight}
                type={'button'}
                label={t('save')}
                handler={handleSave}
              />
            </Box>
          </Box>
        </Box>
        {/*<Box className={style.buttonContainer}>
          <AppButton
            theme={ButtonTheme.NewPrimaryLight}
            type={'button'}
            label={t('new')}
            handler={() => console.log('')}
            startIcon={AddIcon}
          />
        </Box>*/}
        <AppTable
          search={search}
          items={items}
          pager={pager}
          fields={fields}
          rowKeyField={'id'}
          sort={sortable}
          actions={actions}
          searchWithIcon={true}
        />
      </Box>
    </>
  )
}
