import { EducationalUser } from './EducationalUser'

export interface EducationalUserDTO {
  userID: string
  educationalID: string
  completed: boolean
  date: Date
}

export function emptyEducationalUserDTO(): EducationalUserDTO {
  return {
    userID: '',
    educationalID: '',
    completed: false,
    date: new Date()
  }
}

export function fromModel(f: EducationalUser): EducationalUserDTO {
  return {
    userID: f.userID,
    educationalID: f.educationalID,
    completed: f.completed,
    date: f.date,
  }
}

export function toModel(d: EducationalUserDTO): EducationalUser {
  return new EducationalUser(d)
}
