import { v4 as uuidv4 } from 'uuid'
import { PatientLesson } from './PatientLesson'

export interface PatientLessonDTO {
  id: string
  patientID: string
  lessonID: string
  createdAt: Date
  started: boolean
  finished: boolean
  creatorID: string
  index: number
}

export function emptyPatientLessonDTO(): PatientLessonDTO {
  return {
    id: uuidv4(),
    patientID: '',
    lessonID: '',
    createdAt: new Date(),
    started: false,
    finished: false,
    creatorID: '',
    index: 0,
  }
}

export function fromModel(f: PatientLesson): PatientLessonDTO {
  return {
    id: f.id,
    patientID: f.patientID,
    lessonID: f.lessonID,
    createdAt: f.createdAt,
    started: f.started,
    finished: f.finished,
    creatorID: f.creatorID,
    index: f.index,
  }
}

export function toModel(d: PatientLessonDTO): PatientLesson {
  return new PatientLesson(d)
}
