import React, { FormEvent, useEffect, useState } from 'react'
import { ARTICLE_SERVICE_KEY } from '../../modules/content'
import { FormCard } from '../../components/form-card/FormCard'
import { Box } from '@material-ui/core'
import { AppButton, ButtonTheme } from '../../components/app-button/AppButton'
import { navigate } from '@reach/router'
import { ROUTE_ARTICLES } from '../../routes/routes-constants'
import { useTranslation } from 'react-i18next'
import { getContentContainer } from '../../container/content-module'
import { ArticleDTO, emptyArticleDTO, fromModel } from '../../modules/content/models/ArticleDTO'
import { ArticleService } from '../../modules/content/services/ArticleService'
import { TextFieldItem } from '../../components/form-card/TextFieldItem'
import { Alert } from '@material-ui/lab'
import genericStyle from '../../common/utils/generic.module.css'

type EditorProps = {
  id?: string
}

const contentContainer = getContentContainer()
const articleService = contentContainer.get<ArticleService>(ARTICLE_SERVICE_KEY)

export function Editor(props: EditorProps) {
  const { t } = useTranslation()

  const [article, setArticle] = useState<ArticleDTO>(emptyArticleDTO())
  const [errorMessage, setErrorMessage] = useState<string>('')

  useEffect(() => {
    if (!props.id) {
      return
    }
    articleService.getByID(props.id).subscribe((res) => res && setArticle(fromModel(res)))
  }, [])

  const handleInput = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) =>
    setArticle(Object.assign({ ...article }, { [e.target.name]: e.target.value }))

  const goBack = () => navigate(ROUTE_ARTICLES).then()

  const validateArticle = (): boolean => {
    setErrorMessage('')
    return true
  }

  const saveArticle = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    if (!validateArticle()) {
      return
    }

    if (props.id) {
      articleService.update(article).subscribe(() => goBack())
      return
    }
    articleService.add(article).subscribe(() => navigate(ROUTE_ARTICLES))
  }

  return (
    <Box className={genericStyle.pageContainer}>
      <FormCard>
        <form onSubmit={(e) => saveArticle(e)}>
          <Box mb={3}>
            <TextFieldItem
              field="title"
              value={article.ask}
              label={'title'}
              type={'text'}
              handleChange={handleInput}
              rows={undefined}
              required={true}
            />
          </Box>

          <Box mb={3}>
            <TextFieldItem
              field="content"
              value={article.order.toString()}
              label={'content'}
              type={'text'}
              handleChange={handleInput}
              rows={10}
              required={true}
            />
          </Box>

          {errorMessage && (
            <Box mb={3}>
              <Alert severity="warning" key="errorMessage" id="errorMessage">
                {t(errorMessage)}
              </Alert>
            </Box>
          )}

          <Box display="flex" justifyContent="space-between">
            <AppButton
              theme={ButtonTheme.NewSecondary}
              type={'button'}
              label={t('cancel')}
              handler={goBack}
            />
            <AppButton
              theme={ButtonTheme.NewPrimary}
              type={'submit'}
              label={t('save')}
              handler={() => {}}
            />
          </Box>
        </form>
      </FormCard>
    </Box>
  )
}
