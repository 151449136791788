import { getUserContainer } from '../../container/user-module'
import { IUserService, LOGGED_USER_SERVICE_KEY, USER_SERVICE_KEY } from '../../modules/users'
import { Box } from '@material-ui/core'
import { AppTable, Field } from '../../components/table'
import { User, UserQuery } from '../../modules/users/models/User'
import { useTranslation } from 'react-i18next'
import React, { useEffect, useState } from 'react'
import { BoolQueryParam, Query, QueryParam, QueryParamN } from '../../common/api/Query'
import { Actions, Pager, Search, SearchValue } from '../../components/table/types'
import genericStyle from '../../common/utils/generic.module.css'
import { AppButton, ButtonTheme } from '../../components/app-button/AppButton'
import addUser from '../../assets/left_menu/add-user.svg'
import { ROUTE_PATIENTS, ROUTE_USERS_CREATE } from '../../routes/routes-constants'
import { navigate } from '@reach/router'
import { UserGender } from '../../modules/users/enums/UserGender'
import editIcon from '../../assets/table_icons/ico-edit.svg'
import { LoggedUserService } from '../../modules/users/services/LoggedUserService'
import deleteIcon from '../../assets/table_icons/ico-eliminar.svg'

const userContainer = getUserContainer()
const userService = userContainer.get<IUserService>(USER_SERVICE_KEY)
const loggedUserService = userContainer.get<LoggedUserService>(LOGGED_USER_SERVICE_KEY)

export function Table() {
  /*
  const { t } = useTranslation()

  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [users, setUsers] = useState<User[]>([])
  const [count, setCount] = useState<number>(0)
  const [doctors, setDoctors] = useState<User[]>([])
  const [page, setPage] = useState<number>(0)
  const [pager, setPager] = useState<Pager>()
  const [rowsPerPage, setRowsPerPage] = useState<number>(10)

  const [searcher, setSearcher] = useState<SearchValue<UserQuery>[]>([
    {
      name: 'firstName',
      label: t('search') + '...',
    },
  ])

  useEffect(() => {
    if (!isLoading) {
      return
    }
    userService
      .getFilteredList(
        new Query({
          query: [
            {
              name: 'isPatient',
              value: new BoolQueryParam(true),
            },
            ...searcherQuery(searcher),
          ],
          pager: { offset: page * rowsPerPage, limit: rowsPerPage },
        })
      )
      .subscribe((res) => {
        setIsLoading(false)
        setUsers(res.items)
        setCount(res.count)
      })
  }, [isLoading])

  useEffect(() => {
    if (!users.length) {
      return
    }

    const ids = users.map((u) => u.assignedID)
    userService
      .getFilteredList(
        new Query({
          pager: { offset: 0, limit: ids.length },
          query: [
            {
              name: 'ids',
              value: ids,
            },
          ],
        })
      )
      .subscribe((res) => setDoctors(res.items))
  }, [users])

  useEffect(() => {
    setIsLoading(true)
    setPager({
      page,
      count,
      handleChangePage,
      rowsPerPage,
      handleChangeRowsPerPage,
    })
  }, [page, count, rowsPerPage])

  useEffect(() => {
    setIsLoading(true)
  }, [searcher])

  const handleChangePage = (event: unknown, value: number) => setPage(value)

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (Number.isNaN(event.target.value)) {
      setRowsPerPage(10)
      return
    }
    setRowsPerPage(Number.parseInt(event.target.value))
  }

  const handlerAddUser = () => navigate(ROUTE_USERS_CREATE)

  const fields: Field<User>[] = [
    {
      name: 'firstName',
      label: t('fullName'),
      renderFunc: (f, i) => i.firstName + ' ' + i.lastName,
    },
    {
      name: 'historyRecordNumber',
      label: t('historyRecordNumber'),
    },
    {
      name: 'diagnosisSummary',
      label: t('diagnosisSummary'),
    },
    {
      name: 'assignedID',
      label: t('assignedID'),
      renderFunc: (f, i) => {
        if (!i.assignedID) {
          return ''
        }

        const d = doctors.find((d) => d.id === i.assignedID)
        if (!d?.lastName) {
          return ''
        }
        return t(d.gender === UserGender.Female ? 'femaleDoctor' : 'maleDoctor') + ' ' + d.lastName
      },
    },
  ]

  const search: Search<UserQuery> = {
    searchValues: searcher,
    handleSearch: (svs: SearchValue<UserQuery>[]) => setSearcher(svs),
  }

  const editUser = (u: User) => navigate(ROUTE_PATIENTS)

  const deleteUser = (u: User) => navigate(ROUTE_PATIENTS)

  const isCreator = (u: User): boolean => u.assignedID === loggedUserService.get()?.id

  const actions: Actions<User> = {
    actionsColumn: t('Actions'),
    items: [
      {
        handler: editUser,
        icon: editIcon,
        label: 'edit',
        hidden: (u) => !isCreator(u),
      },
      {
        handler: deleteUser,
        icon: deleteIcon,
        label: 'delete',
        hidden: (u) => !isCreator(u),
      },
    ],
  }
   */

  return (
    <Box className={genericStyle.pageContainer}>
      {/*<Box style={{ marginTop: '10px' }}>
        <AppButton
          startIcon={addUser}
          theme={ButtonTheme.NewSecondary}
          type={'button'}
          label={t('addParticipant')}
          handler={handlerAddUser}
        />
      </Box>
      <AppTable
        rowKeyField="id"
        items={users}
        fields={fields}
        actions={actions}
        search={search}
        pager={pager}
      />*/}
    </Box>
  )
}

const searcherQuery = (
  svs: SearchValue<UserQuery>[]
): QueryParam<UserQuery>[] | QueryParamN<UserQuery>[] =>
  svs.filter((sv) => sv.value).map((sv) => ({ name: sv.name, value: sv.value as string }))
