import generic from '../../../../assets/generic.module.css'
import { Box } from '@mui/material'
import React from 'react'

export const CVS330 = () => {
  return (
    <>
      <Box className={generic.pageContainer} style={{ backgroundColor: 'white' }}>
        <Box className={generic.pageContainer} display="flex">
          <h2>
            <b>3.3 Disfunción vesical neurógena</b>
          </h2>
        </Box>
        <Box className={generic.pageContainer}>
          <div>
            La vejiga neurógena es un trastorno del tracto urinario inferior causado por daños o
            enfermedades del sistema nervioso.
            <br />
            <br />
            <br />
            <br />
            <b>Problema</b>
            <br />
            <br />
            La vejiga neurógena, que se da en muchos pacientes con trastornos neurológicos, como
            esclerosis múltiple, enfermedad de Parkinson, lesión medular y espina bífida, puede
            provocar síntomas y complicaciones problemáticos, como incontinencia urinaria,
            frecuencia y urgencia, así como el riesgo de infección y afectación del tracto urinario
            superior y enfermedad renal.
            <br />
            <br />
            El trastorno también puede causar vergüenza y esto puede desembocar en el aislamiento
            social de los pacientes afectados.
            <br />
            <br />
            El daño o las enfermedades en el sistema nervioso central (SNC) o dentro del sistema
            nervioso periférico o autónomo pueden provocar una disfunción vesical neurógena. Esta
            disfunción puede surgir como resultado de varias afecciones neurológicas y se ha
            encontrado en entre el 40 % y el 90 % de los pacientes en Estados Unidos con esclerosis
            múltiple (EM), en entre el 37 % y el 72 % de los pacientes con Parkinson y en el 15 % de
            los pacientes con accidente cerebrovascular.
            <br />
            <br />
            <br />
            <br />
            Se calcula que entre el 70 % y el 84 % de los pacientes con lesiones medulares sufren al
            menos algún grado de disfunción vesical. La disfunción vesical también se observa con
            frecuencia en pacientes con espina bífida, con reflujo vesicoureteral presente en hasta
            el 40 % de los niños afectados a los 5 años de edad y en hasta el 60,9 % de los adultos
            jóvenes con espina bífida que sufren incontinencia urinaria.
            <br />
            <br />
            Entre los síntomas urinarios molestos relacionados con la vejiga neurógena se incluyen
            incontinencia urinaria (IU), frecuencia y urgencia. Los pacientes también pueden sufrir
            un mayor riesgo e incidencia de infecciones del tracto urinario (ITU) y obstrucción de
            la salida de la vejiga.
            <br />
            <br />
          </div>
        </Box>
      </Box>
    </>
  )
}
