import { Intervention } from './Intervention'

export interface InterventionDTO {
  id: string
  name: string
}

export function emptyInterventionDTO(): InterventionDTO {
  return {
    id: '',
    name: '',
  }
}

export function toModel(p: InterventionDTO): Intervention {
  return new Intervention(p)
}

export function fromModel(p: Intervention): InterventionDTO {
  return {
    id: p.id,
    name: p.name,
  }
}
