import generic from '../../../../assets/generic.module.css'
import { Box } from '@mui/material'
import React from 'react'

export const CVS130 = () => {
  return (
    <>
      <Box className={generic.pageContainer} style={{ backgroundColor: 'white' }}>
        <Box className={generic.pageContainer} display="flex">
          <h2>
            <b>1.3. Vejiga hiperactiva</b>
          </h2>
        </Box>
        <Box className={generic.pageContainer}>
          <div>
            Una vejiga hiperactiva puede provocar una necesidad repentina de orinar, que puede ser
            difícil de reprimir, y puede causar pérdidas.
            <br />
            <br />
            Es una condición estresante y embarazosa que lleva a muchas personas a aislarse,
            limitando su trabajo y privándose de una vida social
            <br />
            <br />
            <b>Causas</b>
            <br />
            <br />
            • &nbsp;&nbsp;&nbsp;&nbsp;Vejiga neurógena
            <br />
            <br />
            • &nbsp;&nbsp;&nbsp;&nbsp;Afecciones como la esclerosis múltiple (EM), la espina bífida
            o la lesión medular pueden afectar neurológicamente a la vejiga
            <br />
            <br />
            • &nbsp;&nbsp;&nbsp;&nbsp;Vaciado incompleto de la vejiga
            <br />
            <br />
            • &nbsp;&nbsp;&nbsp;&nbsp;Diabetes
            <br />
            <br />
            • &nbsp;&nbsp;&nbsp;&nbsp;Medicación
            <br />
            <br />
            • &nbsp;&nbsp;&nbsp;&nbsp;Demasiada cafeína o alcohol
            <br />
            <br />
            <b>Tratamiento</b>
            <br />
            <br />
            El tratamiento de la vejiga hiperactiva suele comenzar con estrategias conductuales,
            como:
            <br />
            <br />
            • &nbsp;&nbsp;&nbsp;&nbsp;Horarios de consumo de líquidos
            <br />
            <br />
            • &nbsp;&nbsp;&nbsp;&nbsp;Rutinas miccionales
            <br />
            <br />
            • &nbsp;&nbsp;&nbsp;&nbsp;Técnicas de retención de la vejiga para fortalecer el suelo
            pélvico
            <br />
            <br />
            • &nbsp;&nbsp;&nbsp;&nbsp;Otros tratamientos incluyen cirugía, inyecciones de Botox y
            catéteres permanentes, dispositivos de contención
            <br />
            <br />
            • &nbsp;&nbsp;&nbsp;&nbsp;Medicación
            <br />
            <br />
          </div>
        </Box>
      </Box>
    </>
  )
}
