import { Container, IInit } from '../../../common/container/Container'
import { Observable, of } from 'rxjs'
import { Query } from '../../../common/api/Query'
import { emptyList, ItemList } from '../../../common/models/ItemList'
import { HTTP_CLIENT_KEY, IHTTPClient } from '../../../common/api/HTTPClient'
import { IStatusService } from '../../../common/status/StatusService'
import { STATUS_SERVICE_KEY } from '../../../container/app'
import { ContentContainerConfig } from '../../content/container'
import { catchError, map } from 'rxjs/operators'
import { prepareURL } from '../../../common/api/http-helpers'
import { toModel, Urination } from '../models/Urination'
import { UrinationDTO } from '../models/UrinationDTO'

export interface IUrinationApi extends IInit {
  getByID(id: string): Observable<Urination | undefined>

  getFilteredList(q: Query<Urination>): Observable<ItemList<Urination>>

  getDiariesLastWeek(q: Query<Urination>): Observable<ItemList<Urination>>

  add(e: UrinationDTO): Observable<Urination | undefined>

  update(e: UrinationDTO): Observable<Urination | undefined>

  delete(id: string): Observable<boolean>

  getLastDocument(id: string): Observable<Urination | undefined>

  getExcelFromUrinationDiaries(
    urinations: UrinationDTO[],
    language: string
  ): Observable<Blob | undefined>
}

export class UrinationApi implements IUrinationApi {
  private _container!: Container
  private _httpClient!: IHTTPClient
  private _url!: string
  private _statusService!: IStatusService

  init(c: Container) {
    this._container = c
    this._httpClient = this._container.get<IHTTPClient>(HTTP_CLIENT_KEY)
    this._statusService = this._container.get<IStatusService>(STATUS_SERVICE_KEY)
    this._url = (this._container.config as ContentContainerConfig).moduleFullUrl
  }

  add(e: UrinationDTO): Observable<Urination | undefined> {
    return this._httpClient.post<Urination>({ url: this._url, body: e }).pipe(
      map<UrinationDTO, Urination>((d) => {
        this._statusService.sendStatus({ variant: 'success' })
        return toModel(d)
      }),
      catchError((err) => {
        this._statusService.sendStatus({ variant: 'error', error: err })
        return of(undefined)
      })
    )
  }

  delete(id: string): Observable<boolean> {
    return this._httpClient.delete({ url: `${this._url}/${id}` }).pipe(
      catchError((err) => {
        this._statusService.sendStatus({ variant: 'error', error: err })
        return of(false)
      })
    )
  }

  getByID(id: string): Observable<Urination | undefined> {
    return this._httpClient.get<Urination>({ url: `${this._url}/${id}` }).pipe(
      map<UrinationDTO, Urination>((d) => toModel(d)),
      catchError((err) => {
        this._statusService.sendStatus({ variant: 'error', error: err })
        return of(undefined)
      })
    )
  }

  getFilteredList(q: Query<Urination>): Observable<ItemList<Urination>> {
    return this._httpClient.get<ItemList<Urination>>({ url: prepareURL(this._url, q) }).pipe(
      map<ItemList<UrinationDTO>, ItemList<Urination>>((dto) => {
        const itemList = emptyList<Urination>()
        itemList.count = dto.count
        itemList.items = dto.items.map((d) => toModel(d))
        return itemList
      }),
      catchError((err) => {
        this._statusService.sendStatus({ variant: 'error', error: err })
        return of(emptyList<Urination>())
      })
    )
  }

  update(e: UrinationDTO): Observable<Urination | undefined> {
    return this._httpClient.put<Urination>({ url: this._url, body: e }).pipe(
      map<UrinationDTO, Urination>((d) => {
        this._statusService.sendStatus({ variant: 'success' })
        return toModel(d)
      }),
      catchError((err) => {
        this._statusService.sendStatus({ variant: 'error', error: err })
        return of(undefined)
      })
    )
  }

  getDiariesLastWeek(q: Query<Urination>): Observable<ItemList<Urination>> {
    return this._httpClient
      .post<ItemList<Urination>>({ url: prepareURL(this._url + '/lastweek', q) })
      .pipe(
        map<ItemList<UrinationDTO>, ItemList<Urination>>((dto) => {
          const itemList = emptyList<Urination>()
          itemList.count = dto.count
          itemList.items = dto.items.map((d) => toModel(d))
          return itemList
        }),
        catchError((err) => {
          this._statusService.sendStatus({ variant: 'error', error: err })
          return of(emptyList<Urination>())
        })
      )
  }

  getLastDocument(id: string): Observable<Urination | undefined> {
    return this._httpClient.get<Urination>({ url: `${this._url}/getLastDocument/${id}` }).pipe(
      map<UrinationDTO, Urination>((d) => toModel(d)),
      catchError((err) => {
        //this._statusService.sendStatus({ variant: 'error', error: err })
        return of(undefined)
      })
    )
  }

  getExcelFromUrinationDiaries(
    urinations: UrinationDTO[],
    language: string
  ): Observable<Blob | undefined> {
    return this._httpClient
      .post<Blob>({
        url: `${this._url}/urination-diaries-excel/${language}`,
        body: urinations,
        responseType: 'blob',
      })
      .pipe(
        map<Blob, Blob>((d) => d),
        catchError((err) => {
          console.log('Error generando el excel', err)
          //this._statusService.sendStatus({ variant: 'error', error: err })
          return of(undefined)
        })
      )
  }
}
