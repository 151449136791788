import { Box } from '@material-ui/core'
import { Header } from '../header/Header'
import style from './CustomModal.module.css'
import { useTranslation } from 'react-i18next'
import { AppButton, ButtonTheme } from '../app-button/AppButton'
import close from '../../assets/table_icons/ico-eliminar.svg'
import Grid from '@mui/material/Grid'
import { informedConsent } from '../../assets/legalTerms/InformedConsent'
import { cookiesPolicy } from '../../assets/legalTerms/CookiesPolicy'
import { privacyPolicy } from '../../assets/legalTerms/PrivacyPolicy'
import { termsOfUse } from '../../assets/legalTerms/TermsOfUse'
import { Alert, AlertTitle } from '@mui/material'

type toAgoraProps = {
  title: string
  modalMustAllAccept?: boolean
  warningText: string
  linkOnWarning?: boolean
  handleClose: () => void
  handleSave: () => void
  handleChangeCheck: () => void
  handleTermsOfUse?: () => void
  handleDataTreatment?: () => void
  handleCookiesPolicy?: () => void
}

const INFORMED_CONSENT_URL = '/sharedlegalTerms/informed_consent.pdf'
const PRIVACY_POLICY_PATIENT_URL = '/sharedlegalTerms/privacy_policy_patients.pdf'
const COOKIES_POLICY_URL = 'https://www.wellspect.es/politica-de-cookies/higea'
const PRIVACY_POLICY_URL = 'https://www.wellspect.es/politica-de-privacidad-de-datos/higea_hcp'
const TERMS_OF_USE_URL = 'https://www.wellspect.es/servicios/higea/terminos-de-uso'
const DATA_TREATMENT_URL = 'https://www.wellspect.es/servicios/higea/tratamiento-de-datos'

const LINK_NAME_INFORMED_CONSENT = 'informed_consent.pdf'
const LINK_NAME_COOKIES_POLICY = 'cookies_policy.pdf'
const LINK_NAME_TERMS_OF_USE = 'terms_of_use.pdf'
const LINK_NAME_PRIVACY_POLICY = 'privacy_policy.pdf'
const LINK_NAME_PRIVACY_POLICY_PATIENT = 'privacy_policy_patients.pdf'

export const LegalTermsModal = (props: toAgoraProps): JSX.Element => {
  const { t } = useTranslation()

  const downloadPDF = (pdfURL: string, linkName: string) => {
    fetch(pdfURL, {
      headers: {
        'Content-Type': 'application/pdf',
      },
    })
      .then((response) => response.blob())
      .then((blob) => {
        // Crea una URL temporal para el archivo Blob.
        const url = URL.createObjectURL(blob)
        // Crea un enlace <a> temporal y simula un clic para iniciar la descarga.
        const link = document.createElement('a')
        link.href = url
        link.download = linkName
        link.click()

        // Libera la URL temporal después de la descarga.
        URL.revokeObjectURL(url)
      })
      .catch((error) => {
        console.error('Error al descargar el archivo PDF:', error)
      })
  }

  const redirectTo = (url: string) => {
    window.open(url, '_blank')
  }

  return (
    <div>
      <div className={style.agoraContainer}>
        <Box className={style.containerInside}>
          <Header label={props.title} icon={close} onClick={props.handleClose} />
          <form>
            <Box mb={3}>
              <Grid container spacing={2}>
                <Grid item xs={1} style={{ marginTop: '2.5%' }}>
                  {props.title == t('privacyPoliticPatientRegister') && (
                    <input
                      type="checkbox"
                      id="topping"
                      name="topping"
                      onChange={props.handleChangeCheck}
                    />
                  )}
                </Grid>
                <Grid item xs={11} style={{ marginLeft: '-3%' }}>
                  <p style={{ textAlign: 'center', fontFamily: 'Open-sans, sans-serif' }}>
                    {props.warningText}
                    {props.linkOnWarning && (
                      <>
                        <a href="#" onClick={() => redirectTo(PRIVACY_POLICY_URL)}>
                          {t('privacyPolicy')}
                        </a>
                        <span>{t('professionalRegisterCheck2')}</span>
                      </>
                    )}
                  </p>
                </Grid>
              </Grid>
            </Box>
            {props.title == t('privacyPoliticPatientRegister') && (
              <Box style={{ display: 'flex', justifyContent: 'flex-end', marginBottom: '3%' }}>
                <AppButton
                  theme={ButtonTheme.NewPrimaryLightWithoutWidth}
                  type={'button'}
                  label={t('downloadInformedConsent')}
                  handler={() => downloadPDF(INFORMED_CONSENT_URL, LINK_NAME_INFORMED_CONSENT)}
                />
              </Box>
            )}
            {props.title == t('privacyPoliticProfesionalRegister') && (
              <Grid container spacing={2}>
                <Grid item xs={11} style={{ display: 'flex' }}>
                  <input
                    style={{ marginRight: 10 }}
                    type="checkbox"
                    id="topping"
                    name="topping"
                    onChange={() => (props.handleTermsOfUse ? props.handleTermsOfUse() : null)}
                  />

                  <p style={{ width: '100%', margin: 0 }}>
                    {t('AcceptIt')}
                    <a
                      href="#"
                      style={{ marginRight: '1.3%' }}
                      onClick={() => redirectTo(TERMS_OF_USE_URL)}
                    >
                      {t('theTermsOfUse')}
                    </a>
                  </p>
                </Grid>
                <Grid item xs={11} style={{ display: 'flex' }}>
                  <input
                    style={{ marginRight: 10 }}
                    type="checkbox"
                    id="topping"
                    name="topping"
                    onChange={() =>
                      props.handleDataTreatment ? props.handleDataTreatment() : null
                    }
                  />
                  <p style={{ width: '100%', margin: 0 }}>
                    {t('acceptHe')}
                    <a
                      href="#"
                      style={{ marginRight: '1.5%' }}
                      onClick={() => redirectTo(DATA_TREATMENT_URL)}
                    >
                      {t('dataTreatment')}
                    </a>
                  </p>
                </Grid>
                <Grid item xs={11} style={{ display: 'flex', marginTop: 0 }}>
                  <input
                    style={{ marginRight: 10 }}
                    type="checkbox"
                    id="topping"
                    name="topping"
                    onChange={() =>
                      props.handleCookiesPolicy ? props.handleCookiesPolicy() : null
                    }
                  />
                  <p style={{ width: '100%', paddingBottom: 0, margin: 0 }}>
                    {t('acceptShe')}
                    <a href="#" onClick={() => redirectTo(COOKIES_POLICY_URL)}>
                      {t('theCookiesPolicy')}
                    </a>
                  </p>
                </Grid>
              </Grid>
            )}
            {/* {props.modalMustAllAccept && (
                <Alert severity="warning" key="errorMessage" id="errorMessage">
                  <AlertTitle>{t('mustAcceptAll')}</AlertTitle>
                </Alert>
            )}*/}
            <Box mt={1} mb={1} className={style.buttons}>
              <AppButton
                theme={ButtonTheme.NewSecondary}
                type={'button'}
                label={t('cancel')}
                handler={props.handleClose}
              />
              <AppButton
                theme={ButtonTheme.NewPrimaryLight}
                type={'button'}
                label={t('continue')}
                handler={props.handleSave}
              />
            </Box>
          </form>
        </Box>
      </div>
    </div>
  )
}
