import { Symptom } from './Symptom'

export interface SymptomDTO {
  id: string
  name: string
}

export function emptySymptomDTO(): SymptomDTO {
  return {
    id: '',
    name: '',
  }
}

export function toModel(p: SymptomDTO): Symptom {
  return new Symptom(p)
}

export function fromModel(p: Symptom): SymptomDTO {
  return {
    id: p.id,
    name: p.name,
  }
}
