import { QuestionnaireDTO } from './QuestionnaireDTO'

export class Questionnaire {
  private readonly _id: string
  private readonly _title: string
  private _checked: boolean | undefined

  constructor(q: QuestionnaireDTO) {
    this._id = q.id
    this._title = q.title
    this._checked = false
  }

  get id(): string {
    return this._id
  }

  get title(): string {
    return this._title
  }

  get checked(): boolean | undefined {
    return this._checked
  }

  set checked(value: boolean | undefined) {
    this._checked = value
  }
}

export function toModel(d: QuestionnaireDTO): Questionnaire {
  return new Questionnaire(d)
}
