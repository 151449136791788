import { getUserContainer } from '../../container/user-module'
import { ILoggedUserService } from '../../modules/users/services/LoggedUserService'
import { IUserService, LOGGED_USER_SERVICE_KEY, USER_SERVICE_KEY } from '../../modules/users'
import React, { ChangeEvent, useEffect, useState } from 'react'
import { Box } from '@material-ui/core'
import styles from './Editor.module.css'
import generic from '../../assets/generic.module.css'
import { AvatarContainer } from './AvatarContainer'
import { Profile } from './Profile'
import { ROUTE_HOME } from '../../routes/routes-constants'
import { navigate } from '@reach/router'
import { emptyUserDTO, User } from '../../modules/users/models/User'
import { forkJoin } from 'rxjs'
import { IRolesService } from '../../modules/users/services/RolesServices'
import { ROLES_SERVICE_KEY } from '../../modules/users/container'
import { dataToBase64 } from '../../common/utils/file'
import { emptyFileDTO, FileDTO } from '../../modules/files/models/FileDTO'
import { getFileContainer } from '../../container/file-module'
import { FILE_SERVICE_KEY, IFileService } from '../../modules/files'
import { getPatientContainer } from '../../container/patient-module'
import { IPatientFileService } from '../../modules/patients/services/PatientFileService'
import { PATIENT_FILE_SERVICE_KEY } from '../../modules/patients'
import { emptyPatientFileDTO, PatientFileDTO } from '../../modules/patients/models/PatientFileDTO'
import { v4 } from 'uuid'
import { useGlobalContext } from '../../common/utils/MyGlobalContext'
import { AppButton, ButtonTheme } from '../../components/app-button/AppButton'
import { useTranslation } from 'react-i18next'

const userContainer = getUserContainer()
const userService = userContainer.get<IUserService>(USER_SERVICE_KEY)
const roleService = userContainer.get<IRolesService>(ROLES_SERVICE_KEY)
const loggedUserService = userContainer.get<ILoggedUserService>(LOGGED_USER_SERVICE_KEY)
const fileService = getFileContainer().get<IFileService>(FILE_SERVICE_KEY)
const patientFileService = getPatientContainer().get<IPatientFileService>(PATIENT_FILE_SERVICE_KEY)

export function Editor() {
  const loggedUser = loggedUserService.get()
  const { t } = useTranslation()

  const [isLoading, setIsLoading] = useState<boolean>(false)

  const [photoID, setPhotoID] = useState<string>('')
  const [user, setUser] = useState<User>()
  const [resource, setResource] = useState<FileDTO>(emptyFileDTO(undefined))
  const [role, setRole] = useState<string>('')

  const [patientFile, setPatientFile] = useState<PatientFileDTO>(emptyPatientFileDTO)
  const [dataImagenSelected, setDataImagenSelected] = useState<string>('')
  const { dataImage, setDataImage } = useGlobalContext()

  const handlerLogout = (_: React.MouseEvent<Element, MouseEvent>) => {
    loggedUserService.logout()
    navigate(ROUTE_HOME).then()
  }

  useEffect(() => {
    if (!loggedUser?.id) return
    userService.getByID(loggedUser.id).subscribe((res) => {
      if (res) {
        setPhotoID(res.photoID)
        setUser(res)
        forkJoin(roleService.getByID(res.roleID)).subscribe(
          (roleRes) => roleRes && roleRes[0] && setRole(roleRes[0]?.role)
        )
      }
      setIsLoading(!isLoading)
    })
  }, [photoID, dataImagenSelected])

  useEffect(() => {
    fileService.getByID(photoID).subscribe((res) => {
      if (!res) return
      const data = res?.data
      if (!dataImage) {
        setDataImage(data)
      }
    })
  }, [photoID, isLoading])

  const handleFileInput = async (event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    const files = (event.target as HTMLInputElement).files
    if (!files?.length) {
      return
    }
    const data = await dataToBase64(files[0])
    const file = files[0]
    setResource(Object.assign({ ...resource }, { data, size: data.length }))
    setResource(Object.assign({ ...resource }, { file, name: file.name }))
    setResource(
      Object.assign(
        { ...resource },
        { file, extension: file.name.substring(file.name.lastIndexOf('.') + 1) }
      )
    )
    setResource(Object.assign({ ...resource }, { file, mimeType: file.type }))
    setDataImagenSelected(() => data)
    setDataImage(data || '')
  }

  useEffect(() => {
    saveResource()
    setIsLoading(!isLoading)
  }, [dataImagenSelected])

  const saveResource = () => {
    if (dataImagenSelected !== '' && photoID !== '00000000-0000-0000-0000-000000000000') {
      fileService
        .update({
          id: photoID,
          data: dataImagenSelected,
          size: dataImagenSelected.length,
          name: user?.firstName + '-avatar',
          createdAt: new Date(Date.now()),
          description: resource.description,
          extension: resource.mimeType.substring(resource.mimeType.lastIndexOf('/') + 1),
          mimeType: resource.mimeType,
          ownerID: loggedUser?.id || resource.ownerID,
        })
        .subscribe()
      setPatientFile({
        fileID: resource.id || '',
        patientID: loggedUser?.id || '',
        id: v4(),
      })
      return
    }
    if (photoID === '00000000-0000-0000-0000-000000000000') {
      const uuid = v4()
      fileService
        .add({
          id: uuid,
          data: dataImagenSelected,
          size: dataImagenSelected.length,
          name: user?.firstName + '-avatar',
          createdAt: new Date(Date.now()),
          description: resource.description,
          extension: resource.mimeType.substring(resource.mimeType.lastIndexOf('/') + 1),
          mimeType: resource.mimeType,
          ownerID: loggedUser?.id || resource.ownerID,
        })
        .subscribe((res) => {
          if (!res) return
          setDataImage(res?.data)
        })
      patientFileService
        .add({
          fileID: uuid,
          patientID: loggedUser?.id || resource.ownerID,
          id: v4(),
        })
        .subscribe()
      if (user) user.photoID = uuid
      setPhotoID(uuid)
      userService.update(user?.toDTO() || emptyUserDTO()).subscribe()
      return
    }
  }

  const removeAccount = () => {
    if (user?.id) {
      userService.delete(user?.id).subscribe()
      loggedUserService.logout()
      navigate(ROUTE_HOME).then()
    }
  }

  return (
    <>
      <Box className={generic.pageContainer}>
        <Box className={styles.userProfileContainer}>
          <Box className={styles.leftContainer}>
            <AvatarContainer
              photoID={photoID}
              user={user}
              role={role}
              files={handleFileInput}
              data={dataImage}
              isLoading={isLoading}
            />
            <Box className={styles.removeAccount}>
              <AppButton
                theme={ButtonTheme.NewPrimaryLightWithoutWidth}
                type={'button'}
                label={t('removeAccount')}
                handler={removeAccount}
              />
            </Box>
          </Box>
          <Box className={styles.rightContainer}>{user && <Profile user={user} role={role} />}</Box>
        </Box>
      </Box>
    </>
  )
}
