import { PatientIntervention } from './PatientIntervention'

export interface PatientInterventionDTO {
  id: string
  createdAt: Date
  interventionID: string
  patientID: string
}

export function emptyPatientInterventionDTO(): PatientInterventionDTO {
  return {
    id: '',
    createdAt: new Date(),
    interventionID: '',
    patientID: '',
  }
}

export function toModel(p: PatientInterventionDTO): PatientIntervention {
  return new PatientIntervention(p)
}

export function fromModel(p: PatientIntervention): PatientInterventionDTO {
  return {
    id: p.id,
    createdAt: p.createdAt,
    interventionID: p.interventionID,
    patientID: p.patientID,
  }
}
