import generic from '../../../../assets/generic.module.css'
import { Box, Button, Divider, Modal } from '@mui/material'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import image from './ita.png'

export const CIS331 = () => {
  const { t } = useTranslation()
  const [openModal, setOpenModal] = useState<boolean>(false)
  const { innerWidth } = window
  const handleOpenModal = () => setOpenModal(true)
  const handleCloseModal = () => setOpenModal(false)

  return (
    <>
      <Box style={{ backgroundColor: 'white' }}>
        <Box className={generic.pageContainer} display="flex">
          <h2>
            <b>3.3 ¿Qué es la ITA?</b>
          </h2>
        </Box>

        <Box display="flex" className={generic.pageContainer}>
          <div>
            La ITA es una técnica eficaz para vaciar el intestino mediante la instilación de hasta
            1500 ml de agua tibia a través del ano, en el recto y hasta el ángulo hepático (colon
            descendente y transverso). Sin embargo, el volumen de agua que debe instilarse es
            individual, lo debe recomendar el médico y podría cambiarse según el resultado de la
            irrigación. El agua tiene dos finalidades: ablanda las heces y provoca movimientos
            peristálticos en el colon.
            <br />
            <br />
            El agua se mantiene en el intestino mediante un cono o, cuando se usa una sonda rectal,
            un balón que actúa como un tapón para evitar que el agua se salga. El contenido
            intestinal se expulsa junto con el agua cuando se desinfla el globo o se retira el cono.
            <br />
            <br />
            La ITA es una terapia de mantenimiento y no una cura. El uso habitual de la ITA previene
            los escapes fecales y acelera el tránsito a través del colon. Con la ITA, el recto y el
            colon distal se vaciarán eficazmente de heces y se necesitan aproximadamente 2 días
            hasta que lleguen al recto heces nuevas.
            <br />
            <br />
            Existen numerosas evidencias clínicas que demuestran los beneficios de la ITA. Además de
            reducir los síntomas clínicos del estreñimiento y la incontinencia fecal, los beneficios
            son los siguientes:
            <br />
            <br />
            &nbsp;&nbsp;&nbsp;&nbsp;• &nbsp;&nbsp;&nbsp;&nbsp; mejora de la calidad de vida
            <br />
            <br />
            &nbsp;&nbsp;&nbsp;&nbsp;•&nbsp;&nbsp;&nbsp;&nbsp; menos tiempo dedicado al manejo
            intestinal
            <br />
            <br />
            &nbsp;&nbsp;&nbsp;&nbsp;•&nbsp;&nbsp;&nbsp;&nbsp; menos distensión abdominal
            <br />
            <br />
            &nbsp;&nbsp;&nbsp;&nbsp;•&nbsp;&nbsp;&nbsp;&nbsp; prevención de la incontinencia
            <br />
            <br />
            &nbsp;&nbsp;&nbsp;&nbsp;•&nbsp;&nbsp;&nbsp;&nbsp; conservación de la integridad de la
            piel anal
            <br />
            <br />
            &nbsp;&nbsp;&nbsp;&nbsp;•&nbsp;&nbsp;&nbsp;&nbsp; menos infecciones del tracto urinario
            <br />
            <br />
            En general, la ITA constituye un método terapéutico sencillo para el manejo de la
            disfunción intestinal, tras una falta de mejoría después de aplicar cambios en la
            dieta/el estilo de vida y las medicaciones. La ITA es una terapia reversible que se ha
            demostrado que reduce la necesidad de terapias más invasivas como la neuromodulación y
            la cirugía de estoma. Cuando la ITA demuestra tener éxito en el paciente, se mantiene de
            forma satisfactoria a largo plazo.
          </div>
          <img
            src={image}
            onClick={handleOpenModal}
            style={{
              width: innerWidth < 900 ? '80%' : '80%',
              marginLeft: innerWidth < 900 ? '10%' : '10%',
              marginTop: innerWidth < 900 ? '5%' : '0%',
              borderStyle: 'ridge',
              borderColor: 'dodgerblue',
              height: innerWidth < 900 ? '120%' : '110%',
            }}
          />
        </Box>
        <Modal open={openModal} onClose={handleCloseModal}>
          <Box
            style={{
              backgroundColor: 'white',
              justifyContent: 'center',
              transform: 'translate(0%, 20%)',
              marginLeft: '30%',
              marginRight: '30%',
              padding: '20px',
            }}>
            <h1 style={{ marginLeft: '25%' }}>
              <b>¿Qué es la ITA?</b>
            </h1>
            <Divider variant={'middle'} />
            <img
              src={image}
              style={{
                width: '80%',
                height: '25%',
                marginTop: '1%',
                marginLeft: '10%',
                justifyContent: 'center',
                display: 'flex',
              }}
            />
            <Button
              variant={'outlined'}
              style={{ marginLeft: '45%', marginTop: '1%', justifyContent: 'end', display: 'flex' }}
              onClick={handleCloseModal}>
              {t('close')}
            </Button>
          </Box>
        </Modal>
      </Box>
    </>
  )
}
