import { LessonDTO } from './LessonDTO'

export class Lesson {
  private _id: string
  private _title: string
  private _educationalResourceID: string
  private _video: boolean
  private _routeName: string
  private _exercise: boolean
  private _pathImage: string
  private _index: number

  constructor(p: LessonDTO) {
    this._id = p.id
    this._title = p.title
    this._educationalResourceID = p.educationalResourceID
    this._video = p.video
    this._routeName = p.routeName
    this._exercise = p.exercise
    this._pathImage = p.pathImage
    this._index = p.index
  }

  get id(): string {
    return this._id
  }

  get title(): string {
    return this._title
  }

  get index(): number {
    return this._index
  }

  get educationalResourceID(): string {
    return this._educationalResourceID
  }

  get video(): boolean {
    return this._video
  }

  get routeName(): string {
    return this._routeName
  }

  get exercise(): boolean {
    return this._exercise
  }

  get pathImage(): string {
    return this._pathImage
  }

  set id(value: string) {
    this._id = value
  }

  set title(value: string) {
    this._title = value
  }

  set educationalResourceID(value: string) {
    this._educationalResourceID = value
  }

  set video(value: boolean) {
    this._video = value
  }

  set routeName(value: string) {
    this._routeName = value
  }

  set exercise(value: boolean) {
    this._exercise = value
  }

  set pathImage(value: string) {
    this._pathImage = value
  }
}

export interface LessonQuery {
  name: string
  id: string | undefined
  status: string
}
