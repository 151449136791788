import { LangType } from '../../content/enums/LangType'
import { GoalDTO } from './GoalDTO'

export class Goal {
  private readonly _id: string
  private readonly _createdAt: Date
  private readonly _creatorID: string
  private readonly _parentTitle: string
  private readonly _title: string

  constructor(p: GoalDTO) {
    this._id = p.id
    this._parentTitle = p.parentTitle
    this._title = p.title
    this._createdAt = p.createdAt
    this._creatorID = p.creatorID
  }

  get id(): string {
    return this._id
  }

  get createdAt(): Date {
    return this._createdAt
  }

  get creatorID(): string {
    return this._creatorID
  }

  get parentTitle(): string {
    return this._parentTitle
  }

  get title(): string {
    return this._title
  }
}

export interface GoalQuery {
  title: string
  creatorID: string
}

export function toModel(d: GoalDTO): Goal {
  return new Goal(d)
}
