import generic from '../../../../assets/generic.module.css'
import { Box } from '@mui/material'
import React from 'react'

export const CVS122 = () => {
  return (
    <>
      <Box className={generic.pageContainer} style={{ backgroundColor: 'white' }}>
        <Box className={generic.pageContainer}>
          <h2>
            <b>1.2. Incontinencia</b>
          </h2>
        </Box>
        <Box>
          <h1 style={{ color: 'dodgerblue' }}>
            <b>Vejiga hipoactiva</b>
          </h1>
        </Box>
        <Box className={generic.pageContainer}>
          <div>
            La vejiga hipoactiva se produce cuando el músculo de la vejiga (detrusor) no puede
            contraerse de manera efectiva y hacer que la vejiga se vacíe totalmente. Esto puede
            provocar que queden restos de orina dentro de la vejiga.
            <br />
            <br />
            <b>Causa</b>
            <br />
            <br />
            Los nervios que controlan la vejiga a menudo se dañan debido a una enfermedad o a un
            traumatismo. Las condiciones asociadas a la vejiga hipoactiva son enfermedades
            neurológicas, diabetes, envejecimiento y efectos secundarios de medicaciones.
            <br />
            <br />
            <b>Tratamiento</b>
            <br />
            <br />
            El tratamiento dependerá de la causa, pero puede ser necesario un cateterismo para
            eliminar cualquier orina residual. Los medicamentos pueden ayudar a tratar y controlar
            la retención y puede ser necesaria una cirugía cuando la retención se deba a una
            hiperplasia de próstata.
            <br />
            <br />
            <br />
          </div>
        </Box>
        <Box>
          <h1 style={{ color: 'dodgerblue' }}>
            <b>Incontinencia Mixta</b>
          </h1>
        </Box>
        <Box className={generic.pageContainer}>
          <div>
            Quejas de incontinencia urinaria tanto de esfuerzo como de urgencia, es decir, pérdida
            involuntaria de orina asociada con la urgencia y también con el esfuerzo o la fuerza
            física, incluidas actividades deportivas, o al estornudar o toser. Aproximadamente el 30
            % de pacientes con incontinencia mixta son mujeres.
            <br />
            <br />
            Es posible sufrir al mismo tiempo incontinencia de esfuerzo y de urgencia, lo que se
            denomina incontinencia mixta. En los casos graves, tanto la incontinencia de esfuerzo
            como la de urgencia pueden tratarse con medicación, pero también puede ser necesario
            considerar la cirugía.
            <br />
            <br />
          </div>
        </Box>
      </Box>
    </>
  )
}
