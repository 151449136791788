import React, { useState, useEffect } from 'react'
import Container from '@material-ui/core/Container'
import { useTranslation } from 'react-i18next'
import styles from './Login.module.css'
import logo from './assets/higea/img-login.png'
import Grid from '@mui/material/Grid'
import { getUserContainer } from 'container/user-module'
import { IUserService, USER_SERVICE_KEY } from 'modules/users'
import WarningAmberIcon from '@mui/icons-material/WarningAmber'

const userService = getUserContainer().get<IUserService>(USER_SERVICE_KEY)

export default function ValidatedScreen() {
  const { t } = useTranslation()
  const [problem, setProblem] = useState<boolean>(false)
  const [alreadyValidated, setAlreadyValidated] = useState<boolean>(false)

  useEffect(() => {
    let indexFrom = window.location.pathname.lastIndexOf('/')
    let userID = window.location.pathname.substring(indexFrom + 1)

    userService.validateUser(userID).subscribe((res) => {
      if (res == 'userAlreadyValidated') {
        setAlreadyValidated(true)
      }
      setProblem(res?.active == false)
    })
  }, [])

  return (
    <div className={styles.background}>
      <Container component="div" style={{ marginLeft: '25%', marginTop: '2%' }}>
        <Grid container spacing={2}>
          <Grid item xs={3}>
            <img src={logo} alt="Login Logo" className={styles.logoValidated} />
          </Grid>
          <Grid item xs={9} style={{ marginLeft: '-6%', marginTop: '3%' }}>
            {problem ? (
              <h1 style={{ color: '#ff5959', fontWeight: '600' }}>{t('userNotValidated')}</h1>
            ) : (
              <>
                {alreadyValidated ? (
                  <>
                    <div style={{ display: 'flex', flexDirection: 'row' }}>
                      <h1 style={{ color: '#1996FF', fontWeight: '600' }}>
                        {t('userAlreadyValidated')}
                      </h1>
                      <WarningAmberIcon
                        style={{ marginLeft: '2%', marginTop: '0.5%', width: 35, height: 35 }}
                        color="warning"
                      />
                    </div>
                  </>
                ) : (
                  <h1
                    style={{
                      color: '#1996FF',
                      fontFamily: 'Open-sans, sans-serif',
                      fontWeight: '600',
                    }}>
                    {t('userValidated')}
                  </h1>
                )}
              </>
            )}
          </Grid>
        </Grid>
      </Container>
    </div>
  )
}
