import { Container } from '../../common/container/Container'

export type ContentContainerConfig = {
  moduleFullUrl: string
}

export type ContentProps = {
  parentContainer: Container
  config: ContentContainerConfig
}

export const GOALS_MODULE = Symbol('GOALS_MODULE')

export const GOAL_API_KEY = Symbol('GOAL_API_KEY')

export const GOAL_SERVICE_KEY = Symbol('GOAL_SERVICE_KEY')
