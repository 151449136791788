import React from 'react'
import { RouteComponentProps, Router } from '@reach/router'
import { Permission } from '../common/permission'
import {
  ROUTE_APPOINTMENTS,
  ROUTE_ARTICLE,
  ROUTE_ARTICLES,
  ROUTE_ARTICLES_FORM_EDIT,
  ROUTE_CONFIGURATION,
  ROUTE_CREATE,
  ROUTE_EDUCATIONAL,
  ROUTE_EDUCATIONALINTESTINAL11,
  ROUTE_EDUCATIONALINTESTINAL21,
  ROUTE_EDUCATIONALINTESTINAL22,
  ROUTE_EDUCATIONALINTESTINAL23,
  ROUTE_EDUCATIONALINTESTINAL24,
  ROUTE_EDUCATIONALINTESTINAL31,
  ROUTE_EDUCATIONALINTESTINAL32,
  ROUTE_EDUCATIONALINTESTINAL33,
  ROUTE_EDUCATIONALPROFESSIONAL,
  ROUTE_EDUCATIONALVESICAL11,
  ROUTE_EDUCATIONALVESICAL12,
  ROUTE_EDUCATIONALVESICAL13,
  ROUTE_EDUCATIONALVESICAL14,
  ROUTE_EDUCATIONALVESICAL21,
  ROUTE_EDUCATIONALVESICAL22,
  ROUTE_EDUCATIONALVESICAL23,
  ROUTE_EDUCATIONALVESICAL31,
  ROUTE_EDUCATIONALVESICAL32,
  ROUTE_EDUCATIONALVESICAL33,
  ROUTE_EDUCATIONALVESICAL41,
  ROUTE_EDUCATIONALVESICAL42,
  ROUTE_EDUCATIONALVESICAL43,
  ROUTE_EDUCATIONALVESICAL44,
  ROUTE_EDUCATIONALVESICAL45,
  ROUTE_EDUCATIONALVESICAL46,
  ROUTE_FAQS,
  ROUTE_FAQS_CREATE,
  ROUTE_FAQS_EDIT,
  ROUTE_FECAL,
  ROUTE_HOME,
  ROUTE_PATIENTS,
  ROUTE_PATIENTS_GOALS,
  ROUTE_PATIENTS_ID,
  ROUTE_PROFESSIONALS,
  ROUTE_QUESTIONNAIRES,
  ROUTE_RESOURCES,
  ROUTE_RESOURCES_FORM_EDIT,
  ROUTE_RESOURCES_ID,
  ROUTE_RESOURCES_SHARED_WITH_ME,
  ROUTE_RESOURCES_SHARED_WITH_ME_ID,
  ROUTE_STATISTICS,
  ROUTE_SYMPTOM,
  ROUTE_URINATION,
  ROUTE_USER_PROFILE,
  ROUTE_USERS_CREATE,
  ROUTE_USERS_EDIT,
  ROUTE_GOALS,
  ROUTE_PATIENT_DETAIL,
  ROUTE_EDUCATIONALVESICAL51,
} from './routes-constants'
import { ArticlesForm } from '../pages/articles-form'
import { ArticlesView } from '../pages/articles-view'
import {
  APPOINTMENTS_TITLE,
  ARTICLES_TITLE,
  CONFIGURATION_TITLE,
  CREATE_PARTICIPANT_TITLE,
  CREATE_PROFESSIONAL_TITLE,
  EDUCATIONAL_MATERIAL_TITLE,
  EDUCATIONAL_TITLE,
  FAQS_TITLE,
  GOALS_TITLE,
  PATIENTS_GOALS_TITLE,
  PATIENTS_LIST_TITLE,
  PATIENTS_TITLE,
  PROFESSIONAL_TITLE,
  RESOURCES_SHARED_WITH_ME_TITLE,
  RESOURCES_TITLE,
  STATISTICS_TITLE,
  USER_PROFILE_TITLE,
} from './title-constants'
import { Configuration } from '../pages/configuration'
import { Resources } from '../pages/resources'
import { ResourcesForm } from '../pages/resources-form'
import { EditParticipant } from '../pages/users'
import { UserProfile } from '../pages/user-profile'
import { getUserContainer } from '../container/user-module'
import { LOGGED_USER_SERVICE_KEY } from '../modules/users'
import { LoggedUserService } from '../modules/users/services/LoggedUserService'
import { ArticlesTable } from '../pages/articles-table'
import { Home } from '../pages/home'
import { Statistics } from '../pages/statistics'
import { Faqs } from '../pages/faqs'
import { PatientData } from '../pages/patient-data'
import { CreateParticipant, Patients } from '../pages/patients'
import {
  Educationals,
  EducationalsProfessionalIntestinal11,
  EducationalsProfessionalIntestinal21,
  EducationalsProfessionalIntestinal22,
  EducationalsProfessionalIntestinal23,
  EducationalsProfessionalIntestinal24,
  EducationalsProfessionalIntestinal31,
  EducationalsProfessionalIntestinal32,
  EducationalsProfessionalIntestinal33,
  EducationalsProfessionalVesical,
  EducationalsProfessionalVesical11,
  EducationalsProfessionalVesical12,
  EducationalsProfessionalVesical13,
  EducationalsProfessionalVesical14,
  EducationalsProfessionalVesical22,
  EducationalsProfessionalVesical23,
  EducationalsProfessionalVesical31,
  EducationalsProfessionalVesical32,
  EducationalsProfessionalVesical33,
  EducationalsProfessionalVesical41,
  EducationalsProfessionalVesical42,
  EducationalsProfessionalVesical43,
  EducationalsProfessionalVesical44,
  EducationalsProfessionalVesical45,
  EducationalsProfessionalVesical46,
  EducationalsProfessionalVesical51,
} from '../pages/educational'
import { PatientGoals } from 'pages/patient-goals'
import { AddFaq } from '../features/faqs/AddFaq'
import { Questionnaires } from 'pages/questionnaires'
import { Appointments } from '../pages/appointments'
import { Urination } from '../pages/urination'
import { Fecal } from '../pages/fecal'
import { Professionals } from '../pages/professionals/Professionals'
import { CreateProfessional } from 'pages/professionals'
import { EditFaq } from '../features/faqs'
import { Symptom } from '../pages/symptoms'
import { EditUser } from '../pages/users/EditUser'
import { EducationalProfessional } from '../features/professional-educational'
import { EducationalsProfessionalVesical21 } from '../pages/educational/vesical/EducationalProfessionalVesical2.1'
import { Goals } from '../pages/goals/Goals'
import { PatientDetail } from '../pages/patient-data'

export type RouteProps = {
  title?: string
  subtitle?: string
  id?: string
} & RouteComponentProps

const userContainer = getUserContainer()
const loggedUserService = userContainer.get<LoggedUserService>(LOGGED_USER_SERVICE_KEY)

export const AppRouter: React.FC = () => {
  const generalRoutes = (
    <>
      <Home path={ROUTE_HOME} />
      <Patients path={ROUTE_PATIENTS} title={PATIENTS_LIST_TITLE} />
      <Educationals path={ROUTE_EDUCATIONAL} title={EDUCATIONAL_TITLE} />
      <Goals path={ROUTE_GOALS} title={GOALS_TITLE} />
      <Statistics path={ROUTE_STATISTICS} title={STATISTICS_TITLE} />
      <Urination path={ROUTE_URINATION} title={PATIENTS_TITLE} />
      <Fecal path={ROUTE_FECAL} title={PATIENTS_TITLE} />
      <Faqs path={ROUTE_FAQS} title={FAQS_TITLE} />
      <AddFaq path={ROUTE_FAQS_CREATE} title={FAQS_TITLE} />
      <EditFaq path={ROUTE_FAQS_EDIT} title={FAQS_TITLE} />
      <PatientData path={ROUTE_PATIENTS_ID} title={PATIENTS_TITLE} />
      <PatientGoals path={ROUTE_PATIENTS_GOALS} title={PATIENTS_GOALS_TITLE} />
      <PatientDetail path={ROUTE_PATIENT_DETAIL} title={PATIENTS_TITLE} />
      <CreateParticipant
        path={ROUTE_PATIENTS + '/' + ROUTE_CREATE}
        title={CREATE_PARTICIPANT_TITLE}
      />
      <EducationalProfessional
        path={ROUTE_EDUCATIONALPROFESSIONAL}
        title={EDUCATIONAL_MATERIAL_TITLE}
      />

      <EducationalsProfessionalVesical11
        path={ROUTE_EDUCATIONALVESICAL11}
        title={'Contenido educativo'}
      />
      <EducationalsProfessionalVesical12
        path={ROUTE_EDUCATIONALVESICAL12}
        title={'Contenido educativo'}
      />
      <EducationalsProfessionalVesical13
        path={ROUTE_EDUCATIONALVESICAL13}
        title={'Contenido educativo'}
      />
      <EducationalsProfessionalVesical14
        path={ROUTE_EDUCATIONALVESICAL14}
        title={'Contenido educativo'}
      />
      <EducationalsProfessionalVesical21
        path={ROUTE_EDUCATIONALVESICAL21}
        title={'Contenido educativo'}
      />
      <EducationalsProfessionalVesical22
        path={ROUTE_EDUCATIONALVESICAL22}
        title={'Contenido educativo'}
      />
      <EducationalsProfessionalVesical23
        path={ROUTE_EDUCATIONALVESICAL23}
        title={'Contenido educativo'}
      />
      <EducationalsProfessionalVesical31
        path={ROUTE_EDUCATIONALVESICAL31}
        title={'Contenido educativo'}
      />
      <EducationalsProfessionalVesical32
        path={ROUTE_EDUCATIONALVESICAL32}
        title={'Contenido educativo'}
      />
      <EducationalsProfessionalVesical33
        path={ROUTE_EDUCATIONALVESICAL33}
        title={'Contenido educativo'}
      />
      <EducationalsProfessionalVesical41
        path={ROUTE_EDUCATIONALVESICAL41}
        title={'Contenido educativo'}
      />
      <EducationalsProfessionalVesical42
        path={ROUTE_EDUCATIONALVESICAL42}
        title={'Contenido educativo'}
      />
      <EducationalsProfessionalVesical43
        path={ROUTE_EDUCATIONALVESICAL43}
        title={'Contenido educativo'}
      />
      <EducationalsProfessionalVesical44
        path={ROUTE_EDUCATIONALVESICAL44}
        title={'Contenido educativo'}
      />
      <EducationalsProfessionalVesical45
        path={ROUTE_EDUCATIONALVESICAL45}
        title={'Contenido educativo'}
      />
      <EducationalsProfessionalVesical46
        path={ROUTE_EDUCATIONALVESICAL46}
        title={'Contenido educativo'}
      />
      <EducationalsProfessionalVesical51
        path={ROUTE_EDUCATIONALVESICAL51}
        title={'Contenido educativo'}
      />
      <EducationalsProfessionalIntestinal11
        path={ROUTE_EDUCATIONALINTESTINAL11}
        title={'Contenido educativo'}
      />
      <EducationalsProfessionalIntestinal21
        path={ROUTE_EDUCATIONALINTESTINAL21}
        title={'Contenido educativo'}
      />
      <EducationalsProfessionalIntestinal22
        path={ROUTE_EDUCATIONALINTESTINAL22}
        title={'Contenido educativo'}
      />
      <EducationalsProfessionalIntestinal23
        path={ROUTE_EDUCATIONALINTESTINAL23}
        title={'Contenido educativo'}
      />
      <EducationalsProfessionalIntestinal24
        path={ROUTE_EDUCATIONALINTESTINAL24}
        title={'Contenido educativo'}
      />
      <EducationalsProfessionalIntestinal31
        path={ROUTE_EDUCATIONALINTESTINAL31}
        title={'Contenido educativo'}
      />
      <EducationalsProfessionalIntestinal32
        path={ROUTE_EDUCATIONALINTESTINAL32}
        title={'Contenido educativo'}
      />
      <EducationalsProfessionalIntestinal33
        path={ROUTE_EDUCATIONALINTESTINAL33}
        title={'Contenido educativo'}
      />

      <EditUser path={ROUTE_USERS_EDIT} title={'Editar usuario'} />
      <EditParticipant path={ROUTE_USERS_CREATE} title={CREATE_PARTICIPANT_TITLE} />
      <ArticlesView path={ROUTE_ARTICLE} />
      <Appointments path={ROUTE_APPOINTMENTS} title={APPOINTMENTS_TITLE} />
      <Symptom path={ROUTE_SYMPTOM} title={'Síntomas'} />
      <Questionnaires path={ROUTE_QUESTIONNAIRES} title={PATIENTS_TITLE} />
      <Professionals path={ROUTE_PROFESSIONALS} title={PROFESSIONAL_TITLE} />
      {loggedUserService.isAdmin() && (
        <CreateProfessional
          path={ROUTE_PROFESSIONALS + '/' + ROUTE_CREATE}
          title={CREATE_PROFESSIONAL_TITLE}
        />
      )}

      {loggedUserService.userCan(Permission.createContent) && (
        <ArticlesForm path={ROUTE_ARTICLES_FORM_EDIT} title={ARTICLES_TITLE} />
      )}

      <ArticlesTable path={ROUTE_ARTICLES} title={ARTICLES_TITLE} />
      <Configuration path={ROUTE_CONFIGURATION} title={CONFIGURATION_TITLE} />
      <UserProfile path={ROUTE_USER_PROFILE} title={USER_PROFILE_TITLE} />
      <Resources path={ROUTE_RESOURCES} title={RESOURCES_TITLE} />
      <Resources path={ROUTE_RESOURCES_ID} title={RESOURCES_TITLE} />
      <Resources path={ROUTE_RESOURCES_SHARED_WITH_ME} title={RESOURCES_SHARED_WITH_ME_TITLE} />
      <Resources path={ROUTE_RESOURCES_SHARED_WITH_ME_ID} title={RESOURCES_SHARED_WITH_ME_TITLE} />
      <ResourcesForm path={ROUTE_RESOURCES_FORM_EDIT} title={RESOURCES_TITLE} />
    </>
  )

  return <Router component="div">{generalRoutes}</Router>
}
