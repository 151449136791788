import generic from '../../../../assets/generic.module.css'
import { Box } from '@mui/material'
import React from 'react'

export const CVS131 = () => {
  return (
    <>
      <Box className={generic.pageContainer} style={{ backgroundColor: 'white' }}>
        <Box>
          <h1 style={{ color: 'dodgerblue' }}>
            <b>Retención de orina</b>
          </h1>
        </Box>
        <Box className={generic.pageContainer}>
          <div>
            Hay varias causas de retención:
            <br />
            <br />
            • &nbsp;&nbsp;&nbsp;&nbsp;Daños en la uretra
            <br />
            <br />
            • &nbsp;&nbsp;&nbsp;&nbsp;Problemas nerviosos
            <br />
            <br />
            • &nbsp;&nbsp;&nbsp;&nbsp;Medicamentos
            <br />
            <br />
            • &nbsp;&nbsp;&nbsp;&nbsp;Debilitamiento de los músculos de la vejiga (vejiga atónica)
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
          </div>
        </Box>
      </Box>
    </>
  )
}
