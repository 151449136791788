import generic from '../../../../assets/generic.module.css'
import { Box } from '@mui/material'
import React from 'react'

export const CVS112 = () => {
  return (
    <>
      <Box className={generic.pageContainer} style={{ backgroundColor: 'white' }}>
        <Box className={generic.pageContainer}>
          <h2>
            <b>1.1 Disfunción y trastornos del tracto urinario</b>
          </h2>

          <h1 style={{ color: 'dodgerblue' }}>
            <b>Infecciones del tracto urinario (ITU)</b>
          </h1>
          <div>
            <b>Causas</b>
            <br />
            <br />
            La mayoría de las ITU se deben a una infección bacteriana, como la E. coli, que
            normalmente se encuentra en el intestino.
            <br />
            <br />
            Las bacterias presentan lo que se denomina "factores de virulencia" y esto desempeña una
            función importante a la hora de determinar si las bacterias invadirán el tracto urinario
            y el nivel de infección.
            <br />
            <br />
            Las E. coli uropatógenas están presentes en el intestino y causan daños al expresar
            factores de virulencia específicos que permiten que las bacterias se adhieran a la
            superficie de la vejiga y se multipliquen en el tracto urinario inferior. Otras
            bacterias habituales que causan ITU son{' '}
            <i>Klebsiella, Enterococcus faecalis y Proteus.</i>
            <br />
            <br />
            <br />
            Cualquier persona de cualquier edad, ya sea hombre o mujer, podría desarrollar una ITU,
            pero los siguientes factores de riesgo pueden aumentar la probabilidad de desarrollar
            estas infecciones:
            <br />
            <br />
          </div>
        </Box>
        <Box style={{ display: 'flex' }}>
          <Box style={{ marginLeft: '2%' }}>
            &nbsp;&nbsp;&nbsp;&nbsp;•&nbsp;&nbsp;&nbsp;&nbsp; problemas para vaciar la vejiga por
            completo
            <br />
            <br />
            &nbsp;&nbsp;&nbsp;&nbsp;•&nbsp;&nbsp;&nbsp;&nbsp; llevar una sonda vesical
            <br />
            <br />
            &nbsp;&nbsp;&nbsp;&nbsp;•&nbsp;&nbsp;&nbsp;&nbsp; cambios hormonales
            <br />
            <br />
            &nbsp;&nbsp;&nbsp;&nbsp;•&nbsp;&nbsp;&nbsp;&nbsp; menopausia
            <br />
            <br />
            &nbsp;&nbsp;&nbsp;&nbsp;•&nbsp;&nbsp;&nbsp;&nbsp; edad avanzada
            <br />
            <br />
            &nbsp;&nbsp;&nbsp;&nbsp;•&nbsp;&nbsp;&nbsp;&nbsp; Diabetes
            <br />
            <br />
            &nbsp;&nbsp;&nbsp;&nbsp;•&nbsp;&nbsp;&nbsp;&nbsp; uso intensivo de antibióticos, que
            pueden alterar la flora natural
            <br /> del intestino y del tracto urinario
            <br />
            <br />
          </Box>
          <Box style={{ marginLeft: '5%' }}>
            &nbsp;&nbsp;&nbsp;&nbsp;•&nbsp;&nbsp;&nbsp;&nbsp; estreñimiento
            <br />
            <br />
            &nbsp;&nbsp;&nbsp;&nbsp;•&nbsp;&nbsp;&nbsp;&nbsp; incontinencia fecal
            <br />
            <br />
            &nbsp;&nbsp;&nbsp;&nbsp;•&nbsp;&nbsp;&nbsp;&nbsp; flujo de orina bloqueado
            <br />
            <br />
            &nbsp;&nbsp;&nbsp;&nbsp;•&nbsp;&nbsp;&nbsp;&nbsp; procedimientos que impliquen el tracto
            urinario
            <br />
            <br />
            &nbsp;&nbsp;&nbsp;&nbsp;•&nbsp;&nbsp;&nbsp;&nbsp; sistema inmunológico suprimido
            <br />
            <br />
            &nbsp;&nbsp;&nbsp;&nbsp;•&nbsp;&nbsp;&nbsp;&nbsp; relaciones sexuales frecuentes con
            parejas nuevas o múltiples
            <br />
            <br />
          </Box>
          <Box style={{ marginLeft: '5%' }}>
            &nbsp;&nbsp;&nbsp;&nbsp;•&nbsp;&nbsp;&nbsp;&nbsp; falta de líquidos orales/hidratación
            deficiente
            <br />
            <br />
            &nbsp;&nbsp;&nbsp;&nbsp;•&nbsp;&nbsp;&nbsp;&nbsp; contaminación cruzada
            <br />
            <br />
            &nbsp;&nbsp;&nbsp;&nbsp;•&nbsp;&nbsp;&nbsp;&nbsp; higiene personal deficiente
            <br />
            <br />
            &nbsp;&nbsp;&nbsp;&nbsp;•&nbsp;&nbsp;&nbsp;&nbsp; piedras en el riñón
            <br />
            <br />
            &nbsp;&nbsp;&nbsp;&nbsp;•&nbsp;&nbsp;&nbsp;&nbsp; uso de compresas para la incontinencia
            <br />
            <br />
            &nbsp;&nbsp;&nbsp;&nbsp;•&nbsp;&nbsp;&nbsp;&nbsp; inmovilidad durante un largo periodo
            <br />
            <br />
            &nbsp;&nbsp;&nbsp;&nbsp;•&nbsp;&nbsp;&nbsp;&nbsp; uso de espermicidas y tampones
            <br />
            <br />
          </Box>
        </Box>
      </Box>
    </>
  )
}
