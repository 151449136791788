import { navigate } from '@reach/router'
import { Box } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import React, { useEffect, useState } from 'react'
import styles from './View.module.css'
import { getContentContainer } from '../../container/content-module'
import { ArticleService } from '../../modules/content/services/ArticleService'
import { ARTICLE_SERVICE_KEY } from '../../modules/content'
import { Article } from '../../modules/content/models/Article'
import { LoadingSpinner } from '../../components/loading-spinner/LoadingSpinner'
import style from '../../components/loading-spinner/LoadingSpinner.module.css'
import { AppButton, ButtonTheme } from '../../components/app-button/AppButton'
import genericStyle from '../../common/utils/generic.module.css'

const contentContainer = getContentContainer()
const articlesService = contentContainer.get<ArticleService>(ARTICLE_SERVICE_KEY)

type ArticlesViewProps = {
  id?: string
}

export function View(props: ArticlesViewProps) {
  const { t } = useTranslation()

  const [article, setArticle] = useState<Article>()
  const [isLoading, setIsLoading] = useState<boolean>(true)

  useEffect(() => {
    if (!isLoading || !props.id) {
      return
    }
    articlesService.getByID(props.id).subscribe((res) => {
      res && setArticle(res)
      setIsLoading(false)
    })
  }, [isLoading])

  const mappedContent = article?.answer.split('\n').map((c) =>
    c !== '' ? (
      <p key={c} className={styles.content}>
        {c}
      </p>
    ) : (
      <></>
    )
  )

  const goBack = () => navigate(-1)

  return (
    <Box className={genericStyle.pageContainer}>
      {!isLoading ? (
        <Box key="article" className={styles.articleWrapper}>
          <h1 className={styles.title}>{article?.ask}</h1>
          {mappedContent}
          <Box key="button" className={styles.backButton}>
            <AppButton
              theme={ButtonTheme.NewSecondary}
              type={'button'}
              label={t('back')}
              handler={goBack}
            />
          </Box>
        </Box>
      ) : (
        <LoadingSpinner className={style.loadingSpinner} />
      )}
    </Box>
  )
}
