import { PatientTreatment } from './PatientTreatment'

export interface PatientTreatmentDTO {
  id: string
  createdAt: Date
  treatmentID: string
  brand: string
  patientID: string
}

export function emptyPatientTreatmentDTO(): PatientTreatmentDTO {
  return {
    id: '',
    createdAt: new Date(),
    treatmentID: '',
    brand: '',
    patientID: '',
  }
}

export function toModel(p: PatientTreatmentDTO): PatientTreatment {
  return new PatientTreatment(p)
}

export function fromModel(p: PatientTreatment): PatientTreatmentDTO {
  return {
    id: p.id,
    createdAt: p.createdAt,
    treatmentID: p.treatmentID,
    brand: p.brand,
    patientID: p.patientID,
  }
}
