export const communities = [
  { label: 'Andalucía' },
  { label: 'Aragón' },
  { label: 'Asturias' },
  { label: 'Islas Baleares' },
  { label: 'Canarias' },
  { label: 'Cantabria' },
  { label: 'Castilla y León' },
  { label: 'Castilla-La Mancha' },
  { label: 'Cataluña' },
  { label: 'Comunidad Valenciana' },
  { label: 'Galicia' },
  { label: 'Extremadura' },
  { label: 'Madrid' },
  { label: 'Murcia' },
  { label: 'Navarra' },
  { label: 'País Vasco' },
  { label: 'La Rioja' },
  { label: 'Ceuta' },
  { label: 'Melilla' },
]

export const provinces = [
  { label: 'Albacete', value: 'Castilla-La Mancha' },
  { label: 'Almería', value: 'Andalucía' },
  { label: 'Álava', value: 'País Vasco' },
  { label: 'Guipúzcoa', value: 'País Vasco' },
  { label: 'Vizcaya', value: 'País Vasco' },
  { label: 'Oviedo', value: 'Asturias' },
  { label: 'Ávila', value: 'Castilla y León' },
  { label: 'Palma de Mallorca', value: 'Islas Baleares' },
  { label: 'Barcelona', value: 'Cataluña' },
  { label: 'Gerona', value: 'Cataluña' },
  { label: 'Lérida', value: 'Cataluña' },
  { label: 'Tarragona', value: 'Cataluña' },
  { label: 'Burgos', value: 'Castilla y León' },
  { label: 'Cáceres', value: 'Extremadura' },
  { label: 'Badajoz', value: 'Extremadura' },
  { label: 'Cádiz', value: 'Andalucía' },
  { label: 'Santander', value: 'Cantabria' },
  { label: 'Alicante', value: 'Comunidad Valenciana' },
  { label: 'Castellón de la Plana', value: 'Comunidad Valenciana' },
  { label: 'Valencia', value: 'Comunidad Valenciana' },
  { label: 'Ciudad Real', value: 'Castilla-La Mancha' },
  { label: 'Córdoba', value: 'Andalucía' },
  { label: 'Cuenca', value: 'Castilla-La Mancha' },
  { label: 'Granada', value: 'Andalucía' },
  { label: 'Guadalajara', value: 'Castilla-La Mancha' },
  { label: 'Huelva', value: 'Andalucía' },
  { label: 'Huesca', value: 'Aragón' },
  { label: 'Jaén', value: 'Andalucía' },
  { label: 'León', value: '	Castilla y León' },
  { label: 'La Coruña', value: 'Galicia' },
  { label: 'Lugo', value: 'Galicia' },
  { label: 'Orense', value: 'Galicia' },
  { label: 'Pontevedra', value: 'Galicia' },
  { label: 'Madrid', value: 'Madrid' },
  { label: 'Málaga', value: 'Andalucía' },
  { label: 'Murcia', value: 'Murcia' },
  { label: 'Pamplona', value: 'Navarra' },
  { label: 'Palencia', value: 'Castilla y León' },
  { label: 'Las Palmas de Gran Canaria', value: 'Canarias' },
  { label: 'Logroño', value: 'La Rioja' },
  { label: 'Salamanca', value: 'Castilla y León' },
  { label: 'Tenerife', value: 'Canarias' },
  { label: 'Segovia', value: 'Castilla y León' },
  { label: 'Sevilla', value: 'Andalucía' },
  { label: 'Soria', value: 'Castilla y León' },
  { label: 'Teruel', value: 'Aragón' },
  { label: 'Toledo', value: 'Castilla-La Mancha' },
  { label: 'Valladolid', value: '	Castilla y León' },
  { label: 'Zamora', value: '	Castilla y León' },
  { label: 'Zaragoza', value: 'Aragón' },
  { label: 'Ceuta', value: 'Ceuta' },
  { label: 'Melilla', value: 'Melilla' },
]
