import { IInit, Container } from '../../../common/container/Container'
import { Observable, of } from 'rxjs'
import { IHTTPClient, HTTP_CLIENT_KEY } from '../../../common/api/HTTPClient'
import { IStatusService } from '../../../common/status/StatusService'
import { STATUS_SERVICE_KEY } from '../../../container/app'
import { QuestionnaireContainerConfig } from '../container'
import { ItemList, emptyList } from '../../../common/models/ItemList'
import { map, catchError } from 'rxjs/operators'
import { Query } from '../../../common/api/Query'
import { QuestionnairePatientAnswer } from '../models/QuestionnariePatientAnswer'
import {
  QuestionnairePatientAnswerDTO,
  QuestionnairePatientAnswerQuery,
  toModel,
  toModel as toModelPatientAnswer,
} from '../models/QuestionnariePatientAnswerDTO'

export interface IQuestionnairePatientAnswerApi extends IInit {
  getList(
    q: Query<QuestionnairePatientAnswerQuery>
  ): Observable<ItemList<QuestionnairePatientAnswer>>

  add(ques: QuestionnairePatientAnswerDTO): Observable<QuestionnairePatientAnswer | undefined>

  getByID(id: string): Observable<QuestionnairePatientAnswer | undefined>

  delete(id: string): Observable<boolean>

  getPdfFromAnswers(id: string, language: string): Observable<BinaryType | undefined>
}

export class QuestionnairePatientAnswerApi implements IQuestionnairePatientAnswerApi {
  private _container!: Container
  private _httpClient!: IHTTPClient
  private _url!: string
  private _statusService!: IStatusService

  init(c: Container) {
    this._container = c
    this._httpClient = this._container.get<IHTTPClient>(HTTP_CLIENT_KEY)
    this._statusService = this._container.get<IStatusService>(STATUS_SERVICE_KEY)
    this._url = (this._container.config as QuestionnaireContainerConfig).moduleFullUrl
  }

  getList(
    q: Query<QuestionnairePatientAnswerQuery>
  ): Observable<ItemList<QuestionnairePatientAnswer>> {
    return this._httpClient
      .post<ItemList<QuestionnairePatientAnswer>>({
        url: `${this._url}/patientQuestionnairesAnswers/list`,
        body: q,
      })
      .pipe(
        map<ItemList<QuestionnairePatientAnswerDTO>, ItemList<QuestionnairePatientAnswer>>(
          (dto) => {
            const itemList = emptyList<QuestionnairePatientAnswer>()
            itemList.count = dto.count
            itemList.items = dto.items.map((d) => toModelPatientAnswer(d))
            return itemList
          }
        ),
        catchError((err) => {
          this._statusService.sendStatus({ variant: 'error', error: err })
          return of(emptyList<QuestionnairePatientAnswer>())
        })
      )
  }

  add(e: QuestionnairePatientAnswerDTO): Observable<QuestionnairePatientAnswer | undefined> {
    return this._httpClient
      .post<QuestionnairePatientAnswer>({
        url: `${this._url}/patientQuestionnairesAnswers`,
        body: e,
      })
      .pipe(
        map<QuestionnairePatientAnswerDTO, QuestionnairePatientAnswer>((d) => {
          this._statusService.sendStatus({ variant: 'success' })
          return toModel(d)
        }),
        catchError((err) => {
          this._statusService.sendStatus({ variant: 'error', error: err })
          return of(undefined)
        })
      )
  }

  getByID(id: string): Observable<QuestionnairePatientAnswer | undefined> {
    return this._httpClient.get<QuestionnairePatientAnswer>({ url: `${this._url}/${id}` }).pipe(
      map<QuestionnairePatientAnswerDTO, QuestionnairePatientAnswer>((d) =>
        toModelPatientAnswer(d)
      ),
      catchError((err) => {
        this._statusService.sendStatus({ variant: 'error', error: err })
        return of(undefined)
      })
    )
  }

  delete(id: string): Observable<boolean> {
    return this._httpClient
      .delete({ url: `${this._url}/patientQuestionnairesAnswers/list/${id}` })
      .pipe(
        catchError((err) => {
          this._statusService.sendStatus({ variant: 'error', error: err })
          return of(false)
        })
      )
  }

  getPdfFromAnswers(id: string, language: string): Observable<BinaryType | undefined> {
    return this._httpClient
      .get<BinaryType>({
        url: `${this._url}/generatePDFFromAnswers/${id}/${language}`,
        responseType: 'blob',
      })
      .pipe(
        map<BinaryType, BinaryType>((d) => d),
        catchError((err) => {
          console.log('Error generando el excel', err)
          //this._statusService.sendStatus({ variant: 'error', error: err })
          return of(undefined)
        })
      )
  }
}
