import { EducationalUserDTO } from './EducationalUserDTO'

export class EducationalUser {
  private _userID: string
  private _educationalID: string
  private _completed: boolean
  private _date: Date

  constructor(p: EducationalUserDTO) {
    this._userID = p.userID
    this._educationalID = p.educationalID
    this._completed = p.completed
    this._date = p.date
  }

  get userID(): string {
    return this._userID
  }

  get educationalID(): string {
    return this._educationalID
  }

  get completed(): boolean {
    return this._completed
  }

  get date(): Date {
    return this._date
  }
}
