import generic from '../../../../assets/generic.module.css'
import { Box, Button, Divider, Modal } from '@mui/material'
import React, { useState } from 'react'
import image from '../vesical/vejiga.png'
import image1 from '../vesical/vejigaFemenina.png'
import image2 from '../vesical/vejigaMasculina.png'
import { useTranslation } from 'react-i18next'

export const CVS222 = () => {
  const { t } = useTranslation()
  const [openModal, setOpenModal] = useState<boolean>(false)
  const { innerWidth } = window

  const handleOpenModal = () => setOpenModal(true)

  const handleCloseModal = () => setOpenModal(false)

  const [openModal1, setOpenModal1] = useState<boolean>(false)

  const handleOpenModal1 = () => setOpenModal1(true)

  const handleCloseModal1 = () => setOpenModal1(false)

  const [openModal2, setOpenModal2] = useState<boolean>(false)

  const handleOpenModal2 = () => setOpenModal2(true)

  const handleCloseModal2 = () => setOpenModal2(false)

  return (
    <>
      <Box className={generic.pageContainer} style={{ backgroundColor: 'white' }}>
        <Box className={generic.pageContainer} display="flex">
          <h2>
            <b>2.2. Anatomía y fisiología</b>
          </h2>
        </Box>
        <Box style={{ display: 'flex' }}>
          <Box>
            <h1 style={{ color: 'dodgerblue' }}>
              <b>El tractor urinario inferior</b>
            </h1>
            <h1 style={{ color: 'dodgerblue' }}>
              <b>Vejiga:</b>
            </h1>
            <div>
              &nbsp;&nbsp;&nbsp;&nbsp;•&nbsp;&nbsp;&nbsp;&nbsp; Almacena y vacía la orina
              <br />
              <br />
              &nbsp;&nbsp;&nbsp;&nbsp;•&nbsp;&nbsp;&nbsp;&nbsp; Pared de la vejiga - Capas de
              músculo liso = Detrusor
              <br />
              <br />
              &nbsp;&nbsp;&nbsp;&nbsp;•&nbsp;&nbsp;&nbsp;&nbsp; Baja presión
              <br />
              <br />
              &nbsp;&nbsp;&nbsp;&nbsp;•&nbsp;&nbsp;&nbsp;&nbsp; Capacidad normal de la vejiga 500mls
              <br />
              <br />
              &nbsp;&nbsp;&nbsp;&nbsp;•&nbsp;&nbsp;&nbsp;&nbsp; Primer deseo de orinar 150-200mls
              <br />
              <br />
            </div>
          </Box>
          <Box>
            <img
              src={image}
              onClick={handleOpenModal}
              style={{
                marginLeft: innerWidth < 900 ? '25%' : '80%',
                justifyContent: 'center',
                display: 'flex',
                borderStyle: 'ridge',
                borderColor: 'dodgerblue',
                width: innerWidth < 900 ? '250px' : '500px',
              }}
            />
          </Box>
        </Box>
        <Box style={{ display: 'flex' }}>
          <Box>
            <h1 style={{ color: 'dodgerblue' }}>
              <b>Esfínter - músculo estriado:</b>
            </h1>
            <div>
              &nbsp;&nbsp;&nbsp;&nbsp;•&nbsp;&nbsp;&nbsp;&nbsp; Actúa como una válvula
              <br />
              <br />
            </div>
            <h1 style={{ color: 'dodgerblue' }}>
              <b>Uretra:</b>
            </h1>
            <div>
              &nbsp;&nbsp;&nbsp;&nbsp;•&nbsp;&nbsp;&nbsp;&nbsp; Mantiene el paso de la orina
              <br />
              <br />
              &nbsp;&nbsp;&nbsp;&nbsp;•&nbsp;&nbsp;&nbsp;&nbsp; Hombre - 15-20cm, Mujer 3-5cm de
              largo
              <br />
              <br />
            </div>
          </Box>
          <Box>
            <img
              src={image1}
              onClick={handleOpenModal1}
              style={{
                height: innerWidth < 900 ? '180px' : '400px',
                marginTop: '1%',
                marginLeft: innerWidth < 900 ? '8%' : '40%',
                justifyContent: 'center',
                display: 'flex',
                borderStyle: 'ridge',
                borderColor: 'dodgerblue',
              }}
            />
          </Box>
          <Box>
            <img
              src={image2}
              onClick={handleOpenModal2}
              style={{
                height: innerWidth < 900 ? '180px' : '400px',
                marginTop: '1%',
                justifyContent: 'center',
                display: 'flex',
                borderStyle: 'ridge',
                borderColor: 'dodgerblue',
                marginLeft: innerWidth < 900 ? '13%' : '40%',
              }}
            />
          </Box>
        </Box>
        <Modal open={openModal} onClose={handleCloseModal}>
          <Box
            style={{
              position: 'absolute',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
              backgroundColor: 'white',
              padding: '10px',
            }}>
            <h1 style={{ marginLeft: '25%' }}>
              <b>Anatomía vejiga</b>
            </h1>
            <Divider variant={'middle'} />
            <img
              src={image}
              style={{
                width: '90%',
                marginTop: '1%',
                marginLeft: '10%',
                justifyContent: 'center',
                display: 'flex',
              }}
            />
            <Button
              variant={'outlined'}
              style={{ marginLeft: '45%', marginTop: '1%', justifyContent: 'end', display: 'flex' }}
              onClick={handleCloseModal}>
              {t('close')}
            </Button>
          </Box>
        </Modal>
        <Modal open={openModal1} onClose={handleCloseModal1}>
          <Box
            style={{
              position: 'absolute',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
              backgroundColor: 'white',
              padding: '10px',
            }}>
            <h1 style={{ marginLeft: '25%' }}>
              <b>Masculina</b>
            </h1>
            <Divider variant={'middle'} />
            <img
              src={image1}
              style={{
                width: '80%',
                marginTop: '1%',
                marginLeft: '10%',
                justifyContent: 'center',
                display: 'flex',
              }}
            />
            <Button
              variant={'outlined'}
              style={{ marginLeft: '45%', marginTop: '1%', justifyContent: 'end', display: 'flex' }}
              onClick={handleCloseModal1}>
              {t('close')}
            </Button>
          </Box>
        </Modal>
        <Modal open={openModal2} onClose={handleCloseModal2}>
          <Box
            style={{
              position: 'absolute',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
              backgroundColor: 'white',
              padding: '10px',
            }}>
            <h1 style={{ marginLeft: '25%' }}>
              <b>Femenina</b>
            </h1>
            <Divider variant={'middle'} />
            <img
              src={image2}
              style={{
                width: '80%',
                marginTop: '1%',
                marginLeft: '10%',
                justifyContent: 'center',
                display: 'flex',
              }}
            />
            <Button
              variant={'outlined'}
              style={{ marginLeft: '45%', marginTop: '1%', justifyContent: 'end', display: 'flex' }}
              onClick={handleCloseModal2}>
              {t('close')}
            </Button>
          </Box>
        </Modal>
      </Box>
    </>
  )
}
