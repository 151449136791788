import { AppointmentDTO } from './AppointmentDTO'

export class Appointment {
  private _id: string
  private _title: string
  private _place: string
  private _date: Date
  private _patientID: string
  private _userID: string
  private _urlVideoConf: string
  private _warning: boolean

  constructor(a: AppointmentDTO) {
    this._id = a.id
    this._title = a.title
    this._place = a.place
    this._date = a.date
    this._patientID = a.patientID
    this._userID = a.userID
    this._urlVideoConf = a.urlVideoConf
    this._warning = a.warning
  }

  get id(): string {
    return this._id
  }

  get warning(): boolean {
    return this._warning
  }

  get urlVideoConf(): string {
    return this._urlVideoConf
  }

  set id(value: string) {
    this._id = value
  }

  get title(): string {
    return this._title
  }

  set title(value: string) {
    this._title = value
  }

  get place(): string {
    return this._place
  }

  set place(value: string) {
    this._place = value
  }

  get date(): Date {
    return this._date
  }

  set date(value: Date) {
    this._date = value
  }

  get patientID(): string {
    return this._patientID
  }

  set patientID(value: string) {
    this._patientID = value
  }

  get userID(): string {
    return this._userID
  }

  set userID(value: string) {
    this._userID = value
  }
}

export interface AppointmentQuery {
  id: string
  place: string
  date: Date
  patientID: string
  patientsIds: string[]
  userID: string
  createdBy: string
}

export function toModel(a: AppointmentDTO): Appointment {
  return new Appointment(a)
}
