import { Box, TextField } from '@material-ui/core'
import React, { FormEvent, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { RouteProps } from '../../routes/AppRouter'
import { LoggedUserService } from '../../modules/users/services/LoggedUserService'
import { LOGGED_USER_SERVICE_KEY } from '../../modules/users/container'
import { getUserContainer } from '../../container/user-module'
import { getContentContainer } from '../../container/content-module'
import { ArticleService } from '../../modules/content/services/ArticleService'
import { ArticleDTO, emptyArticleDTO, fromModel } from '../../modules/content/models/ArticleDTO'
import { ARTICLE_SERVICE_KEY } from '../../modules/content'
import { navigate } from '@reach/router'
import { ROUTE_FAQS } from '../../routes/routes-constants'
import { TextFieldItem } from '../../components/form-card/TextFieldItem'
import { FormCard } from '../../components/form-card/FormCard'
import genericStyle from '../../common/utils/generic.module.css'
import { AppButton, ButtonTheme } from '../../components/app-button/AppButton'

export const AddFaq = (props: RouteProps) => {
  const { t } = useTranslation()

  const authService = getUserContainer().get<LoggedUserService>(LOGGED_USER_SERVICE_KEY)
  const articleService = getContentContainer().get<ArticleService>(ARTICLE_SERVICE_KEY)
  const loggedUser = authService.get()

  const [article, setArticle] = useState<ArticleDTO>(emptyArticleDTO())

  useEffect(() => {
    if (props.id) {
      articleService.getByID(props.id).subscribe((res) => {
        if (res) {
          setArticle(fromModel(res))
        }
      })
    }
  }, [])

  const validateArticle = (): boolean => {
    if (article.ask === '' || article.order.toString() === '' || article.answer === '') {
      return false
    }
    return true
  }

  const saveArticle = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    if (!validateArticle()) {
      return
    }
    if (props.id) {
      articleService.update(article).subscribe((_) => goToFaq())
    } else {
      article.creatorID = loggedUser?.id
      article.order = +article.order
      articleService.add(article).subscribe((_) => goToFaq())
    }
  }

  const handleInput = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setArticle(Object.assign({ ...article }, { [e.target.name]: e.target.value }))
  }
  const goToFaq = () => navigate(ROUTE_FAQS).then()

  return (
    <Box className={genericStyle.pageContainer}>
      <FormCard>
        <form style={{ padding: 10 }} onSubmit={(e) => saveArticle(e)}>
          {/*<Box mb={3} style={{ width: 500 }}>
            <TextFieldItem
              field="order"
              value={article.order.toString()}
              type={'number'}
              handleChange={handleInput}
              required={true}
              label={t('order')}
            />
          </Box>*/}

          <Box mb={3} style={{ width: 500 }}>
            <TextFieldItem
              field="ask"
              value={article.ask}
              type={'string'}
              handleChange={handleInput}
              required={true}
              label={t('ask')}
            />
          </Box>
          <Box mb={3} style={{ width: 500 }}>
            <div>
              <TextFieldItem
                field={'answer'}
                value={article.answer}
                label={t('answer')}
                required={true}
                rows={10}
                handleChange={handleInput}
              />
            </div>
          </Box>
          <Box mb={3} display="flex" justifyContent="space-between" style={{ marginBottom: 8 }}>
            <AppButton
              theme={ButtonTheme.NewPrimaryLight}
              handler={saveArticle}
              type={'submit'}
              label={t('save')}
            />
          </Box>
        </form>
      </FormCard>
    </Box>
  )
}
