import { Box } from '@material-ui/core'
import { Avatar, IconButton } from '@mui/material'
import styles from './Editor.module.css'
import { User } from '../../modules/users/models/User'
import { styled } from '@mui/material/styles'
import { ChangeEvent } from 'react'
import style from '../../pages/layout/Header.module.css'
import * as React from 'react'

interface AvatarProps {
  photoID: string | undefined
  user: User | undefined
  role: string
  files: (selectorFiles: ChangeEvent<HTMLInputElement>) => void
  data: string | undefined
  isLoading: boolean
}
export const AvatarContainer = (props: AvatarProps) => {
  //TODO Get avatar image from photoID
  const Input = styled('input')({
    display: 'none',
  })

  return (
    <>
      <Box className={styles.avatarContainer}>
        <label htmlFor="icon-button-file">
          <Input
            accept="image/*"
            id="icon-button-file"
            type="file"
            name="avatar"
            onChange={(e) => props.files(e)}
          />
          <IconButton color={'primary'} id="icon-button-file" component={'span'}>
            {props.data ? (
              <Avatar
                alt="Avatar"
                src={`data:image/jpeg;base64,${props.data}`}
                sx={{ width: 100, height: 100 }}
              />
            ) : (
              <Avatar sx={{ width: '100%', height: '100%' }} className={style.avatar} />
            )}
            {/*<img src={`data:image/jpeg;base64,${props.data}`} style={{width:'90%'}}/>*/}
          </IconButton>
        </label>
        <h3 style={{ marginBottom: 0 }}>
          {props.user?.firstName} {props.user?.lastName}
        </h3>
        <h5 style={{ marginTop: 2 }}>{props.role}</h5>
      </Box>
    </>
  )
}
