export enum TitleType {
  eventCalendarDate = 1,
  registeredSymtom = 2,
  formativeUnitCompleted = 3,
  completedHealthSurvey = 4,
  newMicturitionDiary = 5,
  newFecalDiary = 6,
  all = 7,
}

export const titleTypes = (): Record<TitleType, string> => ({
  [TitleType.eventCalendarDate]: 'eventCalendarDate',
  [TitleType.registeredSymtom]: 'registeredSymtom',
  [TitleType.formativeUnitCompleted]: 'formativeUnitCompleted',
  [TitleType.completedHealthSurvey]: 'completedHealthSurvey',
  [TitleType.newMicturitionDiary]: 'newMicturitionDiary',
  [TitleType.newFecalDiary]: 'newFecalDiary',
  [TitleType.all]: 'showAll',
})
