import React, { useEffect, useState } from 'react'
import './App.css'
import { Box, CssBaseline } from '@material-ui/core'
import { Header, Main } from './pages/layout'
import Login from 'Login'
import Tfa from 'Tfa'
import ValidatedScreen from 'ValidatedScreen'
import ProfessionalRegister from 'ProfessionalRegister'
import { ILoggedUserService } from './modules/users/services/LoggedUserService'
import { getUserContainer } from './container/user-module'
import { IUserService, LOGGED_USER_SERVICE_KEY, USER_SERVICE_KEY } from './modules/users'
import { LoggedUser } from './modules/users/models/LoggedUser'
import { useSnackbar } from 'notistack'
import { useTranslation } from 'react-i18next'
import { IStatusService } from './common/status/StatusService'
import { getAppContainer, STATUS_SERVICE_KEY } from './container/app'
import AppPrivacy from './AppPrivacy'
import { LoggedUserDTO } from 'modules/users/models/LoggedUserDTO'
import { MyGlobalContext } from './common/utils/MyGlobalContext'
import { getFileContainer } from './container/file-module'
import { FILE_SERVICE_KEY, IFileService } from './modules/files'
import i18n from 'i18next'
import iconNotifOff from './assets/higea/Ico_campana_off_copy.svg'

const loggedUserService = getUserContainer().get<ILoggedUserService>(LOGGED_USER_SERVICE_KEY)
const statusService = getAppContainer().get<IStatusService>(STATUS_SERVICE_KEY)
const loggedUser = loggedUserService.get()
const fileService = getFileContainer().get<IFileService>(FILE_SERVICE_KEY)
const userContainer = getUserContainer()
const userService = userContainer.get<IUserService>(USER_SERVICE_KEY)
function App() {
  const { t } = useTranslation()
  const { enqueueSnackbar } = useSnackbar()
  const [user, setUser] = useState<LoggedUser | undefined>(loggedUser)
  const [isProfessionalRegister, setIsProfessionalRegister] = useState<boolean>(false)
  const [isLegacyTerms, setIsLegacyTerms] = useState<boolean>(false)
  const [isValidate, setIsValidate] = useState<boolean>(false)
  const [logged, setLogged] = useState<LoggedUserDTO>()
  const [dataImage, setDataImage] = useState<string>('')
  const [messagesForMe, setMessagesForMe] = useState<[]>([])
  const [photoID, setPhotoID] = useState<string>('')
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [messagesHeader, setMessagesHeader] = useState([])
  const [allMessages, setAllMessages] = useState([])
  const [bellNotif, setBellNotif] = useState<any>(iconNotifOff)
  const [tfa, setTfa] = useState<boolean>(false)
  const [userToLogin, setUserToLogin] = useState<LoggedUserDTO>()

  useEffect(() => {
    setIsLegacyTerms(false)
    setIsProfessionalRegister(false)
    setIsValidate(false)
  }, [])

  useEffect(() => {
    if (user?.language == 1) {
      i18n.changeLanguage('es')
    }
  }, [user])

  useEffect(() => {
    if (window.location.pathname.includes('validateUser')) {
      setIsValidate(true)
    }
  }, [])

  useEffect(() => {
    if (!user?.id) return
    userService.getByID(user.id).subscribe((res) => {
      if (res) {
        setPhotoID(res.photoID)
      }
      setIsLoading(!isLoading)
    })
  }, [user])

  useEffect(() => {
    fileService.getByID(photoID).subscribe((res) => {
      if (!res) return
      const data = res?.data
      if (!dataImage) {
        setDataImage((prev) => data)
      }
    })
  }, [isLoading, user])

  useEffect(() => {
    loggedUserService.getObservable().subscribe((lu) => {
      setUser(lu)
    })
    statusService
      .statusObservable()
      .subscribe((s) =>
        s.variant === 'success'
          ? enqueueSnackbar(t('changesWereSaved'), { variant: s.variant })
          : null
      )
  }, [])

  const goToTFA = (u: LoggedUserDTO) => {
    setUserToLogin(u)
    setTfa(true)
  }

  const goToLofginFromTFA = () => {
    setTfa(false)
  }

  return (
    <>
      <CssBaseline />
      {user && !isValidate && !tfa ? (
        <MyGlobalContext.Provider
          value={{
            dataImage,
            setDataImage,
            messagesForMe,
            setMessagesForMe,
            messagesHeader,
            setMessagesHeader,
            allMessages,
            setAllMessages,
            bellNotif,
            setBellNotif,
          }}
        >
          <Box display="flex" flexBasis="100%" overflow="hidden" flexDirection="row">
            <Box display="flex" flexDirection="column" width="100%">
              <Header />
              <Main />
            </Box>
          </Box>
        </MyGlobalContext.Provider>
      ) : (
        <>
          {isProfessionalRegister && (
            <ProfessionalRegister goToLogin={() => setIsProfessionalRegister(false)} />
          )}
          {isValidate && <ValidatedScreen />}
          {isLegacyTerms && logged && (
            <AppPrivacy
              loggedUser={logged}
              goToLogin={() => {
                setUserToLogin(logged)
                setIsLegacyTerms(false)
                setTfa(true)
              }}
            />
          )}
          {!isProfessionalRegister && !isLegacyTerms && !isValidate && !tfa && (
            <Login
              goToProfessional={() => setIsProfessionalRegister(true)}
              goToLegacyTerms={(dto: LoggedUserDTO) => {
                setLogged(dto)
                setIsLegacyTerms(true)
              }}
              goToTFA={goToTFA}
            />
          )}
          {!isProfessionalRegister && !isLegacyTerms && !isValidate && tfa && (
            <Tfa
              userToLogin={userToLogin}
              goToLogin={goToLofginFromTFA}
              backToLogin={() => {
                setTfa(false)
                window.location.reload()
              }}
            />
          )}
        </>
      )}
    </>
  )
}

export default App
