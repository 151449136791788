import { Container } from '../../common/container/Container'

export type CenterContainerConfig = {
  moduleFullUrl: string
}

export type CenterProps = {
  parentContainer: Container
  config: CenterContainerConfig
}

export const CENTER_MODULE = Symbol('CENTER_MODULE')

export const CENTER_API_KEY = Symbol('CENTER_API_KEY')

export const CENTER_SERVICE_KEY = Symbol('CENTER_SERVICE_KEY')
