import generic from '../../../../assets/generic.module.css'
import { Box } from '@mui/material'
import React from 'react'

export const CVS450 = () => {
  return (
    <>
      <Box className={generic.pageContainer} style={{ backgroundColor: 'white' }}>
        <Box className={generic.pageContainer} display="flex">
          <h2>
            <b>4.5 Contraindicaciones y complicaciones</b>
          </h2>
        </Box>
        <Box className={generic.pageContainer}>
          <div>
            <b>Contraindicaciones del cateterismo intermintente</b>
            <br />
            <br />
            El CI presenta pocas contraindicaciones. La presión intravesical alta es una
            contraindicación absoluta y una destreza manual deficiente en ausencia de un cuidador
            debidamente formado también es una contraindicación.
            <br />
            <br />
            <br />
            <b>Complicaciones del CI</b>
            <br />
            <br />
            Entre las complicaciones se pueden incluir infección, sangrado, uretritis, estenosis, la
            creación de un pasaje falsoy, en hombres, epididimitis. Los eventos relacionados con la
            vejiga pueden causar infecciones del tracto urinario (ITU), sangrado y cálculos. La
            complicación más frecuente del CI es la infección del tracto urinario (ITU).Existen
            diversas razones para que se produzca una infección.
            <br />
            <br />
            Entre las posibles complicaciones con el CI se encuentran las siguientes:
            <br />
            <br />
            &nbsp;&nbsp;&nbsp;&nbsp;•&nbsp;&nbsp;&nbsp;&nbsp; <b>Inserción:</b>a veces puede ser
            difícil insertar una sonda, por lo general debido a que el músculo del esfínter no está
            relajado; en este sentido, toser puede servir de ayuda
            <br />
            <br />
            &nbsp;&nbsp;&nbsp;&nbsp;•&nbsp;&nbsp;&nbsp;&nbsp; <b>Retirada:</b>a veces es difícil
            retirar la sonda, lo que resulta incómodo para los pacientes y puede ser dañino para la
            uretra
            <br />
            <br />
            &nbsp;&nbsp;&nbsp;&nbsp;•&nbsp;&nbsp;&nbsp;&nbsp; <b>Estenosis uretrales:</b>se pueden
            producir como una complicación a largo plazo en algunos pacientes, normalmente después
            de 5 años o más de cateterismo
            <br />
            <br />
            &nbsp;&nbsp;&nbsp;&nbsp;•&nbsp;&nbsp;&nbsp;&nbsp; <b>Pasajes falsos:</b>no son agudos y
            se producen como resultado del cateterismo intermitente a largo plazo
            <br />
            <br />
            <br />
            Algunos problemas habituales con el CIL:
            <br />
            <br />
            &nbsp;&nbsp;&nbsp;&nbsp;•&nbsp;&nbsp;&nbsp;&nbsp; Aunque la prevalencia de bacteriuria
            es menor que en pacientes con sondas permanentes, prevenir infecciones cruzadas sigue
            siendo importante. Los pacientes deben ser conscientes de los cambios en la orina que
            pueden indicar una ITU que requiere tratamiento y una revisión de la técnica
            <br />
            <br />
            &nbsp;&nbsp;&nbsp;&nbsp;•&nbsp;&nbsp;&nbsp;&nbsp; Con frecuencia, las mujeres pueden
            insertarse la sonda en la vagina por error. En este caso, se debe desechar la sonda y
            utilizar una nueva.
            <br />
            <br />
          </div>
        </Box>
      </Box>
    </>
  )
}
