import { useEffect, useState } from 'react'
import { Box, Checkbox, CheckboxProps, FormControlLabel, Grid, Modal } from '@mui/material'
import { withStyles } from '@material-ui/core/styles'
import generic from '../../assets/generic.module.css'
import style from './PatientGoals.module.css'
import { useTranslation } from 'react-i18next'
import { AppTable } from 'components/table'
import { Actions, Field, Pager, Search, SearchValue, Sort } from '../../components/table/types'
import { Goal, GoalQuery } from 'modules/goals/models/Goal'
import { getGoalContainer } from 'container/goal-module'
import { GoalService } from '../../modules/goals/services/GoalService'
import { GOAL_SERVICE_KEY } from 'modules/goals'
import { Query, QueryParam, QueryParamN, SortParam } from 'common/api/Query'
import assignIcon from '../../assets/higea/ico_anadir_copy.svg'
import calendarIcon from '../../assets/higea/ico-calendar-black.svg'
import deleteIcon from '../../assets/higea/ico_eliminar-copy.svg'
import { PatientGoalService } from '../../modules/patients/services/PatientGoalService'
import { getUserContainer } from 'container/user-module'
import { ILoggedUserService } from 'modules/users/services/LoggedUserService'
import { LOGGED_USER_SERVICE_KEY } from 'modules/users'
import { PATIENT_GOAL_SERVICE_KEY } from 'modules/patients/container'
import { getPatientContainer } from 'container/patient-module'
import { AppButton, ButtonTheme } from 'components/app-button/AppButton'
import { navigate } from '@reach/router'
import { ROUTE_PATIENTS_ID } from '../../routes/routes-constants'
import { Fade, Backdrop } from '@material-ui/core'
import React from 'react'
import { PatientGoal } from '../../modules/patients/models/PatientGoal'
import {
  emptyPatientGoal,
  fromModel,
  PatientGoalDTO,
} from '../../modules/patients/models/PatientGoalDTO'
import { v4 as uuidv4 } from 'uuid'
import { FaRegCalendarAlt } from 'react-icons/fa'
import CloseIcon from '@material-ui/icons/Close'
import objectiveIconWhite from '../../assets/higea/ico-objetivos-num copy-white.svg'

type PatientDataProps = {
  id: string
}

type GoalsProps = {
  goal: PatientGoal
  index: number
}

interface AssignedGoal {
  goalID: string
}

interface Props {
  goalsByParentTitle: Record<string, Goal[]>
  assignedGoals: AssignedGoal[]
}

const goalService = getGoalContainer().get<GoalService>(GOAL_SERVICE_KEY)
const patientGoalService = getPatientContainer().get<PatientGoalService>(PATIENT_GOAL_SERVICE_KEY)
const loggedUserService = getUserContainer().get<ILoggedUserService>(LOGGED_USER_SERVICE_KEY)

const searcherQuery = (
  svs: SearchValue<GoalQuery>[]
): QueryParam<GoalQuery>[] | QueryParamN<GoalQuery>[] =>
  svs.filter((sv) => sv.value).map((sv) => ({ name: sv.name, value: sv.value as string }))

export function PatientGoals(props: PatientDataProps) {
  const { t } = useTranslation()
  const loggedUser = loggedUserService.get()
  const [itemsLoaded, setItemsLoaded] = useState<boolean>(false)
  const [assignedGoals, setAssignedGoals] = useState<PatientGoal[]>([])
  const [goals, setGoals] = useState<Goal[]>([])
  const [allGoals, setAllGoals] = useState<Goal[]>([])
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [page, setPage] = useState<number>(0)
  const [pager, setPager] = useState<Pager>()
  const [rowsPerPage, setRowsPerPage] = useState<number>(10)
  const [count, setCount] = useState<number>(0)
  const [currentGoal, setCurrentGoal] = useState<PatientGoalDTO>(emptyPatientGoal())
  const [modalOpened, setModalOpened] = useState<boolean>(false)
  const { innerWidth } = window
  const [sort, setSort] = useState<SortParam<Goal>>({
    field: 'parentTitle',
    desc: true,
  })
  const [searcher, setSearcher] = useState<SearchValue<GoalQuery>[]>([
    {
      name: 'title',
      label: t('search') + ' ' + t('objective'),
    },
  ])

  const fields: Field<Goal>[] = [
    {
      searchable: true,
      sortable: true,
      label: t('objective'),
      name: 'title',
    },
  ]

  useEffect(() => {
    goalService.getFilteredList(new Query({})).subscribe((res) => {
      setAllGoals(res.items)
    })
  }, [])

  useEffect(() => {
    patientGoalService.getListByID(props.id).subscribe((res) => {
      setAssignedGoals(res.items)
    })
  }, [isLoading])

  useEffect(() => {
    if (!isLoading) {
      return
    }
    goalService
      .getFilteredList(
        new Query({
          pager: { offset: page * rowsPerPage, limit: rowsPerPage },
          query: [...searcherQuery(searcher)],
          sort: [{ field: sort.field }],
        })
      )
      .subscribe((res) => {
        setGoals(res.items)
        setCount(res.count)
        setIsLoading(!isLoading)
        setItemsLoaded(true)
      })
  }, [isLoading])

  useEffect(() => {
    setIsLoading(!isLoading)
    setPager({
      page,
      count,
      handleChangePage,
      rowsPerPage,
      handleChangeRowsPerPage,
    })
  }, [page, count, rowsPerPage])

  const handleChangePage = (event: unknown, value: number) => setPage(value)

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) =>
    setRowsPerPage(Number.parseInt(event.target.value) || 10)

  const search: Search<GoalQuery> = {
    searchValues: searcher,
    handleSearch: (svs: SearchValue<GoalQuery>[]) => {
      const result: SearchValue<GoalQuery>[] = svs.map((s) => {
        if (s.type === 'date' && s.value) {
          return Object.assign({ ...s }, { value: new Date('' + s.value).toISOString() })
        }
        return s
      })
      setSearcher(result)
      setIsLoading(true)
    },
  }

  const sortable: Sort<Goal> = {
    name: sort.field,
    direction: sort.desc ? 'desc' : 'asc',
    handleSort: (field) => {
      setSort({ field: field, desc: sort.field === field ? !sort.desc : true })
      setIsLoading(true)
    },
  }

  const assignGoal = (g: Goal) => {
    if (!loggedUser?.id) return
    setIsLoading(true)
    patientGoalService
      .add({
        id: uuidv4(),
        patientID: props.id,
        goalID: g.id,
        creatorID: loggedUser.id,
        createdAt: new Date(),
        achieved: false,
        title: g.title,
        achievedAt: undefined,
      })
      .subscribe((res) => {
        setIsLoading(false)
      })
  }

  const actions: Actions<Goal> = {
    actionsColumn: t('Actions'),
    items: [
      {
        handler: assignGoal,
        icon: assignIcon,
        label: 'assign',
        hidden: (e) => assignedGoals.map((g) => g.goalID).includes(e.id),
      },
    ],
  }

  const handleDelete = (g: PatientGoal) => {
    patientGoalService.delete(g.id).subscribe((res) => {
      setIsLoading(!isLoading)
    })
  }

  const handleChangeCheckBox = (g: PatientGoal) => {
    g.achieved = !g.achieved
    g.achievedAt = new Date()
    patientGoalService.update(fromModel(g)).subscribe((res) => {
      setIsLoading(!isLoading)
    })
  }

  const GreenCheckbox = withStyles({
    root: {
      color: '#c1c1c1',
      '&$checked': {
        color: '#1996FF',
      },
    },
    checked: {},
  })((props: CheckboxProps) => <Checkbox sx={{ color: '#c1c1c1' }} color="default" {...props} />)

  const GoalAssignedTablet = (g: GoalsProps) => {
    return (
      <Grid
        container
        spacing={2}
        className={style.assignedGoal}
        style={{ marginLeft: '-3%', marginTop: '0.5%' }}
      >
        <Grid item xs={5}>
          <p style={{ marginLeft: '-22%', fontSize: 13 }}>{g.goal.title}</p>
        </Grid>
        <Grid item xs={3}>
          <FaRegCalendarAlt
            size={19}
            color={'black'}
            style={{ color: '#0063a6', marginRight: '30%', marginLeft: '-25%' }}
            onClick={() => {
              setCurrentGoal(g.goal)
              setModalOpened(true)
            }}
            title={t('seeDates')}
          />
        </Grid>
        <GreenCheckbox
          id={`custom-checkbox-${g.index}`}
          value={g.goal}
          checked={g.goal.achieved}
          onChange={() => handleChangeCheckBox(g.goal)}
          name={g.goal.title}
          size="small"
        />
        <img
          src={deleteIcon}
          alt="delete"
          onClick={() => handleDelete(g.goal)}
          width={17}
          height={17}
        />
      </Grid>
    )
  }

  const goalsByParentTitle = allGoals.reduce<{ [key: string]: typeof goals }>((acc, goal) => {
    const parentTitle = goal.parentTitle
    if (!acc[parentTitle]) {
      acc[parentTitle] = []
    }
    acc[parentTitle].push(goal)
    return acc
  }, {})

  const GoalAssigned = (g: GoalsProps) => {
    return (
      <Box
        style={{ borderColor: g.goal.achieved ? '#3daa32' : '#c1c1c1' }}
        className={style.assignedGoal}
      >
        <p
          style={{ color: g.goal.achieved ? '#3daa32' : '#000' }}
          className={style.assignedGoalText}
        >
          {g.goal.title}
        </p>
        <Box display={'flex'} alignItems={'center'} justifyContent={'space-between'}>
          <img
            src={calendarIcon}
            alt="calendar icon"
            onClick={() => {
              setCurrentGoal(g.goal)
              setModalOpened(true)
            }}
          />
          <Box className={style.achievedGoal}>
            <FormControlLabel
              control={
                <GreenCheckbox
                  id={`custom-checkbox-${g.index}`}
                  value={g.goal}
                  checked={g.goal.achieved}
                  onChange={() => handleChangeCheckBox(g.goal)}
                  name={g.goal.title}
                />
              }
              label=""
            />
          </Box>
          <img src={deleteIcon} alt="delete" onClick={() => handleDelete(g.goal)} />
        </Box>
      </Box>
    )
  }

  return (
    <>
      <Box style={{ marginBottom: '1%' }}>
        <Box className={style.buttonContainer}>
          <Box style={{ width: '200px' }}>
            <AppButton
              theme={ButtonTheme.NewPrimaryLightWithoutWidth}
              type={'button'}
              label={t('returnToPatient')}
              handler={() => navigate(ROUTE_PATIENTS_ID.replace(':id', `${props.id}`))}
            />
          </Box>
        </Box>
        <Grid container justifyContent={innerWidth < 400 ? 'center' : ''}>
          <Grid item xs={12} sm={5} md={3}>
            <Box
              style={{ backgroundColor: '#6d1ea9', color: 'white', fontWeight: 'bold' }}
              className={innerWidth < 900 ? style.gridButtonTablet : style.gridButton2}
            >
              {innerWidth < 900 ? (
                <img
                  src={objectiveIconWhite}
                  alt="objective"
                  style={{ marginRight: 10 }}
                  width={40}
                  height={40}
                />
              ) : (
                <img
                  src={objectiveIconWhite}
                  alt="objective"
                  style={{ marginRight: 10 }}
                  width={50}
                  height={50}
                />
              )}
              {t('patientsAddGoals')}
            </Box>
          </Grid>
        </Grid>
      </Box>
      <Box className={generic.pageContainer} display="flex">
        <Grid container spacing={2}>
          <Grid item xs={12} sm={7}>
            <Box>
              <h3 style={{ marginLeft: 4, color: '#000', fontSize: '18px' }}>{t('goalsList')}</h3>
              <Box className={style.allGoalsContent}>
                <AppTable
                  search={search}
                  fields={fields}
                  items={goals}
                  pager={pager}
                  rowKeyField={'id'}
                  sort={sortable}
                  actions={actions}
                  searchWithIcon={true}
                  notMarginTop={true}
                  widthSearcher={true}
                  groupBy={'parentTitle'}
                />
              </Box>
            </Box>
          </Grid>
          <Grid item xs={12} sm={5}>
            <Box>
              <h3 style={{ marginLeft: 50, color: '#000', fontSize: '18px' }}>
                {t('goalsAssigned')}
              </h3>
              <Box className={style.assignedGoalsContent}>
                {Object.entries(goalsByParentTitle)
                  .sort(([a], [b]) => a.localeCompare(b))
                  .map(([parentTitle, goals]) => {
                    const assignedGoalsInCategory = assignedGoals.filter((goal) =>
                      goals.some((g) => g.id === goal.goalID)
                    )
                    if (assignedGoalsInCategory.length === 0) {
                      return null
                    }
                    return (
                      <Box key={parentTitle}>
                        <h3>{parentTitle}</h3>
                        {assignedGoalsInCategory.map((goal, index) => (
                          <GoalAssigned key={index} goal={goal} index={index} />
                        ))}
                      </Box>
                    )
                  })}
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Box>
      {/*)}*/}
      <Modal
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          outline: 'none',
        }}
        open={modalOpened}
        onClose={() => setModalOpened(false)}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade
          in={modalOpened}
          timeout={500}
          style={{
            height: '23%',
            width: innerWidth < 900 ? '35%' : '21%',
            display: 'flex',
            justifyContent: 'center',
            backgroundColor: 'white',
            borderRadius: 25,
          }}
        >
          <div>
            <Grid container style={{ paddingTop: 45, paddingLeft: 30 }}>
              <Grid item xs={2}>
                <FaRegCalendarAlt
                  size={20}
                  color={'black'}
                  style={{
                    color: '#0063a6',
                    width: innerWidth < 900 ? '70%' : '35%',
                    marginRight: innerWidth < 900 ? '50%' : '30%',
                  }}
                  onClick={() => {}}
                  title={t('seeDates')}
                />
              </Grid>

              <Grid item xs={10} style={{ marginTop: '-3%', marginLeft: '-4%' }}>
                <p>
                  {t('assignedDate')}:{' '}
                  {new Date(currentGoal?.createdAt)
                    ?.toLocaleString()
                    .substring(
                      0,
                      new Date(currentGoal?.createdAt)?.toLocaleString().length - 3
                    )}{' '}
                  h
                </p>
              </Grid>
              {currentGoal.achieved && currentGoal.achievedAt && (
                <>
                  <Grid item xs={2}>
                    <FaRegCalendarAlt
                      size={20}
                      color={'black'}
                      style={{
                        color: '#0063a6',
                        width: innerWidth < 900 ? '70%' : '35%',
                        marginRight: innerWidth < 900 ? '50%' : '30%',
                      }}
                      onClick={() => {}}
                      title={t('seeDates')}
                    />
                  </Grid>
                  <Grid item xs={10} style={{ marginTop: '-3%', marginLeft: '-4%' }}>
                    <p>
                      {t('completedDate')}:{' '}
                      {new Date(currentGoal?.achievedAt)
                        ?.toLocaleString()
                        .substring(
                          0,
                          new Date(currentGoal?.achievedAt)?.toLocaleString().length - 3
                        )}{' '}
                      h
                    </p>
                  </Grid>
                </>
              )}
            </Grid>
            <CloseIcon
              color="primary"
              style={{
                marginRight: '-1.5%',
                marginTop: '-2.3%',
                backgroundColor: 'grey',
                color: 'white',
                borderRadius: 12,
              }}
              onClick={() => setModalOpened(false)}
            />
          </div>
        </Fade>
      </Modal>
    </>
  )
}
