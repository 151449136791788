import generic from '../../../../assets/generic.module.css'
import { Box } from '@mui/material'
import image from './intestino.svg'
import React from 'react'

export const CIS220 = () => {
  return (
    <>
      <Box className={generic.pageContainer} style={{ backgroundColor: 'white', display: 'flex' }}>
        <Box style={{ marginRight: '5%', marginLeft: '1%' }}>
          <Box className={generic.pageContainer} display="flex">
            <h2>
              <b>2.2 Disfunción intestinal</b>
            </h2>
          </Box>
          <Box className={generic.pageContainer} display="flex">
            <div>
              La disfunción intestinal es una afección que puede tener muchas causas diferentes.
              Podría existir una enfermedad neurológica en las vías de la médula/el cerebro que
              afecte al control intestinal, o bien una obstrucción y, en el caso de algunas
              personas, no existen razones médicas subyacentes por las que el intestino no funciona.
              <br />
              <br />
              <br />
              En algunos problemas intestinales, también existen razones psicológicas.
              Independientemente de los motivos de la disfunción intestinal, el impacto en la
              calidad de vida es inmenso. Las personas con síntomas de disfunción intestinal a
              menudo restringen su vida social o se aíslan por completo y tienen poca o ninguna
              confianza en sí mismas. Esta situación puede afectar a sus relaciones cercanas, puede
              complicar su capacidad para trabajar y las dificultades financieras relacionadas con
              la medicación o los costes de los métodos de contención pueden suponer un gran
              obstáculo para la vida diaria.
              <br />
              <br />
              <br />
            </div>
          </Box>

          <Box className={generic.pageContainer} display="flex">
            <h4 style={{ color: 'dodgerblue' }}>
              <b>Disfunción intestinal neurógena (DIN)</b>
            </h4>
          </Box>
          <Box className={generic.pageContainer} display="flex">
            <div>
              La disfunción intestinal neurógena (DIN) es el término utilizado para describir la
              disfunción del colon debido a la interrupción de las señales nerviosas que causa, por
              ejemplo, la interrupción de la peristalsis y las señales de urgencia. Esto hace que el
              intestino se distienda y el tiempo de tránsito para que las heces se muevan a lo largo
              del intestino se ralentiza en gran medida. Cuanto más tiempo permanecen las heces en
              el intestino, más agua se reabsorbe de las heces, lo que hace que la materia se vuelva
              más dura y aún más difícil de evacuar. La frecuencia de la evacuación se vuelve muy
              lenta e impredecible. Cuando ocurre la evacuación, puede ser muy doloroso y puede
              llevar horas y horas, con sensaciones de vaciado incompleto y manchado. En el caso de
              algunas personas, el intestino se vacía espontáneamente sin previo aviso.
              <br />
              <br />
              Algunos ejemplos de afecciones que pueden verse afectadas por la DIN:
              <br />
              <br />
              &nbsp;&nbsp;&nbsp;&nbsp;•&nbsp;&nbsp;&nbsp;&nbsp; Lesión medular
              <br />
              <br />
              &nbsp;&nbsp;&nbsp;&nbsp;•&nbsp;&nbsp;&nbsp;&nbsp; Lesión medular traumática
              <br />
              <br />
              &nbsp;&nbsp;&nbsp;&nbsp;•&nbsp;&nbsp;&nbsp;&nbsp; Lesión medular no traumática, como
              la espina bífida
              <br />
              <br />
              &nbsp;&nbsp;&nbsp;&nbsp;•&nbsp;&nbsp;&nbsp;&nbsp; Enfermedad de Parkinson
              <br />
              <br />
              &nbsp;&nbsp;&nbsp;&nbsp;•&nbsp;&nbsp;&nbsp;&nbsp; Esclerosis múltiple
              <br />
              <br />
              &nbsp;&nbsp;&nbsp;&nbsp;•&nbsp;&nbsp;&nbsp;&nbsp; Tras un accidente cerebrovascular
              <br />
              <br />
            </div>
          </Box>
        </Box>
      </Box>
    </>
  )
}
