import generic from '../../../../assets/generic.module.css'
import { Box } from '@mui/material'
import React, { useState } from 'react'
import image from './perfilVaron.jpg'
import { useTranslation } from 'react-i18next'

export const CVS232 = () => {
  const { t } = useTranslation()
  const [openModal, setOpenModal] = useState<boolean>(false)
  const { innerWidth } = window

  const handleOpenModal = () => setOpenModal(true)

  return (
    <>
      <Box className={generic.pageContainer} style={{ backgroundColor: 'white' }}>
        <Box className={generic.pageContainer} display="flex">
          <h2>
            <b>2.3 Función de la vejiga y ciclo miccional</b>
          </h2>
        </Box>
        <Box>
          <h1 style={{ color: 'dodgerblue' }}>
            <b>Control de la vejiga</b>
          </h1>
        </Box>
        <Box style={{ display: 'flex' }}>
          <Box className={generic.pageContainer}>
            <div>
              <b>Vaciado controlado por CPM</b>
              <br />
              <br />
              Coordinación de la contracción del detrusor y la relajación de la uretra.
              <br />
              <br />
              <b>El adulto puede controlar voluntariamente la micción</b>
              <br />
              <br />
              Influencia de los nervios voluntaries (somáticos) e involuntarios (autónomos)
              <br />
              <br />
            </div>
          </Box>
          <Box>
            <img
              src={image}
              onClick={handleOpenModal}
              style={{
                width: innerWidth < 900 ? '250px' : '400px',
                marginLeft: innerWidth < 900 ? '5%' : '70%',
                borderStyle: 'ridge',
                borderColor: 'dodgerblue',
              }}
            />
          </Box>
        </Box>
      </Box>
    </>
  )
}
