import { Container, IInit } from '../../../common/container/Container'
import { Observable, of } from 'rxjs'
import { Query } from '../../../common/api/Query'
import { emptyList, ItemList } from '../../../common/models/ItemList'
import { HTTP_CLIENT_KEY, IHTTPClient } from '../../../common/api/HTTPClient'
import { IStatusService } from '../../../common/status/StatusService'
import { STATUS_SERVICE_KEY } from '../../../container/app'
import { ContentContainerConfig } from '../../content/container'
import { catchError, map } from 'rxjs/operators'
import { prepareURL } from '../../../common/api/http-helpers'
import { AppointmentDTO } from '../models/AppointmentDTO'
import { Appointment, AppointmentQuery, toModel } from '../models/Appointment'

export interface IAppointmentApi extends IInit {
  getByID(id: string): Observable<Appointment | undefined>

  getFilteredList(q: Query<AppointmentQuery>): Observable<ItemList<Appointment>>

  getAppointmentsByCreatedByPatient(q: Query<AppointmentQuery>): Observable<ItemList<Appointment>>

  add(e: AppointmentDTO): Observable<Appointment | undefined>

  update(e: AppointmentDTO): Observable<Appointment | undefined>

  delete(id: string): Observable<boolean>
}

export class AppointmentApi implements IAppointmentApi {
  private _container!: Container
  private _httpClient!: IHTTPClient
  private _url!: string
  private _statusService!: IStatusService

  init(c: Container) {
    this._container = c
    this._httpClient = this._container.get<IHTTPClient>(HTTP_CLIENT_KEY)
    this._statusService = this._container.get<IStatusService>(STATUS_SERVICE_KEY)
    this._url = (this._container.config as ContentContainerConfig).moduleFullUrl
  }

  add(e: AppointmentDTO): Observable<Appointment | undefined> {
    return this._httpClient.post<Appointment>({ url: this._url, body: e }).pipe(
      map<AppointmentDTO, Appointment>((d) => {
        this._statusService.sendStatus({ variant: 'success' })
        return toModel(d)
      }),
      catchError((err) => {
        this._statusService.sendStatus({ variant: 'error', error: err })
        return of(undefined)
      })
    )
  }

  delete(id: string): Observable<boolean> {
    return this._httpClient.delete({ url: `${this._url}/${id}` }).pipe(
      catchError((err) => {
        this._statusService.sendStatus({ variant: 'error', error: err })
        return of(false)
      })
    )
  }

  getByID(id: string): Observable<Appointment | undefined> {
    return this._httpClient.get<Appointment>({ url: `${this._url}/${id}` }).pipe(
      map<AppointmentDTO, Appointment>((d) => toModel(d)),
      catchError((err) => {
        this._statusService.sendStatus({ variant: 'error', error: err })
        return of(undefined)
      })
    )
  }

  getFilteredList(q: Query<AppointmentQuery>): Observable<ItemList<Appointment>> {
    return this._httpClient.get<ItemList<Appointment>>({ url: prepareURL(this._url, q) }).pipe(
      map<ItemList<AppointmentDTO>, ItemList<Appointment>>((dto) => {
        const itemList = emptyList<Appointment>()
        itemList.count = dto.count
        itemList.items = dto.items.map((d) => toModel(d))
        return itemList
      }),
      catchError((err) => {
        this._statusService.sendStatus({ variant: 'error', error: err })
        return of(emptyList<Appointment>())
      })
    )
  }

  update(e: AppointmentDTO): Observable<Appointment | undefined> {
    return this._httpClient.put<Appointment>({ url: this._url, body: e }).pipe(
      map<AppointmentDTO, Appointment>((d) => {
        this._statusService.sendStatus({ variant: 'success' })
        return toModel(d)
      }),
      catchError((err) => {
        this._statusService.sendStatus({ variant: 'error', error: err })
        return of(undefined)
      })
    )
  }

  getAppointmentsByCreatedByPatient(q: Query<AppointmentQuery>): Observable<ItemList<Appointment>> {
    return this._httpClient
      .get<ItemList<Appointment>>({ url: prepareURL(`${this._url}/dashboard/`, q) })
      .pipe(
        map<ItemList<AppointmentDTO>, ItemList<Appointment>>((dto) => {
          const itemList = emptyList<Appointment>()
          itemList.count = dto.count
          itemList.items = dto.items.map((d) => toModel(d))
          return itemList
        }),
        catchError((err) => {
          this._statusService.sendStatus({ variant: 'error', error: err })
          return of(emptyList<Appointment>())
        })
      )
  }
}
