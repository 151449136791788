import { LangType } from '../../content/enums/LangType'
import { v4 as uuidv4 } from 'uuid'
import { NotificationMessage } from './NotificationMessage'

export interface NotificationMessageDTO {
  id: string | undefined
  recipientID: string
  messageOfID: string
  messageOfName: string
  readed: boolean
  messageOfPatientID: string
}

export function emptyNotificationMessageDTO(): NotificationMessageDTO {
  return {
    id: uuidv4(),
    recipientID: '',
    messageOfID: '',
    messageOfName: '',
    readed: false,
    messageOfPatientID: '',
  }
}

export function fromModel(g: NotificationMessage): NotificationMessageDTO {
  return {
    id: g.id,
    recipientID: g.recipientID,
    messageOfID: g.messageOfID,
    messageOfName: g.messageOfName,
    readed: g.readed,
    messageOfPatientID: g.messageOfPatientID,
  }
}

export function toModel(d: NotificationMessageDTO): NotificationMessage {
  return new NotificationMessage(d)
}
