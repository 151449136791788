import { TransportType } from '../../../common/enums/TransportType'
import { Notification } from './Notification'
import { PriorityLevel } from '../enums/PriorityLevel'
import { DeliveryStatus } from '../enums/DeliveryStatus'

export interface NotificationDTO {
  id: string | undefined
  recipientID: string
  transportType: TransportType
  title: number
  description: string
  createdAt: Date
  sendAt: Date
  priorityLevel: PriorityLevel
  deliveryStatus: DeliveryStatus
  readed: boolean
  idObject: string
}

export function fromModel(n: Notification): NotificationDTO {
  return {
    id: n.id,
    recipientID: n.recipientID,
    transportType: n.transportType,
    title: n.title,
    description: n.description,
    createdAt: n.createdAt,
    sendAt: n.sendAt,
    priorityLevel: n.priorityLevel,
    deliveryStatus: n.deliveryStatus,
    readed: n.readed,
    idObject: n.idObject,
  }
}

export function toModel(d: NotificationDTO): Notification {
  return new Notification({
    createdAt: new Date(d.createdAt),
    deliveryStatus: d.deliveryStatus,
    id: d.id,
    title: d.title,
    description: d.description,
    priorityLevel: d.priorityLevel,
    recipientID: d.recipientID,
    sendAt: new Date(d.sendAt),
    transportType: d.transportType,
    readed: d.readed,
    idObject: d.idObject,
  })
}
