import { QuestionnairePatient } from './QuestionnairePatient'

export interface QuestionnairePatientDTO {
  id: string
  questionnaireID: string
  assignedAt: Date
  finished: boolean
  finishedAt: Date
  score: number
  patientID: string
  title: string
  scoreText: string
}

export function toModel(d: QuestionnairePatientDTO): QuestionnairePatient {
  return new QuestionnairePatient(d)
}
