import { v4 as uuidv4 } from 'uuid'
import { Appointment } from './Appointment'

export interface AppointmentDTO {
  id: string
  title: string
  place: string
  date: Date
  patientID: string
  userID: string
  urlVideoConf: string
  warning: boolean
}

export function emptyAppointmentDTO(userID: string, patientID: string): AppointmentDTO {
  return {
    id: uuidv4(),
    title: '',
    place: '',
    date: new Date(),
    patientID: patientID,
    userID: userID,
    urlVideoConf: '',
    warning: true,
  }
}

export function fromModel(a: Appointment): AppointmentDTO {
  return {
    id: a.id,
    title: a.title,
    place: a.place,
    date: a.date,
    patientID: a.patientID,
    userID: a.userID,
    urlVideoConf: a.urlVideoConf,
    warning: a.warning,
  }
}
