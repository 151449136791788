import { Container, IInit } from '../../../common/container/Container'
import { PatientContainerConfig } from '../container'
import { Observable, of } from 'rxjs'
import { catchError, map } from 'rxjs/operators'
import { Query } from '../../../common/api/Query'
import { HTTP_CLIENT_KEY, IHTTPClient } from '../../../common/api/HTTPClient'
import { IStatusService } from '../../../common/status/StatusService'
import { STATUS_SERVICE_KEY } from '../../../container/app'
import { emptyList, ItemList } from 'common/models/ItemList'
import { PatientPoint } from '../models/PatientPoint'
import { PatientPointDTO, toModel } from '../models/PatientPointDTO'

export interface IPatientPointApi extends IInit {
  getByID(id: string | null): Observable<PatientPoint | undefined>

  getFilteredItems(q: Query<PatientPoint>): Observable<ItemList<PatientPoint>>

  add(e: PatientPointDTO): Observable<PatientPoint | undefined>

  update(e: PatientPointDTO): Observable<PatientPoint | undefined>

  delete(id: string): Observable<boolean>

  getPatientPointsByPatientID(patientID: string): Observable<ItemList<PatientPoint>>

  getStarsByPatientID(patientID: string): Observable<number | undefined>
}

export class PatientPointApi implements IPatientPointApi {
  private _container!: Container
  private _httpClient!: IHTTPClient
  private _url!: string
  private _statusService!: IStatusService

  init(c: Container) {
    this._container = c
    this._httpClient = this._container.get<IHTTPClient>(HTTP_CLIENT_KEY)
    this._statusService = this._container.get<IStatusService>(STATUS_SERVICE_KEY)
    this._url = (this._container.config as PatientContainerConfig).moduleFullUrl
  }

  getByID(id: string): Observable<PatientPoint | undefined> {
    return this._httpClient
      .get<PatientPoint>({ url: this._url + '/' + id })
      .pipe(
        map<PatientPointDTO, PatientPoint>((d) => toModel(d)),
        catchError((err) => {
          this._statusService.sendStatus({ variant: 'error', error: err })
          return of(undefined)
        })
      )
  }

  getFilteredItems(q: Query<PatientPoint>): Observable<ItemList<PatientPoint>> {
    return this._httpClient
      .post<ItemList<PatientPoint>>({ url: this._url + '/patientsymptom/paginatedList', body: q })
      .pipe(
        map<ItemList<PatientPointDTO>, ItemList<PatientPoint>>((dto) => {
          const itemList = emptyList<PatientPoint>()
          itemList.items = dto.items.map((d) => toModel(d))
          itemList.count = dto.count
          return itemList
        }),
        catchError((err) => {
          this._statusService.sendStatus({ variant: 'error', error: err })
          return of(emptyList<PatientPoint>())
        })
      )
  }

  add(e: PatientPointDTO): Observable<PatientPoint | undefined> {
    return this._httpClient
      .post<PatientPoint>({ url: this._url + '/patientsymptom', body: e })
      .pipe(
        map<PatientPointDTO, PatientPoint>((d) => {
          this._statusService.sendStatus({ variant: 'success' })
          return toModel(d)
        }),
        catchError((err) => {
          this._statusService.sendStatus({ variant: 'error', error: err })
          return of(undefined)
        })
      )
  }

  update(e: PatientPointDTO): Observable<PatientPoint | undefined> {
    return this._httpClient
      .put<PatientPoint>({ url: this._url + '/patientsymptom', body: e })
      .pipe(
        map<PatientPointDTO, PatientPoint>((d) => {
          this._statusService.sendStatus({ variant: 'success' })
          return toModel(d)
        }),
        catchError((err) => {
          this._statusService.sendStatus({ variant: 'error', error: err })
          return of(undefined)
        })
      )
  }

  delete(id: string): Observable<boolean> {
    return this._httpClient.delete({ url: this._url + '/patientsymptom/' + id }).pipe(
      catchError((err) => {
        this._statusService.sendStatus({ variant: 'error', error: err })
        return of(false)
      })
    )
  }

  getPatientPointsByPatientID(patientID: string): Observable<ItemList<PatientPoint>> {
    return this._httpClient
      .get<ItemList<PatientPoint>>({ url: this._url + '/patientpoints/' + patientID })
      .pipe(
        map<ItemList<PatientPointDTO>, ItemList<PatientPoint>>((dto) => {
          let currentDate = new Date()
          let before = new Date()
          before.setDate(new Date().getDate() - 7)
          let beforeDate = before
          let filtered: PatientPoint[] = []
          dto.items.forEach((item) => {
            if (new Date(item.createdAt) >= beforeDate && new Date(item.createdAt) <= currentDate) {
              filtered.push(toModel(item))
            }
          })
          const itemList = emptyList<PatientPoint>()
          itemList.items = filtered
          itemList.count = dto.count
          return itemList
        }),
        catchError((err) => {
          this._statusService.sendStatus({ variant: 'error', error: err })
          return of(emptyList<PatientPoint>())
        })
      )
  }

  getStarsByPatientID(patientID: string): Observable<number | undefined> {
    return this._httpClient
      .get<number>({ url: this._url + '/stars/' + patientID })
      .pipe(
        map<number, number>((r) => r),
        catchError((err) => {
          this._statusService.sendStatus({ variant: 'error', error: err })
          return of(undefined)
        })
      )
  }
}
