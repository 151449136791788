import { Container } from '../../common/container/Container'

export type ContentContainerConfig = {
  moduleFullUrl: string
}

export type ContentProps = {
  parentContainer: Container
  config: ContentContainerConfig
}

export const URINATION_MODULE = Symbol('URINATION_MODULE')

export const URINATION_API_KEY = Symbol('URINATION_API_KEY')

export const URINATION_SERVICE_KEY = Symbol('URINATION_SERVICE_KEY')
