import generic from '../../../../assets/generic.module.css'
import { Box, Button, Divider, Modal } from '@mui/material'
import React, { useState } from 'react'
import image from '../vesical/uninarysis.jpg'
import { useTranslation } from 'react-i18next'

export const CVS210 = () => {
  const { t } = useTranslation()
  const [openModal, setOpenModal] = useState<boolean>(false)
  const { innerWidth } = window
  const handleOpenModal = () => setOpenModal(true)
  const handleCloseModal = () => setOpenModal(false)

  return (
    <>
      <Box className={generic.pageContainer} style={{ backgroundColor: 'white' }}>
        <Box className={generic.pageContainer} display="flex">
          <h2>
            <b>2.1 ¿Qué es y como funciona?</b>
          </h2>
        </Box>
        <Box className={generic.pageContainer}>
          <div>
            La función recurrente normal de vaciar y almacenar la orina se denomina ciclo de
            micción. Se divide en una fase de llenado y otra de vaciado. Actúa como una interacción
            entre el sistema nervioso y el tracto urinario inferior. Es importante que todas las
            partes del sistema urinario funcionen al unísono para que se produzca la micción normal.
            <br />
            <br />
            El sistema urinario consta de <b>riñones (A), uréteres (B), vejiga (C) y uretra (D).</b>
            <br />
            <br />
          </div>
        </Box>
        <Box style={{ display: 'flex', justifyContent: 'center' }}>
          <img
            src={image}
            onClick={handleOpenModal}
            style={{
              width: innerWidth < 900 ? '80%' : '35%',
              borderStyle: 'ridge',
              borderColor: 'dodgerblue',
            }}
          />
        </Box>
        <Box>
          <h1 style={{ color: 'dodgerblue' }}>
            <br />
            <br />
            <br />
            <br />
            <b>¿Por qué es importante el sistema urinario?</b>
          </h1>
        </Box>
        <Box className={generic.pageContainer}>
          <div>
            Además de filtrar y eliminar los productos de desecho del cuerpo, el sistema urinario
            también mantiene nuestro equilibrio electrolítico de sodio, potasio y calcio, volumen
            sanguíneo y presión arterial.
          </div>
        </Box>

        <Modal open={openModal} onClose={handleCloseModal}>
          <Box
            style={{
              position: 'absolute',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
              backgroundColor: 'white',
              padding: '10px',
            }}>
            <h1 style={{ marginLeft: '25%' }}>
              <b>Sistema urinario</b>
            </h1>
            <Divider variant={'middle'} />
            <img
              src={image}
              style={{
                justifyContent: 'center',
                display: 'flex',
                width: '100%',
              }}
            />
            <Button
              variant={'outlined'}
              style={{ marginLeft: '45%', marginTop: '1%', justifyContent: 'end', display: 'flex' }}
              onClick={handleCloseModal}>
              {t('close')}
            </Button>
          </Box>
        </Modal>
      </Box>
    </>
  )
}
