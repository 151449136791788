import { Observable } from 'rxjs'
import { Query } from '../../../common/api/Query'
import { ItemList } from '../../../common/models/ItemList'
import { Container, IInit } from '../../../common/container/Container'
import { IStatusService } from '../../../common/status/StatusService'
import { STATUS_SERVICE_KEY } from '../../../container/app'
import { ProblemDTO } from '../models/ProblemDTO'
import { Problem, ProblemQuery } from '../models/Problem'
import { IProblemApi } from '../api/ProblemApi'

type Props = {
  apiKey: symbol
}

export interface IProblemService extends IInit {
  getByID(id: string): Observable<Problem>
  getFilteredList(q: Query<ProblemQuery>): Observable<ItemList<Problem>>
  add(e: ProblemDTO): Observable<Problem | undefined>
  update(e: ProblemDTO): Observable<Problem | undefined>
  delete(id: string | undefined): Observable<boolean>
}

export class ProblemService implements IProblemService {
  private readonly _apiKey: symbol
  private _container!: Container
  private _api!: IProblemApi
  private _statusService!: IStatusService

  constructor(p: Props) {
    this._apiKey = p.apiKey
  }

  init(c: Container) {
    this._container = c
    this._api = this._container.get<IProblemApi>(this._apiKey)
    this._statusService = this._container.get<IStatusService>(STATUS_SERVICE_KEY)
  }

  getByID(id: string | undefined): Observable<Problem> {
    return this._api.getByID(id)
  }

  getFilteredList(q: Query<ProblemQuery>): Observable<ItemList<Problem>> {
    return this._api.getFilteredList(q).pipe()
  }

  add(e: ProblemDTO): Observable<Problem | undefined> {
    return this._api.add(e)
  }

  delete(id: string | undefined): Observable<boolean> {
    return this._api.delete(id)
  }

  update(e: ProblemDTO): Observable<Problem | undefined> {
    return this._api.update(e)
  }
}
