import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Typography,
} from '@mui/material'
import generic from '../../assets/generic.module.css'
import { Article, ArticleQuery } from '../../modules/content/models/Article'
import React, { useEffect, useState } from 'react'
import { ArticleService } from '../../modules/content/services/ArticleService'
import { ARTICLE_SERVICE_KEY } from '../../modules/content'
import { getContentContainer } from '../../container/content-module'
import { Query, QueryParam } from '../../common/api/Query'
import { LangType } from '../../modules/content/enums/LangType'
import { ArticleType } from '../../modules/content/enums/ArticleType'
import { ExpandMore } from '@material-ui/icons'
import { useTranslation } from 'react-i18next'
import { navigate } from '@reach/router'
import { ROUTE_FAQS_CREATE, ROUTE_FAQS_EDIT } from '../../routes/routes-constants'
import { AppButton, ButtonTheme } from '../../components/app-button/AppButton'
import { getUserContainer } from '../../container/user-module'
import { LoggedUserService } from 'modules/users/services/LoggedUserService'
import { LOGGED_USER_SERVICE_KEY } from 'modules/users'

const articleService = getContentContainer().get<ArticleService>(ARTICLE_SERVICE_KEY)
const userContainer = getUserContainer()
const loggedUserService = userContainer.get<LoggedUserService>(LOGGED_USER_SERVICE_KEY)

export const List = () => {
  const [items, setItems] = useState<Article[]>([])
  const [selectedPanel, setSelectedPanel] = useState<string>('')
  const [isLoading, setIsLoading] = useState<boolean>(true)
  const { t } = useTranslation()

  const goNewFaq = () => {
    navigate(ROUTE_FAQS_CREATE).then()
  }
  //navigate(ROUTE_EDUCATIONAL.replace(':id', `${props.id}`))
  const goEditFaq = (i: Article) => navigate(ROUTE_FAQS_EDIT.replace(':id', i.id || '')).then()

  useEffect(() => {
    if (!isLoading) return

    articleService
      .getFilteredList(
        new Query<ArticleQuery>({
          pager: { offset: 0, limit: -1 },
          query: [
            new QueryParam<ArticleQuery>('language', LangType.Spanish),
            new QueryParam<ArticleQuery>('type', ArticleType.Faqs),
          ],
          sort: [{ field: 'createdAt', desc: true }],
        })
      )
      .subscribe((res) => {
        setIsLoading(false)
        setItems(res.items)
      })
  }, [isLoading])

  const deleteFaq = (i: string) => {
    articleService.delete(i).subscribe((res) => {
      setIsLoading(true)
    })
  }

  return (
    <>
      {!isLoading && (
        <Box className={generic.pageContainer}>
          {loggedUserService.isAdmin() && (
            <Box mb={3} style={{ display: 'flex', justifyContent: 'flex-end', marginRight: '1%' }}>
              <AppButton
                theme={ButtonTheme.NewPrimaryLight}
                type={'button'}
                label={t('add')}
                handler={goNewFaq}
              />
            </Box>
          )}
          <Box mb={3}>
            {items
              .sort(function (a, b) {
                return a.order - b.order
              })
              .map((i) => (
                <Accordion
                  sx={{ marginBottom: 1.5, borderRadius: '4px' }}
                  expanded={selectedPanel === i.id}
                  onChange={() =>
                    selectedPanel === i.id ? setSelectedPanel('') : setSelectedPanel(i.id || '')
                  }>
                  <AccordionSummary expandIcon={<ExpandMore />} id={i.id}>
                    <Typography
                      style={{
                        fontWeight: 'bold',
                        color: '#000',
                        fontFamily: 'Open-sans, sans-serif',
                      }}>
                      {i.ask}
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Typography style={{ color: '#000', fontFamily: 'Open-sans, sans-serif' }}>
                      {i.answer}
                    </Typography>
                    {loggedUserService.isAdmin() && (
                      <>
                        <Button
                          style={{
                            marginTop: '25px',
                            marginRight: '25px',
                            backgroundColor: '#1996FF',
                            fontFamily: 'Open-sans, sans-serif',
                          }}
                          variant={'contained'}
                          onClick={(e) => {
                            goEditFaq(i)
                          }}>
                          {t('edit')}
                        </Button>
                        <Button
                          style={{
                            marginTop: '25px',
                            fontFamily: 'Open-sans, sans-serif',
                          }}
                          variant="outlined"
                          color="error"
                          onClick={(e) => {
                            if (!i.id) return
                            deleteFaq(i.id)
                          }}>
                          {t('delete')}
                        </Button>
                      </>
                    )}
                  </AccordionDetails>
                </Accordion>
              ))}
          </Box>
        </Box>
      )}
    </>
  )
}
