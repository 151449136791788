import style from './CurrentNavHeader.module.css'
import { Box } from '@material-ui/core'

export type CurrentNavHeaderProps = {
  title: string
  strong?: boolean
}

export function CurrentNavHeader(props: CurrentNavHeaderProps): JSX.Element {
  return (
    <div className={style.currentNavHeaderContainer}>
      <Box display="flex" justifyContent="space-between" className={style.titleContainer}>
        <div>
          {props.strong ? (
            <h1 style={{ fontWeight: 'bold' }}>{props.title}</h1>
          ) : (
            <h1>{props.title}</h1>
          )}
        </div>
      </Box>
    </div>
  )
}
