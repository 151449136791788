import { QuestionnaireRange } from "./QuestionnaireRange"


export interface QuestionnaireRangeDTO {
    score: number
    title: string
}

export function toModel(d: QuestionnaireRangeDTO): QuestionnaireRange {
    return new QuestionnaireRange(d);
}