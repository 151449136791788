import { Box, List, ListItem } from '@mui/material'
import generic from '../../assets/generic.module.css'
import { Avatar, ListItemIcon, Typography } from '@material-ui/core'
import moduleActiveIcon from '../../assets/higea/modulo-activo.svg'
import React, { useState } from 'react'
import { Module1Lessons } from './lessonsComponents/vesicalLessons/Module1'
import { Module6Lessons } from './lessonsComponents/vesicalLessons/Module6'
import { Module5Lessons } from './lessonsComponents/vesicalLessons/Module5'
import { Module4Lessons } from './lessonsComponents/vesicalLessons/Module4'
import { Module3Lessons } from './lessonsComponents/vesicalLessons/Module3'
import { Module2Lessons } from './lessonsComponents/vesicalLessons/Module2'
import styles from './EducationalProfessional.module.css'

export const VesicalModule = () => {
  const [acordeon1, setAcordeon1] = useState<boolean>(false)
  const [acordeon2, setAcordeon2] = useState<boolean>(false)
  const [acordeon3, setAcordeon3] = useState<boolean>(false)
  const [acordeon4, setAcordeon4] = useState<boolean>(false)
  const [acordeon5, setAcordeon5] = useState<boolean>(false)
  const [acordeon6, setAcordeon6] = useState<boolean>(false)

  const handleClick1 = () => {
    setAcordeon1(!acordeon1)
  }
  const handleClick2 = () => {
    setAcordeon2(!acordeon2)
  }
  const handleClick3 = () => {
    setAcordeon3(!acordeon3)
  }
  const handleClick4 = () => {
    setAcordeon4(!acordeon4)
  }
  const handleClick5 = () => {
    setAcordeon5(!acordeon5)
  }

  return (
    <List>
      <Box
        className={generic.pageContainer}
        style={{
          backgroundColor: '#0050c4',
          marginTop: '2%',
          marginLeft: '2%',
          marginRight: '2%',
          borderRadius: '10px',
        }}>
        <ListItem>
          <ListItemIcon>
            <Avatar src={moduleActiveIcon} style={{ width: 55, height: 55 }} />
          </ListItemIcon>
          <Typography style={{ marginLeft: 10, color: 'white' }} variant="h6">
            VESICAL
          </Typography>
        </ListItem>
      </Box>
      <ListItem>
        <Box className={styles.lessonContainer}>
          <Box display={'flex'} justifyContent={'space-between'} alignItems={'center'}>
            <ListItem id={'idLesson'} button onClick={handleClick1}>
              <Typography style={{ color: 'blue' }}>Módulo 1. Introducción</Typography>
            </ListItem>
          </Box>
        </Box>
      </ListItem>
      {acordeon1 && <Module1Lessons />}
      <ListItem>
        <Box className={styles.lessonContainer}>
          <Box display={'flex'} justifyContent={'space-between'} alignItems={'center'}>
            <ListItem id={'idLesson'} button onClick={handleClick2}>
              <Typography style={{ color: 'blue' }}>Módulo 2. El sistema urinario</Typography>
            </ListItem>
          </Box>
        </Box>
      </ListItem>
      {acordeon2 && <Module2Lessons />}
      <ListItem>
        <Box className={styles.lessonContainer}>
          <Box display={'flex'} justifyContent={'space-between'} alignItems={'center'}>
            <ListItem id={'idLesson'} button onClick={handleClick3}>
              <Typography style={{ color: 'blue' }}>Módulo 3. Patologías</Typography>
            </ListItem>
          </Box>
        </Box>
      </ListItem>
      {acordeon3 && <Module3Lessons />}
      <ListItem>
        <Box className={styles.lessonContainer}>
          <Box display={'flex'} justifyContent={'space-between'} alignItems={'center'}>
            <ListItem id={'idLesson'} button onClick={handleClick4}>
              <Typography style={{ color: 'blue' }}>Módulo 4. Cateterismo intermitente</Typography>
            </ListItem>
          </Box>
        </Box>
      </ListItem>
      {acordeon4 && <Module4Lessons />}
      <ListItem>
        <Box className={styles.lessonContainer}>
          <Box display={'flex'} justifyContent={'space-between'} alignItems={'center'}>
            <ListItem id={'idLesson'} button onClick={handleClick5}>
              <Typography style={{ color: 'blue' }}>Módulo 5. Urodinámica</Typography>
            </ListItem>
          </Box>
        </Box>
      </ListItem>
      {acordeon5 && <Module5Lessons />}
      {/**   <ListItem>
        <Box className={styles.lessonContainer}>
          <Box display={'flex'} justifyContent={'space-between'} alignItems={'center'}>
            <ListItem id={'idLesson'} button onClick={handleClick6}>
              <Typography style={{ color: 'blue' }}>
                Módulo 6. Reeducación de suelo pélvico
              </Typography>
            </ListItem>
          </Box>
        </Box>
      </ListItem>*/}
      {acordeon6 && <Module6Lessons />}
    </List>
  )
}
