import i18n from '../../../i18n'

export enum LastActivity {
  UrinationDiary,
  FecalDiary,
  Questionnaire,
  SymptomReport,
}

export const lastActivity = (): Record<LastActivity, string> => ({
  [LastActivity.UrinationDiary]: i18n.t('urinationDiaryRegistered'),
  [LastActivity.FecalDiary]: i18n.t('fecalDiaryRegistered'),
  [LastActivity.Questionnaire]: i18n.t('questionnaireRegistered'),
  [LastActivity.SymptomReport]: i18n.t('symptomReportRegistered'),
})
