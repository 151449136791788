import { ConversationNotifDTO } from "./ConversationNotifDTO"

export class ConversationNotif {
    private _patientID: string
    private _patientName: string
    private _totalMessages: number

    constructor(p: ConversationNotifDTO) {
        this._patientID = p.patientID;
        this._patientName = p.patientName;
        this._totalMessages = p.totalMessages;
    }

    get patientID(): string {
        return this._patientID;
    }

    set patientID(value: string) {
        this._patientID = value;
    }


    get patientName(): string {
        return this._patientName;
    }

    set patientName(value: string) {
        this._patientName = value;
    }


    get totalMessages(): number {
        return this._totalMessages;
    }

    set totalMessages(value: number) {
        this._totalMessages = value;
    }

}