import { ConversationNotif } from "./ConversationNotif"



export interface ConversationNotifDTO {
  patientID: string
  patientName: string
  totalMessages: number
}

export function toModel(d: ConversationNotifDTO): ConversationNotif {
  return new ConversationNotif({
    patientID: d.patientID,
    patientName: d.patientName,
    totalMessages: d.totalMessages
  })
}