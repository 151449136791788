import { Container, ContainerItem } from '../common/container/Container'
import { getAppContainer } from './app'
import { moduleURLs } from '../config/api'
import { ContentProps } from '../modules/goals/container'
import { AppointmentApi } from '../modules/appointments/api/AppointmentApi'
import { AppointmentService } from '../modules/appointments/services/AppointmentService'
import {
  APPOINTMENTS_API_KEY,
  APPOINTMENTS_MODULE,
  APPOINTMENTS_SERVICE_KEY,
} from '../modules/appointments'

let container: Container

function init(p: ContentProps) {
  const items = new Map<symbol, ContainerItem>()

  items.set(APPOINTMENTS_API_KEY, new ContainerItem({ instance: new AppointmentApi() }))

  items.set(
    APPOINTMENTS_SERVICE_KEY,
    new ContainerItem({ instance: new AppointmentService({ apiKey: APPOINTMENTS_API_KEY }) })
  )

  container = new Container(items, p.parentContainer, p.config)
}

export function getAppointmentContainer(): Container {
  if (!container) {
    init({
      parentContainer: getAppContainer(),
      config: {
        moduleFullUrl: moduleURLs[APPOINTMENTS_MODULE],
      },
    })
  }

  return container
}
