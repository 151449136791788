import generic from '../../../../assets/generic.module.css'
import { Box, Button, Divider, Modal } from '@mui/material'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import image from './em.png'

export const CIS310 = () => {
  const { t } = useTranslation()
  const [openModal, setOpenModal] = useState<boolean>(false)
  const { innerWidth } = window
  const handleOpenModal = () => setOpenModal(true)
  const handleCloseModal = () => setOpenModal(false)

  return (
    <>
      <Box className={generic.pageContainer} style={{ backgroundColor: 'white' }}>
        <Box className={generic.pageContainer} display="flex">
          <h2>
            <b>3.1 Esclerosis Múltiple y función intestinal</b>
          </h2>
        </Box>
        <Box>
          <Box display="flex" className={generic.pageContainer} style={{ marginRight: '15%' }}>
            <div>
              La esclerosis múltiple (EM) es una afección neurológica de por vida que afecta al
              sistema nervioso central, nervios del cerebro y médula espinal. No todas las personas
              sufrirán los mismos síntomas.
              <br />
              <br />
              En la EM, el sistema inmunológico del cuerpo ataca por error el tejido de mielina,
              cuya función es proteger las fibras nerviosas del sistema nervioso central y
              garantizar una transmisión fluida de mensajes entre el cerebro y el cuerpo. Estos
              ataques dañan el tejido de mielina protector (llamado desmielinización) y las células
              nerviosas, lo que interfiere con la transmisión adecuada de los mensajes.
              <br />
              <br />
              El daño o la cicatrización (esclerosis) puede ocurrir en diferentes partes del sistema
              nervioso central y los síntomas que presenta una persona dependen de la ubicación o de
              la cantidad de lesiones que tenga. Los síntomas físicos comunes son alteraciones de la
              visión, dificultades para mantener el equilibrio y mareos, cansancio, problemas
              vesicales/intestinales y rigidez y/o espasmos musculares.
              <br />
              <br />
            </div>
            <Box className={generic.pageContainer}>
              <img
                src={image}
                onClick={handleOpenModal}
                style={{
                  width: innerWidth < 900 ? '150%' : '100%',
                  marginLeft: innerWidth < 900 ? '20%' : '20%',
                  borderStyle: 'ridge',
                  borderColor: 'dodgerblue',
                }}
              />
            </Box>
          </Box>
        </Box>
        <Box className={generic.pageContainer} style={{ marginTop: '2%', marginRight: '12%' }}>
          <div>
            La EM se puede clasificar en 3 tipos:
            <br />
            <br />
            &nbsp;&nbsp;&nbsp;&nbsp;•&nbsp;&nbsp;&nbsp;&nbsp; Remitente/recurrente
            <br />
            <br />
            &nbsp;&nbsp;&nbsp;&nbsp;•&nbsp;&nbsp;&nbsp;&nbsp; Progresiva primaria
            <br />
            <br />
            &nbsp;&nbsp;&nbsp;&nbsp;•&nbsp;&nbsp;&nbsp;&nbsp; EM secundaria
            <br />
            <br />
            Es importante comprender qué tipo de EM padece una persona, porque algunos síntomas
            empeorarán con el tiempo sin ninguna mejora (EM progresiva), mientras que la EM
            remitente/recurrente se presentará con un deterioro grave de los síntomas, pero también
            una mejora temporal de otros síntomas.
            <br />
            <br />
            El intestino está controlado por las mismas vías neurales que el sistema urinario y
            también pueden verse afectados por la EM. La interrupción de los mensajes entre el
            cerebro y diferentes zonas del sistema digestivo provoca problemas por el paso más lento
            de los alimentos a través del tracto intestinal, reducción de la sensibilidad y del
            control de los músculos del ano, lo que puede provocar problemas como estreñimiento e
            incontinencia fecal. El estreñimiento, la diarrea y la pérdida de control son los
            problemas intestinales más habituales en la EM. Estos síntomas pueden agravarse aún más
            por alteraciones de las sensaciones, reducción de la movilidad/del equilibrio,
            disfunción de las extremidades inferiores y reconocimiento de los síntomas.
            <br />
            <br />
            Los impactos emocionales y psicológicos de la EM, combinados con los síntomas de la
            disfunción intestinal, afectan gravemente a la confianza y la calidad de vida de la
            persona.
            <br />
            <br />
          </div>
        </Box>

        <Modal open={openModal} onClose={handleCloseModal}>
          <Box
            style={{
              backgroundColor: 'white',
              justifyContent: 'center',
              transform: 'translate(0%, 20%)',
              marginLeft: '30%',
              marginRight: '30%',
              padding: '20px',
            }}>
            <h1 style={{ marginLeft: '25%' }}>
              <b>Esclerosis Múltiple y función intestinal</b>
            </h1>
            <Divider variant={'middle'} />
            <img
              src={image}
              style={{
                width: '80%',
                height: '25%',
                marginTop: '1%',
                marginLeft: '10%',
                justifyContent: 'center',
                display: 'flex',
              }}
            />
            <Button
              variant={'outlined'}
              style={{ marginLeft: '45%', marginTop: '1%', justifyContent: 'end', display: 'flex' }}
              onClick={handleCloseModal}>
              {t('close')}
            </Button>
          </Box>
        </Modal>
      </Box>
    </>
  )
}
