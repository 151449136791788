import React, { useEffect, useState } from 'react'
import { Field, Pager, Search, SearchValue, Sort } from '../../components/table/types'
import { Box, Typography, makeStyles, TablePagination, TextField } from '@material-ui/core'
import style from '../patients/Patients.module.css'
import { AppTable } from '../../components/table'
import { PatientSymptom, PatientSymptomQuery } from 'modules/patients/models/PatientSymptom'
import { useTranslation } from 'react-i18next'
import generic from '../../assets/generic.module.css'
import { AppButton, ButtonTheme } from '../../components/app-button/AppButton'
import { Query, QueryParam, QueryParamN, SortParam } from '../../common/api/Query'
import { getPatientContainer } from '../../container/patient-module'
import { PatientSymptomService } from '../../modules/patients/services/PatientSymptomService'
import { PATIENT_SYMPTOM_SERVICE_KEY, SYMPTOM_SERVICE_KEY } from '../../modules/patients/container'
import { SymptomService } from '../../modules/patients/services/SymptomService'
import { navigate } from '@reach/router'
import { ROUTE_PATIENTS_ID } from '../../routes/routes-constants'
import stylesAppoint from '../appointments/Appointments.module.css'
import { Grid, ListItem } from '@mui/material'
import { Symptom } from 'modules/patients/models/Symptom'
import { getUserContainer } from 'container/user-module'
import { ILoggedUserService } from 'modules/users/services/LoggedUserService'
import { LOGGED_USER_SERVICE_KEY } from 'modules/users'

const patientSymptomsService = getPatientContainer().get<PatientSymptomService>(
  PATIENT_SYMPTOM_SERVICE_KEY
)
const symptomService = getPatientContainer().get<SymptomService>(SYMPTOM_SERVICE_KEY)
const loggedUserService = getUserContainer().get<ILoggedUserService>(LOGGED_USER_SERVICE_KEY)

type PatientDataProps = {
  id: string
}

export function Symptoms(props: PatientDataProps) {
  const { t } = useTranslation()
  const [items, setItems] = useState<PatientSymptom[]>([])
  const [page, setPage] = useState<number>(0)
  const [count, setCount] = useState<number>(0)
  const [symptoms, setSymptoms] = useState<Symptom[]>([])
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [symptomsPerPage, setSymptomsPerPage] = useState<number>(10)
  const [searcher, setSearcher] = useState<SearchValue<PatientSymptomQuery>[]>([
    {
      name: 'createdAt',
      label: '',
      type: 'date',
    },
  ])
  const [pager, setPager] = useState<Pager>()
  const [sort, setSort] = useState<SortParam<any>>({
    field: 'createdAt',
    desc: true,
  })

  const sortable: Sort<any> = {
    name: sort.field,
    direction: sort.desc ? 'desc' : 'asc',
    handleSort: (field) => {
      //@ts-ignore
      setSort({ field: field, desc: sort.field === field ? !sort.desc : true })
    },
  }

  const fields: Field<PatientSymptom>[] = [
    {
      searchable: true,
      sortable: false,
      label: t('name'),
      name: 'id',
      renderFunc: (f, i) =>
        symptoms?.filter((s) => s.id == i.symptomID).length > 0
          ? t(symptoms.filter((s) => s.id == i.symptomID)[0].name)
          : '',
    },
    {
      searchable: true,
      sortable: true,
      label: t('createdAt'),
      name: 'createdAt',
      renderFunc: (f, i) =>
        loggedUserService.get()?.language == 1
          ? (new Date(i.createdAt).getDate() <= 9 ? '0' : '') +
            new Date(i.createdAt).getDate() +
            '/' +
            (new Date(i.createdAt).getMonth() <= 9 ? '0' : '') +
            (new Date(i.createdAt).getMonth() + 1) +
            '/' +
            new Date(i.createdAt).getFullYear().toString()
          : new Date(i.createdAt).toLocaleDateString(),
    },
  ]

  const searcherQuery = (
    svs: SearchValue<PatientSymptomQuery>[]
  ): QueryParam<PatientSymptomQuery>[] | QueryParamN<PatientSymptomQuery>[] =>
    svs.filter((sv) => sv.value).map((sv) => ({ name: sv.name, value: sv.value as string }))

  useEffect(() => {
    symptomService.getFilteredList(new Query({})).subscribe((res) => {
      setSymptoms(res.items)
    })
  }, [])

  useEffect(() => {
    patientSymptomsService
      .getFilteredItems(
        new Query({
          query: [
            new QueryParam<PatientSymptomQuery>('patientID', props.id),
            ...searcherQuery(searcher),
          ],
          pager: { offset: page * symptomsPerPage, limit: symptomsPerPage },
          sort: [{ field: sort.field, desc: sort.desc }],
        })
      )
      .subscribe((res) => {
        setItems(res.items)
        setCount(res.count)
        setIsLoading(false)
      })
  }, [isLoading, pager, symptomsPerPage, symptoms, searcher, sort])

  const search: Search<PatientSymptomQuery> = {
    searchValues: searcher,
    handleSearch: (svs: SearchValue<PatientSymptomQuery>[]) => {
      const result = svs.map((s) => {
        if (s.type !== 'date' || !s.value) {
          return s
        }

        const date = new Date(s.value)
        return Object.assign({ ...s }, { value: date.toJSON() })
      })

      setSearcher(result)
    },
  }

  const handlePaginationChange = (event: unknown, value: number) => {
    setPage(value)
  }

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    event.preventDefault()
    if (Number.isNaN(event.target.value)) {
      setSymptomsPerPage(10)
      return
    }
    setSymptomsPerPage(Number.parseInt(event.target.value))
    setPage(0)
  }

  useEffect(() => {
    setIsLoading(!isLoading)
    setPager({
      page,
      count,
      handleChangePage: handlePaginationChange,
      rowsPerPage: symptomsPerPage,
      handleChangeRowsPerPage,
    })
  }, [page, symptomsPerPage])

  return (
    <>
      {!isLoading && (
        <Box className={generic.pageContainer}>
          <Box className={stylesAppoint.buttonContainer}>
            <AppButton
              theme={ButtonTheme.NewPrimaryLightWithoutWidth}
              type={'button'}
              label={t('returnToPatient')}
              handler={() => navigate(ROUTE_PATIENTS_ID.replace(':id', `${props.id}`))}
            />
          </Box>
          <Box className={style.buttonContainer} style={{ marginTop: '2%' }} />
          <Box className={style.tableContainer}>
            <Grid item style={{ marginTop: '-2%', marginBottom: '1%' }}>
              <ListItem style={{ paddingLeft: 0 }}>
                <Typography
                  style={{ color: '#000', fontFamily: 'Open-sans, sans-serif' }}
                  variant="h6">
                  {t('symptoms')}
                </Typography>
              </ListItem>
            </Grid>
            <AppTable
              items={items}
              rowKeyField="id"
              fields={fields}
              search={search}
              pager={pager}
              sort={sortable}
              widthSearcher={true}
            />
          </Box>
        </Box>
      )}
    </>
  )
}
