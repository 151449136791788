import { PatientPointDTO } from './PatientPointDTO'
import { Source } from '../enums/Source_Points'

export class PatientPoint {
  private readonly _id: string
  private _createdAt: Date
  private _points: number
  private _patientID: string
  private _source: Source

  constructor(p: PatientPointDTO) {
    this._id = p.id
    this._createdAt = p.createdAt
    this._points = p.points
    this._patientID = p.patientID
    this._source = p.source
  }

  get id(): string {
    return this._id
  }

  get createdAt(): Date {
    return this._createdAt
  }

  get patientID(): string {
    return this._patientID
  }

  get points(): number {
    return this._points
  }

  get source(): Source {
    return this._source
  }
}

export interface PatientPointQuery {
  id: string
  createdAt: Date
  points: number
  patientID: string
  source: Source
}
