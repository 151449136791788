import { Observable } from 'rxjs'
import { Query } from '../../../common/api/Query'
import { ItemList } from '../../../common/models/ItemList'
import { Container, IInit } from '../../../common/container/Container'
import { IStatusService } from '../../../common/status/StatusService'
import { STATUS_SERVICE_KEY } from '../../../container/app'
import { Diagnostic, DiagnosticQuery } from '../models/Diagnostic'
import { DiagnosticDTO } from '../models/DiagnosticDTO'
import { IDiagnosticApi } from '../api/DiagnosticApi'

type Props = {
  apiKey: symbol
}

export interface IDiagnosticService extends IInit {
  getByID(id: string): Observable<Diagnostic>
  getFilteredList(q: Query<DiagnosticQuery>): Observable<ItemList<Diagnostic>>
  add(e: DiagnosticDTO): Observable<Diagnostic | undefined>
  update(e: DiagnosticDTO): Observable<Diagnostic | undefined>

  delete(id: string | undefined): Observable<boolean>

  getByName(name: string | undefined): Observable<Diagnostic>
}

export class DiagnosticService implements IDiagnosticService {
  private readonly _apiKey: symbol
  private _container!: Container
  private _api!: IDiagnosticApi
  private _statusService!: IStatusService

  constructor(p: Props) {
    this._apiKey = p.apiKey
  }

  init(c: Container) {
    this._container = c
    this._api = this._container.get<IDiagnosticApi>(this._apiKey)
    this._statusService = this._container.get<IStatusService>(STATUS_SERVICE_KEY)
  }

  getByID(id: string | undefined): Observable<Diagnostic> {
    return this._api.getByID(id)
  }

  getFilteredList(q: Query<DiagnosticQuery>): Observable<ItemList<Diagnostic>> {
    return this._api.getFilteredList(q).pipe()
  }

  add(e: DiagnosticDTO): Observable<Diagnostic | undefined> {
    return this._api.add(e)
  }

  delete(id: string | undefined): Observable<boolean> {
    return this._api.delete(id)
  }

  update(e: DiagnosticDTO): Observable<Diagnostic | undefined> {
    return this._api.update(e)
  }

  getByName(name: string | undefined): Observable<Diagnostic> {
    return this._api.getByName(name)
  }
}
