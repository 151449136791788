import { PatientFileDTO } from './PatientFileDTO'

export class PatientFile {
  private readonly _id: string

  private _fileID: string
  private _patientID: string

  constructor(p: PatientFileDTO) {
    this._id = p.id
    this._fileID = p.fileID
    this._patientID = p.patientID
  }

  get id(): string {
    return this._id
  }

  get fileID(): string {
    return this._fileID
  }

  get patientID(): string {
    return this._patientID
  }
}

export interface PatientFileQuery {
  id: string
  fileID: string
  patientID: string
}
