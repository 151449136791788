import { IInit, Container } from '../../../common/container/Container'
import { Observable, of } from 'rxjs'
import { Questionnaire } from '../models/Questionnaire'
import { IHTTPClient, HTTP_CLIENT_KEY } from '../../../common/api/HTTPClient'
import { IStatusService } from '../../../common/status/StatusService'
import { STATUS_SERVICE_KEY } from '../../../container/app'
import { QuestionnaireContainerConfig } from '../container'
import { ItemList, emptyList } from '../../../common/models/ItemList'
import { map, catchError } from 'rxjs/operators'
import { QuestionnaireDTO, toModel } from '../models/QuestionnaireDTO'
import { QuestionnairePatient, QuestionnairePatientQuery } from '../models/QuestionnairePatient'
import {
  QuestionnairePatientDTO,
  toModel as toModelPatient,
} from '../models/QuestionnairePatientDTO'
import { Query } from '../../../common/api/Query'
import { QuestionnaireRange } from '../models/QuestionnaireRange'
import { QuestionnaireRangeDTO, toModel as toModelRanges } from '../models/QuestionnaireRangeDTO'

export interface IQuestionnaireApi extends IInit {
  getList(): Observable<ItemList<Questionnaire>>
  add(q: string[], patientId: string): Observable<boolean>
  getPatientQuestionnaireList(
    q: Query<QuestionnairePatientQuery>,
    patientId: string
  ): Observable<ItemList<QuestionnairePatient>>
  getPatientQuestionnaires(
    q: Query<QuestionnairePatientQuery>
  ): Observable<ItemList<QuestionnairePatient>>
  getByID(id: string): Observable<Questionnaire | undefined>
  deletePatientQuestionnaire(id: string): Observable<boolean>
  getScoreAndResultQuestionnaire(
    patientQuestionnaireId: string
  ): Observable<QuestionnaireRange | undefined>

  getLastDocument(id: string): Observable<QuestionnairePatient | undefined>
}

export class QuestionnaireApi implements IQuestionnaireApi {
  private _container!: Container
  private _httpClient!: IHTTPClient
  private _url!: string
  private _statusService!: IStatusService

  init(c: Container) {
    this._container = c
    this._httpClient = this._container.get<IHTTPClient>(HTTP_CLIENT_KEY)
    this._statusService = this._container.get<IStatusService>(STATUS_SERVICE_KEY)
    this._url = (this._container.config as QuestionnaireContainerConfig).moduleFullUrl
  }

  getList(): Observable<ItemList<Questionnaire>> {
    return this._httpClient
      .get<ItemList<Questionnaire>>({ url: `${this._url}` })
      .pipe(
        map<ItemList<QuestionnaireDTO>, ItemList<Questionnaire>>((dto) => {
          const itemList = emptyList<Questionnaire>()
          itemList.count = dto.count
          itemList.items = dto.items.map((d) => toModel(d))
          return itemList
        }),
        catchError((err) => {
          this._statusService.sendStatus({ variant: 'error', error: err })
          return of(emptyList<Questionnaire>())
        })
      )
  }

  add(q: string[], patientId: string): Observable<boolean> {
    return this._httpClient
      .post<boolean>({ url: `${this._url}/patients/${patientId}`, body: q })
      .pipe(
        catchError((err) => {
          this._statusService.sendStatus({ variant: 'error', error: err })
          return of(false)
        })
      )
  }

  getPatientQuestionnaireList(
    q: Query<QuestionnairePatientQuery>,
    patientId: string
  ): Observable<ItemList<QuestionnairePatient>> {
    return this._httpClient
      .get<ItemList<QuestionnairePatient>>({ url: `${this._url}/patients/${patientId}` })
      .pipe(
        map<ItemList<QuestionnairePatientDTO>, ItemList<QuestionnairePatient>>((dto) => {
          const itemList = emptyList<QuestionnairePatient>()
          itemList.count = dto.count
          itemList.items = dto.items.map((d) => toModelPatient(d))
          return itemList
        }),
        catchError((err) => {
          this._statusService.sendStatus({ variant: 'error', error: err })
          return of(emptyList<QuestionnairePatient>())
        })
      )
  }

  getByID(id: string): Observable<Questionnaire | undefined> {
    return this._httpClient
      .get<Questionnaire>({ url: `${this._url}/${id}` })
      .pipe(
        map<QuestionnaireDTO, Questionnaire>((d) => toModel(d)),
        catchError((err) => {
          this._statusService.sendStatus({ variant: 'error', error: err })
          return of(undefined)
        })
      )
  }

  deletePatientQuestionnaire(id: string): Observable<boolean> {
    return this._httpClient.delete({ url: `${this._url}/patients/${id}` }).pipe(
      catchError((err) => {
        this._statusService.sendStatus({ variant: 'error', error: err })
        return of(false)
      })
    )
  }

  getScoreAndResultQuestionnaire(
    patientQuestionnaireId: string
  ): Observable<QuestionnaireRange | undefined> {
    return this._httpClient
      .get<QuestionnaireRange>({ url: `${this._url}/patients/scores/${patientQuestionnaireId}` })
      .pipe(
        map<QuestionnaireRangeDTO, QuestionnaireRange>((d) => toModelRanges(d)),
        catchError((err) => {
          this._statusService.sendStatus({ variant: 'error', error: err })
          return of(undefined)
        })
      )
  }

  getLastDocument(id: string): Observable<QuestionnairePatient | undefined> {
    return this._httpClient
      .get<QuestionnairePatient>({ url: `${this._url}/patients/getLastDocument/${id}` })
      .pipe(
        map<QuestionnairePatientDTO, QuestionnairePatient>((d) => toModelPatient(d)),
        catchError((err) => {
          //this._statusService.sendStatus({ variant: 'error', error: err })
          return of(undefined)
        })
      )
  }

  getPatientQuestionnaires(
    q: Query<QuestionnairePatientQuery>
  ): Observable<ItemList<QuestionnairePatient>> {
    return this._httpClient
      .get<ItemList<QuestionnairePatient>>({
        url: `${this._url}/questionnairePatients/questionnairePatients`,
      })
      .pipe(
        map<ItemList<QuestionnairePatientDTO>, ItemList<QuestionnairePatient>>((dto) => {
          const itemList = emptyList<QuestionnairePatient>()
          itemList.count = dto.count
          itemList.items = dto.items.map((d) => toModelPatient(d))
          return itemList
        }),
        catchError((err) => {
          this._statusService.sendStatus({ variant: 'error', error: err })
          return of(emptyList<QuestionnairePatient>())
        })
      )
  }
}
