import React from 'react'
import { RouteProps } from '../../routes/AppRouter'
import { UserEdit } from '../../features/users/UserEdit'
import { CurrentNavHeader } from '../../components/current-nav-header/CurrentNavHeader'

export function EditUser(props: RouteProps) {
  const title = props.title || ''

  return (
    <>
      <CurrentNavHeader title={title} />
      <UserEdit id={props.id} />
    </>
  )
}
