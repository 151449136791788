import generic from '../../../../assets/generic.module.css'
import { Box } from '@mui/material'
import React from 'react'

export const CVS113 = () => {
  return (
    <>
      <Box className={generic.pageContainer} style={{ backgroundColor: 'white' }}>
        <Box className={generic.pageContainer} display="flex">
          <h2>
            <b>1.1 Disfunción y trastornos del tracto urinario</b>
          </h2>
        </Box>
        <Box>
          <h1 style={{ color: 'dodgerblue' }}>
            <b>Infecciones del tracto urinario (ITU)</b>
          </h1>
        </Box>
        <Box className={generic.pageContainer}>
          <div>
            <br />
            <br />
            <b>Factores de riesgo</b>
            <br />
            <br />
            Las mujeres tienen más probabilidades de sufrir una ITU que los hombres, debido a la
            ubicación anatómica de su abertura uretral hacia el ano y a que tienen una uretra más
            corta. Aproximadamente la mitad de las mujeres pueden experimentar al menos una ITU
            durante su vida.
            <br />
            <br />
            Las mujeres embarazadas no tienen más probabilidades de desarrollar una ITU que otras
            mujeres, pero si se produce una, esmás probable que llegue a los riñones. Esto se debe a
            que los cambios hormonales en el cuerpo durante el embarazo afectan al tracto urinario.
            <br />
            <br />
            <br />
            <br />
            <b>Tratamiento</b>
            <br />
            <br />
            Si la ITU es sintomática y probablemente causada por bacterias, por lo general se trata
            con antibióticos.
            <br />
            <br />
            El tipo de medicación y la duración del tratamiento dependerán de los síntomas y el
            historial médico del individuo.
            <br />
            <br />
            Siempre se debe completar el tratamiento para las ITU, con el fin de asegurarse de que
            la infección se elimine por completo y de reducir el riesgo de resistencia a los
            antibióticos. Los síntomas de la ITU pueden remitir antes de que la infección haya
            desaparecido por completo.
            <br />
            <br />
            Siempre se recomienda beber muchos líquidos y orinar con frecuencia para las personas
            que sufren ITU, ya que esto ayuda a eliminar las bacterias.
            <br />
            <br />
          </div>
        </Box>
      </Box>
    </>
  )
}
