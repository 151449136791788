import { EducationalDTO } from './EducationalDTO'
import { Lesson } from './Lesson'

export class Educational {
  private readonly _id: string
  private _name: string
  private _lessons: Lesson[]
  private _gender: string

  constructor(p: EducationalDTO) {
    this._id = p.id
    this._name = p.name
    this._lessons = p.lessons
    this._gender = p.gender
  }

  get id(): string {
    return this._id
  }

  get name(): string {
    return this._name
  }

  set name(value: string) {
    this._name = value
  }

  get lessons(): Lesson[] {
    return this._lessons
  }

  get gender(): string {
    return this._gender
  }
}

export interface EducationalQuery {
  name: string
  id: string
}
