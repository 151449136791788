import React from 'react'
import { RouteProps } from '../../routes/AppRouter'
import { VesicalModule } from './VesicalModules'
import { IntestinalModule } from './IntestinalModules'
import style from '../../components/current-nav-header/CurrentNavHeader.module.css'
import { Box } from '@material-ui/core'
import educationalIcon from '../../assets/higea/icon-aprendizaje.svg'
import { useTranslation } from 'react-i18next'

export const EducationalProfessional = (props: RouteProps) => {
  const { t } = useTranslation()

  return (
    <>
      <div className={style.currentNavHeaderContainer}>
        <Box
          display="flex"
          className={style.titleContainer}
          flexDirection={'row'}
          style={{ marginTop: '10px' }}>
          <Box>
            <img src={educationalIcon} alt="reports" />
          </Box>
          <Box>
            <h1>{props.title}</h1>
          </Box>
        </Box>
      </div>
      <VesicalModule />
      <IntestinalModule />
      <Box
        style={{
          backgroundColor: 'white',
          height: '55px',
          marginTop: '1%',
          display: 'flex',
          justifyContent: 'center',
          fontSize: 16,
          alignContent: 'center',
          alignItems: 'center',
          textAlign: 'center',
          position: 'absolute',
          bottom: 0,
          right: 0,
          left: 0,
        }}>
        {t('learnMoreOn')}:
        <a
          style={{ color: 'blue', marginLeft: '0.4%' }}
          href={'https://www.wellspect.es/education/'}>
          https://www.wellspect.es/education/
        </a>
      </Box>
    </>
  )
}
