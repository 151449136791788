import { toModel as userToModel, User, UserDTO, UserQuery } from '../models/User'
import { Container, IInit } from '../../../common/container/Container'
import { UserContainerConfig } from '../container'
import { Observable, of } from 'rxjs'
import { Query } from '../../../common/api/Query'
import { HTTP_CLIENT_KEY, IHTTPClient } from '../../../common/api/HTTPClient'
import { IStatusService } from '../../../common/status/StatusService'
import { catchError, map } from 'rxjs/operators'
import { emptyList, ItemList } from '../../../common/models/ItemList'
import { AuthDTO } from '../models/AuthDTO'
import { LoggedUserDTO } from '../models/LoggedUserDTO'
import { prepareURL } from '../../../common/api/http-helpers'
import { RelatedUserDTO, toModel } from '../models/RelatedUserDTO'
import { RelatedUser } from '../models/RelatedUser'
import { FamiliarData, FamiliarDataQuery } from '../models/FamiliarData'
import { FamiliarDataDTO, toModel as dataToModel } from '../models/FamiliarDataDTO'
import { STATUS_SERVICE_KEY } from '../../../container/app'
import { Role } from '../models/Role'
import { RoleDTO, toModel as roleToModel } from '../models/RoleDTO'
import { Patient } from '../../patients/models/Patient'
import { PatientDTO, toModel as toModelPatient } from '../../patients/models/PatientDTO'

export interface IUserApi extends IInit {
  getByID(id: string): Observable<User | undefined>

  getFilteredItems(q: Query<UserQuery>): Observable<User[]>

  getFilteredList(q: Query<UserQuery>): Observable<ItemList<User>>

  add(e: UserDTO): Observable<User | undefined>

  update(e: UserDTO): Observable<User | undefined>

  updateByAdmin(e: UserDTO): Observable<User | undefined>

  login(a: AuthDTO): Observable<LoggedUserDTO | undefined>

  logout(): Observable<boolean>

  getRelated(id: string, q: Query<User>): Observable<RelatedUser | undefined>

  getByDoctorID(q: Query<UserQuery>): Observable<ItemList<User>>

  removeRelated(id: string, rID: string): Observable<boolean>

  getInformationByID(id: string): Observable<FamiliarData | undefined>

  getUserRolesByCircle(q: Query<User>): Observable<ItemList<User>>

  getInformationByUser(q: Query<FamiliarDataQuery>): Observable<ItemList<FamiliarData>>

  addInformation(e: FamiliarDataDTO): Observable<FamiliarData | undefined>

  updateInformation(e: FamiliarDataDTO): Observable<FamiliarData | undefined>

  deleteInformation(id: string): Observable<boolean>

  getPermissions(): Observable<ItemList<Role>>

  delete(id: string): Observable<boolean>

  addPatientUser(e: UserDTO): Observable<User | undefined>

  sendRecoverPassword(email: string): Observable<boolean>

  getUsersByDoctor(id: string): Observable<User[] | undefined>

  getPatientsByDoctor(id: string): Observable<Patient[] | undefined>

  validateUser(id: string): Observable<any | undefined>

  sendHelpEmail(desc: string, email: string): Observable<User | undefined>

  verifyToken(userID: string, token: string): Observable<any | undefined>

  isRegistered(email: string): Observable<boolean | undefined>
}

export class UserApi implements IUserApi {
  private _container!: Container
  private _httpClient!: IHTTPClient
  private _url!: string
  private _statusService!: IStatusService

  init(c: Container) {
    this._container = c
    this._httpClient = this._container.get<IHTTPClient>(HTTP_CLIENT_KEY)
    this._statusService = this._container.get<IStatusService>(STATUS_SERVICE_KEY)
    this._url = (this._container.config as UserContainerConfig).moduleFullUrl
  }

  add(e: UserDTO): Observable<User | undefined> {
    return this._httpClient.post<User>({ url: `${this._url}/users`, body: e }).pipe(
      catchError((err) => {
        this._statusService.sendStatus({ variant: 'error', error: err })
        return of(undefined)
      })
    )
  }

  getByID(id: string): Observable<User | undefined> {
    return this._httpClient.get<User>({ url: `${this._url}/users/${id}` }).pipe(
      map<UserDTO, User>((dto) => userToModel(dto)),
      catchError((err) => {
        this._statusService.sendStatus({ variant: 'error', error: err })
        return of(undefined)
      })
    )
  }

  isRegistered(email: string): Observable<boolean | undefined> {
    return this._httpClient
      .post<boolean>({
        url: `${this._url}/registered`,
        body: {
          email: email,
        },
      })
      .pipe()
  }

  getFilteredItems(q: Query<UserQuery>): Observable<User[]> {
    return this._httpClient.get<User[]>({ url: prepareURL(`${this._url}/users`, q) }).pipe(
      map<UserDTO[], User[]>((dto) => {
        return dto.map((d) => userToModel(d))
      }),
      catchError((err) => {
        this._statusService.sendStatus({ variant: 'error', error: err })
        return of([])
      })
    )
  }

  getFilteredList(q: Query<UserQuery>): Observable<ItemList<User>> {
    return this._httpClient.get<ItemList<User>>({ url: prepareURL(`${this._url}/users`, q) }).pipe(
      map<ItemList<UserDTO>, ItemList<User>>((dto) => {
        const itemList = emptyList<User>()
        itemList.count = dto.count
        itemList.items = dto.items.map((d) => userToModel(d))
        return itemList
      }),
      catchError((err) => {
        this._statusService.sendStatus({ variant: 'error', error: err })
        return of(emptyList<User>())
      })
    )
  }

  getByDoctorID(q: Query<UserQuery>): Observable<ItemList<User>> {
    return this._httpClient
      .get<ItemList<User>>({ url: prepareURL(`${this._url}/users-doctor/${q.getParam('id')}`, q) })
      .pipe(
        map<ItemList<UserDTO>, ItemList<User>>((dto) => {
          const itemList = emptyList<User>()
          itemList.count = dto.count
          itemList.items = dto.items.map((d) => userToModel(d))
          return itemList
        }),
        catchError((err) => {
          this._statusService.sendStatus({ variant: 'error', error: err })
          return of(emptyList<User>())
        })
      )
  }

  updateByAdmin(e: UserDTO): Observable<User | undefined> {
    return this._httpClient.put<User>({ url: this._url + '/users', body: e }).pipe(
      catchError((err) => {
        this._statusService.sendStatus({ variant: 'error', error: err })
        return of(undefined)
      })
    )
  }

  update(e: UserDTO): Observable<User | undefined> {
    return this._httpClient.put<User>({ url: `${this._url}/users/${e.id}/settings`, body: e }).pipe(
      catchError((err) => {
        this._statusService.sendStatus({ variant: 'error', error: err })
        return of(undefined)
      })
    )
  }

  getRelated(id: string, q: Query<User>): Observable<RelatedUser | undefined> {
    return this._httpClient
      .get<RelatedUserDTO>({ url: prepareURL(`${this._url}/related/${id}`, q) })
      .pipe(
        map<RelatedUserDTO, RelatedUser>((d) => toModel(d)),
        catchError((err) => {
          this._statusService.sendStatus({ variant: 'error', error: err })
          return of(undefined)
        })
      )
  }

  removeRelated(id: string, rID: string): Observable<boolean> {
    return this._httpClient.delete({ url: `${this._url}/related/${id}/${rID}` }).pipe(
      catchError((err) => {
        this._statusService.sendStatus({ variant: 'error', error: err })
        return of(false)
      })
    )
  }

  getUserRolesByCircle(q: Query<User>): Observable<ItemList<User>> {
    return this._httpClient
      .get<ItemList<User>>({ url: prepareURL(`${this._url}/user-roles`, q) })
      .pipe(
        map<ItemList<UserDTO>, ItemList<User>>((dto) => {
          const itemList = emptyList<User>()
          itemList.items = dto.items.map((d) => userToModel(d))
          itemList.count = dto.count
          return itemList
        }),
        catchError((err) => {
          this._statusService.sendStatus({ variant: 'error', error: err })
          return of(emptyList<User>())
        })
      )
  }

  getInformationByID(id: string): Observable<FamiliarData | undefined> {
    return this._httpClient.get<FamiliarData>({ url: `${this._url}/familiar-data/${id}` }).pipe(
      map<FamiliarDataDTO, FamiliarData>((dto) => dataToModel(dto)),
      catchError((err) => {
        this._statusService.sendStatus({ variant: 'error', error: err })
        return of(undefined)
      })
    )
  }

  getInformationByUser(q: Query<FamiliarDataQuery>): Observable<ItemList<FamiliarData>> {
    return this._httpClient
      .get<ItemList<FamiliarData>>({ url: prepareURL(`${this._url}/familiar-data`, q) })
      .pipe(
        map<ItemList<FamiliarDataDTO>, ItemList<FamiliarData>>((dto) => {
          const itemList = emptyList<FamiliarData>()
          itemList.items = dto.items.map((d) => dataToModel(d))
          itemList.count = dto.count
          return itemList
        }),
        catchError((err) => {
          this._statusService.sendStatus({ variant: 'error', error: err })
          return of(emptyList<FamiliarData>())
        })
      )
  }

  addInformation(e: FamiliarDataDTO): Observable<FamiliarData | undefined> {
    return this._httpClient.post<FamiliarData>({ url: `${this._url}/familiar-data`, body: e }).pipe(
      map<FamiliarDataDTO, FamiliarData>((dto) => {
        this._statusService.sendStatus({ variant: 'success' })
        return dataToModel(dto)
      }),
      catchError((err) => {
        this._statusService.sendStatus({ variant: 'error', error: err })
        return of(undefined)
      })
    )
  }

  updateInformation(e: FamiliarDataDTO): Observable<FamiliarData | undefined> {
    return this._httpClient.put<FamiliarData>({ url: `${this._url}/familiar-data`, body: e }).pipe(
      map<FamiliarDataDTO, FamiliarData>((dto) => {
        this._statusService.sendStatus({ variant: 'success' })
        return dataToModel(dto)
      }),
      catchError((err) => {
        this._statusService.sendStatus({ variant: 'error', error: err })
        return of(undefined)
      })
    )
  }

  deleteInformation(id: string): Observable<boolean> {
    return this._httpClient.delete({ url: `${this._url}/familiar-data/${id}` }).pipe(
      catchError((err) => {
        this._statusService.sendStatus({ variant: 'error', error: err })
        return of(false)
      })
    )
  }

  login(a: AuthDTO): Observable<LoggedUserDTO | undefined> {
    return this._httpClient.post<LoggedUserDTO>({ url: this._url + '/loginWeb', body: a }).pipe(
      catchError((err) => {
        this._statusService.sendStatus({ variant: 'error', error: err })
        return of(undefined)
      })
    )
  }

  logout(): Observable<boolean> {
    return this._httpClient.post<boolean>({ url: this._url + '/logout' }).pipe(
      catchError((err) => {
        this._statusService.sendStatus({ variant: 'error', error: err })
        return of(false)
      })
    )
  }

  getPermissions(): Observable<ItemList<Role>> {
    return this._httpClient.get<ItemList<Role>>({ url: `${this._url}/permissions` }).pipe(
      map<ItemList<RoleDTO>, ItemList<Role>>((dto) => {
        const itemList = emptyList<Role>()
        itemList.items = dto.items.map((d) => roleToModel(d))
        itemList.count = dto.count
        return itemList
      }),
      catchError((err) => {
        this._statusService.sendStatus({ variant: 'error', error: err })
        return of(emptyList<Role>())
      })
    )
  }

  delete(id: string): Observable<boolean> {
    return this._httpClient.delete({ url: `${this._url}/users/${id}` }).pipe(
      catchError((err) => {
        this._statusService.sendStatus({ variant: 'error', error: err })
        return of(false)
      })
    )
  }

  addPatientUser(e: UserDTO): Observable<User | undefined> {
    return this._httpClient
      .post<User>({ url: `${this._url}/users/createUserPatient`, body: e })
      .pipe(
        catchError((err) => {
          this._statusService.sendStatus({ variant: 'error', error: err })
          return of(undefined)
        })
      )
  }

  sendRecoverPassword(email: string): Observable<boolean> {
    return this._httpClient
      .post<boolean>({ url: this._url + '/recoverPassword', body: { email: email } })
      .pipe(
        catchError((err) => {
          this._statusService.sendStatus({ variant: 'error', error: err })
          return of(false)
        })
      )
  }

  getUsersByDoctor(id: string): Observable<User[] | undefined> {
    return this._httpClient.get<User[]>({ url: `${this._url}/users/getUsersByDoctor/${id}` }).pipe(
      map<UserDTO[], User[]>((dto) => dto.map((d) => userToModel(d))),
      catchError((err) => {
        this._statusService.sendStatus({ variant: 'error', error: err })
        return of([])
      })
    )
  }

  getPatientsByDoctor(id: string): Observable<Patient[] | undefined> {
    return this._httpClient
      .get<Patient[]>({ url: `${this._url}/users/getPatientsByDoctor/${id}` })
      .pipe(
        map<PatientDTO[], Patient[]>((dto) => dto.map((d) => toModelPatient(d))),
        catchError((err) => {
          this._statusService.sendStatus({ variant: 'error', error: err })
          return of([])
        })
      )
  }

  validateUser(id: string): Observable<any | undefined> {
    return this._httpClient.get<User>({ url: `${this._url}/validate/${id}` }).pipe(
      map<any, any>((dto) => {
        let response = new Buffer(dto, 'base64').toString('ascii')
        if (response) {
          return response
        } else {
          return userToModel(dto)
        }
      }),
      catchError((err) => {
        this._statusService.sendStatus({ variant: 'error', error: err })
        return of(undefined)
      })
    )
  }

  sendHelpEmail(description: string, email: string): Observable<User | undefined> {
    return this._httpClient
      .post<User>({
        url: this._url + '/sendHelpEmail',
        body: { description: description, email: email },
      })
      .pipe(
        map<UserDTO, User>((dto) => userToModel(dto)),
        catchError((err) => {
          this._statusService.sendStatus({ variant: 'error', error: err })
          return of(undefined)
        })
      )
  }

  verifyToken(userID: string, token: string): Observable<any | undefined> {
    return this._httpClient
      .post<any>({
        url: this._url + '/users/verifyToken',
        body: { userID: userID, token: token },
      })
      .pipe(
        map<any, any>((dto) => dto),
        catchError((err) => {
          this._statusService.sendStatus({ variant: 'error', error: err })
          return of(undefined)
        })
      )
  }
}
