import { LangType } from '../../content/enums/LangType'
import { v4 as uuidv4 } from 'uuid'
import { Goal } from './Goal'

export interface GoalDTO {
  id: string
  parentTitle: string
  title: string
  createdAt: Date
  creatorID: string
}

export function emptyGoalDTO(): GoalDTO {
  return {
    id: uuidv4(),
    parentTitle: '',
    title: '',
    createdAt: new Date(),
    creatorID: '',
  }
}

export function fromModel(g: Goal): GoalDTO {
  return {
    id: g.id,
    parentTitle: g.parentTitle,
    title: g.title,
    createdAt: g.createdAt,
    creatorID: g.creatorID,
  }
}

export function toModel(d: GoalDTO): Goal {
  return new Goal(d)
}
