import TextField from '@mui/material/TextField'
import { ChangeEvent } from 'react'
import { useTranslation } from 'react-i18next'

export type TextFieldItemProps = {
  field: string
  value: string
  label: string
  handleChange: (event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => void
  rows?: number
  required: boolean
  type?: string
  disabled?: boolean
}

export function TextFieldItem(props: TextFieldItemProps) {
  const { t } = useTranslation()

  return (
    <TextField
      id={props.field}
      key={props.field}
      label={t(props.label)}
      name={props.field}
      value={props.value}
      onChange={props.handleChange}
      type={props.type}
      multiline={(props.rows && props.rows > 0) || false}
      rows={props.rows}
      required={props.required}
      disabled={props.disabled}
      fullWidth
      variant="outlined"
      style={{ color: '#000', fontFamily: 'Open-sans, sans-serif' }}
      sx={{
        /*'.MuiOutlinedInput-notchedOutline': {
          borderColor: '#000',
        },*/
        '.css-1sumxir-MuiFormLabel-root-MuiInputLabel-root': {
          color: '#000',
          fontFamily: 'Open-sans, sans-serif',
        },
        '.css-1t8l2tu-MuiInputBase-input-MuiOutlinedInput-input': {
          fontFamily: 'Open-sans, sans-serif',
        },
      }}
    />
  )
}
