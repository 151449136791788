import { IStatusService } from 'common/status/StatusService'
import { STATUS_SERVICE_KEY } from 'container/app'
import { IInit, Container } from '../../../common/container/Container'
import { IPatientGoalApi } from '../api/PatientGoalApi'
import { PatientGoal, PatientGoalQuery } from '../models/PatientGoal'
import { PatientGoalDTO } from '../models/PatientGoalDTO'
import { Observable } from 'rxjs'
import { Query } from '../../../common/api/Query'
import { ItemList } from '../../../common/models/ItemList'

type Props = {
  apiKey: symbol
}

export interface IPatientGoalService extends IInit {
  add(p: PatientGoalDTO): Observable<PatientGoal | undefined>
  getListByID(id: string): Observable<ItemList<PatientGoal>>
  delete(id: string): Observable<boolean>
  update(e: PatientGoalDTO): Observable<PatientGoal | undefined>
  getFilteredList(q: Query<PatientGoalQuery>): Observable<ItemList<PatientGoal>>
}

export class PatientGoalService implements IPatientGoalService {
  private readonly _apiKey: symbol
  private _container!: Container
  private _api!: IPatientGoalApi
  private _statusService!: IStatusService

  constructor(p: Props) {
    this._apiKey = p.apiKey
  }

  init(c: Container) {
    this._container = c
    this._api = this._container.get<IPatientGoalApi>(this._apiKey)
    this._statusService = this._container.get<IStatusService>(STATUS_SERVICE_KEY)
  }

  add(p: PatientGoalDTO): Observable<PatientGoal | undefined> {
    return this._api.add(p)
  }

  getListByID(id: string): Observable<ItemList<PatientGoal>> {
    return this._api.getListByID(id)
  }

  delete(id: string): Observable<boolean> {
    return this._api.delete(id)
  }

  update(e: PatientGoalDTO): Observable<PatientGoal | undefined> {
    return this._api.update(e)
  }

  getFilteredList(q: Query<PatientGoalQuery>): Observable<ItemList<PatientGoal>> {
    return this._api.getFilteredList(q).pipe()
  }
}
