import generic from '../../../../assets/generic.module.css'
import { Box } from '@mui/material'
import image from './intestino.svg'
import React from 'react'

export const CIS212 = () => {
  return (
    <>
      <Box className={generic.pageContainer} style={{ backgroundColor: 'white', display: 'flex' }}>
        <Box style={{ marginRight: '5%', marginLeft: '1%' }}>
          <Box className={generic.pageContainer} display="flex">
            <h2>
              <b>2.1 Descripción general de la incontinencia fecal (IF)</b>
            </h2>
          </Box>
          <Box className={generic.pageContainer} display="flex">
            <h4 style={{ color: 'dodgerblue' }}>
              <b>Estimulación del nervio sacro y tratamiento quirúrgico</b>
            </h4>
          </Box>

          <Box className={generic.pageContainer} display="flex">
            <div>
              Se ha reportado en estudios clínicos que la neuromodulación, como la estimulación del
              nervio sacro, reduce los síntomas en un 50 % en la IF en entre el 67 y el 100 % de los
              pacientes. Es posible que este tratamiento no se ofrezca en todas las áreas
              geográficas. Si este tratamiento no se ofrece o bien no es idóneo, los cirujanos
              especialistas pueden evaluar a los pacientes y plantear un estoma para manejar los
              síntomas.
              <br />
              <br />
              <br />
            </div>
          </Box>

          <Box className={generic.pageContainer} display="flex">
            <h4 style={{ color: 'dodgerblue' }}>
              <b>Otras estrategias de ayuda</b>
            </h4>
          </Box>
          <Box className={generic.pageContainer} display="flex">
            <div>
              El objetivo del manejo intestinal es reducir el número de episodios de IF y mejorar la
              calidad de vida. A pesar de la variedad de opciones de tratamiento, muchas personas no
              logran una mejora significativa y a largo plazo en la reducción de los episodios. Esto
              significa que es posible que muchos pacientes sigan necesitando productos de
              contención, obturadores fecales y cremas de barrera para hacer frente a sus síntomas.
              Las compresas/los pañales para incontinencia solo sirven para evitar que las heces se
              derramen sobre la prendas o la ropa de cama/los equipos, etc.
              <br />
              <br />
              No existe ningún dispositivo disponible que pueda evitar que las heces dañen la piel
              externa tras una fuga, por lo que, si una persona pierde el control intestinal, debe
              cambiarse de inmediato la compresa de contención y limpiarse la piel.
              <br />
              <br />
              Un obturador para incontinencia fecal es una barrera pasiva para ayudar a evitar
              episodios de IF, que proporciona resultados inmediatos y podría ser una terapia
              independiente o usarse en combinación con otros tratamientos, como biofeedback,
              modificaciones dietéticas y/o el uso de medicamentos. La diferencia entre los
              obturadores y las compresas para incontinencia es que los obturadores son más
              invasivos, pero presentan las ventajas de ofrecer un mejor control de los olores y una
              reducción de los posibles problemas cutáneos. Es posible que también se necesite una
              crema de barrera para evitar más daños en la delicada piel.
              <br />
              <br />
              <br />
            </div>
          </Box>
        </Box>
      </Box>
    </>
  )
}
