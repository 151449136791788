import { Box, MenuItem, Select, SelectChangeEvent, TextField } from '@mui/material'
import style from './Patients.module.css'
import styles from '../user-profile/Editor.module.css'
import { useTranslation } from 'react-i18next'
import { ChangeEvent } from 'react'
import { PatientDTO } from '../../modules/patients/models/PatientDTO'
import { KeyboardDatePicker } from '@material-ui/pickers'
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date'
import { UserGender } from '../../modules/users/enums/UserGender'
import { UserDTO } from '../../modules/users/models/User'
import { useState, useEffect } from 'react'
import Autocomplete from '@mui/material/Autocomplete'
import { communities, provinces } from '../professionals/communities'

interface GeneralViewProps {
  patient: PatientDTO
  user: UserDTO
  handlePatientInput: (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void
  handleUserInput: (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void
  handleSelect: (e: SelectChangeEvent) => void
  handleDate: (e: MaterialUiPickersDate, name: string) => void
  handleCancel: () => void
  handleSave: () => void
  handleChangeCommunity: (c: string) => void
  handleChangeProvince: (c: string) => void
  handleChangeProvinceCommunity: (prov: string, com: string) => void
}

export const GeneralView = (p: GeneralViewProps) => {
  const { t } = useTranslation()
  const [community, setCommunity] = useState<string>('')
  const [gender, setGender] = useState<string>('')
  const { innerWidth } = window

  useEffect(() => {
    setGender(p.patient.gender == UserGender.Female ? 'female' : 'male')
  }, [])

  const handleSelect = (e: SelectChangeEvent) => {
    setGender(e.target.value)
    p.handleSelect(e)
  }

  return (
    <>
      <Box className={style.generalContainer}>
        <Box display="flex" flexDirection="row">
          <Box>
            <h4>{t('internalID')}</h4>
            <TextField
              id={'internalID'}
              key={'internalID'}
              name="internalID"
              className={styles.textField}
              value={p.patient.internalID}
              type="name"
              variant={'outlined'}
              size={'small'}
              onChange={p.handlePatientInput}
            />
          </Box>
          <Box>
            <h4>{t('registerDate')}</h4>
            <KeyboardDatePicker
              style={{ width: '200px', marginBottom: '40px', fontFamily: 'Open-sans, sans-serif' }}
              InputProps={{ style: { fontFamily: 'Open-sans, sans-serif' } }}
              key={'registerDate'}
              id={'registerDate'}
              autoOk
              variant="inline"
              inputVariant={'outlined'}
              label={''}
              format="DD/MM/YYYY"
              value={p.patient.createdAt}
              onChange={(e) => p.handleDate(e, 'registerDate')}
              size={'small'}
            />
          </Box>
        </Box>
        <Box display="flex" flexDirection="row">
          <Box className={styles.textFieldBox}>
            <h4>{t('name') + ' *'}</h4>
            <TextField
              id={'firstName'}
              key={'firstName'}
              name="firstName"
              className={styles.textField}
              value={p.patient.firstName}
              type="name"
              required={true}
              variant={'outlined'}
              size={'small'}
              onChange={p.handlePatientInput}
            />
          </Box>
          <Box>
            <h4>{t('lastName') + ' *'}</h4>
            <TextField
              id={'lastName'}
              key={'lastName'}
              name="lastName"
              className={styles.textField}
              value={p.patient.lastName}
              type="name"
              required={true}
              variant={'outlined'}
              size={'small'}
              onChange={p.handlePatientInput}
            />
          </Box>
          <Box>
            <h4>{t('gender') + ' *'}</h4>
            <Select
              value={gender}
              onChange={handleSelect}
              name="gender"
              inputProps={{ 'aria-label': 'Gender' }}
              style={{
                width: innerWidth < 900 ? '105px' : '233px',
                marginRight: '20px',
                minHeight: '40px',
                height: '40px',
                fontFamily: 'Open-sans, sans-serif',
              }}
            >
              <MenuItem value="female">{t('female')}</MenuItem>
              <MenuItem value="male">{t('male')}</MenuItem>
              <MenuItem value="noneOfTheAbove">{t('noneOfTheAbove')}</MenuItem>
            </Select>
          </Box>
          <Box>
            <h4>{t('birthDate')}</h4>
            <KeyboardDatePicker
              style={{
                width: innerWidth < 900 ? '170px' : '200px',
                fontFamily: 'Open-sans, sans-serif',
              }}
              InputProps={{ style: { fontFamily: 'Open-sans, sans-serif' } }}
              key={'birthDate'}
              id={'birthDate'}
              autoOk
              variant="inline"
              inputVariant={'outlined'}
              label={''}
              format="DD/MM/YYYY"
              value={p.patient.dob}
              onChange={(e) => p.handleDate(e, 'birthDate')}
              size={'small'}
            />
          </Box>
        </Box>
        <Box display="flex" flexDirection="row">
          <Box>
            <h4>{t('contactPhone')}</h4>
            <TextField
              id={'contactPhone'}
              key={'contactPhone'}
              name="contactPhone"
              className={styles.textField}
              value={p.patient.contactPhone}
              type="name"
              variant={'outlined'}
              size={'small'}
              onChange={p.handlePatientInput}
            />
          </Box>
          <Box>
            <h4>{t('mobilePhone')}</h4>
            <TextField
              id={'mobilePhone'}
              key={'mobilePhone'}
              name="mobilePhone"
              className={styles.textField}
              value={p.patient.mobilePhone}
              type="name"
              variant={'outlined'}
              size={'small'}
              onChange={p.handlePatientInput}
            />
          </Box>
        </Box>
        <Box display="flex" flexDirection="row">
          <Box>
            <h4>{t('city')}</h4>
            <TextField
              id={'city'}
              key={'city'}
              name="city"
              className={styles.textField}
              value={p.patient.city}
              type="name"
              variant={'outlined'}
              size={'small'}
              onChange={p.handlePatientInput}
            />
          </Box>
          <Box className={styles.textFieldBox}>
            <h4>{t('autonomousCommunity')}</h4>
            <Autocomplete
              disablePortal
              id="combo-box-demo"
              options={communities}
              sx={{
                width: 230,
                '& .MuiAutocomplete-input, & .MuiInputLabel-root': {
                  fontFamily: `Open-sans, sans-serif !important`,
                },
              }}
              value={{ label: community }}
              style={{ width: innerWidth < 900 ? '170px' : '233px' }}
              onChange={(_, b) => {
                setCommunity(b?.label || '')
                p.handleChangeCommunity(b?.label || '')
                p.handleChangeProvince('')
              }}
              renderInput={(params) => <TextField {...params} size="small" />}
            />
          </Box>
          <Box className={styles.textFieldBox} style={{ marginLeft: 20 }}>
            <h4>{t('province')}</h4>
            <Autocomplete
              disablePortal
              id="combo-box-demo"
              value={{ label: p.patient.province, value: p.patient.province }}
              options={community == '' ? provinces : provinces.filter((p) => p.value == community)}
              sx={{
                width: 230,
                '& .MuiAutocomplete-input, & .MuiInputLabel-root': {
                  fontFamily: `Open-sans, sans-serif !important`,
                },
              }}
              style={{ width: innerWidth < 900 ? '170px' : '233px' }}
              onChange={(_, b) => {
                setCommunity(b?.value || '')
                p.handleChangeProvince(b?.label || '')
                p.handleChangeProvinceCommunity(b?.label || '', b?.value || '')
              }}
              renderInput={(params) => <TextField {...params} size="small" />}
            />
          </Box>
        </Box>
        <Box display="flex" flexDirection="row">
          <Box>
            <h4>{t('postalCode')}</h4>
            <TextField
              id={'zip'}
              key={'zip'}
              name="zip"
              className={styles.textField}
              value={p.patient.zip}
              type="name"
              variant={'outlined'}
              size={'small'}
              onChange={p.handlePatientInput}
            />
          </Box>
          <Box>
            <h4>{t('country')}</h4>
            <TextField
              id={'country'}
              key={'country'}
              name="country"
              className={styles.textField}
              value={p.patient.country}
              type="name"
              variant={'outlined'}
              size={'small'}
              onChange={p.handlePatientInput}
            />
          </Box>
        </Box>
        <Box className={style.separator}>
          <h3>{t('userAccess')}</h3>
        </Box>
        <Box>
          <h4>{t('user') + ' (email)' + ' *'}</h4>
          <TextField
            id={'email'}
            key={'email'}
            name="email"
            className={styles.textField}
            value={p.user.email}
            type="name"
            required={true}
            variant={'outlined'}
            size={'small'}
            onChange={(e) => {
              p.handleUserInput(e)
              p.handlePatientInput(e)
            }}
          />
        </Box>
      </Box>
    </>
  )
}
