import generic from '../../../../assets/generic.module.css'
import { Box } from '@mui/material'
import image from './intestino.svg'
import React from 'react'

export const CIS230 = () => {
  return (
    <>
      <Box className={generic.pageContainer} style={{ backgroundColor: 'white', display: 'flex' }}>
        <Box style={{ marginRight: '5%', marginLeft: '1%' }}>
          <Box className={generic.pageContainer} display="flex">
            <h2>
              <b>2.3 Estreñimiento</b>
            </h2>
          </Box>
          <Box className={generic.pageContainer} display="flex">
            <div>
              El estreñimiento es uno de los síntomas más habituales y se puede dividir según la
              causa de los síntomas en estreñimiento primario y secundario.
              <br />
              <br />
              &nbsp;&nbsp;&nbsp;&nbsp;•&nbsp;&nbsp;&nbsp;&nbsp; El estreñimiento primario es, por
              ejemplo, el estreñimiento funcional o idiopático, el estreñimiento de tránsito lento
              (ETL).
              <br />
              <br />
              &nbsp;&nbsp;&nbsp;&nbsp;•&nbsp;&nbsp;&nbsp;&nbsp; El estreñimiento secundario tiene
              una causa subyacente, como una variedad de medicamentos, una afección médica (por
              ejemplo, Hirschsprung, EM, Parkinson) y/u obstrucción mecánica, como estenosis rectal,
              rectocele u obstrucción intestinal.
              <br />
              <br />
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;El estreñimiento primario o funcional es algo habitual y
              se calcula que la prevalencia se encuentra entre el 2 y el 28 % de la población.
              Afecta en especial a los ancianos y a las personas de nivel socioeconómico más
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;bajo. El estreñimiento es 10 veces más frecuente en
              mujeres que en hombres.
              <br />
              <br />
              <br />
            </div>
          </Box>
          <Box className={generic.pageContainer} display="flex">
            <div>
              El <b>estreñimiento funcional</b>por lo general se considera inofensivo en el sentido
              médico, es decir, que no causa ningún daño orgánico ni acorta la esperanza de vida.
              Sin embargo, es habitual que la calidad de vida se reduzca debido a la carga de los
              síntomas.
              <br />
              <br />
              El <b>estreñimiento refractario</b>se describe cuando no se logra aliviar los síntomas
              después de un periodo de tratamiento determinado.
              <br />
              <br />
              El <b>estreñimiento de tránsito</b>lento (ETL) o la inercia colónica se define como
              estreñimiento funcional grave sin ninguna disfunción del suelo pélvico. El ETL se
              diagnostica junto con pruebas radiográficas de tránsito retardado con ausencia
              fisiológica de actividad motora colónica y sin respuesta a la estimulación
              farmacológica, durante el registro de la motilidad del colon. Entre el 15 y el 30 % de
              los pacientes con estreñimiento tienen estreñimiento de tránsito lento. Se produce
              esencialmente porque la peristalsis dentro del colon se reduce en gran medida y se
              ralentiza la capacidad de las heces para moverse a lo largo del intestino. El motivo
              de la reducción del movimiento no se conoce con certeza. Algunas teorías indican una
              falta de fibra en la dieta, neuropatía autónoma y trastornos tanto del sistema
              nervioso entérico como del sistema endocrino.
              <br />
              <br />
            </div>
          </Box>
        </Box>
      </Box>
    </>
  )
}
