import { Container } from '../../common/container/Container'

export type ContentContainerConfig = {
  moduleFullUrl: string
}

export type ContentProps = {
  parentContainer: Container
  config: ContentContainerConfig
}

export const FECAL_MODULE = Symbol('FECAL_MODULE')

export const FECAL_API_KEY = Symbol('FECAL_API_KEY')

export const FECAL_SERVICE_KEY = Symbol('FECAL_SERVICE_KEY')
