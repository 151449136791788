import { emptyList, ItemList } from '../../../common/models/ItemList'
import { Observable, of } from 'rxjs'
import { Container, IInit } from '../../../common/container/Container'
import { HTTP_CLIENT_KEY, IHTTPClient } from '../../../common/api/HTTPClient'
import { IStatusService } from '../../../common/status/StatusService'
import { STATUS_SERVICE_KEY } from '../../../container/app'
import { Query } from '../../../common/api/Query'
import { Patient, PatientQuery } from '../models/Patient'
import { PatientContainerConfig } from '../container'
import { prepareURL } from '../../../common/api/http-helpers'
import { catchError, map } from 'rxjs/operators'
import { Symptom, SymptomQuery } from '../models/Symptom'
import { SymptomDTO, toModel } from '../models/SymptomDTO'

export interface ISymptomApi extends IInit {
  getByID(id: string | undefined): Observable<Symptom>
  getFilteredList(q: Query<SymptomQuery>): Observable<ItemList<Symptom>>
  add(e: SymptomDTO): Observable<Symptom | undefined>
  update(e: SymptomDTO): Observable<Symptom | undefined>
  delete(id: string | undefined): Observable<boolean>
}

export class SymptomApi implements ISymptomApi {
  private _container!: Container
  private _httpClient!: IHTTPClient
  private _url!: string
  private _statusService!: IStatusService

  init(c: Container) {
    this._container = c
    this._httpClient = this._container.get<IHTTPClient>(HTTP_CLIENT_KEY)
    this._statusService = this._container.get<IStatusService>(STATUS_SERVICE_KEY)
    this._url = (this._container.config as PatientContainerConfig).moduleFullUrl
  }

  add(e: SymptomDTO): Observable<Symptom | undefined> {
    return this._httpClient
      .post<Symptom>({ url: this._url, body: e })
      .pipe(
        map<SymptomDTO, Symptom>((d) => toModel(d)),
        catchError((err) => {
          this._statusService.sendStatus({ variant: 'error', error: err })
          return of(undefined)
        })
      )
  }

  delete(id: string | undefined): Observable<boolean> {
    return this._httpClient.delete({ url: this._url + '/' + id }).pipe(
      catchError((err) => {
        this._statusService.sendStatus({ variant: 'error', error: err })
        return of(false)
      })
    )
  }

  getByID(id: string | undefined): Observable<Symptom> {
    return this._httpClient
      .get<Symptom>({ url: `${this._url}/symptoms/${id}` })
      .pipe(
        map<SymptomDTO, Symptom>((d) => toModel(d))
      )
  }

  getFilteredList(q: Query<SymptomQuery>): Observable<ItemList<Symptom>> {
    return this._httpClient
      .post<ItemList<Symptom>>({ url: this._url + '/symptomsList', body: q })
      .pipe(
        map<ItemList<SymptomDTO>, ItemList<Symptom>>((dto) => {
          const itemList = emptyList<Symptom>()
          itemList.count = dto.count
          itemList.items = dto.items.map((d) => toModel(d))
          return itemList
        }),
        catchError((err) => {
          this._statusService.sendStatus({ variant: 'error', error: err })
          return of(emptyList<Symptom>())
        })
      )
  }

  update(e: SymptomDTO): Observable<Symptom | undefined> {
    return this._httpClient
      .put<Symptom>({ url: this._url, body: e })
      .pipe(
        map<SymptomDTO, Symptom>((d) => toModel(d)),
        catchError((err) => {
          //this._statusService.sendStatus({ variant: 'error', error: err })
          return of(undefined)
        })
      )
  }
}
