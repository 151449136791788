import { PatientFile } from './PatientFile'

export interface PatientFileDTO {
  id: string
  fileID: string
  patientID: string
}

export function emptyPatientFileDTO(): PatientFileDTO {
  return {
    id: '',
    fileID: '',
    patientID: '',
  }
}

export function toModel(p: PatientFileDTO): PatientFile {
  return new PatientFile(p)
}

export function fromModel(p: PatientFile): PatientFileDTO {
  return {
    id: p.id,
    fileID: p.fileID,
    patientID: p.patientID,
  }
}
