import { Role } from './Role'

export interface RoleDTO {
  id: number
  role: string
  admin: boolean
  professional: boolean
  patient: boolean
}

export function fromModel(m: Role): RoleDTO {
  return {
    id: m.id,
    role: m.role,
    admin: m.admin,
    professional: m.professional,
    patient: m.patient,
  }
}

export function toModel(d: RoleDTO): Role {
  return new Role(d)
}
