import { FormCard } from '../../components/form-card/FormCard'
import React, { ChangeEvent, FormEvent, useEffect, useState } from 'react'
import { emptyFileDTO, FileDTO, fromModel } from '../../modules/files/models/FileDTO'
import { TextFieldItem } from '../../components/form-card/TextFieldItem'
import { Box, TextField } from '@material-ui/core'
import { AppButton, ButtonTheme } from '../../components/app-button/AppButton'
import { ROUTE_RESOURCES } from '../../routes/routes-constants'
import { navigate } from '@reach/router'
import { useTranslation } from 'react-i18next'
import { Alert } from '@material-ui/lab'
import { getUserContainer } from '../../container/user-module'
import { LOGGED_USER_SERVICE_KEY } from '../../modules/users'
import { LoggedUserService } from '../../modules/users/services/LoggedUserService'
import { getFileContainer } from '../../container/file-module'
import { FileService } from '../../modules/files/services/FileService'
import { FILE_SERVICE_KEY } from '../../modules/files'
import genericStyle from '../../common/utils/generic.module.css'
import { dataToBase64 } from '../../common/utils/file'

type EditorProps = {
  id?: string
}

enum ResourceErrors {
  NO_FILE = 'noFile',
}

const loggedUserContainer = getUserContainer()
const loggedUserService = loggedUserContainer.get<LoggedUserService>(LOGGED_USER_SERVICE_KEY)

const fileContainer = getFileContainer()
const fileService = fileContainer.get<FileService>(FILE_SERVICE_KEY)

export function Editor(props: EditorProps) {
  const { t } = useTranslation()

  const loggedUser = loggedUserService.get()

  const [resource, setResource] = useState<FileDTO>(emptyFileDTO(loggedUser?.id))
  const [errorMessage, setErrorMessage] = useState<string>('')

  useEffect(() => {
    if (!props.id) {
      return
    }
    fileService.getByID(props.id).subscribe((res) => res && setResource(fromModel(res)))
  }, [])

  const handleInput = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) =>
    setResource(Object.assign({ ...resource }, { [e.target.name]: e.target.value }))

  const handleFileInput = async (event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    const files = (event.target as HTMLInputElement).files
    if (!files?.length) {
      return
    }

    const data = await dataToBase64(files[0])
    setResource(Object.assign({ ...resource }, { data, size: data.length }))
  }

  const goBack = () => navigate(ROUTE_RESOURCES)

  const validateResource = (): boolean => {
    if (!resource.data) {
      setErrorMessage(ResourceErrors.NO_FILE)
      return false
    }

    setErrorMessage('')
    return true
  }

  const saveResource = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault()

    if (!validateResource()) {
      return
    }

    if (props.id) {
      fileService.update(resource).subscribe(() => goBack())
      return
    }
    fileService.add(resource).subscribe(() => goBack())
  }

  return (
    <Box className={genericStyle.pageContainer}>
      <FormCard>
        <form onSubmit={saveResource}>
          <Box mb={3}>
            <TextFieldItem
              field="name"
              value={resource.name}
              label={'name'}
              type={'text'}
              handleChange={handleInput}
              rows={undefined}
              required={true}
            />
          </Box>
          <Box mb={3}>
            <TextFieldItem
              field="description"
              value={resource.description}
              label={'description'}
              type={'text'}
              handleChange={handleInput}
              rows={5}
              required={false}
            />
          </Box>

          <Box mb={3}>
            <TextField
              fullWidth
              variant={'outlined'}
              id="pdfFile"
              onChange={handleFileInput}
              type={'file'}
              label={t('pdfFile')}
              InputLabelProps={{
                shrink: true,
              }}
            />
          </Box>

          {errorMessage && (
            <Box mb={3}>
              <Alert severity="warning" key="errorMessage" id="errorMessage">
                {t(errorMessage)}
              </Alert>
            </Box>
          )}

          <Box display="flex" justifyContent="space-between">
            <AppButton
              theme={ButtonTheme.NewSecondary}
              type={'button'}
              label={t('cancel')}
              handler={goBack}
            />
            <AppButton
              theme={ButtonTheme.NewPrimary}
              type={'submit'}
              label={t('save')}
              handler={() => {}}
            />
          </Box>
        </form>
      </FormCard>
    </Box>
  )
}
