import generic from '../../../../assets/generic.module.css'
import { Box } from '@mui/material'
import React from 'react'

export const CVS320 = () => {
  return (
    <>
      <Box className={generic.pageContainer} style={{ backgroundColor: 'white' }}>
        <Box className={generic.pageContainer} display="flex">
          <h2>
            <b>3.2 Esclerosis Múltiple Función Vesical y problemas vesicales</b>
          </h2>
        </Box>
        <Box className={generic.pageContainer}>
          <div>
            <b>Función vesical</b>
            <br />
            <br />
            La esclerosis múltiple (EM) es una afección neurológica de por vida que afecta al
            sistema nervioso central, los nervios en el cerebro y la médula espinal. No todas las
            personas sufrirán los mismos síntomas.
            <br />
            <br />
            En la EM, el sistema inmunológico del cuerpo ataca por error el tejido de mielina, cuya
            función es proteger las fibras nerviosas del sistema nervioso central y garantizar una
            transición fluida de mensajes entre el cerebro y el cuerpo. Estos ataques dañan el
            tejido de mielina protector (llamado desmielinización) y las células nerviosas, lo que
            interfiere con la transmisión adecuada de los mensajes.
            <br />
            <br />
            El daño o la cicatrización (esclerosis) puede ocurrir en diferentes partes del sistema
            nervioso central y los síntomas que presenta una persona dependen de la ubicación o de
            la cantidad de lesiones que tenga. Los síntomas físicos comunes son alteraciones de la
            visión, dificultades para mantener el equilibrio y mareos, cansancio, problemas de
            incontinencia vesical/intestinal y rigidez y/o espasmos.
            <br />
            <br />
            La EM puede causar diferentes tipos de problemas vesicales e intestinales. Estos
            problemas a menudo están relacionados con la disfunción de las extremidades inferiores,
            por lo que los problemas de movilidad suelen ir acompañados de problemas adicionales de
            incontinencia intestinal y vesical.
            <br />
            <br />
            <b>Problemas vesicales</b>
            <br />
            <br />
            Cuando el nervio está dañado, resulta más difícil la transmisión de señales entre el
            cerebro y la parte de la médula espinal que controla el sistema urinario. La EM afecta
            al almacenamiento y al vaciado de la vejiga. El daño en los nervios puede provocar una
            vejiga hiperactiva (VHA). Es habitual sufrir una vejiga hiperactiva y la lesión nerviosa
            provoca necesidades urgentes, frecuentes e incontrolables para vaciar la vejiga aunque
            no esté llena.
            <br />
            <br />
            Puede producirse un vaciado incompleto de la vejiga o una falta de coordinación durante
            el vaciado, lo que se denomina disinergia del esfínter detrusor (músculo de la vejiga).
            Los músculos del suelo pélvico y el músculo del esfínter que rodea la uretra se contraen
            espontáneamente durante el vaciado. Esto hace que la uretra se cierre, con el resultado
            de que la vejiga no se vacía correctamente. Los músculos de la vejiga y el esfínter
            pueden debilitarse, lo que puede provocar una combinación de problemas denominados
            incontinencia por rebosamiento.
            <br />
            <br />
            Dado que la vía o la progresión de la EM es impredecible, la función vesical también
            puede variar, siendo más problemática durante algunos periodos y relativamente sencilla
            en otros. Sin embargo, sigue siendo muy importante tratar los problemas de vaciado de la
            vejiga de manera coherente, ya que la orina residual puede provocar infecciones del
            tracto urinario.
            <br />
            <br />
          </div>
        </Box>
      </Box>
    </>
  )
}
