import { TreatmentDTO } from './TreatmentDTO'

export class Treatment {
  private readonly _id: string
  private _name: string
  private _trademark: string
  private _brand: string

  constructor(p: TreatmentDTO) {
    this._id = p.id
    this._name = p.name
    this._trademark = p.trademark
    this._brand = p.brand
  }

  get id(): string {
    return this._id
  }

  get name(): string {
    return this._name
  }

  get trademark(): string {
    return this._trademark
  }

  get brand(): string {
    return this._brand
  }
}

export interface TreatmentQuery {
  id: string
  ids: string[]
  name: string
  trademark: string
}
