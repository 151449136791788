import generic from '../../../../assets/generic.module.css'
import { Box, Button, Divider, Modal } from '@mui/material'
import image from './columnEnglish.png'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import style from '../vesical/Table.module.css'

export const CIS113 = () => {
  const { t } = useTranslation()
  const [openModal, setOpenModal] = useState<boolean>(false)
  const { innerWidth } = window
  const handleOpenModal = () => setOpenModal(true)

  const handleCloseModal = () => setOpenModal(false)

  return (
    <>
      <Box className={generic.pageContainer} style={{ backgroundColor: 'white', display: 'flex' }}>
        <Box style={{ width: '50%' }}>
          <Box className={generic.pageContainer} display="flex">
            <h2>
              <b>1.1 La función del intestino</b>
            </h2>
          </Box>
          <Box className={generic.pageContainer}>
            <div>
              Los pensamientos y las emociones que desencadena el estrés pueden afectar al estómago
              y al intestino y también puede suceder lo contrario: lo que sucede en el intestino
              puede causar estrés y malestar a largo plazo. El estreñimiento crónico, la diarrea y
              otros tipos de afecciones intestinales pueden desencadenar ansiedad y generar un
              círculo vicioso de estrés.
              <br />
              <br />
              <br />
              <br />
            </div>
          </Box>
          <Box className={generic.pageContainer}>
            <div>
              Hay tres nervios importantes para controlar la función de la vejiga y el intestino. En
              la siguiente tabla se explica la función de los nervios y su posición en la médula
              espinal.
              <br />
              <br />
            </div>
            <table className={style.table}>
              <tr className={style.tr}>
                <th>Nervio</th>
                <th>Función vejiga - intestino</th>
                <th>Espinal - Vejiga</th>
                <th>Espinal - Intestino</th>
              </tr>
              <tr className={style.td}>
                <td>Nervio hipogástrico</td>
                <td>Continencia</td>
                <td>Th10-L2</td>
                <td>Th12-L3</td>
              </tr>
              <tr className={style.td}>
                <td>Nervio pélvico</td>
                <td>Micción/Defecación</td>
                <td>S2-S4</td>
                <td>S2-S4</td>
              </tr>
              <tr className={style.td}>
                <td>Nervio pudendo</td>
                <td>Uretral externo/ Esfínter anal</td>
                <td>S2-S4</td>
                <td>S2-S4</td>
              </tr>
            </table>
          </Box>
        </Box>
        <img
          src={image}
          onClick={handleOpenModal}
          style={{
            width: innerWidth < 900 ? '40%' : '22%',
            height: '15%',
            marginTop: '2%',
            marginLeft: '10%',
            marginRight: '10%',
            display: 'flex',
            justifyContent: 'center',
            borderStyle: 'ridge',
            borderColor: 'dodgerblue',
          }}
        />

        <Modal open={openModal} onClose={handleCloseModal}>
          <Box
            style={{
              backgroundColor: 'white',
              justifyContent: 'center',
              transform: 'translate(0%, 20%)',
              marginLeft: '30%',
              marginRight: '30%',
              padding: '10px',
            }}>
            <h1 style={{ marginLeft: '25%' }}>
              <b>¿Qué es el intestino y como funciona?</b>
            </h1>
            <Divider variant={'middle'} />
            <img
              src={image}
              style={{
                width: '75%',
                justifyContent: 'center',
                display: 'flex',
                marginLeft: '12%',
                marginTop: '1%',
              }}
              alt={'segunda'}
            />
            <Button
              variant={'outlined'}
              style={{ marginLeft: '45%', marginTop: '1%', justifyContent: 'end', display: 'flex' }}
              onClick={handleCloseModal}>
              {t('close')}
            </Button>
          </Box>
        </Modal>
      </Box>
    </>
  )
}
