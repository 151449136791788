import { Observable } from 'rxjs'
import { Container, IInit } from '../../../common/container/Container'
import { Query } from '../../../common/api/Query'
import { IStatusService } from '../../../common/status/StatusService'
import { STATUS_SERVICE_KEY } from '../../../container/app'
import { ItemList } from '../../../common/models/ItemList'
import { PatientTreatment, PatientTreatmentQuery } from '../models/PatientTreatment'
import { PatientTreatmentDTO } from '../models/PatientTreatmentDTO'
import { IPatientTreatmentApi } from '../api/PatientTreatmentApi'

type Props = {
  apiKey: symbol
}

export interface IPatientTreatmentService extends IInit {
  getByID(id: string | null): Observable<PatientTreatment | undefined>

  getFilteredItems(q: Query<PatientTreatmentQuery>): Observable<ItemList<PatientTreatment>>

  add(e: PatientTreatmentDTO): Observable<PatientTreatment | undefined>

  update(e: PatientTreatmentDTO): Observable<PatientTreatment | undefined>

  delete(id: string): Observable<boolean>

  getPatientTreatmentByPatientID(patientID: string): Observable<PatientTreatment[] | undefined>
}

export class PatientTreatmentService implements IPatientTreatmentApi {
  private readonly _apiKey: symbol
  private _container!: Container
  private _api!: IPatientTreatmentApi
  private _statusService!: IStatusService

  constructor(p: Props) {
    this._apiKey = p.apiKey
  }

  init(c: Container) {
    this._container = c
    this._api = this._container.get<IPatientTreatmentApi>(this._apiKey)
    this._statusService = this._container.get<IStatusService>(STATUS_SERVICE_KEY)
  }

  getByID(id: string | null): Observable<PatientTreatment | undefined> {
    return this._api.getByID(id)
  }

  getFilteredItems(q: Query<PatientTreatmentQuery>): Observable<ItemList<PatientTreatment>> {
    return this._api.getFilteredItems(q).pipe()
  }

  add(e: PatientTreatmentDTO): Observable<PatientTreatment | undefined> {
    return this._api.add(e)
  }

  update(e: PatientTreatmentDTO): Observable<PatientTreatment | undefined> {
    return this._api.update(e)
  }

  delete(id: string): Observable<boolean> {
    return this._api.delete(id)
  }

  getPatientTreatmentByPatientID(
    patientID: string
  ): Observable<ItemList<PatientTreatment> | undefined> {
    return this._api.getPatientTreatmentByPatientID(patientID)
  }
}
