import generic from '../../../../assets/generic.module.css'
import { Box } from '@mui/material'
import image from './intestino.svg'
import React from 'react'

export const CIS221 = () => {
  return (
    <>
      <Box className={generic.pageContainer} style={{ backgroundColor: 'white', display: 'flex' }}>
        <Box style={{ marginRight: '5%', marginLeft: '1%' }}>
          <Box className={generic.pageContainer} display="flex">
            <h2>
              <b>2.2 Disfunción intestinal</b>
            </h2>
          </Box>
          <Box className={generic.pageContainer} display="flex">
            <h4 style={{ color: 'dodgerblue' }}>
              <b>Trastornos funcionales intestinales (TFI)</b>
            </h4>
          </Box>

          <Box className={generic.pageContainer} display="flex">
            <div>
              Los trastornos intestinales sin ninguna causa morfológica, metabólica o neurológica se
              denominan trastornos intestinales funcionales. Existen otras definiciones en la
              literatura científica pero, por lo general, un TFI se diagnostica después de realizar
              investigaciones completas que no logran identificar una causa orgánica subyacente.
              <br />
              <br />
              Entre algunos ejemplos de TFI, se incluyen los siguientes:
              <br />
              <br />
              &nbsp;&nbsp;&nbsp;&nbsp;•&nbsp;&nbsp;&nbsp;&nbsp; Estreñimiento crónico (o
              estreñimiento funcional)
              <br />
              <br />
              &nbsp;&nbsp;&nbsp;&nbsp;•&nbsp;&nbsp;&nbsp;&nbsp; Incontinencia fecal
              <br />
              <br />
              &nbsp;&nbsp;&nbsp;&nbsp;•&nbsp;&nbsp;&nbsp;&nbsp; Estreñimiento de tránsito lento
              (ETL)
              <br />
              <br />
            </div>
          </Box>

          <Box className={generic.pageContainer} display="flex">
            <h4 style={{ color: 'dodgerblue' }}>
              <b>Otras causas de disfunción intestinal</b>
            </h4>
          </Box>
          <Box className={generic.pageContainer} display="flex">
            <div>
              Las causas orgánicas pueden variar desde afecciones congénitas, como la enfermedad de
              Hirschsprung y malformaciones anorrectales, hasta trastornos metabólicos y endocrinos,
              disfunciones del suelo pélvico y alteraciones postquirúrgicas. El uso de ciertos
              medicamentos también puede afectar la función intestinal.
              <br />
              <br />
              Las diferencias entre las disfunciones son la causa de los síntomas, no los síntomas.
              <br />
              <br />
              <b>
                Entre los síntomas de la disfunción intestinal se pueden incluir los siguientes:
              </b>
              <br />
              <br />
              &nbsp;&nbsp;&nbsp;&nbsp;•&nbsp;&nbsp;&nbsp;&nbsp; Dolor abdominal
              <br />
              <br />
              &nbsp;&nbsp;&nbsp;&nbsp;•&nbsp;&nbsp;&nbsp;&nbsp; Distensión abdominal
              <br />
              <br />
              &nbsp;&nbsp;&nbsp;&nbsp;•&nbsp;&nbsp;&nbsp;&nbsp; Saciedad precoz
              <br />
              <br />
              &nbsp;&nbsp;&nbsp;&nbsp;•&nbsp;&nbsp;&nbsp;&nbsp; Pérdida del control voluntario de la
              evacuación intestinal (incontinencia fecal)
              <br />
              <br />
              &nbsp;&nbsp;&nbsp;&nbsp;•&nbsp;&nbsp;&nbsp;&nbsp; Dificultad para evacuar
              (estreñimiento)
              <br />
              <br />
              &nbsp;&nbsp;&nbsp;&nbsp;•&nbsp;&nbsp;&nbsp;&nbsp; Síntomas vesicales neurológicos
              asociados
              <br />
              <br />
              <br />
              <br />
            </div>
          </Box>
        </Box>
      </Box>
    </>
  )
}
