import { File as F } from '../../modules/files/models/File'

export const dataToBase64 = async (f: File): Promise<string> =>
  btoa(
    new Uint8Array(await f.arrayBuffer()).reduce(
      (data, byte) => data + String.fromCharCode(byte),
      ''
    )
  )

export function viewFile(file: F) {
  const f = atob(file.data)
  const length = f.length
  let ab = new ArrayBuffer(length)
  let ua = new Uint8Array(ab)
  for (let i = 0; i < length; i++) {
    ua[i] = f.charCodeAt(i)
  }
  const blob = new Blob([ab], { type: file.mimeType })

  if (navigator.msSaveOrOpenBlob) {
    navigator.msSaveOrOpenBlob(blob, file.name)
    return
  }

  const blobURL = URL.createObjectURL(blob)
  const link = document.createElement('a')

  link.href = blobURL
  link.setAttribute('target', '_blank')
  document.body.appendChild(link)
  link.click()
  document.body.removeChild(link)

  setTimeout(() => {
    window.URL.revokeObjectURL(blobURL)
  }, 100)
}

export function downloadFile(name: string, mimeType: string, data: string) {
  const f = atob(data)
  const length = f.length
  let ab = new ArrayBuffer(length)
  let ua = new Uint8Array(ab)
  for (let i = 0; i < length; i++) {
    ua[i] = f.charCodeAt(i)
  }
  const blob = new Blob([ab], { type: mimeType })

  if (navigator.msSaveOrOpenBlob) {
    navigator.msSaveOrOpenBlob(blob, name)
    return
  }
  const blobURL = URL.createObjectURL(blob)
  const link = document.createElement('a')

  link.href = blobURL
  link.download = name
  link.setAttribute('download', name)

  if (!link.download) {
    link.setAttribute('target', '_blank')
  }
  document.body.appendChild(link)
  link.click()
  document.body.removeChild(link)

  setTimeout(() => {
    window.URL.revokeObjectURL(blobURL)
  }, 100)
}
