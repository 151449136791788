import { Container, IInit } from '../../../common/container/Container'
import { Observable, of } from 'rxjs'
import { Query } from '../../../common/api/Query'
import { emptyList, ItemList } from '../../../common/models/ItemList'
import { HTTP_CLIENT_KEY, IHTTPClient } from '../../../common/api/HTTPClient'
import { IStatusService } from '../../../common/status/StatusService'
import { STATUS_SERVICE_KEY } from '../../../container/app'
import { ContentContainerConfig } from '../../content/container'
import { catchError, map } from 'rxjs/operators'
import { prepareURL } from '../../../common/api/http-helpers'
import { Fecal, toModel } from '../models/Fecal'
import { FecalDTO } from '../models/FecalDTO'
import { Urination } from '../../urination/models/Urination'
import { UrinationDTO } from '../../urination/models/UrinationDTO'

export interface IFecalApi extends IInit {
  getByID(id: string): Observable<Fecal | undefined>

  getFilteredList(q: Query<Fecal>): Observable<ItemList<Fecal>>

  add(e: FecalDTO): Observable<Fecal | undefined>

  update(e: FecalDTO): Observable<Fecal | undefined>

  delete(id: string): Observable<boolean>

  getDiariesLastWeek(q: Query<Fecal>): Observable<ItemList<Fecal>>

  getLastDocument(id: string): Observable<Fecal | undefined>

  getExcelFromFecalDiaries(fecals: FecalDTO[], language: string): Observable<Blob | undefined>
}

export class FecalApi implements IFecalApi {
  private _container!: Container
  private _httpClient!: IHTTPClient
  private _url!: string
  private _statusService!: IStatusService

  init(c: Container) {
    this._container = c
    this._httpClient = this._container.get<IHTTPClient>(HTTP_CLIENT_KEY)
    this._statusService = this._container.get<IStatusService>(STATUS_SERVICE_KEY)
    this._url = (this._container.config as ContentContainerConfig).moduleFullUrl
  }

  add(e: FecalDTO): Observable<Fecal | undefined> {
    return this._httpClient.post<Fecal>({ url: this._url, body: e }).pipe(
      map<FecalDTO, Fecal>((d) => {
        this._statusService.sendStatus({ variant: 'success' })
        return toModel(d)
      }),
      catchError((err) => {
        this._statusService.sendStatus({ variant: 'error', error: err })
        return of(undefined)
      })
    )
  }

  delete(id: string): Observable<boolean> {
    return this._httpClient.delete({ url: `${this._url}/${id}` }).pipe(
      catchError((err) => {
        this._statusService.sendStatus({ variant: 'error', error: err })
        return of(false)
      })
    )
  }

  getByID(id: string): Observable<Fecal | undefined> {
    return this._httpClient.get<Fecal>({ url: `${this._url}/${id}` }).pipe(
      map<FecalDTO, Fecal>((d) => toModel(d)),
      catchError((err) => {
        this._statusService.sendStatus({ variant: 'error', error: err })
        return of(undefined)
      })
    )
  }

  getFilteredList(q: Query<Fecal>): Observable<ItemList<Fecal>> {
    return this._httpClient.get<ItemList<Fecal>>({ url: prepareURL(this._url, q) }).pipe(
      map<ItemList<FecalDTO>, ItemList<Fecal>>((dto) => {
        const itemList = emptyList<Fecal>()
        itemList.count = dto.count
        itemList.items = dto.items.map((d) => toModel(d))
        return itemList
      }),
      catchError((err) => {
        this._statusService.sendStatus({ variant: 'error', error: err })
        return of(emptyList<Fecal>())
      })
    )
  }

  update(e: FecalDTO): Observable<Fecal | undefined> {
    return this._httpClient.put<Fecal>({ url: this._url, body: e }).pipe(
      map<FecalDTO, Fecal>((d) => {
        this._statusService.sendStatus({ variant: 'success' })
        return toModel(d)
      }),
      catchError((err) => {
        this._statusService.sendStatus({ variant: 'error', error: err })
        return of(undefined)
      })
    )
  }

  getDiariesLastWeek(q: Query<Fecal>): Observable<ItemList<Fecal>> {
    return this._httpClient
      .post<ItemList<Fecal>>({ url: prepareURL(this._url + '/lastweek', q) })
      .pipe(
        map<ItemList<FecalDTO>, ItemList<Fecal>>((dto) => {
          const itemList = emptyList<Fecal>()
          itemList.count = dto.count
          itemList.items = dto.items.map((d) => toModel(d))
          return itemList
        }),
        catchError((err) => {
          this._statusService.sendStatus({ variant: 'error', error: err })
          return of(emptyList<Fecal>())
        })
      )
  }

  getLastDocument(id: string): Observable<Fecal | undefined> {
    return this._httpClient.get<Fecal>({ url: `${this._url}/getLastDocument/${id}` }).pipe(
      map<FecalDTO, Fecal>((d) => toModel(d)),
      catchError((err) => {
        //this._statusService.sendStatus({ variant: 'error', error: err })
        return of(undefined)
      })
    )
  }

  getExcelFromFecalDiaries(fecals: FecalDTO[], language: string): Observable<Blob | undefined> {
    return this._httpClient
      .post<Blob>({
        url: `${this._url}/fecal-diaries-excel/${language}`,
        body: fecals,
        responseType: 'blob',
      })
      .pipe(
        map<Blob, Blob>((d) => d),
        catchError((err) => {
          this._statusService.sendStatus({ variant: 'error', error: err })
          return of(undefined)
        })
      )
  }
}
