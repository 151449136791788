import { IInit, Container } from '../../../common/container/Container'
import { Observable } from 'rxjs'
import { Questionnaire } from '../models/Questionnaire'
import { IQuestionnaireApi } from '../api/QuestionnaireApi'
import { IStatusService } from '../../../common/status/StatusService'
import { STATUS_SERVICE_KEY } from '../../../container/app'
import { ItemList } from '../../../common/models/ItemList'
import { QuestionnairePatient, QuestionnairePatientQuery } from '../models/QuestionnairePatient'
import { Query } from '../../../common/api/Query'
import { QuestionnaireRange } from '../models/QuestionnaireRange'

type Props = {
  apiKey: symbol
}

export interface IQuestionnaireService extends IInit {
  getList(): Observable<ItemList<Questionnaire>>
  add(q: string[], patientId: string): Observable<boolean>
  getPatientQuestionnaireList(
    q: Query<QuestionnairePatientQuery>,
    patientId: string
  ): Observable<ItemList<QuestionnairePatient>>
  getByID(id: string): Observable<Questionnaire | undefined>
  deletePatientQuestionnaire(id: string): Observable<boolean>
  getScoreAndResultQuestionnaire(
    patientQuestionnaireId: string
  ): Observable<QuestionnaireRange | undefined>

  getLastDocument(id: string): Observable<QuestionnairePatient | undefined>
  getPatientQuestionnaires(
    q: Query<QuestionnairePatientQuery>
  ): Observable<ItemList<QuestionnairePatient>>
}

export class QuestionnairesService implements IQuestionnaireService {
  private readonly _apiKey: symbol
  private _container!: Container
  private _api!: IQuestionnaireApi
  private _statusService!: IStatusService

  constructor(p: Props) {
    this._apiKey = p.apiKey
  }

  init(c: Container) {
    this._container = c
    this._api = this._container.get<IQuestionnaireApi>(this._apiKey)
    this._statusService = this._container.get<IStatusService>(STATUS_SERVICE_KEY)
  }

  getList(): Observable<ItemList<Questionnaire>> {
    return this._api.getList()
  }

  add(q: string[], patientId: string): Observable<boolean> {
    return this._api.add(q, patientId)
  }

  getPatientQuestionnaireList(
    q: Query<QuestionnairePatientQuery>,
    patientId: string
  ): Observable<ItemList<QuestionnairePatient>> {
    return this._api.getPatientQuestionnaireList(q, patientId)
  }

  getPatientQuestionnaires(
    q: Query<QuestionnairePatientQuery>
  ): Observable<ItemList<QuestionnairePatient>> {
    return this._api.getPatientQuestionnaires(q)
  }

  getByID(id: string): Observable<Questionnaire | undefined> {
    return this._api.getByID(id)
  }

  deletePatientQuestionnaire(id: string): Observable<boolean> {
    return this._api.deletePatientQuestionnaire(id)
  }

  getScoreAndResultQuestionnaire(
    patientQuestionnaireId: string
  ): Observable<QuestionnaireRange | undefined> {
    return this._api.getScoreAndResultQuestionnaire(patientQuestionnaireId)
  }

  getLastDocument(id: string): Observable<QuestionnairePatient | undefined> {
    return this._api.getLastDocument(id)
  }
}
