import i18n from 'i18n/index'

export const PATIENTS_TITLE = i18n.t('patient')

export const EDUCATIONAL_TITLE = i18n.t('educationals')

export const PATIENTS_GOALS_TITLE = i18n.t('patientsAddGoals')
export const GOALS_TITLE = i18n.t('goals')
export const STATISTICS_TITLE = i18n.t('statistics')
export const FECAL_TITLE = i18n.t('fecal')
export const URINATION_TITLE = i18n.t('urination')
export const FAQS_TITLE = i18n.t('faqs')
export const USER_PROFILE_TITLE = i18n.t('myProfile')
export const CONFIGURATION_TITLE = i18n.t('configuration')
export const PATIENTS_FORMS_TITLE = i18n.t('patientsForms')
export const PATIENT_FORM_VIEW_TITLE = i18n.t('form')
export const RESOURCES_TITLE = i18n.t('resources')
export const CREATE_PARTICIPANT_TITLE = i18n.t('addParticipant')
export const QUESTIONNAIRES_TITLE = i18n.t('questionnaires')
export const PROFESSIONAL_TITLE = i18n.t('professionals')
export const CREATE_PROFESSIONAL_TITLE = i18n.t('addProfessionals')
export const PATIENTS_LIST_TITLE = i18n.t('patientList')
export const EDUCATIONAL_MATERIAL_TITLE = i18n.t('educationalContent')

//FIXME remove
export const CIRCLES_TITLE = i18n.t('circles')
export const USERS_TITLE = i18n.t('patients')
export const NEWSWALL_TITLE = i18n.t('newsWall')
export const MESSENGER_TITLE = i18n.t('messenger')
export const CALENDAR_TITLE = i18n.t('calendar')
export const RESOURCES_SHARED_WITH_ME_TITLE = i18n.t('resourcesSharedWithMe')
export const RESOURCES_NOTIFICATIONS_CONFIG_TITLE = i18n.t('notificationsConfig')
export const ROLE_PERMISSIONS_TITLE = i18n.t('title_role_permissions')
export const ARTICLES_TITLE = i18n.t('articles')

export const APPOINTMENTS_TITLE = i18n.t('appontiments')
