export const ROUTE_HOME = `/`
export const ROUTE_PATIENTS = `/patients`
export const ROUTE_PATIENTS_ID = `/patients/:id`
export const ROUTE_PATIENTS_GOALS = `/patients/:id/goals`
export const ROUTE_GOALS = `/goals`
export const ROUTE_STATISTICS = `/patients/:id/statistics`
export const ROUTE_FECAL = `/patients/:id/fecal`
export const ROUTE_URINATION = `/patients/:id/urination`
export const ROUTE_FAQS = `/faqs`
export const ROUTE_FAQS_CREATE = `/faqs/form-create`
export const ROUTE_FAQS_EDIT = `/faqs/form-edit/:id`
export const ROUTE_USER_PROFILE = `/user-profile`
export const ROUTE_PATIENT_FORMS = `/forms`
export const ROUTE_PATIENT_FORMS_VIEW = `/forms/:id`
export const ROUTE_CREATE = `create`
export const ROUTE_USERS_CREATE = `/user/createParticipant`
export const ROUTE_USERS_EDIT = `/user/:id`
export const ROUTE_PATIENT_SUMMARY = `/patient/patientSummary`
export const ROUTE_RESOURCES = `/resources`
export const ROUTE_RESOURCES_ID = `/resources/:id`
export const ROUTE_CONFIGURATION = `/configuration`
export const ROUTE_EDUCATIONAL = '/educational/:id'
export const ROUTE_EDUCATIONALPROFESSIONAL = '/educational'
export const ROUTE_EDUCATIONALINTESTINAL11 = '/educational/intestinal/module1.1'
export const ROUTE_EDUCATIONALINTESTINAL21 = '/educational/intestinal/module2.1'
export const ROUTE_EDUCATIONALINTESTINAL22 = '/educational/intestinal/module2.2'
export const ROUTE_EDUCATIONALINTESTINAL23 = '/educational/intestinal/module2.3'
export const ROUTE_EDUCATIONALINTESTINAL24 = '/educational/intestinal/module2.4'
export const ROUTE_EDUCATIONALINTESTINAL31 = '/educational/intestinal/module3.1'
export const ROUTE_EDUCATIONALINTESTINAL32 = '/educational/intestinal/module3.2'
export const ROUTE_EDUCATIONALINTESTINAL33 = '/educational/intestinal/module3.3'
export const ROUTE_EDUCATIONALVESICAL11 = '/educational/vesical/module1.1'
export const ROUTE_EDUCATIONALVESICAL12 = '/educational/vesical/module1.2'
export const ROUTE_EDUCATIONALVESICAL13 = '/educational/vesical/module1.3'
export const ROUTE_EDUCATIONALVESICAL14 = '/educational/vesical/module1.4'
export const ROUTE_EDUCATIONALVESICAL21 = '/educational/vesical/module2.1'
export const ROUTE_EDUCATIONALVESICAL22 = '/educational/vesical/module2.2'
export const ROUTE_EDUCATIONALVESICAL23 = '/educational/vesical/module2.3'
export const ROUTE_EDUCATIONALVESICAL31 = '/educational/vesical/module3.1'
export const ROUTE_EDUCATIONALVESICAL32 = '/educational/vesical/module3.2'
export const ROUTE_EDUCATIONALVESICAL33 = '/educational/vesical/module3.3'
export const ROUTE_EDUCATIONALVESICAL41 = '/educational/vesical/module4.1'
export const ROUTE_EDUCATIONALVESICAL42 = '/educational/vesical/module4.2'
export const ROUTE_EDUCATIONALVESICAL43 = '/educational/vesical/module4.3'
export const ROUTE_EDUCATIONALVESICAL44 = '/educational/vesical/module4.4'
export const ROUTE_EDUCATIONALVESICAL45 = '/educational/vesical/module4.5'
export const ROUTE_EDUCATIONALVESICAL46 = '/educational/vesical/module4.6'
export const ROUTE_EDUCATIONALVESICAL51 = '/educational/vesical/module5.1'
export const ROUTE_TASKS = `/tasks`
export const ROUTE_APPOINTMENTS = `/appointments/:id`
export const ROUTE_SYMPTOM = `/symptom/:id`
export const ROUTE_QUESTIONNAIRES = `/questionnaires/:id`
export const ROUTE_PROFESSIONALS = `/professionals`
export const ROUTE_PATIENT_DETAIL = `/patients/:id/details`

//FIXME remove
export const ROUTE_CIRCLES = `/`
export const ROUTE_USERS = `/users`
export const ROUTE_NEWS_WALL = `/newsWall`
export const ROUTE_MESSENGER = `/messenger`
export const ROUTE_CALENDAR = `/calendar`
export const ROUTE_CALENDAR_FORM = `/calendar/form`
export const ROUTE_CALENDAR_FORM_EDIT = `/calendar/form/:id`
export const ROUTE_ARTICLES = `/articles`
export const ROUTE_ARTICLE = `/articles/:id`
export const ROUTE_ARTICLES_FORM = `/articles/form`
export const ROUTE_ARTICLES_FORM_EDIT = `/articles/form/:id`
export const ROUTE_RESOURCES_SHARED_WITH_ME = `/resources/shared`
export const ROUTE_RESOURCES_SHARED_WITH_ME_ID = `/resources/shared/:id`
export const ROUTE_RESOURCES_FORM = `/resources/form`
export const ROUTE_RESOURCES_FORM_EDIT = `/resources/form/:id`
export const ROUTE_NOTIFICATIONS_CONFIG = `/notifications-config`
export const ROUTE_SYMPTOMS_CONFIG = `/symptoms-config`
export const ROUTE_DENTIST_CONFIG = `/dentist-config`
export const ROUTE_ROLE_PERMISSIONS = `/role-permissions`
export const ROUTE_PATIENT_DATA = `/patient-data`
export const ROUTE_PATIENT_CALENDAR_CONFIG = `/patient-calendar-config`
