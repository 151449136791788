// @ts-ignore
import Chart from 'chart.js/auto'
import React, { useEffect, useRef, useState } from 'react'
import styles from './GenericChart.module.css'
import { Box } from '@mui/material'

export type genericChartProps = {
  title: string
  type: string
  data: object[]
  labelX: number[] | string[]
  xAxisLabel: string
  yAxisLabel: string
  showLegend: boolean
  showTooltip: boolean
  showTitle: boolean
  width?: number
}

export function GenericChart(props: genericChartProps): JSX.Element {
  const [myChart, setMyChart] = useState<Chart>()
  const myCanvas = useRef<any>()
  const [isLoading, setIsLoading] = useState<boolean>(true)
  useEffect(() => {
    setIsLoading(true)
  }, [props])

  useEffect(() => {
    if (isLoading) {
      if (myChart) myChart.destroy()
      setMyChart(
        new Chart(myCanvas.current, {
          type: props.type,
          data: {
            color: '#000',
            labels: props.labelX,
            datasets: props.data,
            fontFamily: 'Open-sans, sans-serif',
          },
          options: {
            beginAtZero: true,
            responsive: true,
            plugins: {
              legend: {
                display: props.showLegend,
                position: 'bottom',
                color: '#000',
                fontFamily: 'Open-sans, sans-serif',
                labels: {
                  filter: function (
                    legendItem: { datasetIndex: string | number },
                    chart: { datasets: { [x: string]: { label: string } } }
                  ) {
                    // Oculta la leyenda si el label del conjunto de datos es vacío
                    return chart.datasets[legendItem.datasetIndex].label != ''
                  },
                },
              },
              title: {
                display: props.showTitle,
                text: props.title,
                color: '#000',
                fontFamily: 'Open-sans, sans-serif',
              },
              tooltip: {
                color: '#000',
                enabled: props.showTooltip,
                displayColors: false,
                fontFamily: 'Open-sans, sans-serif',
                callbacks: {
                  label: function (tooltipItems: any, data: any) {
                    return tooltipItems.formattedValue + ' ' + props.yAxisLabel
                  },
                  title: function (value: any) {
                    return props.yAxisLabel + ' ' + value[0].label
                  },
                },
              },
            },
            scales: {
              x: {
                ticks: {
                  callback: function (value: number, index: any, values: any) {
                    if (props.xAxisLabel === '' && props.labelX) return props.labelX[index]
                    return props.xAxisLabel + ' ' + value
                  },
                },
              },
              y: {
                min: 0,
                suggestedMax: 2500,
                ticks: {
                  beginAtZero: true,
                  stepSize: 500,
                },
              },
            },
          },
        })
      )
    }
    setIsLoading(false)
  }, [isLoading])

  return (
    <>
      <canvas id="myChart" ref={myCanvas} className={styles.canvas} />
    </>
  )
}

export function GenericChartFecal(props: genericChartProps): JSX.Element {
  const [myChart, setMyChart] = useState<Chart>()
  const myCanvas = useRef<any>()
  const [isLoading, setIsLoading] = useState<boolean>(true)

  useEffect(() => {
    setIsLoading(true)
  }, [props])

  useEffect(() => {
    if (isLoading) {
      if (myChart) myChart.destroy()
      setMyChart(
        new Chart(myCanvas.current, {
          type: props.type,
          data: {
            color: '#000',
            labels: props.labelX,
            datasets: props.data,
            fontFamily: 'Open-sans, sans-serif',
          },
          options: {
            beginAtZero: true,
            responsive: true,
            plugins: {
              legend: {
                display: props.showLegend,
                position: 'bottom',
                color: '#000',
                fontFamily: 'Open-sans, sans-serif',
              },
              title: {
                display: props.showTitle,
                text: props.title,
                color: '#000',
                fontFamily: 'Open-sans, sans-serif',
              },
              tooltip: {
                color: '#000',
                enabled: props.showTooltip,
                displayColors: false,
                fontFamily: 'Open-sans, sans-serif',
                callbacks: {
                  label: function (tooltipItems: any, data: any) {
                    return tooltipItems.formattedValue + ' ' + props.yAxisLabel
                  },
                  title: function (value: any) {
                    return props.yAxisLabel + ' ' + value[0].label
                  },
                },
              },
            },
            scales: {
              x: {
                ticks: {
                  callback: function (value: number, index: any, values: any) {
                    if (props.xAxisLabel === '' && props.labelX) return props.labelX[index]
                    return props.xAxisLabel + ' ' + value
                  },
                },
              },
            },
          },
        })
      )
    }
    setIsLoading(false)
  }, [isLoading])

  return (
    <>
      <canvas id="myChart" ref={myCanvas} className={styles.canvas} />
    </>
  )
}

export function GenericPieChart(props: genericChartProps): JSX.Element {
  const [myChart, setMyChart] = useState<Chart>()
  const myCanvas = useRef<any>()
  const [isLoading, setIsLoading] = useState<boolean>(true)

  useEffect(() => {
    setIsLoading(true)
  }, [props])

  useEffect(() => {
    if (isLoading) {
      if (myChart) myChart.destroy()
      setMyChart(
        new Chart(myCanvas.current, {
          type: props.type,
          data: {
            color: '#000',
            labels: props.labelX,
            datasets: props.data,
            fontFamily: 'Open-sans, sans-serif',
          },
          options: {
            beginAtZero: true,
            responsive: true,
            plugins: {
              legend: {
                display: props.showLegend,
                position: 'bottom',
              },
              title: {
                display: props.showTitle,
                text: props.title,
                color: '#000',
                fontFamily: 'Open-sans, sans-serif',
              },
              tooltip: {
                color: '#000',
                enabled: props.showTooltip,
                displayColors: false,
                fontFamily: 'Open-sans, sans-serif',
                callbacks: {
                  label: function (tooltipItems: any, data: any) {
                    return tooltipItems.formattedValue + ' ' + props.yAxisLabel
                  },
                  title: function (value: any) {
                    return props.yAxisLabel + ' ' + value[0].label
                  },
                },
              },
            },
          },
        })
      )
    }
    setIsLoading(false)
  }, [isLoading])

  return (
    <>
      <canvas id="myChart" ref={myCanvas} className={styles.canvas} />
    </>
  )
}
