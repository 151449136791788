import { v4 as uuidv4 } from 'uuid'
import { UserGender } from '../../users/enums/UserGender'
import { Patient } from './Patient'
import { Treatment } from '../enums/Treatment'
import { Procedure } from '../enums/Procedure'
import { Diagnosis } from '../enums/Diagnosis'
import { LastActivity } from '../enums/LastActivity'

export interface PatientDTO {
  id: string
  userID: string | undefined
  internalID: string | undefined
  firstName: string
  lastName: string
  gender: UserGender
  dob: Date
  contactPhone: string
  mobilePhone: string
  email: string
  address: string | undefined
  city: string
  zip: string | undefined
  country: string
  createdAt: Date
  createdBy: string
  carerID: string
  diagnosis: Diagnosis
  diagnosisDate: Date
  procedure: Procedure
  procedureDate: Date | undefined
  complications: string | undefined
  currentTreatment: Treatment
  brand: string | undefined
  diet: string | undefined
  medicine: string | undefined
  observations: string | undefined
  dischargeDate: Date | undefined
  notifications: number | undefined
  autonomousCommunity: string
  province: string
  lastActivity: LastActivity | undefined
}

export function emptyPatientDTO(): PatientDTO {
  return {
    address: '',
    brand: undefined,
    carerID: '',
    city: '',
    complications: undefined,
    contactPhone: '',
    country: '',
    currentTreatment: Treatment.Pills,
    diagnosis: Diagnosis.Normal,
    diagnosisDate: new Date(),
    diet: undefined,
    email: '',
    firstName: '',
    lastName: '',
    medicine: undefined,
    mobilePhone: '',
    observations: undefined,
    procedure: Procedure.Normal,
    procedureDate: new Date(),
    userID: undefined,
    zip: '',
    createdAt: new Date(),
    createdBy: '',
    dob: new Date(),
    gender: UserGender.Female,
    id: uuidv4(),
    dischargeDate: undefined,
    notifications: undefined,
    internalID: '',
    autonomousCommunity: '',
    province: '',
    lastActivity: undefined,
  }
}

export function toModel(p: PatientDTO): Patient {
  return new Patient(p)
}

export function fromModel(p: Patient): PatientDTO {
  return {
    address: p.address,
    brand: p.brand,
    carerID: p.carerID,
    city: p.city,
    complications: p.complications,
    contactPhone: p.contactPhone,
    country: p.country,
    createdAt: p.createdAt,
    createdBy: p.createdBy,
    currentTreatment: p.currentTreatment,
    diagnosis: p.diagnosis,
    diagnosisDate: p.diagnosisDate,
    diet: p.diet,
    dob: p.dob,
    email: p.email,
    firstName: p.firstName,
    gender: p.gender,
    id: p.id,
    lastName: p.lastName,
    medicine: p.medicine,
    mobilePhone: p.mobilePhone,
    observations: p.observations,
    procedure: p.procedure,
    procedureDate: p.procedureDate,
    userID: p.userID,
    zip: p.zip,
    dischargeDate: p.dischargeDate,
    notifications: p.notifications,
    autonomousCommunity: p.autonomousCommunity,
    province: p.province,
    internalID: p.internalID,
    lastActivity: p.lastActivity,
  }
}
