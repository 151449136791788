import { InterventionDTO } from './InterventionDTO'

export class Intervention {
  private readonly _id: string
  private _name: string

  constructor(p: InterventionDTO) {
    this._id = p.id
    this._name = p.name
  }

  get id(): string {
    return this._id
  }

  get name(): string {
    return this._name
  }
}

export interface InterventionQuery {
  id: string
  ids: string[]
  name: string
}
