import { Query } from '../../../common/api/Query'
import { v4 as uuidv4 } from 'uuid'

export type RoleProps = {
  id: number
  role: string
  admin: boolean
  professional: boolean
  patient: boolean
}

export class Role {
  private readonly _id: number
  private _role: string
  private _admin: boolean
  private _professional: boolean
  private _patient: boolean

  constructor(p: RoleProps) {
    this._id = p.id
    this._role = p.role
    this._admin = p.admin
    this._professional = p.professional
    this._patient = p.patient
  }

  get id(): number {
    return this._id
  }

  get role(): string {
    return this._role
  }

  set role(value: string) {
    this._role = value
  }

  get admin(): boolean {
    return this._admin
  }

  set admin(value: boolean) {
    this._admin = value
  }

  get professional(): boolean {
    return this._professional
  }

  set professional(value: boolean) {
    this._professional = value
  }

  get patient(): boolean {
    return this._patient
  }

  set patient(value: boolean) {
    this._patient = value
  }
}

export class RoleQuery extends Query<Role> {}
