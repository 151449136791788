import { ViewProps } from './Statistics'
import { Box } from '@mui/material'
import { useTranslation } from 'react-i18next'
import style from './Stats.module.css'
import {
  GenericChart,
  GenericChartFecal,
  GenericPieChart,
} from '../../components/generic-chart/GenericChart'
import { useEffect, useState } from 'react'
import { Data, DataType } from './MiccionalView'
import dayjs from 'dayjs'
import { FecesConsistency, FecesQuantity } from '../../modules/fecal/enum/Feces'
import { getUserContainer } from 'container/user-module'
import { LOGGED_USER_SERVICE_KEY } from 'modules/users'
import { ILoggedUserService } from 'modules/users/services/LoggedUserService'

export type pieType = {
  data: number[]
  label: string
  backgroundColor: string[]
  hoverOffset: number
}

const loggedUserService = getUserContainer().get<ILoggedUserService>(LOGGED_USER_SERVICE_KEY)

export const FecalView = (props: ViewProps) => {
  const { t } = useTranslation()
  const [labelX, setLabelX] = useState<string[]>()
  const [tries, setTries] = useState<DataType[]>([])
  const [efficiency, setEfficiency] = useState<DataType[]>([])
  const [losses, setLosses] = useState<DataType[]>([])
  const [consistency, setConsistency] = useState<pieType[]>([])
  const [quantity, setQuantity] = useState<pieType[]>([])
  const consistencyLabel = [
    t('type1'),
    t('type2'),
    t('type3'),
    t('type4'),
    t('type5'),
    t('type6'),
    t('type7'),
  ]
  const quantityLabel = [t('few'), t('normal'), t('many')]
  const { innerWidth } = window
  const [isLoading, setIsLoading] = useState<boolean>(true)

  useEffect(() => {
    setTimeout(() => {
      if (!isLoading) return
      const labelXTmp: string[] = []
      const axisT: Data[] = []
      const axisE: Data[] = []
      const axisL: Data[] = []
      let chartT: DataType[] = [
        {
          label: '',
          data: [],
          borderWidth: 3,
          borderColor: '#68B3E0',
          pointRadius: 2,
        },
      ]
      let chartE: DataType[] = [
        {
          label: '',
          data: [],
          borderWidth: 3,
          borderColor: '#68B3E0',
          pointRadius: 2,
        },
      ]
      let chartL: DataType[] = [
        {
          label: '',
          data: [],
          backgroundColor: ['#68B3E0'],
        },
      ]
      let chartC: pieType[] = [
        {
          label: '',
          data: [0, 0, 0, 0, 0, 0, 0],
          backgroundColor: [
            'rgb(255, 99, 132)',
            'rgb(54, 162, 235)',
            'rgb(255, 205, 86)',
            'rgb(103,103,103)',
            'rgb(122,210,79)',
            'rgb(169,80,62)',
            'rgb(210,31,62)',
          ],
          hoverOffset: 4,
        },
      ]

      let chartQ: pieType[] = [
        {
          label: '',
          data: [0, 0, 0],
          backgroundColor: ['rgb(255, 99, 132)', 'rgb(54, 162, 235)', 'rgb(255, 205, 86)'],
          hoverOffset: 4,
        },
      ]

      props.fecal?.forEach((u) => {
        let d = new Date(u.date)

        if (!props.finishDate) return
        if (
          new Date(d.getFullYear(), d.getMonth(), d.getDate()) >= props.startDate &&
          new Date(d.getFullYear(), d.getMonth(), d.getDate()) <= props.finishDate
        ) {
          let date

          if (loggedUserService.get()?.language == 1) {
            date =
              new Date(u.date).getDate() +
              '/' +
              (new Date(u.date).getMonth() + 1) +
              '/' +
              new Date(u.date).getFullYear().toString().substring(2, 4)
          } else {
            date = new Date(u.date).toLocaleDateString()
          }

          axisT.push({ x: date, y: u.attempts })
          axisE.push({ x: date, y: u.effectiveness })
          axisL.push({ x: date, y: u.loss })

          switch (u.consistency) {
            case FecesConsistency.HardLump:
              chartC[0].data[0]++
              break
            case FecesConsistency.SausageShape:
              chartC[0].data[1]++
              break
            case FecesConsistency.CrackedSausage:
              chartC[0].data[2]++
              break
            case FecesConsistency.SlimSausage:
              chartC[0].data[3]++
              break
            case FecesConsistency.SoftDrops:
              chartC[0].data[4]++
              break
            case FecesConsistency.Thick:
              chartC[0].data[5]++
              break
            case FecesConsistency.Liquid:
              chartC[0].data[6]++
              break
            default:
              break
          }
          switch (u.quantity) {
            case FecesQuantity.Few:
              chartQ[0].data[0]++
              break
            case FecesQuantity.Normal:
              chartQ[0].data[1]++
              break
            case FecesQuantity.Many:
              chartQ[0].data[2]++
              break
            default:
              break
          }
          if (!labelXTmp.includes(dayjs(u.date).format('DD/MM/YYYY'))) {
            labelXTmp.push(dayjs(u.date).format('DD/MM/YYYY'))
          }
        }
      })

      chartT[0].data = axisT
      chartE[0].data = axisE
      chartL[0].data = axisL
      setTries(chartT)
      setEfficiency(chartE)
      setLosses(chartL)

      setConsistency(chartC)
      setQuantity(chartQ)
      setLabelX(labelXTmp)
      setIsLoading(!isLoading)
    }, 200)
  }, [isLoading])

  return (
    <>
      <Box className={style.chartsContainer}>
        {innerWidth > 900 ? (
          <>
            <Box display="flex" flex-direction="row">
              <Box display="flex" justifyContent="center" maxWidth="800px">
                <GenericChartFecal
                  title={t('diaryAttempts')}
                  type="line"
                  data={tries}
                  labelX={labelX ? labelX : ['']}
                  xAxisLabel={''}
                  yAxisLabel={''}
                  showLegend={false}
                  showTitle={true}
                  showTooltip={true}
                />
              </Box>
              <Box display="flex" justifyContent="center" maxWidth="800px">
                <GenericChartFecal
                  title={t('diaryEfficiency')}
                  type="line"
                  data={efficiency}
                  labelX={labelX ? labelX : ['']}
                  xAxisLabel={''}
                  yAxisLabel={''}
                  showLegend={false}
                  showTitle={true}
                  showTooltip={true}
                />
              </Box>
            </Box>
            <Box display="flex" flex-direction="row">
              <Box display="flex" justifyContent="center" maxWidth="800px">
                <GenericChartFecal
                  title={t('diaryLosses')}
                  type="bar"
                  data={losses}
                  labelX={labelX ? labelX : ['']}
                  xAxisLabel={''}
                  yAxisLabel={''}
                  showLegend={false}
                  showTitle={true}
                  showTooltip={true}
                />
              </Box>
              <Box display="flex" justifyContent="center" maxWidth="800px">
                <GenericPieChart
                  title={t('fecalConsistency')}
                  type="pie"
                  data={consistency}
                  labelX={consistencyLabel}
                  xAxisLabel={''}
                  yAxisLabel={''}
                  showLegend={true}
                  showTitle={true}
                  showTooltip={true}
                />
              </Box>
            </Box>
            <Box display="flex" flex-direction="row">
              <Box display="flex" justifyContent="center" maxWidth="800px">
                <GenericPieChart
                  title={t('fecalQuantity')}
                  type="pie"
                  data={quantity}
                  labelX={quantityLabel}
                  xAxisLabel={''}
                  yAxisLabel={''}
                  showLegend={true}
                  showTitle={true}
                  showTooltip={true}
                />
              </Box>
            </Box>
          </>
        ) : (
          <>
            <Box display="flex" justifyContent="center" maxWidth="700px">
              <GenericChartFecal
                title={t('diaryAttempts')}
                type="line"
                data={tries}
                labelX={labelX ? labelX : ['']}
                xAxisLabel={''}
                yAxisLabel={''}
                showLegend={false}
                showTitle={true}
                showTooltip={true}
              />
            </Box>
            <Box display="flex" justifyContent="center" maxWidth="700px">
              <GenericChartFecal
                title={t('diaryEfficiency')}
                type="line"
                data={efficiency}
                labelX={labelX ? labelX : ['']}
                xAxisLabel={''}
                yAxisLabel={''}
                showLegend={false}
                showTitle={true}
                showTooltip={true}
              />
            </Box>
            <Box display="flex" justifyContent="center" maxWidth="700px">
              <GenericChartFecal
                title={t('diaryLosses')}
                type="bar"
                data={losses}
                labelX={labelX ? labelX : ['']}
                xAxisLabel={''}
                yAxisLabel={''}
                showLegend={false}
                showTitle={true}
                showTooltip={true}
              />
            </Box>
            <Box display="flex" justifyContent="center" maxWidth="700px">
              <GenericPieChart
                title={t('fecalConsistency')}
                type="pie"
                data={consistency}
                labelX={consistencyLabel}
                xAxisLabel={''}
                yAxisLabel={''}
                showLegend={true}
                showTitle={true}
                showTooltip={true}
              />
            </Box>
            <Box display="flex" flex-direction="row">
              <Box display="flex" justifyContent="center" maxWidth="700px">
                <GenericPieChart
                  title={t('fecalQuantity')}
                  type="pie"
                  data={quantity}
                  labelX={quantityLabel}
                  xAxisLabel={''}
                  yAxisLabel={''}
                  showLegend={true}
                  showTitle={true}
                  showTooltip={true}
                />
              </Box>
            </Box>
          </>
        )}
      </Box>
    </>
  )
}
