import { PatientDiagnosticDTO } from './PatientDiagnosticDTO'

export class PatientDiagnostic {
  private readonly _id: string
  private _createdAt: Date
  private _diagnosticID: string
  private _patientID: string
  private _text: string

  constructor(p: PatientDiagnosticDTO) {
    this._id = p.id
    this._createdAt = p.createdAt
    this._diagnosticID = p.diagnosticID
    this._patientID = p.patientID
    this._text = p.text
  }

  get id(): string {
    return this._id
  }

  get text(): string {
    return this._text
  }

  get createdAt(): Date {
    return this._createdAt
  }

  get diagnosticID(): string {
    return this._diagnosticID
  }

  get patientID(): string {
    return this._patientID
  }
}

export interface PatientDiagnosticQuery {
  id: string
  createdAt: Date
  diagnosticID: string
  patientID: string
}
