import React from 'react'
import { RouteProps } from '../../routes/AppRouter'
import { CreateParticipant as C } from '../../features/users'
import { CurrentNavHeader } from '../../components/current-nav-header/CurrentNavHeader'

export function EditParticipant(props: RouteProps) {
  const title = props.title || ''

  return (
    <>
      <CurrentNavHeader title={title} />
      <C id={props.id} />
    </>
  )
}
