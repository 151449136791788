export enum Diagnosis {
  Normal = 1,
  Tension,
  Diabetes,
}

export const diagnosis = (): Record<Diagnosis, string> => ({
  [Diagnosis.Normal]: 'normal',
  [Diagnosis.Tension]: 'tension',
  [Diagnosis.Diabetes]: 'diabetes',
})
