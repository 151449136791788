import { emptyList, ItemList } from '../../../common/models/ItemList'
import { Observable, of } from 'rxjs'
import { Container, IInit } from '../../../common/container/Container'
import { HTTP_CLIENT_KEY, IHTTPClient } from '../../../common/api/HTTPClient'
import { IStatusService } from '../../../common/status/StatusService'
import { STATUS_SERVICE_KEY } from '../../../container/app'
import { Query } from '../../../common/api/Query'
import { PatientContainerConfig } from '../container'
import { catchError, map } from 'rxjs/operators'
import { Intervention, InterventionQuery } from '../models/Intervention'
import { InterventionDTO, toModel } from '../models/InterventionDTO'

export interface IInterventionApi extends IInit {
  getByID(id: string | undefined): Observable<Intervention>

  getFilteredList(q: Query<InterventionQuery>): Observable<ItemList<Intervention>>

  add(e: InterventionDTO): Observable<Intervention | undefined>

  update(e: InterventionDTO): Observable<Intervention | undefined>

  delete(id: string | undefined): Observable<boolean>

  getByName(name: string | undefined): Observable<Intervention>

  getPatientIntervention(patientID: string): Observable<Intervention | undefined>
}

export class InterventionApi implements IInterventionApi {
  private _container!: Container
  private _httpClient!: IHTTPClient
  private _url!: string
  private _statusService!: IStatusService

  init(c: Container) {
    this._container = c
    this._httpClient = this._container.get<IHTTPClient>(HTTP_CLIENT_KEY)
    this._statusService = this._container.get<IStatusService>(STATUS_SERVICE_KEY)
    this._url = (this._container.config as PatientContainerConfig).moduleFullUrl
  }

  add(e: InterventionDTO): Observable<Intervention | undefined> {
    return this._httpClient
      .post<Intervention>({ url: this._url, body: e })
      .pipe(
        map<InterventionDTO, Intervention>((d) => toModel(d)),
        catchError((err) => {
          this._statusService.sendStatus({ variant: 'error', error: err })
          return of(undefined)
        })
      )
  }

  delete(id: string | undefined): Observable<boolean> {
    return this._httpClient.delete({ url: this._url + '/' + id }).pipe(
      catchError((err) => {
        this._statusService.sendStatus({ variant: 'error', error: err })
        return of(false)
      })
    )
  }

  getByID(id: string | undefined): Observable<Intervention> {
    return this._httpClient
      .get<Intervention>({ url: `${this._url}/intervention/${id}` })
      .pipe(
        map<InterventionDTO, Intervention>((d) => toModel(d))
      )
  }

  getFilteredList(q: Query<InterventionQuery>): Observable<ItemList<Intervention>> {
    return this._httpClient
      .post<ItemList<Intervention>>({ url: this._url + '/interventionsList', body: q })
      .pipe(
        map<ItemList<InterventionDTO>, ItemList<Intervention>>((dto) => {
          const itemList = emptyList<Intervention>()
          itemList.count = dto.count
          itemList.items = dto.items.map((d) => toModel(d))
          return itemList
        }),
        catchError((err) => {
          this._statusService.sendStatus({ variant: 'error', error: err })
          return of(emptyList<Intervention>())
        })
      )
  }

  update(e: InterventionDTO): Observable<Intervention | undefined> {
    return this._httpClient
      .put<Intervention>({ url: this._url, body: e })
      .pipe(
        map<InterventionDTO, Intervention>((d) => toModel(d)),
        catchError((err) => {
          this._statusService.sendStatus({ variant: 'error', error: err })
          return of(undefined)
        })
      )
  }

  getByName(name: string | undefined): Observable<Intervention> {
    return this._httpClient
      .post<Intervention>({ url: `${this._url}/intervention/${name}` })
      .pipe(
        map<InterventionDTO, Intervention>((d) => toModel(d))
      )
  }

  getPatientIntervention(patientID: string): Observable<Intervention | undefined> {
    return this._httpClient
      .get<Intervention>({ url: `${this._url}/interventionbypatient/${patientID}` })
      .pipe(
        map<InterventionDTO, Intervention>((d) => toModel(d)),
        catchError((err) => {
          this._statusService.sendStatus({ variant: 'error', error: err })
          return of(undefined)
        })
      )
  }
}
