import { Container, IInit } from '../../../common/container/Container'
import { PatientContainerConfig } from '../container'
import { Observable, of } from 'rxjs'
import { catchError, map } from 'rxjs/operators'
import { Query } from '../../../common/api/Query'
import { HTTP_CLIENT_KEY, IHTTPClient } from '../../../common/api/HTTPClient'
import { IStatusService } from '../../../common/status/StatusService'
import { STATUS_SERVICE_KEY } from '../../../container/app'
import { emptyList, ItemList } from 'common/models/ItemList'
import { PatientProblem } from '../models/PatientProblem'
import { PatientProblemDTO, toModel } from '../models/PatientProblemDTO'

export interface IPatientProblemApi extends IInit {
  getByID(id: string | null): Observable<PatientProblem | undefined>

  getFilteredItems(q: Query<PatientProblem>): Observable<ItemList<PatientProblem>>

  add(e: PatientProblemDTO): Observable<PatientProblem | undefined>

  update(e: PatientProblemDTO): Observable<PatientProblem | undefined>

  delete(id: string): Observable<boolean>
}

export class PatientProblemApi implements IPatientProblemApi {
  private _container!: Container
  private _httpClient!: IHTTPClient
  private _url!: string
  private _statusService!: IStatusService

  init(c: Container) {
    this._container = c
    this._httpClient = this._container.get<IHTTPClient>(HTTP_CLIENT_KEY)
    this._statusService = this._container.get<IStatusService>(STATUS_SERVICE_KEY)
    this._url = (this._container.config as PatientContainerConfig).moduleFullUrl
  }

  getByID(id: string): Observable<PatientProblem | undefined> {
    return this._httpClient
      .get<PatientProblem>({ url: this._url + '/' + id })
      .pipe(
        map<PatientProblemDTO, PatientProblem>((d) => toModel(d)),
        catchError((err) => {
          this._statusService.sendStatus({ variant: 'error', error: err })
          return of(undefined)
        })
      )
  }

  getFilteredItems(q: Query<PatientProblem>): Observable<ItemList<PatientProblem>> {
    return this._httpClient
      .post<ItemList<PatientProblem>>({ url: this._url + '/patientproblem/paginatedList', body: q })
      .pipe(
        map<ItemList<PatientProblemDTO>, ItemList<PatientProblem>>((dto) => {
          const itemList = emptyList<PatientProblem>()
          itemList.items = dto.items.map((d) => toModel(d))
          itemList.count = dto.count
          return itemList
        }),
        catchError((err) => {
          this._statusService.sendStatus({ variant: 'error', error: err })
          return of(emptyList<PatientProblem>())
        })
      )
  }

  add(e: PatientProblemDTO): Observable<PatientProblem | undefined> {
    return this._httpClient
      .post<PatientProblem>({ url: this._url + '/patientproblem', body: e })
      .pipe(
        map<PatientProblemDTO, PatientProblem>((d) => {
          this._statusService.sendStatus({ variant: 'success' })
          return toModel(d)
        }),
        catchError((err) => {
          this._statusService.sendStatus({ variant: 'error', error: err })
          return of(undefined)
        })
      )
  }

  update(e: PatientProblemDTO): Observable<PatientProblem | undefined> {
    return this._httpClient
      .put<PatientProblem>({ url: this._url + '/patientproblem', body: e })
      .pipe(
        map<PatientProblemDTO, PatientProblem>((d) => {
          this._statusService.sendStatus({ variant: 'success' })
          return toModel(d)
        }),
        catchError((err) => {
          this._statusService.sendStatus({ variant: 'error', error: err })
          return of(undefined)
        })
      )
  }

  delete(id: string): Observable<boolean> {
    return this._httpClient.delete({ url: this._url + '/patientproblem/' + id }).pipe(
      catchError((err) => {
        this._statusService.sendStatus({ variant: 'error', error: err })
        return of(false)
      })
    )
  }
}
