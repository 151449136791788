import { Container, ContainerItem } from '../common/container/Container'
import { getAppContainer } from './app'
import { moduleURLs } from '../config/api'
import {
  ContentProps,
  URINATION_API_KEY,
  URINATION_MODULE,
  URINATION_SERVICE_KEY,
} from '../modules/urination/container'
import { UrinationService } from '../modules/urination/services/UrinationService'
import { UrinationApi } from '../modules/urination/api/UrinationApi'

let container: Container

function init(p: ContentProps) {
  const items = new Map<symbol, ContainerItem>()

  items.set(URINATION_API_KEY, new ContainerItem({ instance: new UrinationApi() }))

  items.set(
    URINATION_SERVICE_KEY,
    new ContainerItem({ instance: new UrinationService({ apiKey: URINATION_API_KEY }) })
  )

  container = new Container(items, p.parentContainer, p.config)
}

export function getUrinationContainer(): Container {
  if (!container) {
    init({
      parentContainer: getAppContainer(),
      config: {
        moduleFullUrl: moduleURLs[URINATION_MODULE],
      },
    })
  }

  return container
}
