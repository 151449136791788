import { Observable } from 'rxjs'
import { Container, IInit } from '../../../common/container/Container'
import { Query } from '../../../common/api/Query'
import { IStatusService } from '../../../common/status/StatusService'
import { STATUS_SERVICE_KEY } from '../../../container/app'
import { ItemList } from '../../../common/models/ItemList'
import { PatientLesson } from '../models/PatientLesson'
import { IPatientLessonApi } from '../api/PatientLessonApi'
import { PatientLessonDTO } from '../models/PatientLessonDTO'
import { Lesson } from '../models/Lesson'
import { Educational } from '../models/Educational'

type Props = {
  apiKey: symbol
}

export interface IPatientLessonService extends IInit {
  getByID(id: string | null): Observable<PatientLesson | undefined>

  getFilteredItems(q: Query<PatientLesson>): Observable<ItemList<PatientLesson>>

  add(e: PatientLessonDTO): Observable<PatientLesson | undefined>

  update(e: PatientLessonDTO): Observable<PatientLesson | undefined>

  delete(id: string): Observable<boolean>

  getLessonsByIDPatient(id: string): Observable<ItemList<Lesson>>

  getLessonsAndModulesAssignedByIDPatient(id: string): Observable<ItemList<Educational>>

  getLessonsAndModulesAvailableByIDPatient(id: string): Observable<ItemList<Educational>>

  deleteByPatientAndLessonID(patientID: string, lessonID: string): Observable<boolean>

  getPatientLessonsByPatientID(patientID: string): Observable<ItemList<PatientLesson>>

  getLessonByID(lessonID: string): Observable<Lesson | undefined>
}

export class PatientLessonService implements IPatientLessonApi {
  private readonly _apiKey: symbol
  private _container!: Container
  private _api!: IPatientLessonApi
  private _statusService!: IStatusService

  constructor(p: Props) {
    this._apiKey = p.apiKey
  }

  init(c: Container) {
    this._container = c
    this._api = this._container.get<IPatientLessonApi>(this._apiKey)
    this._statusService = this._container.get<IStatusService>(STATUS_SERVICE_KEY)
  }

  getByID(id: string | null): Observable<PatientLesson | undefined> {
    return this._api.getByID(id)
  }

  getFilteredItems(q: Query<PatientLesson>): Observable<ItemList<PatientLesson>> {
    return this._api.getFilteredItems(q).pipe()
  }

  add(e: PatientLessonDTO): Observable<PatientLesson | undefined> {
    return this._api.add(e)
  }

  update(e: PatientLessonDTO): Observable<PatientLesson | undefined> {
    return this._api.update(e)
  }

  delete(id: string): Observable<boolean> {
    return this._api.delete(id)
  }
  getLessonsByIDPatient(id: string): Observable<ItemList<Lesson>> {
    return this._api.getLessonsByIDPatient(id)
  }

  getLessonsAndModulesAssignedByIDPatient(id: string): Observable<ItemList<Educational>> {
    return this._api.getLessonsAndModulesAssignedByIDPatient(id)
  }
  getLessonsAndModulesAvailableByIDPatient(id: string): Observable<ItemList<Educational>> {
    return this._api.getLessonsAndModulesAvailableByIDPatient(id)
  }

  deleteByPatientAndLessonID(patientID: string, lessonID: string): Observable<boolean> {
    return this._api.deleteByPatientAndLessonID(patientID, lessonID)
  }

  getPatientLessonsByPatientID(patientID: string): Observable<ItemList<PatientLesson>> {
    return this._api.getPatientLessonsByPatientID(patientID)
  }

  getLessonByID(lessonID: string): Observable<Lesson | undefined> {
    return this._api.getLessonByID(lessonID);
  }
}
