import generic from '../../../../assets/generic.module.css'
import { Box } from '@mui/material'
import React from 'react'

export const CVS460 = () => {
  return (
    <>
      <Box className={generic.pageContainer} style={{ backgroundColor: 'white' }}>
        <Box className={generic.pageContainer} display="flex">
          <h2>
            <b>4.6 Alternativas al cateterismo intermitente</b>
          </h2>
        </Box>
        <Box className={generic.pageContainer}>
          <div>
            Las alternativas al cateterismo intermitente son el cateterismo permanente suprapúbico y
            uretral. Sin embargo, una sonda permanente es un procedimiento invasivo; se coloca a
            través de la pared abdominal (suprapúbica) o uretral y el vaciado es continuo. Las ITU
            asociadas a la sonda son la complicación más habitual de todos los cateterismos. Se ha
            observado que el CI reduce el riesgo de infección en comparación con las sondas
            permanentes: por ejemplo, una reducción del 20 % después de un breve uso postoperatorio.
            <br />
            <br />
            Las bacterias se adhieren a las superficies de la sonda, donde forman un biofilm, Esto
            dificulta el tratamiento, ya que los antibióticos no pueden penetrar este biofilm. A
            menudo, las fugas son una consecuencia de la irritación de la vejiga causada por la
            sonda, lo que puede provocar fuertes contracciones de la vejiga.
            <br />
            <br />
          </div>
        </Box>
      </Box>
    </>
  )
}
