import { Patient, PatientQuery } from '../models/Patient'
import { Observable } from 'rxjs'
import { Query } from '../../../common/api/Query'
import { ItemList } from '../../../common/models/ItemList'
import { Container, IInit } from '../../../common/container/Container'
import { IStatusService } from '../../../common/status/StatusService'
import { IPatientApi } from '../api/PatientApi'
import { STATUS_SERVICE_KEY } from '../../../container/app'
import { PatientDTO } from '../models/PatientDTO'

type Props = {
  apiKey: symbol
}

export interface IPatientService extends IInit {
  getByID(id: string): Observable<Patient>
  getFilteredList(q: Query<PatientQuery>): Observable<ItemList<Patient>>
  add(e: PatientDTO): Observable<Patient | undefined>
  update(e: PatientDTO): Observable<Patient | undefined>

  delete(id: string | undefined): Observable<boolean>
}

export class PatientService implements IPatientService {
  private readonly _apiKey: symbol
  private _container!: Container
  private _api!: IPatientApi
  private _statusService!: IStatusService

  constructor(p: Props) {
    this._apiKey = p.apiKey
  }

  init(c: Container) {
    this._container = c
    this._api = this._container.get<IPatientApi>(this._apiKey)
    this._statusService = this._container.get<IStatusService>(STATUS_SERVICE_KEY)
  }

  getByID(id: string | undefined): Observable<Patient> {
    return this._api.getByID(id)
  }

  getFilteredList(q: Query<PatientQuery>): Observable<ItemList<Patient>> {
    return this._api.getFilteredList(q).pipe()
  }

  add(e: PatientDTO): Observable<Patient | undefined> {
    return this._api.add(e)
  }

  delete(id: string | undefined): Observable<boolean> {
    return this._api.delete(id)
  }

  update(e: PatientDTO): Observable<Patient | undefined> {
    return this._api.update(e)
  }
}
