import generic from '../../../../assets/generic.module.css'
import { Box } from '@mui/material'
import React from 'react'

export const CVS440 = () => {
  return (
    <>
      <Box className={generic.pageContainer} style={{ backgroundColor: 'white' }}>
        <Box className={generic.pageContainer} display="flex">
          <h2>
            <b>4.4 Ténica de cateterismo</b>
          </h2>
        </Box>
        <Box className={generic.pageContainer}>
          <div>
            La forma de realizar el CI dependerá de la necesidad individual y del periodo para el
            que esté indicado el cateterismo. Las diferentes técnicas son las siguientes:
            <br />
            <br />
            &nbsp;&nbsp;&nbsp;&nbsp;•&nbsp;&nbsp;&nbsp;&nbsp; <b>Técnica estéril:</b>cateterismo en
            condiciones de quirófano
            <br />
            <br />
            &nbsp;&nbsp;&nbsp;&nbsp;•&nbsp;&nbsp;&nbsp;&nbsp; <b>Técnica aséptica:</b>cateterismo
            con sonda estéril, desinfección/limpieza de los genitales, guantes estériles, pinzas (si
            procede) y lubricante estéril (si procede)
            <br />
            <br />
            &nbsp;&nbsp;&nbsp;&nbsp;•&nbsp;&nbsp;&nbsp;&nbsp; <b>Técnica limpia:</b>cateterismo con
            una técnica 'no estéril' por parte de pacientes/cuidadores en el hogar
            <br />
            <br />
          </div>
        </Box>
      </Box>
    </>
  )
}
