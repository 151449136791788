import { Treatment } from './Treatment'

export interface TreatmentDTO {
  id: string
  name: string
  trademark: string
  brand: string
}

export function emptyTreatmentDTO(): TreatmentDTO {
  return {
    id: '',
    name: '',
    trademark: '',
    brand: ''
  }
}

export function toModel(p: TreatmentDTO): Treatment {
  return new Treatment(p)
}

export function fromModel(p: Treatment): TreatmentDTO {
  return {
    id: p.id,
    name: p.name,
    trademark: p.trademark,
    brand: p.brand
  }
}
