import generic from '../../../../assets/generic.module.css'
import { Box, Button, Divider, Modal } from '@mui/material'
import React, { useState } from 'react'
import image from './mod-2-2-3b.png'
import image1 from './vejigaFemenina.png'
import ureter from './ureter.png'
import { useTranslation } from 'react-i18next'

export const CVS230 = () => {
  const { t } = useTranslation()
  const [openModal, setOpenModal] = useState<boolean>(false)
  const [openModal1, setOpenModal1] = useState<boolean>(false)
  const { innerWidth } = window
  const handleOpenModal = () => setOpenModal(true)
  const handleCloseModal = () => setOpenModal(false)
  const handleOpenModal1 = () => setOpenModal1(true)
  const handleCloseModal1 = () => setOpenModal1(false)

  return (
    <>
      <Box className={generic.pageContainer} style={{ backgroundColor: 'white' }}>
        <Box className={generic.pageContainer}>
          <h2>
            <b>2.3 Función de la vejiga y ciclo miccional</b>
          </h2>
        </Box>
        <Box>
          <h1 style={{ color: 'dodgerblue' }}>
            <b>Función de la vejiga urinaria</b>
          </h1>
        </Box>
        <Box className={generic.pageContainer} style={{ display: 'flex' }}>
          <Box style={{ marginRight: '8%' }}>
            <div>
              La vejiga es un órgano muscular hueco en la pelvis, justo encima y detrás del hueso
              púbico. La vejiga tiene dos funciones principales que son el almacenamiento y el
              vaciado de orina.
              <br />
              <br />
              Aporta continencia, al retrasar y controlar la micción de modo que, de media, una
              persona orina de cuatro a ocho veces al día con un volumen de la vejiga de 200 a 400
              ml (aprox.)
              <br />
              <br />
              La orina se produce en los riñones y viaja por dos conductos llamados uréteres hasta
              la parte posterior de la vejiga.
              <br />
              <br />
              La vejiga es un órgano elástico y puede aumentar su volumen en gran medida para
              albergar entre 600 y 800 ml de orina.
              <br />
              <br />
              La micción es el proceso de excreción de orina de la vejiga.
              <br />
              <br />
              El sistema nervioso central controla la función de la vejiga. Los centros nerviosos
              del control de la micción se encuentran en la médula espinal, el tronco encefálico y
              la corteza cerebral.
              <br />
              <br />
              El control de la micción depende del comportamiento aprendido durante la maduración
              del sistema nervioso.
              <br />
              <br />
              La pared de la vejiga está formada por el músculo detrusor, que es un músculo liso, y
              varias capas de mucosa epitelial.
              <br />
              <br />
              Durante la micción, el músculo detrusor se contrae y los esfínteres uretrales
              (válvulas) se relajan para permitir que la orina fluya hacia fuera.
              <br />
              <br />
              <b>
                A = Vejiga urinaria&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; B = Abertura de
                uréteres&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; C = Uretra
              </b>
              <br />
              <br />
              La orina sale de la vejiga por la uretra, que transporta la orina fuera del cuerpo. En
              las mujeres, la uretra es bastante corta, mientras que en los hombres, la uretra es
              más larga y está dividida en 3 segmentos (la uretra prostática, la uretra membranosa y
              la uretra peneana).
              <br />
              <br />
            </div>
          </Box>
          <Box>
            <img
              src={image}
              onClick={handleOpenModal}
              style={{
                marginLeft: '1%',
                justifyContent: 'center',
                display: 'flex',
                borderStyle: 'ridge',
                borderColor: 'dodgerblue',
                width: '300px',
              }}
            />
            <img
              src={ureter}
              onClick={handleOpenModal1}
              style={{
                marginLeft: '1%',
                justifyContent: 'center',
                display: 'flex',
                borderStyle: 'ridge',
                borderColor: 'dodgerblue',
                width: '300px',
                marginTop: innerWidth < 900 ? '25%' : '10%',
              }}
            />
          </Box>
        </Box>
        <Modal open={openModal} onClose={handleCloseModal}>
          <Box
            style={{
              position: 'absolute',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
              backgroundColor: 'white',
              padding: '10px',
            }}>
            <h1 style={{ marginLeft: '25%' }}>
              <b>Función de la vejiga</b>
            </h1>
            <Divider variant={'middle'} />
            <img
              src={image}
              style={{
                width: '70%',
                marginTop: '1%',
                marginLeft: '20%',
                justifyContent: 'center',
                display: 'flex',
              }}
            />
            <Button
              variant={'outlined'}
              style={{ marginLeft: '45%', marginTop: '1%', justifyContent: 'end', display: 'flex' }}
              onClick={handleCloseModal}>
              {t('close')}
            </Button>
          </Box>
        </Modal>
        <Modal open={openModal1} onClose={handleCloseModal1}>
          <Box
            style={{
              position: 'absolute',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
              backgroundColor: 'white',
              padding: '10px',
            }}>
            <h1 style={{ marginLeft: '25%' }}>
              <b>Función de la vejiga</b>
            </h1>
            <Divider variant={'middle'} />
            <img
              src={ureter}
              style={{
                width: '70%',
                marginTop: '1%',
                marginLeft: '20%',
                justifyContent: 'center',
                display: 'flex',
              }}
            />
            <Button
              variant={'outlined'}
              style={{ marginLeft: '45%', marginTop: '1%', justifyContent: 'end', display: 'flex' }}
              onClick={handleCloseModal1}>
              {t('close')}
            </Button>
          </Box>
        </Modal>
      </Box>
    </>
  )
}
