import { RouteProps } from '../../../routes/AppRouter'
import { CurrentNavHeader } from '../../../components/current-nav-header/CurrentNavHeader'
import React from 'react'
import {
  CIS110,
  CIS111,
  CIS112,
  CIS113,
  CIS210,
  CIS211,
  CIS212,
  CIS220,
  CIS221,
  CIS230,
  CIS231,
  CIS232,
  CIS240,
  CIS310,
  CIS320,
  CIS330,
  CIS331,
} from '../../../features/professional-educational'
import { useTheme } from '@mui/material/styles'
import MobileStepper from '@mui/material/MobileStepper'
import Button from '@mui/material/Button'
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft'
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight'
import { Box } from '@mui/material'
import generic from '../../../assets/generic.module.css'
import { useTranslation } from 'react-i18next'
import { AppButton, ButtonTheme } from 'components/app-button/AppButton'
import { navigate } from '@reach/router'
import { ROUTE_EDUCATIONALPROFESSIONAL } from 'routes/routes-constants'
import { ROUTE_EDUCATIONALINTESTINAL21 } from '../../../routes/routes-constants'
import style from '../EducationalProfessional.module.css'

export function EducationalsProfessionalIntestinal11(props: RouteProps) {
  const { t } = useTranslation()
  const theme = useTheme()
  const [activeStep, setActiveStep] = React.useState<number>(0)

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1)
  }

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1)
  }

  return (
    <>
      <CurrentNavHeader title={'INTESTINAL - Módulo 1. Introducción'} strong={true} />
      <Box className={style.buttonContainer}>
        <AppButton
          theme={ButtonTheme.NewPrimary}
          type={'button'}
          label={t('returnContentEducational')}
          handler={() => navigate(ROUTE_EDUCATIONALPROFESSIONAL)}
        />
      </Box>
      <Box
        className={generic.pageContainer}
        style={{ backgroundColor: 'white', marginTop: '1%', marginRight: '1%' }}>
        <Box
          className={generic.pageContainer}
          style={{ backgroundColor: 'white', marginTop: '1%', marginRight: '1%' }}>
          {activeStep === 0 && <CIS110 />}
          {activeStep === 1 && <CIS111 />}
          {activeStep === 2 && <CIS112 />}
          {activeStep === 3 && <CIS113 />}
          <Box style={{ display: 'flex', justifyContent: 'center' }}>
            <MobileStepper
              style={{ display: 'flex' }}
              variant="dots"
              steps={4}
              position="static"
              activeStep={activeStep}
              sx={{ maxWidth: 400, flexGrow: 1 }}
              nextButton={
                <Button size="small" onClick={handleNext} disabled={activeStep === 3}>
                  {t('next')}
                  {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
                </Button>
              }
              backButton={
                <Button size="small" onClick={handleBack} disabled={activeStep === 0}>
                  {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
                  {t('back')}
                </Button>
              }
            />
          </Box>
          {activeStep === 3 && (
            <Box className={style.buttonContainerBottom}>
              <AppButton
                theme={ButtonTheme.NewPrimary}
                type={'button'}
                label={t('nextContentEducational')}
                handler={() => navigate(ROUTE_EDUCATIONALINTESTINAL21)}
              />
            </Box>
          )}
        </Box>
      </Box>
    </>
  )
}
