import generic from '../../../../assets/generic.module.css'
import { Box } from '@mui/material'
import image from './intestino.svg'
import React from 'react'

export const CIS231 = () => {
  return (
    <>
      <Box className={generic.pageContainer} style={{ backgroundColor: 'white', display: 'flex' }}>
        <Box style={{ marginRight: '5%', marginLeft: '1%' }}>
          <Box className={generic.pageContainer} display="flex">
            <h2>
              <b>2.3 Estreñimiento</b>
            </h2>
          </Box>
          <Box className={generic.pageContainer}>
            <div>
              La evaluación, el diagnóstico y el tratamiento del estreñimiento deben adaptarse a
              cada persona.
            </div>
          </Box>
          <Box className={generic.pageContainer} display="flex">
            <h4 style={{ color: 'dodgerblue' }}>
              <b>Tratamiento del estreñimiento</b>
            </h4>
          </Box>

          <Box className={generic.pageContainer} display="flex">
            <div>
              Los tratamientos deben ofrecerse en función de la pirámide del manejo intestinal.
              <br />
              <br />
            </div>
          </Box>

          <Box className={generic.pageContainer} display="flex">
            <h4 style={{ color: 'dodgerblue' }}>
              <b>Modificaciones en el estilo de vida</b>
            </h4>
          </Box>
          <Box className={generic.pageContainer} display="flex">
            <div>
              Para lograr un tratamiento eficaz y cumplir no solo el tratamiento con medicamentos
              sino también los cambios en la dieta y el estilo de vida, se necesita comprensión y
              aceptación de la terapia.
              <br />
              <br />
            </div>
          </Box>
          <Box className={generic.pageContainer} display="flex">
            <h4 style={{ color: 'dodgerblue' }}>
              <b>Tratamientos farmacológicos</b>
            </h4>
          </Box>
          <Box className={generic.pageContainer} display="flex">
            <div>
              Todas las medicaciones, ya sean preparados orales como supositorios rectales, solo
              deben prescribirse para tratar los hallazgos tras la evaluación. Es fundamental
              diferenciar entre laxantes emolientes, laxantes estimulantes, laxantes formadores de
              masa y laxantes osmóticos. Se calcula que las tasas de respuesta con el tratamiento
              con laxantes se encuentra entre el 20 y el 40 % en ensayos clínicos aleatorizados y el
              cumplimiento terapéutico con los laxantes es deficiente.
              <br />
              <br />
              Se ha demostrado que el <b>tratamiento con biofeedback</b>es más eficaz que los
              laxantes para el estreñimiento en pacientes con disinergia pélvica.
              <br />
              <br />
              Las vías de tratamiento deben comenzar con tratamientos conservadores, tal y como se
              ha descrito anteriormente. Sin embargo, los pacientes con ETL no responden a los
              tratamientos médicos. (Hay algunos estudios que indican que los tratamientos no
              farmacológicos como el biofeedback, la ITA y la acupuntura pueden ser efectivos). El
              diagnóstico del ETL a menudo no se determina hasta que se ha demostrado que estos
              pasos no son eficaces. En aquellos pacientes que no responden a los tratamientos
              conservadores, existe una variedad de enfoques quirúrgicos para el ETL.
              <br />
              <br />
            </div>
          </Box>
          <Box className={generic.pageContainer} display="flex">
            <h4 style={{ color: 'dodgerblue' }}>
              <b>Irrigación transanal</b>
            </h4>
          </Box>
          <Box className={generic.pageContainer} display="flex">
            <div>
              La irrigación transanal es un tratamiento óptimo para el manejo del estreñimiento
              primario y secundario. Se ha demostrado que este tratamiento proporciona evacuaciones
              intestinales regulares y predecibles, mejora la calidad de vida y permite a la persona
              gestionar su propio régimen intestinal.
              <br />
              <br />
              También se ha demostrado que la irrigación transanal tiene éxito en algunos pacientes
              con ETL, aunque podría existir el riesgo de que la pared del intestino grueso sea
              menos propensa a responder a los estímulos de la irrigación en casos de estreñimiento
              grave y crónico.
              <br />
              <br />
            </div>
          </Box>
        </Box>
      </Box>
    </>
  )
}
