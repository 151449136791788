import { EducationalUser } from '../models/EducationalUser'
import { Container, IInit } from '../../../common/container/Container'
import { EducationalContainerConfig } from '../container'
import { Observable, of } from 'rxjs'
import { prepareURL } from '../../../common/api/http-helpers'
import { catchError, map } from 'rxjs/operators'
import { Query } from '../../../common/api/Query'
import { HTTP_CLIENT_KEY, IHTTPClient } from '../../../common/api/HTTPClient'
import { IStatusService } from '../../../common/status/StatusService'
import { EducationalUserDTO, toModel } from '../models/EducationalUserDTO'
import { STATUS_SERVICE_KEY } from '../../../container/app'
import {emptyList, ItemList} from "../../../common/models/ItemList";

export interface IEducationalUserApi extends IInit {
  getByID(id: string | null): Observable<EducationalUser>

  getFilteredItems(q: Query<EducationalUser>): Observable<ItemList<EducationalUser>>

  add(e: EducationalUserDTO): Observable<EducationalUser | undefined>

  update(e: EducationalUserDTO): Observable<EducationalUser | undefined>

  delete(id: string): Observable<boolean>
}

export class EducationalUserApi implements IEducationalUserApi {
  private _container!: Container
  private _httpClient!: IHTTPClient
  private _url!: string
  private _statusService!: IStatusService

  init(c: Container) {
    this._container = c
    this._httpClient = this._container.get<IHTTPClient>(HTTP_CLIENT_KEY)
    this._statusService = this._container.get<IStatusService>(STATUS_SERVICE_KEY)
    this._url = (this._container.config as EducationalContainerConfig).moduleFullUrl
  }

  getByID(id: string | null): Observable<EducationalUser> {
    return this._httpClient
      .get<EducationalUser>({ url: this._url + '/' + id })
      .pipe(
        map<EducationalUserDTO, EducationalUser>((d) => toModel(d))
      )
  }

  getFilteredItems(q: Query<EducationalUser>): Observable<ItemList<EducationalUser>> {
    return this._httpClient
      .get<ItemList<EducationalUser>>({ url: prepareURL(`${this._url}/educationalUser/list`, q) })
      .pipe(
        map<ItemList<EducationalUserDTO>, ItemList<EducationalUser>>((dto) => {
          const itemList = emptyList<EducationalUser>()
          itemList.items = dto.items.map((d) => toModel(d))
          itemList.count = dto.count
          return itemList
        }),
        catchError((err) => {
          this._statusService.sendStatus({ variant: 'error', error: err })
          return of(emptyList<EducationalUser>())
        })
      )
  }

  add(e: EducationalUserDTO): Observable<EducationalUser | undefined> {
    return this._httpClient
      .post<EducationalUser>({ url: `${this._url}/educationalUser`, body: e })
      .pipe(
        map<EducationalUserDTO, EducationalUser>((d) => {
          this._statusService.sendStatus({ variant: 'success' })
          return toModel(d)
        }),
        catchError((err) => {
          this._statusService.sendStatus({ variant: 'error', error: err })
          return of(undefined)
        })
      )
  }

  update(e: EducationalUserDTO): Observable<EducationalUser | undefined> {
    return this._httpClient
      .put<EducationalUser>({ url: this._url, body: e })
      .pipe(
        map<EducationalUserDTO, EducationalUser>((d) => {
          this._statusService.sendStatus({ variant: 'success' })
          return toModel(d)
        }),
        catchError((err) => {
          this._statusService.sendStatus({ variant: 'error', error: err })
          return of(undefined)
        })
      )
  }

  delete(id: string): Observable<boolean> {
    return this._httpClient.delete({ url: this._url + '/' + id }).pipe(
      catchError((err) => {
        this._statusService.sendStatus({ variant: 'error', error: err })
        return of(false)
      })
    )
  }
}
