import { RouteProps } from "../../routes/AppRouter";
import { CurrentNavHeader } from "../../components/current-nav-header/CurrentNavHeader";
import { Goals as G } from "../../features/goals/Goals";
import React from "react";

export function Goals(props: RouteProps) {
  const title = props.title || "";

  return (
    <>
      <CurrentNavHeader title={title} />
      <G />
    </>
  );
}
