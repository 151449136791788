import { ArticleDTO } from './ArticleDTO'
import { ArticleType } from '../enums/ArticleType'
import { LangType } from '../enums/LangType'

export class Article {
  private readonly _id: string | undefined
  private readonly _createdAt: Date
  private readonly _creatorID: string | undefined
  private readonly _type: ArticleType
  private readonly _language: LangType

  private _ask: string
  private _answer: string
  private _order: number
  private _updatedAt: Date

  constructor(p: ArticleDTO) {
    this._id = p.id
    this._ask = p.ask
    this._answer = p.answer
    this._createdAt = p.createdAt
    this._updatedAt = p.updatedAt
    this._creatorID = p.creatorID
    this._type = p.type
    this._language = p.language
    this._order = p.order
  }

  get id(): string | undefined {
    return this._id
  }

  get createdAt(): Date {
    return this._createdAt
  }

  get creatorID(): string | undefined {
    return this._creatorID
  }

  get ask(): string {
    return this._ask
  }

  get answer(): string {
    return this._answer
  }

  get updatedAt(): Date {
    return this._updatedAt
  }

  set ask(value: string) {
    this._ask = value
  }

  set answer(value: string) {
    this._answer = value
  }

  set updatedAt(value: Date) {
    this._updatedAt = value
  }

  set order(value: number) {
    this._order = value
  }

  get type(): ArticleType {
    return this._type
  }

  get language(): LangType {
    return this._language
  }

  get order(): number {
    return this._order
  }
}

export interface ArticleQuery {
  type: ArticleType
  language: LangType
  creatorID: string | undefined
  order: number
}
