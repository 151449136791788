import { Problem } from './Problem'

export interface ProblemDTO {
  id: string
  name: string
}

export function emptyProblemDTO(): ProblemDTO {
  return {
    id: '',
    name: '',
  }
}

export function toModel(p: ProblemDTO): Problem {
  return new Problem(p)
}

export function fromModel(p: Problem): ProblemDTO {
  return {
    id: p.id,
    name: p.name,
  }
}
