import { Button, makeStyles } from '@material-ui/core'
import styles from './AppButton.module.css'
import React from 'react'
import { capitalize } from '../../common/utils/strings'

export enum ButtonTheme {
  NewPrimary,
  NewPrimaryResponsive,
  NewSecondary,
  BasicPrimary,
  BasicSecondary,
  BasicTrasparent,
  NewPrimaryLight,
  BasicTransparentBlue,
  NewPrimaryLightWithoutWidth,
}

const useStyles = makeStyles({
  label: {
    flexDirection: 'column',
    padding: '5px',
    width: '90%',
  },
  startIcon: {
    marginLeft: '0',
    marginRight: '0',
  },
})

type AppButtonProps = {
  theme: ButtonTheme
  label?: string
  labelOptions?: { count: number }
  handler: (e?: any) => void
  startIcon?: string
  endIcon?: string
  span?: boolean
  type: 'button' | 'submit' | 'reset'
  vertical?: boolean
  fullWidth?: boolean
}

export function AppButton(props: AppButtonProps) {
  const classes = useStyles()

  return (
    <Button
      className={getClassName(props.theme)}
      color={getColor(props.theme)}
      variant="contained"
      size="large"
      fullWidth={props.fullWidth}
      disableElevation={true}
      type={props.type}
      component={props.span ? 'span' : 'button'}
      onClick={props.handler}
      endIcon={props.endIcon && <img src={props.endIcon} alt="endIcon" />}
      startIcon={props.startIcon && <img src={props.startIcon} alt="startIcon" />}
      classes={props.vertical ? { label: classes.label, startIcon: classes.startIcon } : undefined}>
      {props.label ? capitalize(props.label) : ''}
    </Button>
  )
}

function getClassName(theme: ButtonTheme) {
  let style = styles.button
  switch (theme) {
    case ButtonTheme.NewPrimary:
      style += ' ' + styles.newPrimary
      break
    case ButtonTheme.NewSecondary:
      style += ' ' + styles.newSecondary
      break
    case ButtonTheme.BasicTrasparent:
      style += ' ' + styles.transparent
      break
    case ButtonTheme.BasicTransparentBlue:
      style += ' ' + styles.transparent2
      break
    case ButtonTheme.NewPrimaryResponsive:
      style += ' ' + styles.newPrimaryResponsive
      break
    case ButtonTheme.NewPrimaryLight:
      style += ' ' + styles.newPrimaryLight
      break
    case ButtonTheme.NewPrimaryLightWithoutWidth:
      style += ' ' + styles.newPrimaryLightWithoutWidth
      break
    default:
      break
  }

  return style
}

function getColor(theme: ButtonTheme) {
  const name = ButtonTheme[theme].toLowerCase()
  if (name.includes('primary')) {
    return 'primary'
  }
  if (name.includes('secondary')) {
    return 'secondary'
  }
  return 'default'
}
