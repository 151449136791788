import generic from '../../../../assets/generic.module.css'
import { Box } from '@mui/material'
import React from 'react'

export const CVS121 = () => {
  return (
    <>
      <Box className={generic.pageContainer} style={{ backgroundColor: 'white' }}>
        <Box className={generic.pageContainer}>
          <h2>
            <b>1.2. Incontinencia</b>
          </h2>
        </Box>
        <Box>
          <h1 style={{ color: 'dodgerblue' }}>
            <b>Incontinencia (urinaria) de urgencia</b>
          </h1>
        </Box>
        <Box className={generic.pageContainer}>
          <div>
            Es la queja de la pérdida involuntaria de orina asociada con la urgencia. La
            incontinencia de urgencia comienza con una fuerte sensación de querer vaciar la vejiga.
            La vejiga se contrae cuando no debería, lo que provoca una fuga de orina. Este tipo de
            incontinencia está causada por una disfunción en la propia vejiga y es común en
            enfermedades neurológicas como la esclerosis múltiple.
            <br />
            <br />
            <b>Causa</b>
            <br />
            <br />
            Vejiga hiperactiva (VH) o hipersensibilidad de la vejiga. La VH se define como urgencia
            urinaria, generalmente con frecuencia urinaria y nocturia, con o sin incontinencia
            urinaria de urgencia. Con la VH, el músculo de la vejiga (detrusor) puede volverse
            hiperactivo y contraerse cuando no es necesario. Esto puede ser causado por daños en los
            nervios, debido a condiciones neurológicas como la esclerosis múltiple, la enfermedad de
            Parkinson, la espina bífida o un accidente cerebrovascular.
            <br />
            <br />
            La hipersensibilidad de la vejiga puede producirse si hay irritación de la vejiga con
            una infección o cálculos.
            <br />
            <br />
            <b>Tratamiento</b>
            <br />
            <br />
            Reeducación de la vejiga, asesoramiento sobre líquidos, ejercicios del suelo pélvico,
            botox, terapia farmacológica. Algunos de estos tratamientos también se pueden usar en
            combinación con el cateterismo intermitente.
            <br />
            <br />
            <br />
          </div>
        </Box>
        <Box>
          <h1 style={{ color: 'dodgerblue' }}>
            <b>Incontinencia por rebosamiento</b>
          </h1>
        </Box>
        <Box className={generic.pageContainer}>
          <div>
            La pérdida involuntaria de orina se produce cuando la vejiga no puede vaciarse de forma
            adecuada, debido a una obstrucción del flujo de salida o un músculo detrusor hipoactivo.
            <br />
            <br />
            <br />
            <b>Causa</b>
            <br />
            <br />
            Obstrucción de la salida de la vejiga (OSV): hiperplasia de próstata, estenosis de la
            uretra, vejiga hipoactiva/insuficiencia vesical: daño de los nervios periféricos, daño
            en el tronco encefálico. <br />
            <br />
            La OSV es un bloqueo en la base de la vejiga que restringe o detiene la salida de la
            orina. Esta retención puede ser crónica o aguda. <br />
            <br />
            Una causa habitual en los hombres es la hiperplasia de la próstata. Otras causas pueden
            ser estenosis uretrales, defectos de nacimiento, cálculos en la vejiga, cáncer de
            vejiga/pelvis o después de una cirugía correctiva de incontinencia. <br />
            <br />
          </div>
        </Box>
      </Box>
    </>
  )
}
