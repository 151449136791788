import generic from '../../../../assets/generic.module.css'
import { Box } from '@mui/material'
import image from './intestino.svg'

export const CIS111 = () => {
  return (
    <>
      <Box className={generic.pageContainer} style={{ backgroundColor: 'white', display: 'flex' }}>
        <Box style={{ marginRight: '5%', marginLeft: '1%' }}>
          <Box className={generic.pageContainer} display="flex">
            <h2>
              <b>1.1 La función del intestino</b>
            </h2>
          </Box>
          <Box className={generic.pageContainer} display="flex">
            <h4 style={{ color: 'dodgerblue' }}>
              <b>Intestino grueso</b>
            </h4>
          </Box>

          <Box className={generic.pageContainer} display="flex">
            <div>
              Esta es la última parte del intestino y consta de varias partes, de las cuales, el
              colon sigmoide, el recto y el ano son las últimas. Su función principal es absorber
              agua. Los productos de desecho digeridos se convierten en heces y salen del cuerpo a
              través del ano.
            </div>
          </Box>

          <Box className={generic.pageContainer} display="flex">
            <h4 style={{ color: 'dodgerblue' }}>
              <b>Suelo pélvico</b>
            </h4>
          </Box>
          <Box className={generic.pageContainer} display="flex">
            <div>
              Tanto los hombres como las mujeres tienen músculos del suelo pélvico. Se trata de una
              estructura en forma de embudo con diferentes músculos y tejido conectivo, debajo de la
              cavidad de la pelvis. El suelo pélvico tiene dos espacios que nos permiten orinar y
              defecar: un orificio por el que pasa la uretra y otro orificio que permite que pase el
              canal anal. En las mujeres, hay un tercer orificio para la vagina. La fuerza de los
              músculos del suelo pélvico puede debilitarse con la edad, las hormonas, otros factores
              del estilo de vida y las lesiones.
              <br />
              <br />
              Los m&uacute;sculos del suelo p&eacute;lvico tienen varias funciones:
              <br />
              <br />
              &nbsp;&nbsp;&nbsp;&nbsp;&bull;&nbsp;&nbsp;&nbsp;&nbsp; Sujetar los &oacute;rganos
              internos dentro de la cavidad p&eacute;lvica. Por ejemplo, la vejiga, el intestino y
              el &uacute;tero.
              <br />
              <br />
              &nbsp;&nbsp;&nbsp;&nbsp;&bull;&nbsp;&nbsp;&nbsp;&nbsp; Los m&uacute;sculos pueden
              tensarse con control voluntario para ofrecer resistencia al aumento de presi&oacute;n
              dentro de la pelvis y el abdomen. Por ejemplo, al toser o al levantar un objeto
              pesado.
              <br />
              <br />
              &nbsp;&nbsp;&nbsp;&nbsp;&bull;&nbsp;&nbsp;&nbsp;&nbsp; Continencia vesical e
              intestinal. Como grupos de m&uacute;sculos, trabajan en oposici&oacute;n; en una
              persona neurol&oacute;gicamente intacta, los m&uacute;sculos del suelo p&eacute;lvico
              se relajan para permitir el paso de las heces. El recto y la uretra tienen una
              acci&oacute;n de esf&iacute;nter (relajaci&oacute;n) para orinar y vaciar el
              intestino.
            </div>
          </Box>
        </Box>
      </Box>
    </>
  )
}
