import { Article } from './Article'
import { v4 as uuidv4 } from 'uuid'
import { ArticleType } from '../enums/ArticleType'
import { LangType } from '../enums/LangType'

export interface ArticleDTO {
  id: string | undefined
  ask: string
  answer: string
  order: number
  type: ArticleType
  language: LangType
  createdAt: Date
  updatedAt: Date
  creatorID: string | undefined
}

export function emptyArticleDTO(): ArticleDTO {
  return {
    id: uuidv4(),
    ask: '',
    answer: '',
    createdAt: new Date(),
    updatedAt: new Date(),
    creatorID: '',
    order: 0,
    language: LangType.Spanish, //change
    type: ArticleType.Faqs,
  }
}

export function fromModel(a: Article): ArticleDTO {
  return {
    id: a.id,
    ask: a.ask,
    answer: a.answer,
    createdAt: a.createdAt,
    updatedAt: a.updatedAt,
    creatorID: a.creatorID,
    order: a.order,
    language: a.language,
    type: a.type,
  }
}

export function toModel(d: ArticleDTO): Article {
  return new Article(d)
}
