import generic from '../../../../assets/generic.module.css'
import { Box } from '@mui/material'
import React from 'react'

export const CVS331 = () => {
  return (
    <>
      <Box className={generic.pageContainer} style={{ backgroundColor: 'white' }}>
        <Box className={generic.pageContainer} display="flex">
          <h2>
            <b>3.3 Vejiga neurógena - Disfunción vesical, manejo y tratamiento</b>
          </h2>
        </Box>
        <Box>
          <h1 style={{ color: 'dodgerblue' }}>
            <b>Disfunción vesical neurógena</b>
          </h1>
        </Box>
        <Box className={generic.pageContainer}>
          <div>
            <b>Manejo y tratamiento</b>
            <br />
            <br />
            Entre los tratamientos para la vejiga neurógena, pueden incluirse los siguientes:
            modificación del comportamiento, ejercicios de los músculos del suelo pélvico,
            estimulación eléctrica, terapia con medicamentos, cateterismo o, a veces, cirugía.
            <br />
            <br />
            &nbsp;&nbsp;&nbsp;&nbsp;•&nbsp;&nbsp;&nbsp;&nbsp; La{' '}
            <b>modificación del comportamiento,</b>también denominada micción cronometrada o
            reeducación vesical, puede reducir los problemas causados por la vejiga hiperactiva.
            Combina fuerza de voluntad con ejercicio. Se le pide al paciente que lleve un diario de
            micción, que es un registro diario de la cantidad y el tiempo de ingesta de líquidos,
            los tiempos de micción y los episodios de pérdida. La finalidad es aumentar gradualmente
            la cantidad de tiempo entre micciones y lograr una mayor capacidad de la vejiga; así
            mejorarán los episodios de urgencia, de frecuencia urinaria y nocturna. Para que
            funcionen los programas de reeducación vesical, deben durar alrededor de 4 semanas, si
            es apropiado para la persona.
            <br />
            <br />
            &nbsp;&nbsp;&nbsp;&nbsp;•&nbsp;&nbsp;&nbsp;&nbsp;{' '}
            <b>Terapia de estimulación eléctrica.</b>La estimulación eléctrica se utiliza en el
            tratamiento de la incontinencia de esfuerzo, la incontinencia de urgencia y la
            incontinencia mixta. También se puede utilizar para las dificultades de vaciado de la
            vejiga causadas por un reflejo miccional débil y un detrusor hipoactivo. La finalidad de
            la estimulación eléctrica es fortalecer la función de los nervios normales para
            controlar la función de la vejiga. Es importante saber que existen varias opciones
            diferentes dentro de la estimulación eléctrica, como TENS, IVES, neuromodulación sacra y
            SARS.
            <br />
            <br />
            &nbsp;&nbsp;&nbsp;&nbsp;•&nbsp;&nbsp;&nbsp;&nbsp; <b>Terapia farmacológica.</b>A día de
            hoy, todavía no existen fármacos para músculos específicos como el esfínter. Sin
            embargo, existen algunas clases de medicamentos que reducen los espasmos y temblores
            musculares y otros que pueden inducir contracciones. A veces, pueden ser efectivos en
            algunas condiciones de la vejiga neurógena.
            <br />
            <br />
            &nbsp;&nbsp;&nbsp;&nbsp;•&nbsp;&nbsp;&nbsp;&nbsp; <b>Cateterismo.</b>El cateterismo se
            utiliza para garantizar el drenaje vesical completo y puede ser intermitente o
            permanente.
            <br />
            <br />
            &nbsp;&nbsp;&nbsp;&nbsp;•&nbsp;&nbsp;&nbsp;&nbsp; Con la <b>derivación urinaria</b>se
            crea un estoma (abertura) a través del cual la orina se desvía a una bolsa de recogida.
            <br />
            <br />
          </div>
        </Box>
      </Box>
    </>
  )
}
