import generic from '../../../../assets/generic.module.css'
import { Box } from '@mui/material'
import React from 'react'

export const CVS140 = () => {
  return (
    <>
      <Box className={generic.pageContainer} style={{ backgroundColor: 'white' }}>
        <Box>
          <h2>
            <b>1.4. Vejiga hipoactiva</b>
          </h2>
        </Box>
        <Box className={generic.pageContainer}>
          <div>
            Esta afección se refiere a que los músculos de la vejiga están debilitados y no pueden
            contraerse para facilitar la evacuación de la orina. <br />
            <br />
            <br />
            Las personas con una lesión medular, espina bífida, esclerosis múltiple o una lesión en
            el parto pueden sufrir retención urinaria debido a una vejiga hipoactiva.
            <br />
            <br />
            <br />
          </div>
        </Box>
        <Box>
          <h1 style={{ color: 'dodgerblue' }}>
            <b>Incontinencia por rebosamiento debido a la vejiga hipoactiva</b>
          </h1>
        </Box>

        <Box className={generic.pageContainer}>
          <div>
            Esta afección también puede causar incontinencia por rebosamiento, en la que la vejiga
            se llena y se estira hasta su máxima capacidad, pero no es posible orinar. Al no poder
            drenar la orina, la vejiga se distiende, perdiendo aún más su tono muscular y con ello
            la capacidad de orinar.
            <br />
            <br />
            Además de ser dolorosa, la vejiga hipoactiva puede provocar una infección del tracto
            urinario, ya que las bacterias dañinas se acumulan en la vejiga.
            <br />
            <br />
            Con una micción regular y completa, se puede mantener un tracto urinario saludable, ya
            que las bacterias se eliminan, pero una acumulación de bacterias puede presentar graves
            problemas de salud.
            <br />
            <br />
            <br />
            <b>Tratamiento</b>
            <br />
            <br />
            Los tratamientos estándar para vaciar una vejiga hipoactiva son:
            <br />
            <br />
            &nbsp;&nbsp;&nbsp;&nbsp;• &nbsp;&nbsp;&nbsp;&nbsp;Sondaje intermitente
            <br />
            <br />
            &nbsp;&nbsp;&nbsp;&nbsp;• &nbsp;&nbsp;&nbsp;&nbsp;Sondaje permanente
            <br />
            <br />
            &nbsp;&nbsp;&nbsp;&nbsp;• &nbsp;&nbsp;&nbsp;&nbsp;Cirugía (cistostomía con tubo
            suprapúbico)
            <br />
            <br />
            &nbsp;&nbsp;&nbsp;&nbsp;• &nbsp;&nbsp;&nbsp;&nbsp;Mientras que los dos últimos
            tratamientos requieren terapias invasivas que residen en el cuerpo durante semanas o
            meses antes de cambiar, con los riesgos asociados, el Cateterismo Intermitente (CI) es
            una terapia que imita más el ritmo
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;natural del cuerpo
            para vaciar la vejiga
            <br />
            <br />
          </div>
        </Box>
      </Box>
    </>
  )
}
