import { RouteProps } from '../../../routes/AppRouter'
import { CurrentNavHeader } from '../../../components/current-nav-header/CurrentNavHeader'
import React from 'react'
import {
  CIS212,
  CVS110,
  CVS111,
  CVS112,
  CVS113,
  CVS114,
  CVS120,
  CVS121,
  CVS122,
  CVS130,
  CVS131,
  CVS140,
  CVS210,
  CVS211,
  CVS220,
  CVS221,
  CVS222,
  CVS230,
  CVS231,
  CVS232,
  CVS233,
  CVS234,
  CVS310,
  CVS311,
  CVS320,
  CVS330,
  CVS331,
  CVS410,
  CVS420,
  CVS430,
  CVS431,
  CVS440,
  CVS450,
  CVS460,
} from '../../../features/professional-educational'
import { useTheme } from '@mui/material/styles'
import MobileStepper from '@mui/material/MobileStepper'
import Button from '@mui/material/Button'
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft'
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight'
import { Box } from '@mui/material'
import generic from '../../../assets/generic.module.css'
import { useTranslation } from 'react-i18next'
import style from '../EducationalProfessional.module.css'
import { AppButton, ButtonTheme } from 'components/app-button/AppButton'
import { navigate } from '@reach/router'
import { ROUTE_EDUCATIONALPROFESSIONAL, ROUTE_EDUCATIONALVESICAL45 } from 'routes/routes-constants'

export type Prop = {
  activeStep: number
}
export function EducationalsProfessionalVesical44(props: RouteProps, p: Prop) {
  const { t } = useTranslation()
  const theme = useTheme()
  const [activeStep, setActiveStep] = React.useState<number>(0)

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1)
  }

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1)
  }

  return (
    <>
      <CurrentNavHeader title={'VESICAL - Módulo 4. Cateterismo intermitente'} strong={true} />
      <Box className={style.buttonContainer}>
        <AppButton
          theme={ButtonTheme.NewPrimary}
          type={'button'}
          label={t('')}
          handler={() => navigate(ROUTE_EDUCATIONALPROFESSIONAL)}
        />
      </Box>
      <Box
        className={generic.pageContainer}
        style={{ backgroundColor: 'white', marginTop: '1%', marginRight: '1%' }}>
        <Box
          className={generic.pageContainer}
          style={{ backgroundColor: 'white', marginTop: '1%', marginRight: '1%' }}>
          {activeStep === 0 && <CVS440 />}
          <Box style={{ display: 'flex', justifyContent: 'center' }}>
            <MobileStepper
              style={{ display: 'flex' }}
              variant="dots"
              steps={1}
              position="static"
              activeStep={activeStep}
              sx={{ maxWidth: 400, flexGrow: 1 }}
              nextButton={
                <Button size="small" onClick={handleNext} disabled={activeStep === 0}>
                  {t('next')}
                  {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
                </Button>
              }
              backButton={
                <Button size="small" onClick={handleBack} disabled={activeStep === 0}>
                  {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
                  {t('back')}
                </Button>
              }
            />
          </Box>
          {activeStep === 0 && (
            <Box className={style.buttonContainerBottom}>
              <AppButton
                theme={ButtonTheme.NewPrimary}
                type={'button'}
                label={t('nextContentEducational')}
                handler={() => navigate(ROUTE_EDUCATIONALVESICAL45)}
              />
            </Box>
          )}
        </Box>
      </Box>
    </>
  )
}
