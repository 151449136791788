import generic from '../../../../assets/generic.module.css'
import { Box, Button, Divider, Modal } from '@mui/material'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import image from './shit.png'

export const CIS240 = () => {
  const { t } = useTranslation()
  const [openModal, setOpenModal] = useState<boolean>(false)
  const { innerWidth } = window
  const handleOpenModal = () => setOpenModal(true)

  const handleCloseModal = () => setOpenModal(false)

  return (
    <>
      <Box className={generic.pageContainer} style={{ backgroundColor: 'white' }}>
        <Box className={generic.pageContainer} display="flex">
          <h2>
            <b>2.4 Evaluación intestinal</b>
          </h2>
        </Box>
        <Box>
          <Box display="flex" className={generic.pageContainer} style={{ marginRight: '15%' }}>
            <div>
              Para determinar la vía de tratamiento correcta, es vital realizar una evaluación
              detallada. Los componentes deben incluir una evaluación de la salud general de la
              persona, cirugía abdominal y colorrectal previa, medicamentos, además de la capacidad
              funcional para llegar al baño, el reconocimiento y la conciencia de las sensaciones
              intestinales y el manejo intestinal actual.
              <br />
              <br />
            </div>
          </Box>
        </Box>

        <Box className={generic.pageContainer} style={{ marginTop: '2%', marginRight: '12%' }}>
          <Box>
            <h1 style={{ color: 'dodgerblue' }}>
              <b>Diario intestinal y diario de alimentos</b>
            </h1>
          </Box>
          <Box style={{ display: 'flex', marginLeft: '1%' }}>
            <Box>
              <div>
                Entre los componentes esenciales para evaluar a cualquier persona con síntomas
                intestinales se deben incluir un diario de alimentos y un diario intestinal.
                <br />
                <br />
                <b>Gráfico de heces de Bristol</b>
                <br />
                <br />
                También es fundamental determinar cómo son las deposiciones de la persona. El
                Gráfico de heces de Bristol es una buena herramienta en este caso.
                <br />
                <br />
                <br />
              </div>
            </Box>
            <Box>
              <Box className={generic.pageContainer}>
                <img
                  src={image}
                  onClick={handleOpenModal}
                  style={{
                    width: innerWidth < 900 ? '140%' : '50%',
                    marginLeft: innerWidth < 900 ? '20%' : '50%',
                    borderStyle: 'ridge',
                    borderColor: 'dodgerblue',
                  }}
                />
              </Box>
            </Box>
          </Box>
          <Box>
            <h1 style={{ color: 'dodgerblue' }}>
              <b>Puntuaciones de impacto de síntomas</b>
            </h1>
          </Box>
          <Box>
            <Box display="flex" className={generic.pageContainer} style={{ marginRight: '15%' }}>
              <div>
                Existen varias herramientas disponibles para evaluar el intestino. Los síntomas de
                disfunción intestinal se pueden medir con una variación de diferentes puntuaciones,
                algunos ejemplos son el NBD score, la puntuación de estreñimiento de la Cleveland
                Clinic y la puntuación de Wexner de la incontinencia fecal. El Gráfico de heces de
                Bristol se puede utilizar como descripción de los síntomas. También hay varias
                herramientas que miden el impacto de los síntomas intestinales, incluida una
                evaluación de la calidad de vida.
                <br />
                <br />
              </div>
            </Box>
          </Box>
          <Box>
            <h1 style={{ color: 'dodgerblue' }}>
              <b>Evaluación física</b>
            </h1>
          </Box>
          <Box>
            <Box display="flex" className={generic.pageContainer} style={{ marginRight: '15%' }}>
              <div>
                En algunas personas, es necesario realizar un tacto rectal. Esto solo deberá
                realizarlo un especialista.
                <br />
                <br />
              </div>
            </Box>
          </Box>
          <Box>
            <h1 style={{ color: 'dodgerblue' }}>
              <b>Hallazgos de la evaluación</b>
            </h1>
          </Box>
          <Box>
            <Box display="flex" className={generic.pageContainer} style={{ marginRight: '15%' }}>
              <div>
                Una vez que se ha realizado una evaluación intestinal completa, se pueden planificar
                tratamientos que identifiquen cualquier modificación en la dieta y los líquidos, o
                bien intervenciones cuyo fin sea alterar la consistencia de las heces, así como
                estrategias de comportamiento dirigidas a ir al servicio con regularidad.
                <br />
                <br />
                Existe una serie de ‘señales de alarma’ que pueden resaltarse si se realiza una
                evaluación intestinal en profundidad. Estas pueden incluir informes de sangre o moco
                en las heces, dolor intestinal o rectal, fuga espontánea sin sensación en ausencia
                de enfermedad neurógena y, si se descubre alguna señal de alarma durante la
                evaluación, es importante remitir a la persona a su médico de cabecera lo antes
                posible y documentar la situación.
                <br />
                <br />
              </div>
            </Box>
          </Box>
        </Box>

        <Modal open={openModal} onClose={handleCloseModal}>
          <Box
            style={{
              backgroundColor: 'white',
              justifyContent: 'center',
              transform: 'translate(0%, 20%)',
              marginLeft: '30%',
              marginRight: '30%',
              padding: '20px',
            }}>
            <h1 style={{ marginLeft: '25%' }}>
              <b>Gráfico de heces de Bristol</b>
            </h1>
            <Divider variant={'middle'} />
            <img
              src={image}
              style={{
                width: '80%',
                height: '25%',
                marginTop: '1%',
                marginLeft: '10%',
                justifyContent: 'center',
                display: 'flex',
              }}
            />
            <Button
              variant={'outlined'}
              style={{ marginLeft: '45%', marginTop: '1%', justifyContent: 'end', display: 'flex' }}
              onClick={handleCloseModal}>
              {t('close')}
            </Button>
          </Box>
        </Modal>
      </Box>
    </>
  )
}
