import { Container, ContainerItem } from '../common/container/Container'
import { getAppContainer } from './app'
import { moduleURLs } from '../config/api'
import { PatientApi } from '../modules/patients/api/PatientApi'
import { PatientService } from '../modules/patients/services/PatientService'
import {
  PATIENT_API_KEY,
  PATIENTS_MODULE,
  PATIENT_SERVICE_KEY,
  SYMPTOM_SERVICE_KEY,
  SYMPTOM_API_KEY,
  DIAGNOSTIC_API_KEY,
  DIAGNOSTIC_SERVICE_KEY,
  INTERVENTION_SERVICE_KEY,
  INTERVENTION_API_KEY,
  TREATMENT_API_KEY,
  TREATMENT_SERVICE_KEY,
  PATIENT_FILE_SERVICE_KEY,
  PATIENT_FILE_API_KEY,
} from '../modules/patients'
import {
  PatientProps,
  PATIENT_GOAL_API_KEY,
  PATIENT_GOAL_SERVICE_KEY,
  PATIENT_SYMPTOM_API_KEY,
  PATIENT_SYMPTOM_SERVICE_KEY,
  PATIENT_TREATMENT_API_KEY,
  PATIENT_TREATMENT_SERVICE_KEY,
  PATIENT_INTERVENTION_API_KEY,
  PATIENT_INTERVENTION_SERVICE_KEY,
  PATIENT_DIAGNOSTIC_API_KEY,
  PATIENT_DIAGNOSTIC_SERVICE_KEY,
  PATIENT_POINT_API_KEY,
  PATIENT_POINT_SERVICE_KEY,
  PATIENT_NOTES_API_KEY,
  PATIENT_NOTES_SERVICE_KEY,
  PROBLEM_API_KEY,
  PROBLEM_SERVICE_KEY,
  PATIENT_PROBLEM_API_KEY,
  PATIENT_PROBLEM_SERVICE_KEY,
} from '../modules/patients/container'
import { PatientGoalApi } from 'modules/patients/api/PatientGoalApi'
import { PatientGoalService } from 'modules/patients/services/PatientGoalService'
import { PatientSymptomApi } from '../modules/patients/api/PatientSymptomApi'
import { PatientSymptomService } from '../modules/patients/services/PatientSymptomService'
import { SymptomApi } from '../modules/patients/api/SymptomApi'
import { SymptomService } from '../modules/patients/services/SymptomService'
import { DiagnosticApi } from '../modules/patients/api/DiagnosticApi'
import { InterventionApi } from '../modules/patients/api/InterventionApi'
import { TreatmentApi } from '../modules/patients/api/TreatmentApi'
import { DiagnosticService } from '../modules/patients/services/DiagnosticService'
import { InterventionService } from '../modules/patients/services/InterventionService'
import { TreatmentService } from '../modules/patients/services/TreatmentService'
import { PatientDiagnosticApi } from '../modules/patients/api/PatientDiagnosticApi'
import { PatientDiagnosticService } from '../modules/patients/services/PatientDiagnosticService'
import { PatientInterventionApi } from '../modules/patients/api/PatientInterventionApi'
import { PatientInterventionService } from '../modules/patients/services/PatientInterventionService'
import { PatientTreatmentApi } from '../modules/patients/api/PatientTreatmentApi'
import { PatientTreatmentService } from '../modules/patients/services/PatientTreatmentService'
import { PatientPointApi } from '../modules/patients/api/PatientPointApi'
import { PatientPointService } from '../modules/patients/services/PatientPointService'
import { PatientFileApi } from '../modules/patients/api/PatientFileApi'
import { PatientFileService } from '../modules/patients/services/PatientFileService'
import { PatientNoteApi } from 'modules/patients/api/PatientNoteApi'
import { PatientNoteService } from 'modules/patients/services/PatientNoteService'
import { ProblemApi } from 'modules/patients/api/ProblemApi'
import { ProblemService } from 'modules/patients/services/ProblemService'
import { PatientProblemApi } from 'modules/patients/api/PatientProblemApi'
import { PatientProblemService } from 'modules/patients/services/PatientProblemService'

let container: Container

function init(p: PatientProps) {
  const items = new Map<symbol, ContainerItem>()

  items.set(PATIENT_PROBLEM_API_KEY, new ContainerItem({ instance: new PatientProblemApi() }))

  items.set(PATIENT_API_KEY, new ContainerItem({ instance: new PatientApi() }))

  items.set(PROBLEM_API_KEY, new ContainerItem({ instance: new ProblemApi() }))

  items.set(
    PATIENT_SERVICE_KEY,
    new ContainerItem({
      instance: new PatientService({ apiKey: PATIENT_API_KEY }),
    })
  )

  items.set(SYMPTOM_API_KEY, new ContainerItem({ instance: new SymptomApi() }))

  items.set(PATIENT_GOAL_API_KEY, new ContainerItem({ instance: new PatientGoalApi() }))

  items.set(DIAGNOSTIC_API_KEY, new ContainerItem({ instance: new DiagnosticApi() }))

  items.set(INTERVENTION_API_KEY, new ContainerItem({ instance: new InterventionApi() }))

  items.set(TREATMENT_API_KEY, new ContainerItem({ instance: new TreatmentApi() }))

  items.set(
    PATIENT_GOAL_SERVICE_KEY,
    new ContainerItem({ instance: new PatientGoalService({ apiKey: PATIENT_GOAL_API_KEY }) })
  )
  items.set(
    SYMPTOM_SERVICE_KEY,
    new ContainerItem({ instance: new SymptomService({ apiKey: SYMPTOM_API_KEY }) })
  )
  items.set(
    DIAGNOSTIC_SERVICE_KEY,
    new ContainerItem({ instance: new DiagnosticService({ apiKey: DIAGNOSTIC_API_KEY }) })
  )
  items.set(
    INTERVENTION_SERVICE_KEY,
    new ContainerItem({ instance: new InterventionService({ apiKey: INTERVENTION_API_KEY }) })
  )
  items.set(
    TREATMENT_SERVICE_KEY,
    new ContainerItem({ instance: new TreatmentService({ apiKey: TREATMENT_API_KEY }) })
  )

  items.set(PATIENT_SYMPTOM_API_KEY, new ContainerItem({ instance: new PatientSymptomApi() }))

  items.set(
    PATIENT_SYMPTOM_SERVICE_KEY,
    new ContainerItem({ instance: new PatientSymptomService({ apiKey: PATIENT_SYMPTOM_API_KEY }) })
  )

  items.set(PATIENT_DIAGNOSTIC_API_KEY, new ContainerItem({ instance: new PatientDiagnosticApi() }))

  items.set(
    PATIENT_DIAGNOSTIC_SERVICE_KEY,
    new ContainerItem({
      instance: new PatientDiagnosticService({ apiKey: PATIENT_DIAGNOSTIC_API_KEY }),
    })
  )

  items.set(
    PATIENT_INTERVENTION_API_KEY,
    new ContainerItem({ instance: new PatientInterventionApi() })
  )

  items.set(
    PATIENT_INTERVENTION_SERVICE_KEY,
    new ContainerItem({
      instance: new PatientInterventionService({ apiKey: PATIENT_INTERVENTION_API_KEY }),
    })
  )

  items.set(PATIENT_TREATMENT_API_KEY, new ContainerItem({ instance: new PatientTreatmentApi() }))

  items.set(
    PATIENT_TREATMENT_SERVICE_KEY,
    new ContainerItem({
      instance: new PatientTreatmentService({ apiKey: PATIENT_TREATMENT_API_KEY }),
    })
  )

  items.set(PATIENT_POINT_API_KEY, new ContainerItem({ instance: new PatientPointApi() }))

  items.set(
    PATIENT_POINT_SERVICE_KEY,
    new ContainerItem({
      instance: new PatientPointService({ apiKey: PATIENT_POINT_API_KEY }),
    })
  )

  items.set(PATIENT_FILE_API_KEY, new ContainerItem({ instance: new PatientFileApi() }))

  items.set(
    PATIENT_FILE_SERVICE_KEY,
    new ContainerItem({
      instance: new PatientFileService({ apiKey: PATIENT_FILE_API_KEY }),
    })
  )

  items.set(PATIENT_NOTES_API_KEY, new ContainerItem({ instance: new PatientNoteApi() }))

  items.set(
    PATIENT_NOTES_SERVICE_KEY,
    new ContainerItem({
      instance: new PatientNoteService({ apiKey: PATIENT_NOTES_API_KEY }),
    })
  )

  items.set(
    PROBLEM_SERVICE_KEY,
    new ContainerItem({
      instance: new ProblemService({ apiKey: PROBLEM_API_KEY }),
    })
  )

  items.set(
    PATIENT_PROBLEM_SERVICE_KEY,
    new ContainerItem({
      instance: new PatientProblemService({ apiKey: PATIENT_PROBLEM_API_KEY }),
    })
  )

  container = new Container(items, p.parentContainer, p.config)
}

export function getPatientContainer(): Container {
  if (!container) {
    init({
      parentContainer: getAppContainer(),
      config: {
        moduleFullUrl: moduleURLs[PATIENTS_MODULE],
      },
    })
  }

  return container
}
