import { RouteProps } from '../../routes/AppRouter'
import { CurrentNavHeader } from '../../components/current-nav-header/CurrentNavHeader'
import React from 'react'
import { ResourcesForm as RF } from '../../features/resource'
import { ROUTE_CREATE } from '../../routes/routes-constants'

export function ResourcesForm(props: RouteProps) {
  const title = props.title || ''

  return (
    <>
      <CurrentNavHeader title={title} />
      <RF id={props.id === ROUTE_CREATE ? undefined : props.id} />
    </>
  )
}
