import { Observable } from 'rxjs'
import { Query } from '../../../common/api/Query'
import { ItemList } from '../../../common/models/ItemList'
import { Container, IInit } from '../../../common/container/Container'
import { IStatusService } from '../../../common/status/StatusService'
import { STATUS_SERVICE_KEY } from '../../../container/app'
import { Treatment, TreatmentQuery } from '../models/Treatment'
import { TreatmentDTO } from '../models/TreatmentDTO'
import { ITreatmentApi } from '../api/TreatmentApi'
import { Diagnostic } from '../models/Diagnostic'

type Props = {
  apiKey: symbol
}

export interface ITreatmentService extends IInit {
  getByID(id: string): Observable<Treatment>
  getFilteredList(q: Query<TreatmentQuery>): Observable<ItemList<Treatment>>
  add(e: TreatmentDTO): Observable<Treatment | undefined>
  update(e: TreatmentDTO): Observable<Treatment | undefined>

  delete(id: string | undefined): Observable<boolean>

  getByName(name: string | undefined): Observable<Treatment>

  getPatientTreatment(patientID: string): Observable<Treatment | undefined>
}

export class TreatmentService implements ITreatmentService {
  private readonly _apiKey: symbol
  private _container!: Container
  private _api!: ITreatmentApi
  private _statusService!: IStatusService

  constructor(p: Props) {
    this._apiKey = p.apiKey
  }

  init(c: Container) {
    this._container = c
    this._api = this._container.get<ITreatmentApi>(this._apiKey)
    this._statusService = this._container.get<IStatusService>(STATUS_SERVICE_KEY)
  }

  getByID(id: string | undefined): Observable<Treatment> {
    return this._api.getByID(id)
  }

  getFilteredList(q: Query<TreatmentQuery>): Observable<ItemList<Treatment>> {
    return this._api.getFilteredList(q).pipe()
  }

  add(e: TreatmentDTO): Observable<Treatment | undefined> {
    return this._api.add(e)
  }

  delete(id: string | undefined): Observable<boolean> {
    return this._api.delete(id)
  }

  update(e: TreatmentDTO): Observable<Treatment | undefined> {
    return this._api.update(e)
  }

  getByName(name: string | undefined): Observable<Treatment> {
    return this._api.getByName(name)
  }

  getPatientTreatment(patientID: string): Observable<Treatment | undefined> {
    return this._api.getPatientTreatment(patientID)
  }
}
