import { AppBar, Box, Tab, Tabs, Typography } from '@mui/material'
import generic from '../../assets/generic.module.css'
import { useTranslation } from 'react-i18next'
import style from './Stats.module.css'
import { useEffect, useState } from 'react'
import { MiccionalView } from './MiccionalView'
import { FecalView } from './FecalView'
import { SymptomsView } from './SymptomsView'
import { KeyboardDatePicker } from '@material-ui/pickers'
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date'
import { AppButton, ButtonTheme } from '../../components/app-button/AppButton'
import { FecalService } from '../../modules/fecal/services/FecalService'
import { FECAL_SERVICE_KEY } from '../../modules/fecal'
import { getFecalContainer } from '../../container/fecal-module'
import { Query, QueryParam } from '../../common/api/Query'
import { Fecal } from '../../modules/fecal/models/Fecal'
import { URINATION_SERVICE_KEY } from '../../modules/urination'
import { getUrinationContainer } from '../../container/urination-module'
import { UrinationService } from '../../modules/urination/services/UrinationService'
import { Urination } from '../../modules/urination/models/Urination'
import { PatientSymptom } from '../../modules/patients/models/PatientSymptom'
import { getPatientContainer } from '../../container/patient-module'
import { PatientSymptomService } from '../../modules/patients/services/PatientSymptomService'
import { PATIENT_SYMPTOM_SERVICE_KEY, SYMPTOM_SERVICE_KEY } from '../../modules/patients/container'
import stylesAppoint from '../appointments/Appointments.module.css'
import { navigate } from '@reach/router'
import { ROUTE_PATIENTS_ID } from 'routes/routes-constants'
import { Symptom } from 'modules/patients/models/Symptom'
import { SymptomService } from 'modules/patients/services/SymptomService'
import { Grid, ListItem } from '@mui/material'
import { createStyles, makeStyles } from '@material-ui/core'

enum View {
  Miccional = 1,
  Fecal,
  Symptoms,
}

export type ViewProps = {
  startDate: Date
  finishDate?: Date
  fecal: Fecal[] | undefined
  urination: Urination[] | undefined
  symptoms: PTInterface[] | undefined
}

type StatisticsProps = {
  id: string
}

type PTInterface = {
  createdAt: Date
  symptomID: string
  patientID: string
  other: string
  name: string
}

const symptomService = getPatientContainer().get<SymptomService>(SYMPTOM_SERVICE_KEY)
const fecalService = getFecalContainer().get<FecalService>(FECAL_SERVICE_KEY)
const urinationService = getUrinationContainer().get<UrinationService>(URINATION_SERVICE_KEY)
const patientSymptomsService = getPatientContainer().get<PatientSymptomService>(
  PATIENT_SYMPTOM_SERVICE_KEY
)

export const Statistics = (props: StatisticsProps) => {
  const { t } = useTranslation()
  const dateMinusWeek = new Date()
  dateMinusWeek.setDate(dateMinusWeek.getDate() - 7)
  const [firstDone, setFirstDone] = useState<boolean>(false)
  const [tabValue, setTabValue] = useState<number>(0)
  const [view, setView] = useState<View>(View.Miccional)
  const [tabsView, setTabsView] = useState<JSX.Element>(<></>)
  const [startDate, setStartDate] = useState<Date>(dateMinusWeek)
  const [finishDate, setFinishDate] = useState<Date>(new Date())
  const [isLoading, setIsLoading] = useState<boolean>(true)
  const [fecal, setFecal] = useState<Fecal[]>()
  const [urination, setUrination] = useState<Urination[]>()
  const [symptoms, setSymptoms] = useState<PTInterface[]>()
  const [allSymptoms, setAllSymptoms] = useState<Symptom[]>()

  useEffect(() => {
    symptomService.getFilteredList(new Query({})).subscribe((res) => {
      setAllSymptoms(res.items)
      get()
    })
  }, [])

  useEffect(() => {
    get()
  }, [allSymptoms, startDate])

  const get = async () => {
    const newStartDate = new Date(startDate)
    newStartDate.setDate(newStartDate.getDate() + 1)
    let urinationItems = await urinationService
      .getFilteredList(
        new Query({
          query: [
            new QueryParam<Urination>('patientID', props.id || ''),
            new QueryParam<Urination>('dateBetween', newStartDate.toISOString()),
          ],
        })
      )
      .toPromise()
    let sortedUR = urinationItems.items.sort(function (o1: Urination, o2: Urination) {
      if (new Date(o1.date) < new Date(o2.date)) return -1
      else if (new Date(o2.date) < new Date(o1.date)) return 1
      else return 0
    })
    setUrination(sortedUR)

    let fecalItems = await fecalService
      .getFilteredList(
        new Query({
          query: [new QueryParam<Fecal>('patientID', props.id || '')],
        })
      )
      .toPromise()

    let sortedFecal = fecalItems.items.sort(function (o1: Fecal, o2: Fecal) {
      if (new Date(o1.date) < new Date(o2.date)) return -1
      else if (new Date(o2.date) < new Date(o1.date)) return 1
      else return 0
    })

    setFecal(sortedFecal)

    let patientItems = await patientSymptomsService
      .getPatientSymptomsByPatientID(props.id || '')
      .toPromise()
    let sorted = patientItems.items.sort(function (o1: PatientSymptom, o2: PatientSymptom) {
      if (new Date(o1.createdAt) < new Date(o2.createdAt)) return -1
      else if (new Date(o2.createdAt) < new Date(o1.createdAt)) return 1
      else return 0
    })
    let aux: PTInterface[] = []
    sorted.forEach((s) => {
      let item = allSymptoms?.filter((sym) => sym.id == s.symptomID)
      if (item && item?.length > 0) {
        aux.push({
          createdAt: s.createdAt,
          symptomID: s.symptomID,
          patientID: s.patientID,
          other: s.other,
          name: item[0].name,
        })
      }
    })

    setSymptoms(aux)
    setFirstDone(true)
  }

  const handleTabChange = (event: React.ChangeEvent<{}>, tabNumber: number) => {
    setTabValue(tabNumber)
    switch (tabNumber) {
      case 0:
        setView(View.Miccional)
        break
      case 1:
        setView(View.Fecal)
        break
      case 2:
        setView(View.Symptoms)
        break
      default:
        break
    }
  }

  const getView = (view: View, vp: ViewProps): JSX.Element => {
    switch (view) {
      case View.Miccional:
        return <MiccionalView {...vp} />
      case View.Fecal:
        return <FecalView {...vp} />
      case View.Symptoms:
        return <SymptomsView {...vp} />
    }
  }

  useEffect(() => {
    firstDone &&
      setTabsView(
        getView(view, {
          startDate: startDate,
          finishDate: finishDate,
          fecal: fecal,
          urination: urination,
          symptoms: symptoms,
        })
      )
    setIsLoading(false)
  }, [view, symptoms, fecal, urination, startDate, finishDate, isLoading, firstDone])

  const handleDate = (e: MaterialUiPickersDate, name: string) => {
    if (e && name == 'startDate') {
      let d = e.toDate()
      setStartDate(new Date(d.getFullYear(), d.getMonth(), d.getDate()))
    }
    if (e && name == 'finishDate') {
      let d = e.toDate()
      setFinishDate(new Date(d.getFullYear(), d.getMonth(), d.getDate()))
    }
    setIsLoading(!isLoading)
  }

  const handleClean = () => {
    const dateMinusWeek = new Date()
    dateMinusWeek.setDate(dateMinusWeek.getDate() - 7)
    setFinishDate(new Date())
    setStartDate(dateMinusWeek)
    setIsLoading(true)
  }

  const handleApply = () => {
    setIsLoading(!isLoading)
  }

  const useStyles = makeStyles(() =>
    createStyles({
      componentStyle: {
        '& .MuiIconButton-root': {
          padding: 0,
        },
      },
    })
  )

  const classes = useStyles()

  return (
    <>
      <Box className={generic.pageContainer}>
        <Box className={stylesAppoint.buttonContainer2}>
          <AppButton
            theme={ButtonTheme.NewPrimaryLightWithoutWidth}
            type={'button'}
            label={t('returnToPatient')}
            handler={() => navigate(ROUTE_PATIENTS_ID.replace(':id', `${props.id}`))}
          />
        </Box>
        <Grid item style={{ marginBottom: '1%' }}>
          <ListItem style={{ paddingLeft: 0 }}>
            <Typography style={{ color: '#000', fontFamily: 'Open-sans, sans-serif' }} variant="h6">
              {t('statistics')}
            </Typography>
          </ListItem>
        </Grid>
        <Box className={style.filtersContainer} pb={2}>
          <Box className={style.buttonsContainer}>
            <AppButton
              theme={ButtonTheme.BasicTrasparent}
              type="button"
              label={t('cleanFilters')}
              handler={handleClean}
            />

            <AppButton
              theme={ButtonTheme.NewPrimaryLight}
              type="button"
              label={t('apply')}
              handler={handleApply}
            />
          </Box>
          <Box className={style.filtersDetailContainer}>
            <h2
              style={{
                marginBottom: 0,
                color: '#000',
                fontWeight: 'normal',
                fontFamily: 'Open-sans, sans-serif',
              }}
            >
              {t('filters')}
            </h2>
            <Box display="flex" flexDirection="row" alignItems={'center'} pt={1}>
              {view !== View.Miccional && (
                <h5 style={{ color: '#000', fontFamily: 'Open-sans, sans-serif' }}>{t('from')}</h5>
              )}
              <KeyboardDatePicker
                style={{ width: '150px', marginLeft: '10px', marginRight: '10px' }}
                InputProps={{
                  className: classes.componentStyle,
                  style: { fontFamily: 'Open-sans, sans-serif' },
                }}
                key={'startDate'}
                id={'startDate'}
                autoOk
                variant="inline"
                inputVariant={'outlined'}
                label={''}
                format="DD/MM/YYYY"
                value={startDate}
                onChange={(e) => handleDate(e, 'startDate')}
                size={'small'}
              />
              {view !== View.Miccional && (
                <>
                  <h5 style={{ color: '#000', fontFamily: 'Open-sans, sans-serif' }}>{t('to')}</h5>
                  <KeyboardDatePicker
                    style={{ width: '150px', marginLeft: '10px' }}
                    InputProps={{
                      className: classes.componentStyle,
                      style: { fontFamily: 'Open-sans, sans-serif' },
                    }}
                    key={'finishDate'}
                    id={'finishDate'}
                    autoOk
                    variant="inline"
                    inputVariant={'outlined'}
                    label={''}
                    format={'DD/MM/YYYY'}
                    value={finishDate}
                    onChange={(e) => handleDate(e, 'finishDate')}
                    size={'small'}
                  />
                </>
              )}
            </Box>
          </Box>
        </Box>
        {!isLoading && (
          <Box className={style.tabContainer}>
            <AppBar position="static" className={style.caltabs}>
              <Tabs value={tabValue} onChange={handleTabChange} className={style.caltabs}>
                <Tab
                  label={t('miccionalDiary')}
                  className={tabValue === 0 ? style.parsetabsActive : style.parsetabs}
                />
                <Tab
                  label={t('fecalDiary')}
                  className={tabValue === 1 ? style.parsetabsActive : style.parsetabs}
                />
                <Tab
                  label={t('symptoms')}
                  className={tabValue === 2 ? style.parsetabsActive : style.parsetabs}
                />
              </Tabs>
            </AppBar>
            {tabsView}
          </Box>
        )}
      </Box>
    </>
  )
}
