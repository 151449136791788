import { Container, IInit } from '../../../common/container/Container'
import { Observable, of } from 'rxjs'
import { Query } from '../../../common/api/Query'
import { emptyList, ItemList } from '../../../common/models/ItemList'
import { HTTP_CLIENT_KEY, IHTTPClient } from '../../../common/api/HTTPClient'
import { IStatusService } from '../../../common/status/StatusService'
import { STATUS_SERVICE_KEY } from '../../../container/app'
import { ContentContainerConfig } from '../../content/container'
import { catchError, map } from 'rxjs/operators'
import { prepareURL } from '../../../common/api/http-helpers'
import { Goal } from '../models/Goal'
import { GoalDTO, toModel } from '../models/GoalDTO'

export interface IGoalApi extends IInit {
  getByID(id: string): Observable<Goal | undefined>

  getFilteredList(q: Query<Goal>): Observable<ItemList<Goal>>

  add(e: GoalDTO): Observable<Goal | undefined>

  update(e: GoalDTO): Observable<Goal | undefined>

  delete(id: string): Observable<boolean>
}

export class GoalApi implements IGoalApi {
  private _container!: Container
  private _httpClient!: IHTTPClient
  private _url!: string
  private _statusService!: IStatusService

  init(c: Container) {
    this._container = c
    this._httpClient = this._container.get<IHTTPClient>(HTTP_CLIENT_KEY)
    this._statusService = this._container.get<IStatusService>(STATUS_SERVICE_KEY)
    this._url = (this._container.config as ContentContainerConfig).moduleFullUrl
  }

  add(e: GoalDTO): Observable<Goal | undefined> {
    return this._httpClient
      .post<Goal>({ url: this._url, body: e })
      .pipe(
        map<GoalDTO, Goal>((d) => {
          this._statusService.sendStatus({ variant: 'success' })
          return toModel(d)
        }),
        catchError((err) => {
          this._statusService.sendStatus({ variant: 'error', error: err })
          return of(undefined)
        })
      )
  }

  delete(id: string): Observable<boolean> {
    return this._httpClient.delete({ url: `${this._url}/${id}` }).pipe(
      catchError((err) => {
        this._statusService.sendStatus({ variant: 'error', error: err })
        return of(false)
      })
    )
  }

  getByID(id: string): Observable<Goal | undefined> {
    return this._httpClient
      .get<Goal>({ url: `${this._url}/${id}` })
      .pipe(
        map<GoalDTO, Goal>((d) => toModel(d)),
        catchError((err) => {
          this._statusService.sendStatus({ variant: 'error', error: err })
          return of(undefined)
        })
      )
  }

  getFilteredList(q: Query<Goal>): Observable<ItemList<Goal>> {
    return this._httpClient
      .get<ItemList<Goal>>({ url: prepareURL(this._url, q) })
      .pipe(
        map<ItemList<GoalDTO>, ItemList<Goal>>((dto) => {
          const itemList = emptyList<Goal>()
          itemList.count = dto.count
          itemList.items = dto.items.map((d) => toModel(d))
          return itemList
        }),
        catchError((err) => {
          this._statusService.sendStatus({ variant: 'error', error: err })
          return of(emptyList<Goal>())
        })
      )
  }

  update(e: GoalDTO): Observable<Goal | undefined> {
    return this._httpClient
      .put<Goal>({ url: this._url, body: e })
      .pipe(
        map<GoalDTO, Goal>((d) => {
          this._statusService.sendStatus({ variant: 'success' })
          return toModel(d)
        }),
        catchError((err) => {
          this._statusService.sendStatus({ variant: 'error', error: err })
          return of(undefined)
        })
      )
  }
}
