import { Observable } from 'rxjs'
import { Query } from '../../../common/api/Query'
import { ItemList } from '../../../common/models/ItemList'
import { Container, IInit } from '../../../common/container/Container'
import { IStatusService } from '../../../common/status/StatusService'
import { STATUS_SERVICE_KEY } from '../../../container/app'
import { Intervention, InterventionQuery } from '../models/Intervention'
import { InterventionDTO } from '../models/InterventionDTO'
import { IInterventionApi } from '../api/InterventionApi'
import { Diagnostic } from '../models/Diagnostic'

type Props = {
  apiKey: symbol
}

export interface IInterventionService extends IInit {
  getByID(id: string): Observable<Intervention>
  getFilteredList(q: Query<InterventionQuery>): Observable<ItemList<Intervention>>
  add(e: InterventionDTO): Observable<Intervention | undefined>
  update(e: InterventionDTO): Observable<Intervention | undefined>

  delete(id: string | undefined): Observable<boolean>

  getByName(name: string | undefined): Observable<Intervention>

  getPatientIntervention(patientID: string): Observable<Intervention | undefined>
}

export class InterventionService implements IInterventionService {
  private readonly _apiKey: symbol
  private _container!: Container
  private _api!: IInterventionApi
  private _statusService!: IStatusService

  constructor(p: Props) {
    this._apiKey = p.apiKey
  }

  init(c: Container) {
    this._container = c
    this._api = this._container.get<IInterventionApi>(this._apiKey)
    this._statusService = this._container.get<IStatusService>(STATUS_SERVICE_KEY)
  }

  getByID(id: string | undefined): Observable<Intervention> {
    return this._api.getByID(id)
  }

  getFilteredList(q: Query<InterventionQuery>): Observable<ItemList<Intervention>> {
    return this._api.getFilteredList(q).pipe()
  }

  add(e: InterventionDTO): Observable<Intervention | undefined> {
    return this._api.add(e)
  }

  delete(id: string | undefined): Observable<boolean> {
    return this._api.delete(id)
  }

  update(e: InterventionDTO): Observable<Intervention | undefined> {
    return this._api.update(e)
  }

  getByName(name: string | undefined): Observable<Intervention> {
    return this._api.getByName(name)
  }

  getPatientIntervention(patientID: string): Observable<Intervention | undefined> {
    return this._api.getPatientIntervention(patientID)
  }
}
