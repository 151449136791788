import { Box, List, ListItem } from '@mui/material'
import { Avatar, IconButton, Typography } from '@material-ui/core'
import viewIcon from '../../../../assets/higea/ico-view.svg'
import React from 'react'
import { navigate } from '@reach/router'
import {
  ROUTE_EDUCATIONALINTESTINAL21,
  ROUTE_EDUCATIONALINTESTINAL22,
  ROUTE_EDUCATIONALINTESTINAL23,
  ROUTE_EDUCATIONALINTESTINAL24,
  ROUTE_EDUCATIONALINTESTINAL31,
  ROUTE_EDUCATIONALINTESTINAL32,
  ROUTE_EDUCATIONALINTESTINAL33,
} from '../../../../routes/routes-constants'
import styles from '../../EducationalProfessional.module.css'

export const Module3LessonsIns = () => {
  const handleIntestinal31 = () => navigate(ROUTE_EDUCATIONALINTESTINAL31).then()
  const handleIntestinal32 = () => navigate(ROUTE_EDUCATIONALINTESTINAL32).then()

  return (
    <List>
      <ListItem>
        <Box className={styles.lessonItem}>
          <Box display={'flex'} justifyContent={'space-between'} alignItems={'center'}>
            <ListItem id={'idLesson'}>
              <Typography style={{ color: 'blue' }}>
                3.1 Esclerosis Múltiple y función intestinal
              </Typography>
            </ListItem>
            <IconButton>
              <Avatar
                src={viewIcon}
                style={{ width: 29, height: 25 }}
                onClick={handleIntestinal31}
              />
            </IconButton>
          </Box>
        </Box>
      </ListItem>
      <ListItem>
        <Box className={styles.lessonItem}>
          <Box display={'flex'} justifyContent={'space-between'} alignItems={'center'}>
            <ListItem id={'idLesson'}>
              <Typography style={{ color: 'blue' }}>
                3.2 Síndrome de resección anterior baja (LARS)
              </Typography>
            </ListItem>
            <IconButton>
              <Avatar
                src={viewIcon}
                style={{ width: 29, height: 25 }}
                onClick={handleIntestinal32}
              />
            </IconButton>
          </Box>
        </Box>
      </ListItem>
    </List>
  )
}
