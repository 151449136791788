import { Box, List, ListItem } from '@mui/material'
import { Avatar, IconButton, Typography } from '@material-ui/core'
import viewIcon from '../../../../assets/higea/ico-view.svg'
import React from 'react'
import { navigate } from '@reach/router'
import {
  ROUTE_EDUCATIONALVESICAL31,
  ROUTE_EDUCATIONALVESICAL32,
  ROUTE_EDUCATIONALVESICAL33,
  ROUTE_EDUCATIONALVESICAL41,
  ROUTE_EDUCATIONALVESICAL42,
  ROUTE_EDUCATIONALVESICAL43,
  ROUTE_EDUCATIONALVESICAL44,
  ROUTE_EDUCATIONALVESICAL45,
  ROUTE_EDUCATIONALVESICAL46,
} from '../../../../routes/routes-constants'
import styles from '../../EducationalProfessional.module.css'

export const Module4Lessons = () => {
  const handleVesical41 = () => navigate(ROUTE_EDUCATIONALVESICAL41).then()
  const handleVesical42 = () => navigate(ROUTE_EDUCATIONALVESICAL42).then()
  const handleVesical43 = () => navigate(ROUTE_EDUCATIONALVESICAL43).then()
  const handleVesical44 = () => navigate(ROUTE_EDUCATIONALVESICAL44).then()
  const handleVesical45 = () => navigate(ROUTE_EDUCATIONALVESICAL45).then()
  const handleVesical46 = () => navigate(ROUTE_EDUCATIONALVESICAL46).then()

  return (
    <List>
      <ListItem>
        <Box className={styles.lessonItem}>
          <Box display={'flex'} justifyContent={'space-between'} alignItems={'center'}>
            <ListItem id={'idLesson'}>
              <Typography style={{ color: 'blue' }}>
                4.1 ¿En qué consiste el cateterismo intermitente (CI)?
              </Typography>
            </ListItem>
            <IconButton>
              <Avatar src={viewIcon} style={{ width: 29, height: 25 }} onClick={handleVesical41} />
            </IconButton>
          </Box>
        </Box>
      </ListItem>
      <ListItem>
        <Box className={styles.lessonItem}>
          <Box display={'flex'} justifyContent={'space-between'} alignItems={'center'}>
            <ListItem id={'idLesson'}>
              <Typography style={{ color: 'blue' }}>
                4.2 Indicaciones para el cateterismo intermitente
              </Typography>
            </ListItem>
            <IconButton>
              <Avatar src={viewIcon} style={{ width: 29, height: 25 }} onClick={handleVesical42} />
            </IconButton>
          </Box>
        </Box>
      </ListItem>
      <ListItem>
        <Box className={styles.lessonItem}>
          <Box display={'flex'} justifyContent={'space-between'} alignItems={'center'}>
            <ListItem id={'idLesson'}>
              <Typography style={{ color: 'blue' }}>4.3 Tipos de sonda</Typography>
            </ListItem>
            <IconButton>
              <Avatar src={viewIcon} style={{ width: 29, height: 25 }} onClick={handleVesical43} />
            </IconButton>
          </Box>
        </Box>
      </ListItem>
      <ListItem>
        <Box className={styles.lessonItem}>
          <Box display={'flex'} justifyContent={'space-between'} alignItems={'center'}>
            <ListItem id={'idLesson'}>
              <Typography style={{ color: 'blue' }}>4.4 Técnica de cateterismo</Typography>
            </ListItem>
            <IconButton>
              <Avatar src={viewIcon} style={{ width: 29, height: 25 }} onClick={handleVesical44} />
            </IconButton>
          </Box>
        </Box>
      </ListItem>
      <ListItem>
        <Box className={styles.lessonItem}>
          <Box display={'flex'} justifyContent={'space-between'} alignItems={'center'}>
            <ListItem id={'idLesson'}>
              <Typography style={{ color: 'blue' }}>
                4.5 Contraindicaciones y complicaciones
              </Typography>
            </ListItem>
            <IconButton>
              <Avatar src={viewIcon} style={{ width: 29, height: 25 }} onClick={handleVesical45} />
            </IconButton>
          </Box>
        </Box>
      </ListItem>
      <ListItem>
        <Box className={styles.lessonItem}>
          <Box display={'flex'} justifyContent={'space-between'} alignItems={'center'}>
            <ListItem id={'idLesson'}>
              <Typography style={{ color: 'blue' }}>
                4.6 Alternativas al cateterismo intermitente
              </Typography>
            </ListItem>
            <IconButton>
              <Avatar src={viewIcon} style={{ width: 29, height: 25 }} onClick={handleVesical46} />
            </IconButton>
          </Box>
        </Box>
      </ListItem>
    </List>
  )
}
