import { PatientSymptom } from './PatientSymptom'

export interface PatientSymptomDTO {
  id: string
  createdAt: Date
  symptomID: string
  patientID: string
  other: string
}

export function emptyPatientSymptomDTO(): PatientSymptomDTO {
  return {
    id: '',
    createdAt: new Date(),
    symptomID: '',
    patientID: '',
    other: '',
  }
}

export function toModel(p: PatientSymptomDTO): PatientSymptom {
  return new PatientSymptom(p)
}

export function fromModel(p: PatientSymptom): PatientSymptomDTO {
  return {
    id: p.id,
    createdAt: p.createdAt,
    symptomID: p.symptomID,
    patientID: p.patientID,
    other: p.other,
  }
}
