import { Grid } from '@material-ui/core'
import styles from '../../Login.module.css'
import React from 'react'
import { Box } from '@mui/material'
import { useTranslation } from 'react-i18next'

const COOKIES_POLICY_URL = 'https://www.wellspect.es/politica-de-cookies/higea'
const PRIVACY_POLICY_URL = 'https://www.wellspect.es/politica-de-privacidad-de-datos/higea_hcp'
const TERMS_OF_USE_URL = 'https://www.wellspect.es/servicios/higea/terminos-de-uso'

export const Footer = () => {
  const { t } = useTranslation()

  const openPDFInNewWindow = (pdfURL: string) => {
    window.open(pdfURL, '_blank')
  }

  return (
    <Box className={styles.footer} component={'footer'}>
      <Grid container spacing={0} style={{ justifyContent: 'center' }}>
        <Grid item style={{ marginRight: '0.7%' }}>
          <h4 className={styles.links} onClick={() => openPDFInNewWindow(TERMS_OF_USE_URL)}>
            * {t('Términos de uso')}
          </h4>
        </Grid>
        <Grid item style={{ marginRight: '0.7%' }}>
          <h4 className={styles.links} onClick={() => openPDFInNewWindow(COOKIES_POLICY_URL)}>
            * {t('Política de cookies')}
          </h4>
        </Grid>
        <Grid item style={{ marginRight: '0.7%' }}>
          <h4 className={styles.links} onClick={() => openPDFInNewWindow(PRIVACY_POLICY_URL)}>
            * {t('Política de privacidad')}
          </h4>
        </Grid>
      </Grid>
    </Box>
  )
}
