import React, { useState } from 'react'
import TextField from '@material-ui/core/TextField'
import Container from '@material-ui/core/Container'
import { getUserContainer } from './container/user-module'
import { ILoggedUserService } from './modules/users/services/LoggedUserService'
import { LOGGED_USER_SERVICE_KEY } from './modules/users'
import { useTranslation } from 'react-i18next'
import styles from './Login.module.css'
import loginWeb2 from './assets/brand_logo/Logo-Higea.svg'
import icoUser from './assets/higea/Ico-usuario.svg'
import icoPass from './assets/higea/Ico_candado.svg'
import { Box, Grid, makeStyles } from '@material-ui/core'
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
} from '@mui/material'
import { LoggedUserDTO } from 'modules/users/models/LoggedUserDTO'
import i18n from 'i18next'
import { useSnackbar } from 'notistack'

const loggedUserService = getUserContainer().get<ILoggedUserService>(LOGGED_USER_SERVICE_KEY)

type LoginProps = {
  goToProfessional: () => void
  goToLegacyTerms: (dto: LoggedUserDTO) => void
  goToTFA: (u: LoggedUserDTO) => void
}

const COOKIES_POLICY_URL = 'https://www.wellspect.es/politica-de-cookies/higea'
const PRIVACY_POLICY_URL = 'https://www.wellspect.es/politica-de-privacidad-de-datos/higea_hcp'
const TERMS_OF_USE_URL = 'https://www.wellspect.es/servicios/higea/terminos-de-uso'

export default function Login(props: LoginProps) {
  const { t } = useTranslation()
  const { enqueueSnackbar } = useSnackbar()
  const [login, setLogin] = useState('')
  const [password, setPassword] = useState('')
  const [hasError, setHasError] = useState('')
  const [openModalPass, setOpenModalPass] = useState<boolean>(false)
  const [recoverEmail, setRecoverEmail] = useState<string>('')
  const { innerWidth } = window

  const handlerLogin = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    doLogin()
  }

  const openPDFInNewWindow = (pdfURL: string) => {
    window.open(pdfURL, '_blank')
  }

  /* const downloadPDF = () => {
    const pdfURL = '/sharedlegalTerms/privacy_policy.pdf'

    fetch(pdfURL, {
      headers: {
        'Content-Type': 'application/pdf',
      },
    })
      .then((response) => response.blob())
      .then((blob) => {
        console.log('blob', blob)
        // Crea una URL temporal para el archivo Blob.
        const url = URL.createObjectURL(blob)

        console.log('url', url)

        // Crea un enlace <a> temporal y simula un clic para iniciar la descarga.
        const link = document.createElement('a')
        link.href = url
        link.download = 'privacy_policy.pdf'
        link.click()

        // Libera la URL temporal después de la descarga.
        URL.revokeObjectURL(url)
      })
      .catch((error) => {
        console.error('Error al descargar el archivo PDF:', error)
      })
  }*/

  const doLogin = () => {
    loggedUserService.login({ login, password }).subscribe((res) => {
      if (res) {
        if (res.language == 1) {
          i18n.changeLanguage('es')
        } else {
          i18n.changeLanguage('en')
        }
        if (!res.active) {
          setHasError(t('notValidated'))
        } else if (res?.firstLogin) {
          props.goToLegacyTerms(res)
        } else {
          res && props.goToTFA(res)
        }
      } else {
        setHasError(t('auth error text'))
      }
    })
  }

  const handleForgotPassword = () => {
    setOpenModalPass(true)
  }

  const handleClose = () => {
    setOpenModalPass(false)
  }

  const handleSendNewPassword = () => {
    setOpenModalPass(false)
    if (recoverEmail != '') {
      enqueueSnackbar(t('emailSent'), { variant: 'success' })
      loggedUserService.sendRecoverPassword(recoverEmail)
    }
  }

  const useStyles = makeStyles({
    input: {
      '&:-webkit-autofill': {
        WebkitBoxShadow: '0 0 0 1000px white inset',
        fontSize: '14px',
        fontFamily: 'Open-sans, sans-serif',
      },
      '&::placeholder': {
        color: '#000',
        fontStyle: 'Roboto',
        fontSize: 14,
        //fontWeight: 'bold',
        opacity: 1,
        fontFamily: 'Open-sans, sans-serif',
      },
    },
  })

  const classes = useStyles()
  return (
    <div className={styles.background}>
      <Container component="div" className={styles.mainContainer}>
        <img src={loginWeb2} alt="Logo" style={{ width: '20%', marginBottom: '7.5%' }} />
        <div className={styles.container}>
          <form
            className={styles.formContainer}
            noValidate
            onSubmit={handlerLogin}
            style={{ marginLeft: innerWidth < 900 ? '-3%' : '' }}
          >
            <h2 style={{ fontFamily: 'Gotham', textAlign: 'left' }}>{t('startSession')}</h2>
            <Divider style={{ marginBottom: 20 }} />
            <Box className={styles.boxInput} style={{ display: 'flex', alignItems: 'center' }}>
              <img
                src={icoUser}
                alt={'User'}
                style={{ width: '5%', marginLeft: 8, marginRight: 4 }}
              />
              <TextField
                //id="input-with-icon-textfield"
                variant="standard"
                size={'small'}
                style={{ height: '40px', padding: '4px', fontFamily: 'Open-sans, sans-serif' }}
                InputProps={{ disableUnderline: true, classes: { input: classes.input } }}
                margin="normal"
                required
                fullWidth
                id="login"
                placeholder={'email'}
                //label={t('email')}
                name="login"
                autoComplete="login"
                autoFocus
                value={login}
                onChange={(e) => setLogin(e.target.value)}
              />
            </Box>
            <Box
              className={styles.boxInput}
              style={{ display: 'flex', alignItems: 'center', marginTop: 10 }}
            >
              <img
                src={icoPass}
                alt={'User'}
                style={{ width: '5%', marginLeft: 8, marginRight: 4 }}
              />
              <TextField
                variant="standard"
                size={'small'}
                style={{ height: '40px', padding: '4px', fontFamily: 'Open-sans, sans-serif' }}
                InputProps={{ disableUnderline: true, classes: { input: classes.input } }}
                margin="normal"
                required
                fullWidth
                name="password"
                placeholder={t('Password')}
                //label={t('Password')}
                type="password"
                id="password"
                autoComplete="current-password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
            </Box>
            {hasError != '' && <h5 color="error">{hasError}</h5>}
            <Box className={styles.button}>
              <Button
                type={'submit'}
                fullWidth={true}
                style={{
                  backgroundColor: '#add5ff',
                  color: 'white',
                  borderRadius: '40px',
                  textTransform: 'none',
                  fontFamily: 'Open-sans, sans-serif',
                }}
              >
                {t('login')}
              </Button>
            </Box>
            <h4 className={styles.forgottenPasswordText} onClick={handleForgotPassword}>
              {t('forgottenPassword')}
            </h4>
            <h4 className={styles.forgottenPasswordText} onClick={props.goToProfessional}>
              {t('registerAsProfessional')}
            </h4>
          </form>
        </div>
        <h3 style={{ marginTop: '3%', fontFamily: 'Gotham', fontSize: 18, color: '#868686' }}>
          Sponsored by Wellspect
        </h3>
        <Divider style={{ width: '25%', marginBottom: 40, marginTop: 10 }} variant="middle" />
        <Grid container spacing={0} style={{ justifyContent: 'center' }}>
          <Grid item style={{ marginRight: '0.7%' }}>
            <h4 className={styles.links} onClick={() => openPDFInNewWindow(TERMS_OF_USE_URL)}>
              * {t('Términos de uso')}
            </h4>
          </Grid>
          <Grid item style={{ marginRight: '0.7%' }}>
            <h4 className={styles.links} onClick={() => openPDFInNewWindow(COOKIES_POLICY_URL)}>
              * {t('Política de cookies')}
            </h4>
          </Grid>
          <Grid item style={{ marginRight: '0.7%' }}>
            <h4 className={styles.links} onClick={() => openPDFInNewWindow(PRIVACY_POLICY_URL)}>
              * {t('Política de privacidad')}
            </h4>
          </Grid>
        </Grid>
      </Container>
      <Dialog open={openModalPass} onClose={handleClose}>
        <DialogTitle>{t('forgotPasswordModalTitle')}</DialogTitle>
        <DialogContent>
          <DialogContentText>{t('forgotPasswordModalText')}</DialogContentText>
          <TextField
            variant="standard"
            margin="normal"
            fullWidth
            name="emailRecover"
            label={t('email')}
            id="emailRecover"
            value={recoverEmail}
            onChange={(e) => setRecoverEmail(e.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleSendNewPassword}>{t('accept')}</Button>
          <Button onClick={handleClose}>{t('cancel')}</Button>
        </DialogActions>
      </Dialog>
    </div>
  )
}
