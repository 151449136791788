import { PatientProblem } from './PatientProblem'

export interface PatientProblemDTO {
  id: string
  createdAt: Date
  problemID: string
  patientID: string
}

export function emptyPatientProblemDTO(): PatientProblemDTO {
  return {
    id: '',
    createdAt: new Date(),
    problemID: '',
    patientID: '',
  }
}

export function toModel(p: PatientProblemDTO): PatientProblem {
  return new PatientProblem(p)
}

export function fromModel(p: PatientProblem): PatientProblemDTO {
  return {
    id: p.id,
    createdAt: p.createdAt,
    problemID: p.problemID,
    patientID: p.patientID,
  }
}
