import i18n from '../../../i18n'

export enum DeliveryStatus {
  Created = 1,
  InProgress,
  Sent,
  Received,
  Viewed,
  Deleted,
  Error = 30,
}

export const deliveryStatuses = (): Record<DeliveryStatus, string> => ({
  [DeliveryStatus.Created]: i18n.t('created'),
  [DeliveryStatus.InProgress]: i18n.t('inprogress'),
  [DeliveryStatus.Sent]: i18n.t('sent'),
  [DeliveryStatus.Received]: i18n.t('received'),
  [DeliveryStatus.Viewed]: i18n.t('viewed'),
  [DeliveryStatus.Deleted]: i18n.t('removed'),
  [DeliveryStatus.Error]: i18n.t('error'),
})
