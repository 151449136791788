import { NotificationMessageDTO } from './NotificationMessageDTO'

export class NotificationMessage {
  private readonly _id: string | undefined
  private readonly _recipientID: string
  private readonly _messageOfID: string
  private readonly _messageOfPatientID: string
  private readonly _messageOfName: string
  private _readed: boolean

  constructor(p: NotificationMessageDTO) {
    this._id = p.id
    this._recipientID = p.recipientID
    this._messageOfID = p.messageOfID
    this._messageOfName = p.messageOfName
    this._readed = p.readed
    this._messageOfPatientID = p.messageOfPatientID
  }

  get id(): string | undefined {
    return this._id
  }

  get messageOfPatientID(): string {
    return this._messageOfPatientID
  }

  get recipientID(): string {
    return this._recipientID
  }

  get messageOfID(): string {
    return this._messageOfID
  }

  get messageOfName(): string {
    return this._messageOfName
  }

  get readed(): boolean {
    return this._readed
  }

  set readed(r: boolean) {
    this._readed = r
  }
}

export interface NotificationMessageQuery {
  recipientID: string
  messageOfPatientID: string
}
