import generic from '../../../../assets/generic.module.css'
import { Box, Button, Divider, Modal } from '@mui/material'
import image from './intestinoSpanish.png'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'

export const CIS110 = () => {
  const { t } = useTranslation()
  const [openModal, setOpenModal] = useState<boolean>(false)
  const { innerWidth } = window
  const handleOpenModal = () => setOpenModal(true)

  const handleCloseModal = () => setOpenModal(false)

  return (
    <>
      <Box className={generic.pageContainer} style={{ backgroundColor: 'white', display: 'flex' }}>
        <Box style={{ width: '50%' }}>
          <Box className={generic.pageContainer} display="flex">
            <h2>
              <b>1.1 La función del intestino</b>
            </h2>
          </Box>
          <Box>
            <h1 style={{ color: 'dodgerblue' }}>
              <b>¿Qué es el intestino y como funciona?</b>
            </h1>
          </Box>

          <Box>
            <div style={{ marginLeft: '2%' }}>
              La función del sistema digestivo es la asimilación de los alimentos por el organismo y
              la eliminación de los desechos. El intestino es donde se almacenan los productos de
              desecho de la alimentación hasta que se vacían en forma de evacuación intestinal
              (heces/excrementos).
              <br />
              <br />
              Los alimentos y los líquidos que llegan al organismo se descomponen en trozos más
              pequeños antes de llegar al intestino, hasta llegar a un tamaño que el cuerpo pueda
              absorber y/o para llevar los nutrientes donde sea necesario. Los músculos del
              intestino hacen avanzar el contenido con un movimiento en onda llamado peristalsis.
              Las partes restantes que el cuerpo no necesita de los alimentos que comemos, es decir,
              los desechos, se convierten en heces.
              <br />
              <br />
              El tiempo de tránsito normal a través del colon (es decir, de la boca al ano) es de
              entre 1 y 3 días. La variación entre los tiempos de tránsito depende de cada persona,
              su dieta y su estilo de vida.
            </div>
          </Box>

          <Box>
            <h1 style={{ marginLeft: '2%' }}>
              <br />
              <b>¿CUÁLES SON LAS DIFERENTES FUNCIONES DEL INTESTINO?</b>
            </h1>
          </Box>
          <Box>
            <h4 style={{ color: 'dodgerblue', marginLeft: '2%' }}>Estómago</h4>
          </Box>
          <Box>
            <div style={{ marginLeft: '2%' }}>
              Los alimentos se digieren (se descomponen) después de masticarlos en la boca, con la
              ayuda de los ácidos gástricos y las enzimas digestivas. El estómago hace que los
              alimentos digeridos se desplacen al intestino delgado.
            </div>
          </Box>
          <Box>
            <h4 style={{ color: 'dodgerblue', marginLeft: '2%' }}>Intestino delgado</h4>
          </Box>
          <Box>
            <div style={{ marginLeft: '2%' }}>
              Zona entre el estómago y el intestino grueso. Consta de varias partes. Su función es
              descomponer y absorber continuamente los nutrientes de los alimentos que comemos. Hace
              que los alimentos digeridos se muevan hasta el intestino grueso.
            </div>
          </Box>
        </Box>
        <img
          src={image}
          alt={'Image'}
          onClick={handleOpenModal}
          style={{
            width: innerWidth < 900 ? '30%' : '15%',
            height: '15%',
            marginTop: innerWidth < 900 ? '8%' : '2%',
            marginLeft: innerWidth < 900 ? '10%' : '10%',
            marginRight: '10%',
            display: 'flex',
            justifyContent: 'center',
            borderStyle: 'ridge',
            borderColor: 'dodgerblue',
          }}
        />

        <Modal open={openModal} onClose={handleCloseModal}>
          <Box
            style={{
              backgroundColor: 'white',
              justifyContent: 'center',
              transform: 'translate(0%, 20%)',
              marginLeft: '30%',
              marginRight: '30%',
              padding: '10px',
            }}>
            <h1 style={{ marginLeft: '25%' }}>
              <b>¿Qué es el intestino y como funciona?</b>
            </h1>
            <Divider variant={'middle'} />
            <img
              src={image}
              style={{
                width: '50%',
                height: '25%',
                marginLeft: '25%',
                justifyContent: 'center',
                display: 'flex',
              }}
            />
            <Button
              variant={'outlined'}
              style={{ marginLeft: '45%', marginTop: '1%', justifyContent: 'end', display: 'flex' }}
              onClick={handleCloseModal}>
              {t('close')}
            </Button>
          </Box>
        </Modal>
      </Box>
    </>
  )
}
