import generic from '../../../../assets/generic.module.css'
import { Box } from '@mui/material'
//import video from './master_EUD_esp_v5.mp4'

export const CVS510 = () => {
    const { innerWidth } = window

  return (
    <>
      <Box className={generic.pageContainer} style={{ backgroundColor: 'white' }}>
        <Box className={generic.pageContainer}>
          <h2>
            <b>5.1 Vídeo</b>
          </h2>
        </Box>
        <Box
          className={generic.pageContainer}
          style={{ display: 'flex', justifyContent: 'center' }}>
          <video
            width={innerWidth < 900 ? '720' : '750'}
            height={innerWidth < 900 ? '450' : '500'}
            controls>
            <source src={"https://drive.google.com/uc?export=download&id=1T9z40QafVzhC7-ISY0wG6bthdITz7cQ7&confirm=t&uuid=b6eda575-8a8a-4283-9269-a4f34e3937c0"} type="video/mp4" />
          </video>
        </Box>
      </Box>
    </>
  )
}
