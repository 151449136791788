import generic from '../../../../assets/generic.module.css'
import { Box } from '@mui/material'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'

export const CIS210 = () => {
  return (
    <>
      <Box className={generic.pageContainer} style={{ backgroundColor: 'white' }}>
        <Box className={generic.pageContainer} display="flex">
          <h2>
            <b>2.1 Descripción general de la incontinencia fecal (IF)</b>
          </h2>
        </Box>
        <Box className={generic.pageContainer}>
          <div>
            La incontinencia fecal (IF) es el síntoma que se clasifica como el más molesto para los
            pacientes. La IF también es también el síntoma del que menos se informa, debido a la
            vergüenza que produce y a que supone un tabú.
            <br />
            <br />
            Existen distintos informes de prevalencia con variaciones del 0,4 al 18 % de la
            población. A veces, se informa de una mayor prevalencia en mujeres y se observa
            habitualmente en ancianos, lo que puede deberse a las diferencias anatómicas de género
            y, posteriormente, debido a factores asociados con el proceso del envejecimiento.
            <br />
            <br />
            La definición de la IF es la pérdida involuntaria de contenido rectal (heces) a través
            del canal anal y la incapacidad de posponer una evacuación hasta que sea socialmente
            idóneo.
            <br />
            <br />
            Existen 3 clasificaciones de síntomas de IF:
            <br />
            <br />
            1. Incontinencia pasiva, definida como la pérdida involuntaria de heces sin sensación de
            urgencia o necesidad de defecar;
            <br />
            <br />
            2. Incontinencia de urgencia, definida como la pérdida del contenido intestinal a pesar
            de los intentos de retener el contenido;
            <br />
            <br />
            3. Incontinencia mixta, definida como síntomas tanto pasivos como de urgencia. <br />
            <br />
            <br />
            La evaluación, el diagnóstico y el tratamiento de la IF deben adaptarse a cada persona.
            <br />
            <br />
            <br />
            <br />
          </div>
        </Box>
      </Box>
    </>
  )
}
