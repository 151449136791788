import { useState } from 'react'
import Container from '@material-ui/core/Container'
import { getUserContainer } from './container/user-module'
import { ILoggedUserService } from './modules/users/services/LoggedUserService'
import { IUserService, LOGGED_USER_SERVICE_KEY, USER_SERVICE_KEY } from './modules/users'
import { useTranslation } from 'react-i18next'
import styles from './Login.module.css'
import loginWeb2 from './assets/brand_logo/Logo-Higea.svg'
import { Box } from '@material-ui/core'
import { fromModel } from 'modules/users/models/User'
import Grid from '@material-ui/core/Grid'
import { AiFillPlusCircle, AiFillMinusCircle } from 'react-icons/ai'
import { AppButton, ButtonTheme } from 'components/app-button/AppButton'
import { LoggedUserDTO } from 'modules/users/models/LoggedUserDTO'
import { AiOutlineArrowLeft } from 'react-icons/ai'

const loggedUserService = getUserContainer().get<ILoggedUserService>(LOGGED_USER_SERVICE_KEY)
const userService = getUserContainer().get<IUserService>(USER_SERVICE_KEY)

type AppPrivacyProps = {
  loggedUser: LoggedUserDTO
  goToLogin: () => void
}

const COOKIES_POLICY_URL = 'https://www.wellspect.es/politica-de-cookies/higea'
const PRIVACY_POLICY_URL = 'https://www.wellspect.es/politica-de-privacidad-de-datos/higea_hcp'
const TERMS_OF_USE_URL = 'https://www.wellspect.es/servicios/higea/terminos-de-uso'

export default function AppPrivacy(props: AppPrivacyProps) {
  const { t } = useTranslation()
  const [accepted, setAccepted] = useState<boolean>(false)

  const handleContinue = () => {
    userService.getByID(props.loggedUser.id).subscribe((res) => {
      if (res) {
        let user = res
        user.firstLogin = false
        props.goToLogin()
        userService.update(fromModel(res)).subscribe((res) => {
          loggedUserService.storeUser(props.loggedUser)
        })
      }
    })
  }
  const openPDFInNewWindow = (pdfURL: string) => {
    window.open(pdfURL, '_blank')
  }

  return (
    <div className={styles.background}>
      <Container
        component="div"
        className={styles.mainContainer}
        style={{ overflowX: 'hidden', overflowY: 'hidden' }}
      >
        <img
          src={loginWeb2}
          alt="Logo"
          style={{ width: '20%', marginTop: '2.5%', marginBottom: '2%' }}
        />
        <Box
          style={{
            width: '95%',
            backgroundColor: 'white',
            height: '78%',
            borderRadius: 40,
            padding: 30,
          }}
        >
          <Box style={{ display: 'flex', justifyContent: 'center', marginBottom: '1.5%' }}>
            <h1 style={{ color: 'black', fontWeight: 'bold' }}>Aviso legal</h1>
          </Box>
          <Box
            style={{
              overflowY: 'auto',
              overflowX: 'hidden',
              marginLeft: '6%',
              position: 'relative',
              height: '60%',
            }}
          >
            <Grid container spacing={3} style={{ display: 'flex', justifyContent: 'center' }}>
              <Grid item style={{ marginTop: '0.4%' }}>
                <Box style={{ width: 15, height: 15, backgroundColor: '#1996FF' }}></Box>
              </Grid>
              <Grid item md={3}>
                <p
                  onClick={() => openPDFInNewWindow(PRIVACY_POLICY_URL)}
                  style={{
                    fontWeight: 'bold',
                    fontSize: 23,
                    marginTop: '-0.3%',
                    textDecorationLine: 'underline',
                    color: 'black',
                    cursor: 'pointer',
                  }}
                >
                  Política de privacidad
                </p>
              </Grid>
            </Grid>
            <Grid
              container
              spacing={3}
              style={{ marginTop: '1.5%', display: 'flex', justifyContent: 'center' }}
            >
              <Grid item style={{ marginTop: '0.6%' }}>
                <Box style={{ width: 15, height: 15, backgroundColor: '#1996FF' }}></Box>
              </Grid>
              <Grid item md={3}>
                <p
                  onClick={() => openPDFInNewWindow(COOKIES_POLICY_URL)}
                  style={{
                    fontWeight: 'bold',
                    fontSize: 23,
                    marginTop: '-0.3%',
                    textDecorationLine: 'underline',
                    color: 'black',
                    cursor: 'pointer',
                  }}
                >
                  Política de cookies
                </p>
              </Grid>
            </Grid>
            <Grid
              container
              spacing={3}
              style={{ marginTop: '1.5%', display: 'flex', justifyContent: 'center' }}
            >
              <Grid item style={{ marginTop: '0.6%' }}>
                <Box style={{ width: 15, height: 15, backgroundColor: '#1996FF' }}></Box>
              </Grid>
              <Grid item md={3}>
                <p
                  onClick={() => openPDFInNewWindow(TERMS_OF_USE_URL)}
                  style={{
                    fontWeight: 'bold',
                    fontSize: 23,
                    marginTop: '-0.3%',
                    textDecorationLine: 'underline',
                    color: 'black',
                    cursor: 'pointer',
                  }}
                >
                  Términos de uso
                </p>
              </Grid>
            </Grid>
          </Box>
          <hr />
          <Box
            style={{
              display: 'flex',
              padding: '20px',
              columnGap: '1rem',
              justifyContent: 'space-between',
              marginBottom: '2%',
              position: 'relative',
            }}
          >
            <AiOutlineArrowLeft
              size={28}
              style={{ marginTop: '-0.7%' }}
              onClick={() => props.goToLogin()}
            />
            <Grid container spacing={1} style={{ marginLeft: '6%', marginTop: '1%' }}>
              <Grid item style={{ marginTop: '-3%' }}>
                <input
                  type="checkbox"
                  id="cbox1"
                  value="first_checkbox"
                  checked={accepted}
                  style={{ zoom: 1.8 }}
                  onChange={() => setAccepted(!accepted)}
                />
              </Grid>
              <Grid item xs={11} style={{ fontSize: 17, marginTop: '-2.5%', marginLeft: '0.5%' }}>
                Acepto los términos legales de la plataforma de Higea
              </Grid>
            </Grid>
            <div style={{ marginTop: '-1%' }}>
              <AppButton
                theme={ButtonTheme.NewPrimaryLight}
                type={'button'}
                label={t('continue')}
                handler={() => accepted && handleContinue()}
              />
            </div>
          </Box>
        </Box>
        <h1 style={{ marginTop: '0%' }}>Wellspect With You</h1>
      </Container>
    </div>
  )
}
