import generic from '../../../../assets/generic.module.css'
import { Box, Button, Divider, Modal } from '@mui/material'
import React, { useState } from 'react'
import image from '../intestinal/enfermera.jpg'
import { useTranslation } from 'react-i18next'

export const CVS110 = () => {
  const { t } = useTranslation()
  const [openModal, setOpenModal] = useState<boolean>(false)
  const { innerWidth } = window
  const handleOpenModal = () => setOpenModal(true)

  const handleCloseModal = () => setOpenModal(false)

  return (
    <>
      <Box className={generic.pageContainer} style={{ backgroundColor: 'white', display: 'flex' }}>
        <Box className={generic.pageContainer} style={{ width: '50%' }}>
          <h2>
            <b>1.1 Disfunción y trastornos del tracto urinario</b>
          </h2>
          <div>
            <b>
              El término ITUI abarca a ambos sexos, pero se aplica más a menudo a los hombres,
              aunque la prevalencia global es mayor en las mujeres
            </b>
            <br />
            <br />
            <br />
            <b>Los problemas urinarios pueden dividirse en tres grupos principales:</b>
            <br />
            <br />
            &nbsp;&nbsp;&nbsp;&nbsp;•&nbsp;&nbsp;&nbsp;&nbsp; Incontinencia (fugas)
            <br />
            <br />
            &nbsp;&nbsp;&nbsp;&nbsp;•&nbsp;&nbsp;&nbsp;&nbsp; Retención (dificultades para vaciar la
            vejiga)
            <br />
            <br />
            &nbsp;&nbsp;&nbsp;&nbsp;•&nbsp;&nbsp;&nbsp;&nbsp; ITU (infecciones)
            <br />
            <br />
            <br />
            <br />
          </div>
        </Box>
        <img
          src={image}
          onClick={handleOpenModal}
          style={
            innerWidth < 900
              ? { width: '250px', marginLeft: '7.5%', height: '280px' }
              : { width: '500px', marginLeft: '5%' }
          }
        />
        <Modal open={openModal} onClose={handleCloseModal}>
          <Box
            style={{
              backgroundColor: 'white',
              justifyContent: 'center',
              transform: 'translate(0%, 20%)',
              marginLeft: '30%',
              marginRight: '30%',
              padding: '10px',
            }}>
            <h1 style={{ marginLeft: '25%' }}>
              <b>1.1 Disfunción y trastornos del tracto unirario</b>
            </h1>
            <Divider variant={'middle'} />
            <img
              src={image}
              style={{
                width: '50%',
                height: '25%',
                marginLeft: '25%',
                justifyContent: 'center',
                display: 'flex',
              }}
            />
            <Button
              variant={'outlined'}
              style={{ marginLeft: '45%', marginTop: '1%', justifyContent: 'end', display: 'flex' }}
              onClick={handleCloseModal}>
              {t('close')}
            </Button>
          </Box>
        </Modal>
      </Box>
    </>
  )
}
