import generic from '../../../../assets/generic.module.css'
import { Box, Button, Divider, Modal } from '@mui/material'
import image2 from './piramide.png'
import image from './enfermera.jpg'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'

export const CIS330 = () => {
  const { t } = useTranslation()
  const [openModal, setOpenModal] = useState<boolean>(false)
  const { innerWidth } = window
  const handleOpenModal = () => setOpenModal(true)
  const handleCloseModal = () => setOpenModal(false)

  return (
    <>
      <Box className={generic.pageContainer} style={{ backgroundColor: 'white', display: 'flex' }}>
        <Box style={{ width: '50%' }}>
          <Box className={generic.pageContainer} display="flex">
            <h2>
              <b>4.1. ¿Qué es la ITA?</b>
            </h2>
          </Box>
          <Box className={generic.pageContainer}>
            <div>
              La irrigación transanal, o ITA, también se puede llamar irrigación retrógrada, rectal
              o anal y se refiere a una técnica mínimamente invasiva para vaciar el intestino.
              <br />
              <br />
              La irrigación retrógrada es un término amplio que podría usarse para todas las
              irrigaciones a través del ano y es lo opuesto a la irrigación anterógrada que requiere
              cirugía para instilar agua directamente en el colon.
              <br />
              <br />
            </div>
          </Box>
          <Box className={generic.pageContainer}>
            <img
              src={image2}
              style={{
                marginTop: '2%',
                width: '75%',
                borderStyle: 'ridge',
                borderColor: 'dodgerblue',
              }}
            />
          </Box>
        </Box>
        {/* TODO NECESITO LA IMAGEN DEL VIEJO Y LA ENFERMERA*/}
        <img
          src={image}
          onClick={handleOpenModal}
          style={{
            width: innerWidth < 900 ? '50%' : '40%',
            marginTop: '2%',
            marginLeft: innerWidth < 900 ? '5%' : '10%',
            marginRight: '5%',
          }}
        />

        <Modal open={openModal} onClose={handleCloseModal}>
          <Box
            style={{
              backgroundColor: 'white',
              justifyContent: 'center',
              transform: 'translate(0%, 20%)',
              marginLeft: '30%',
              marginRight: '30%',
              padding: '10px',
            }}>
            <h1 style={{ marginLeft: '25%' }}>
              <b>¿Qué es el intestino y como funciona?</b>
            </h1>
            <Divider variant={'middle'} />
            <img
              src={image}
              style={{
                width: '75%',
                justifyContent: 'center',
                display: 'flex',
                marginLeft: '12%',
                marginTop: '1%',
              }}
              alt={'segunda'}
            />
            <Button
              variant={'outlined'}
              style={{ marginLeft: '45%', marginTop: '1%', justifyContent: 'end', display: 'flex' }}
              onClick={handleCloseModal}>
              {t('close')}
            </Button>
          </Box>
        </Modal>
      </Box>
    </>
  )
}
