import { Message } from './Message'
import { Conversation } from './Conversation'
import { Reaction } from './Reaction'
import { ReadingStatus } from '../enums/ReadingStatus'
import { MessageUser } from './MessageUser'
import { v4 as uuidv4 } from 'uuid'

export interface ConversationDTO {
  id: string | undefined
  user: string
  user2: string
  messages: MessageDTO[] | undefined
  createdAt: Date
  updatedAt: Date
  status: number
}

export function emptyConversationDTO(idUser: string, user2: string): ConversationDTO {
  const id = uuidv4()
  return {
    id,
    user: idUser,
    user2: user2,
    messages: [],
    createdAt: new Date(),
    updatedAt: new Date(),
    status: 0,
  }
}

export function toModel(d: ConversationDTO): Conversation {
  return new Conversation({
    id: d.id,
    user: d.user,
    user2: d.user2,
    messages: d.messages,
    createdAt: d.createdAt,
    updatedAt: d.updatedAt,
    status: d.status,
  })
}

export function fromModel(c: Conversation): ConversationDTO {
  return {
    id: c.id,
    user: c.user,
    user2: c.user2,
    messages: c.messages,
    createdAt: c.createdAt,
    updatedAt: c.updatedAt,
    status: c.status,
  }
}

export interface MessageDTO {
  id?: string | undefined
  text: string
  authorID: string
  conversationID: string
  createdAt: Date
  files?: string[]
  senderID: string
  reactions?: ReactionDTO[]
  status: number
  recipientName: string
  recipientID: string
}

export function messageToModel(m: MessageDTO): Message {
  return new Message({
    id: m.id,
    text: m.text,
    authorID: m.authorID,
    conversationID: m.conversationID,
    createdAt: m.createdAt,
    files: m.files,
    senderID: m.senderID,
    reactions: m.reactions?.map((m) => reactionToModel(m)) || [],
    status: m.status,
    recipientName: m.recipientName,
    recipientID: m.recipientID,
  })
}

export function messageFromModel(m: Message): MessageDTO {
  return {
    id: m.id,
    text: m.text,
    authorID: m.authorID,
    conversationID: m.conversationID,
    createdAt: m.createdAt,
    files: m.files,
    senderID: m.senderID,
    status: m.status,
    reactions: m.reactions?.map((r) => reactionFromModel(r)),
    recipientName: m.recipientName,
    recipientID: m.recipientID,
  }
}

export interface Message2UsersDTO {
  userID: string
  messageID: string
  readingStatus: ReadingStatus
}

export interface ReactionDTO {
  userID: string
  messageID: string
  emoji: string
}

export function reactionFromModel(r: Reaction): ReactionDTO {
  return {
    emoji: r.emoji,
    messageID: r.messageID,
    userID: r.userID,
  }
}

export function reactionToModel(r: ReactionDTO): Reaction {
  return new Reaction(r)
}

export function message2UsersToModel(m: Message2UsersDTO): MessageUser {
  return new MessageUser(m)
}
