import { RouteProps } from '../../routes/AppRouter'
import { CurrentNavHeader } from '../../components/current-nav-header/CurrentNavHeader'
import { Editor as CP } from '../../features/patients/Editor'
import React from 'react'

export function CreateParticipant(props: RouteProps) {
  const title = props.title || ''

  return (
    <>
      <CurrentNavHeader title={title} />
      <CP />
    </>
  )
}
