import { Container } from '../../common/container/Container'

export type ContentContainerConfig = {
  moduleFullUrl: string
}

export type ContentProps = {
  parentContainer: Container
  config: ContentContainerConfig
}

export const APPOINTMENTS_MODULE = Symbol('APPOINTMENTS_MODULE')

export const APPOINTMENTS_API_KEY = Symbol('APPOINTMENTS_API_KEY')

export const APPOINTMENTS_SERVICE_KEY = Symbol('APPOINTMENTS_SERVICE_KEY')
