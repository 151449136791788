import { QuestionnairePatientAnswer } from './QuestionnariePatientAnswer'

export interface QuestionnairePatientAnswerDTO {
  id: string
  answer: string
  questPatientID: string
  response: string
  score: number
  resultText: string
  index: number
}

export function toModel(q: QuestionnairePatientAnswerDTO): QuestionnairePatientAnswer {
  return new QuestionnairePatientAnswer(q)
}

export interface QuestionnairePatientAnswerQuery {
  id: string
  answer: string
  questPatientID: string
  response: string
  score: number
  resultText: string
}

export function fromModel(e: QuestionnairePatientAnswer): QuestionnairePatientAnswerDTO {
  return {
    id: e.id,
    answer: e.answer,
    questPatientID: e.questPatientID,
    response: e.response,
    score: e.score,
    resultText: e.resultText,
    index: e.index,
  }
}
