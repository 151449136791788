import { v4 as uuidv4 } from 'uuid'
import { UserGender } from '../enums/UserGender'
import { LangType } from '../../../common/enums/LangType'

export interface UserDTO {
  id: string
  firstName: string
  lastName: string
  gender: UserGender
  email: string
  dob: Date
  createdAt: Date
  phone: string
  centerID: string
  language: LangType
  photoID: string
  roleID: string
  active: boolean
  password: string
  repeatPassword: string
  autonomousCommunity: string
  province: string
  firstLogin: boolean
}

export function emptyUserDTO() {
  return {
    id: uuidv4(),
    firstName: '',
    lastName: '',
    gender: UserGender.Female,
    email: '',
    dob: new Date(),
    createdAt: new Date(),
    phone: '',
    centerID: '',
    language: LangType.Spanish,
    photoID: '',
    roleID: '',
    active: false,
    password: '',
    repeatPassword: '',
    autonomousCommunity: '',
    province: '',
    firstLogin: true,
  }
}

export function fromModel(u: User): UserDTO {
  return {
    id: u.id,
    firstName: u.firstName,
    lastName: u.lastName,
    gender: u.gender,
    email: u.email,
    dob: u.dob,
    createdAt: u.createdAt,
    phone: u.phone,
    centerID: u.centerID,
    language: u.language,
    photoID: u.photoID,
    roleID: u.roleID,
    active: u.active,
    password: '',
    repeatPassword: '',
    autonomousCommunity: u.autonomousCommunity,
    province: u.province,
    firstLogin: u.firstLogin,
  }
}

export class User {
  private readonly _id: string
  private _firstName: string
  private _lastName: string
  private _gender: UserGender
  private _email: string
  private _dob: Date
  private _createdAt: Date
  private _phone: string
  private _centerID: string
  private _language: LangType
  private _photoID: string
  private _roleID: string
  private _active: boolean
  private _password: string
  private _repeatPassword: string
  private _autonomousCommunity: string
  private _province: string
  private _firstLogin: boolean

  constructor(p: UserDTO) {
    this._id = p.id
    this._firstName = p.firstName
    this._lastName = p.lastName
    this._gender = p.gender
    this._email = p.email
    this._dob = p.dob
    this._createdAt = p.createdAt
    this._phone = p.phone
    this._centerID = p.centerID
    this._language = p.language
    this._photoID = p.photoID
    this._roleID = p.roleID
    this._active = p.active
    this._password = p.password
    this._repeatPassword = p.repeatPassword
    this._autonomousCommunity = p.autonomousCommunity
    this._province = p.province
    this._firstLogin = p.firstLogin
  }

  get id(): string {
    return this._id
  }

  get firstName(): string {
    return this._firstName
  }

  get firstLogin(): boolean {
    return this._firstLogin
  }

  set firstLogin(first: boolean) {
    this._firstLogin = first
  }

  get autonomousCommunity(): string {
    return this._autonomousCommunity
  }

  get province(): string {
    return this._province
  }

  set firstName(value: string) {
    this._firstName = value
  }

  get lastName(): string {
    return this._lastName
  }

  set lastName(value: string) {
    this._lastName = value
  }

  get gender(): UserGender {
    return this._gender
  }

  set gender(value: UserGender) {
    this._gender = value
  }

  get email(): string {
    return this._email
  }

  set email(value: string) {
    this._email = value
  }

  get dob(): Date {
    return this._dob
  }

  set dob(value: Date) {
    this._dob = value
  }

  get createdAt(): Date {
    return this._createdAt
  }

  set createdAt(value: Date) {
    this._createdAt = value
  }

  get phone(): string {
    return this._phone
  }

  set phone(value: string) {
    this._phone = value
  }

  get centerID(): string {
    return this._centerID
  }

  set centerID(value: string) {
    this._centerID = value
  }

  get language(): LangType {
    return this._language
  }

  set language(value: LangType) {
    this._language = value
  }

  get photoID(): string {
    return this._photoID
  }

  set photoID(value: string) {
    this._photoID = value
  }

  get roleID(): string {
    return this._roleID
  }

  set roleID(value: string) {
    this._roleID = value
  }

  get active(): boolean {
    return this._active
  }

  set active(value: boolean) {
    this._active = value
  }

  set password(value: string) {
    this._password = value
  }

  get password(): string {
    return this._password
  }

  set repeatPassword(value: string) {
    this._repeatPassword = value
  }

  get repeatPassword(): string {
    return this._repeatPassword
  }

  toDTO(): UserDTO {
    return {
      id: this.id,
      firstName: this.firstName,
      lastName: this.lastName,
      gender: this.gender,
      email: this.email,
      dob: this.dob,
      createdAt: this.createdAt,
      phone: this.phone,
      centerID: this.centerID,
      language: this.language,
      photoID: this.photoID,
      roleID: this.roleID,
      active: this.active,
      password: this.password,
      repeatPassword: this.repeatPassword,
      autonomousCommunity: this.autonomousCommunity,
      province: this.province,
      firstLogin: this.firstLogin,
    }
  }
}

export interface UserQuery {
  id: string
  ids: string[]
  firstName: string
  lastName: string
  centerID: string
  gender: UserGender
  language: LangType
  dob: Date
  roleID: string
  active: boolean
  autonomousCommunity: string
  province: string
  professionalRole: string
}

export function toModel(d: UserDTO): User {
  return new User(d)
}
