import generic from '../../../../assets/generic.module.css'
import { Box, Button, Divider, Modal } from '@mui/material'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import image from './piramide.png'

export const CIS211 = () => {
  const { t } = useTranslation()
  const [openModal, setOpenModal] = useState<boolean>(false)
  const { innerWidth } = window
  const handleOpenModal = () => setOpenModal(true)
  const handleCloseModal = () => setOpenModal(false)

  return (
    <>
      <Box className={generic.pageContainer} style={{ backgroundColor: 'white' }}>
        <Box className={generic.pageContainer}>
          <h2>
            <b>2.1 Descripción general de la incontinencia fecal (IF)</b>
          </h2>
        </Box>
        <Box>
          <Box>
            <h1 style={{ color: 'dodgerblue' }}>
              <b>Tratamientos de la IF</b>
            </h1>
          </Box>
          <Box className={generic.pageContainer}>
            <h3>
              <b>Líquidos y dieta</b>
            </h3>
          </Box>

          <Box display="flex" className={generic.pageContainer} style={{ marginRight: '15%' }}>
            <div>
              El cuidado intestinal estándar con cambios en el estilo de vida y fármacos son los
              primeros pasos del tratamiento. Para lograr un tratamiento eficaz y cumplir, no solo
              el tratamiento con medicamentos, sino también los cambios en el estilo de vida, se
              necesita comprensión y aceptación de la terapia.
              <br />
              <br />
              <br />
              <br />
              Los tratamientos posteriores deben ofrecerse en función de la pirámide del manejo
              intestinal.
              <br />
              <br />
              <br />
              <br />
              <br />
            </div>
            <Box className={generic.pageContainer}>
              <img
                src={image}
                onClick={handleOpenModal}
                style={{
                  width: innerWidth < 900 ? '110%' : '80%',
                  marginLeft: innerWidth < 900 ? '35%' : '10%',
                  borderStyle: 'ridge',
                  borderColor: 'dodgerblue',
                }}
              />
            </Box>
          </Box>
        </Box>
        <Box style={{ marginTop: '2%', marginRight: '12%' }}>
          <Box>
            <h1 style={{ color: 'dodgerblue' }}>
              <b>Tratamientos farmacológicos </b>
            </h1>
          </Box>
          <Box className={generic.pageContainer}>
            <div>
              Los tratamientos farmacológicos, que incluyen preparaciones orales y supositorios, han
              demostrado tasas de respuesta bajas, de entre el 20 y el 40 %, en ensayos clínicos
              aleatorizados con criterios principales de valoración basados en síntomas.
              <br />
              <br />
              <b>Irrigación transanal</b>
              <br />
              <br />
              La irrigación transanal es un tratamiento óptimo para la incontinencia fecal y ha
              demostrado mejorar los síntomas de la IF, así como la calidad de vida y la
              socialización y evita la necesidad de optar por tratamientos más invasivos.
            </div>
          </Box>
        </Box>

        <Modal open={openModal} onClose={handleCloseModal}>
          <Box
            style={{
              backgroundColor: 'white',
              justifyContent: 'center',
              transform: 'translate(0%, 20%)',
              marginLeft: '30%',
              marginRight: '30%',
              padding: '20px',
            }}>
            <h1 style={{ marginLeft: '25%' }}>
              <b>Descripción general de incontinencia fecal (IF)</b>
            </h1>
            <Divider variant={'middle'} />
            <img
              src={image}
              style={{
                width: '80%',
                height: '25%',
                marginTop: '1%',
                marginLeft: '10%',
                justifyContent: 'center',
                display: 'flex',
              }}
            />
            <Button
              variant={'outlined'}
              style={{ marginLeft: '45%', marginTop: '1%', justifyContent: 'end', display: 'flex' }}
              onClick={handleCloseModal}>
              {t('close')}
            </Button>
          </Box>
        </Modal>
      </Box>
    </>
  )
}
