import { User, UserDTO, UserQuery } from '../models/User'
import { Container, IInit } from '../../../common/container/Container'
import { Observable } from 'rxjs'
import { Query } from '../../../common/api/Query'
import { IStatusService } from '../../../common/status/StatusService'
import { IUserApi } from '../api/UserApi'
import { ItemList } from '../../../common/models/ItemList'
import { RelatedUser } from '../models/RelatedUser'
import { FamiliarData, FamiliarDataQuery } from '../models/FamiliarData'
import { FamiliarDataDTO } from '../models/FamiliarDataDTO'
import { STATUS_SERVICE_KEY } from '../../../container/app'
import { Role } from '../models/Role'
import { Patient } from 'modules/patients/models/Patient'

type Props = {
  apiKey: symbol
}

export interface IUserService extends IInit {
  getByID(id: string): Observable<User | undefined>

  getFilteredItems(q: Query<UserQuery>): Observable<User[]>

  getFilteredList(q: Query<UserQuery>): Observable<ItemList<User>>

  add(e: UserDTO): Observable<User | undefined>

  addPatientUser(e: UserDTO): Observable<User | undefined>

  update(e: UserDTO): Observable<User | undefined>

  updateByAdmin(e: UserDTO): Observable<User | undefined>

  getRelated(id: string, q: Query<User>): Observable<RelatedUser | undefined>

  getUsersByDoctor(id: string): Observable<User[] | undefined>

  getPatientsByDoctor(id: string): Observable<Patient[] | undefined>

  getByDoctorID(q: Query<UserQuery>): Observable<ItemList<User>>

  removeRelated(id: string, rID: string): Observable<boolean>

  getUserRolesByCircle(q: Query<User>): Observable<ItemList<User>>

  getInformationByID(id: string): Observable<FamiliarData | undefined>

  getInformationByUser(q: Query<FamiliarDataQuery>): Observable<ItemList<FamiliarData>>

  addInformation(e: FamiliarDataDTO): Observable<FamiliarData | undefined>

  updateInformation(e: FamiliarDataDTO): Observable<FamiliarData | undefined>

  deleteInformation(id: string): Observable<boolean>

  getPermissions(): Observable<ItemList<Role>>

  delete(id: string): Observable<boolean>

  validateUser(id: string): Observable<any | undefined>

  sendHelpEmail(description: string, email: string): Observable<User | undefined>

  verifyToken(userID: string, token: string): Observable<any | undefined>

  isRegistered(email: string): Observable<boolean | undefined>
}

export class UserService implements IUserService {
  private readonly _apiKey: symbol
  private _container!: Container
  private _api!: IUserApi
  private _statusService!: IStatusService

  constructor(p: Props) {
    this._apiKey = p.apiKey
  }

  init(c: Container) {
    this._container = c
    this._api = this._container.get<IUserApi>(this._apiKey)
    this._statusService = this._container.get<IStatusService>(STATUS_SERVICE_KEY)
  }

  add(e: UserDTO): Observable<User | undefined> {
    return this._api.add(e)
  }

  getByID(id: string): Observable<User | undefined> {
    return this._api.getByID(id)
  }

  isRegistered(email: string): Observable<boolean | undefined> {
    return this._api.isRegistered(email)
  }

  getFilteredItems(q: Query<UserQuery>): Observable<User[]> {
    return this._api.getFilteredItems(q).pipe()
  }

  getFilteredList(q: Query<UserQuery>): Observable<ItemList<User>> {
    return this._api.getFilteredList(q).pipe()
  }

  update(e: UserDTO): Observable<User | undefined> {
    return this._api.update(e)
  }

  updateByAdmin(e: UserDTO): Observable<User | undefined> {
    return this._api.updateByAdmin(e)
  }

  getRelated(id: string, q: Query<User>): Observable<RelatedUser | undefined> {
    return this._api.getRelated(id, q)
  }

  getByDoctorID(q: Query<UserQuery>): Observable<ItemList<User>> {
    return this._api.getByDoctorID(q)
  }

  removeRelated(id: string, rID: string): Observable<boolean> {
    return this._api.removeRelated(id, rID)
  }

  getUserRolesByCircle(q: Query<User>): Observable<ItemList<User>> {
    return this._api.getUserRolesByCircle(q)
  }

  getInformationByID(id: string): Observable<FamiliarData | undefined> {
    return this._api.getInformationByID(id)
  }

  getInformationByUser(q: Query<FamiliarDataQuery>): Observable<ItemList<FamiliarData>> {
    return this._api.getInformationByUser(q)
  }

  addInformation(e: FamiliarDataDTO): Observable<FamiliarData | undefined> {
    return this._api.addInformation(e)
  }

  updateInformation(e: FamiliarDataDTO): Observable<FamiliarData | undefined> {
    return this._api.updateInformation(e)
  }

  deleteInformation(id: string): Observable<boolean> {
    return this._api.deleteInformation(id)
  }

  getPermissions(): Observable<ItemList<Role>> {
    return this._api.getPermissions()
  }

  delete(id: string): Observable<boolean> {
    return this._api.delete(id)
  }

  addPatientUser(e: UserDTO): Observable<User | undefined> {
    return this._api.addPatientUser(e)
  }

  getUsersByDoctor(id: string): Observable<User[] | undefined> {
    return this._api.getUsersByDoctor(id)
  }

  getPatientsByDoctor(id: string): Observable<Patient[] | undefined> {
    return this._api.getPatientsByDoctor(id)
  }

  validateUser(id: string): Observable<any | undefined> {
    return this._api.validateUser(id)
  }

  sendHelpEmail(descr: string, email: string): Observable<User | undefined> {
    return this._api.sendHelpEmail(descr, email)
  }

  verifyToken(userID: string, token: string): Observable<User | undefined> {
    return this._api.verifyToken(userID, token)
  }
}
