import { Box, List, ListItem } from '@mui/material'
import { Avatar, IconButton, Typography } from '@material-ui/core'
import viewIcon from '../../../../assets/higea/ico-view.svg'
import React from 'react'
import { navigate } from '@reach/router'
import {
  ROUTE_EDUCATIONALVESICAL21,
  ROUTE_EDUCATIONALVESICAL22,
  ROUTE_EDUCATIONALVESICAL23,
} from '../../../../routes/routes-constants'
import styles from '../../EducationalProfessional.module.css'

export const Module2Lessons = () => {
  const handleVesical21 = () => navigate(ROUTE_EDUCATIONALVESICAL21).then()
  const handleVesical22 = () => navigate(ROUTE_EDUCATIONALVESICAL22).then()
  const handleVesical23 = () => navigate(ROUTE_EDUCATIONALVESICAL23).then()

  return (
    <List>
      <ListItem>
        <Box className={styles.lessonItem}>
          <Box display={'flex'} justifyContent={'space-between'} alignItems={'center'}>
            <ListItem id={'idLesson'}>
              <Typography style={{ color: 'blue' }}>2.1 ¿Qué es y como funciona?</Typography>
            </ListItem>
            <IconButton>
              <Avatar src={viewIcon} style={{ width: 29, height: 25 }} onClick={handleVesical21} />
            </IconButton>
          </Box>
        </Box>
      </ListItem>
      <ListItem>
        <Box className={styles.lessonItem}>
          <Box display={'flex'} justifyContent={'space-between'} alignItems={'center'}>
            <ListItem id={'idLesson'}>
              <Typography style={{ color: 'blue' }}>2.2 Anatomía y fisiología</Typography>
            </ListItem>
            <IconButton>
              <Avatar src={viewIcon} style={{ width: 29, height: 25 }} onClick={handleVesical22} />
            </IconButton>
          </Box>
        </Box>
      </ListItem>
      <ListItem>
        <Box className={styles.lessonItem}>
          <Box display={'flex'} justifyContent={'space-between'} alignItems={'center'}>
            <ListItem id={'idLesson'}>
              <Typography style={{ color: 'blue' }}>
                2.3 Función de la vejiga y ciclo miccional
              </Typography>
            </ListItem>
            <IconButton>
              <Avatar src={viewIcon} style={{ width: 29, height: 25 }} onClick={handleVesical23} />
            </IconButton>
          </Box>
        </Box>
      </ListItem>
    </List>
  )
}
