import { Box, Grid, Avatar, Divider, Typography, Button } from '@mui/material'
import style from './NotificationBox.module.css'
import { useTranslation } from 'react-i18next'
import { deliveryStatuses } from 'modules/notifications/enums/DeliveryStatus'
import { TitleType } from 'modules/notifications/enums/TitleType'
import { AdviceInterface } from '../../pages/layout/Dashboard'
import { Appointment } from 'modules/appointments/models/Appointment'
import React, { useState, useEffect } from 'react'
import { getAppointmentContainer } from '../../container/appointment-module'
import { AppointmentService } from '../../modules/appointments/services/AppointmentService'
import { APPOINTMENTS_SERVICE_KEY } from '../../modules/appointments'
import {
  ROUTE_APPOINTMENTS,
  ROUTE_SYMPTOM,
  ROUTE_EDUCATIONAL,
  ROUTE_QUESTIONNAIRES,
  ROUTE_URINATION,
  ROUTE_FECAL,
} from '../../routes/routes-constants'
import { navigate } from '@reach/router'
import { getPatientContainer } from '../../container/patient-module'
import { SymptomService } from '../../modules/patients/services/SymptomService'
import { SYMPTOM_SERVICE_KEY } from '../../modules/patients'
import { Symptom } from '../../modules/patients/models/Symptom'
import { Educational } from '../../modules/educational/models/Educational'
import { getEducationalContainer } from '../../container/educational-module'
import { EducationalService } from '../../modules/educational/services/EducationalService'
import { EDUCATIONAL_SERVICE_KEY } from '../../modules/educational'
import { Questionnaire } from '../../modules/questionnaires/models/Questionnaire'
import { getQuestionnaireContainer } from '../../container/questionnaire-module'
import { QuestionnairesService } from '../../modules/questionnaires/services/QuestionnaireService'
import { QUESTIONNAIRE_SERVICE_KEY } from '../../modules/questionnaires'
import { dateToDateString } from '../../common/utils/date'

type NotificationProps = {
  advice: AdviceInterface
}

const appointmentService =
  getAppointmentContainer().get<AppointmentService>(APPOINTMENTS_SERVICE_KEY)
const symptomService = getPatientContainer().get<SymptomService>(SYMPTOM_SERVICE_KEY)
const educationalService =
  getEducationalContainer().get<EducationalService>(EDUCATIONAL_SERVICE_KEY)
const questionnaireService =
  getQuestionnaireContainer().get<QuestionnairesService>(QUESTIONNAIRE_SERVICE_KEY)

export function NotificationBox(props: NotificationProps): JSX.Element {
  const { t } = useTranslation()
  const [appointment, setAppointment] = useState<Appointment>()
  const [symptom, setSymptom] = useState<Symptom>()
  const [educational, setEducational] = useState<Educational>()
  const [questionnaire, setQuestionnaire] = useState<Questionnaire>()
  const [isLoading, setIsLoading] = useState<boolean>(true)
  const meses = [
    'Enero',
    'Febrero',
    'Marzo',
    'Abril',
    'Mayo',
    'Junio',
    'Julio',
    'Agosto',
    'Septiembre',
    'Octubre',
    'Noviembre',
    'Diciembre',
  ]

  const optionDate = {
    weekday: undefined,
    year: 'numeric',
    month: 'long',
    day: 'numeric',
  }

  const getObject = async () => {
    switch (props.advice.title) {
      case 1:
        let appointment = await appointmentService.getByID(props.advice.idObject).toPromise()
        setAppointment(appointment)
        break
      case 2:
        let sympt = await symptomService.getByID(props.advice.idObject).toPromise()
        setSymptom(sympt)
        break
      case 3:
        let ed = await educationalService.getByID(props.advice.idObject).toPromise()
        setEducational(ed)
        break
      case 4:
        let ques = await questionnaireService.getByID(props.advice.idObject).toPromise()
        setQuestionnaire(ques)
        break
    }
    setIsLoading(false)
  }

  useEffect(() => {
    getObject()
  }, [])

  const goTo = () => {
    switch (props.advice.title) {
      case 1:
        navigate(ROUTE_APPOINTMENTS.replace(':id', props.advice.recipientID)).then()
        break
      case 2:
        navigate(ROUTE_SYMPTOM.replace(':id', props.advice.recipientID)).then()
        break
      case 3:
        navigate(ROUTE_EDUCATIONAL.replace(':id', `${props.advice.recipientID}`))
        break
      case 4:
        navigate(ROUTE_QUESTIONNAIRES.replace(':id', props.advice.recipientID)).then()
        break
      case 5:
        navigate(ROUTE_URINATION.replace(':id', props.advice.recipientID)).then()
        break
      case 6:
        navigate(ROUTE_FECAL.replace(':id', props.advice.recipientID)).then()
        break
    }
  }

  return (
    <>
      {!isLoading && (
        <Box className={style.noticePanelGrid} onClick={goTo}>
          {props.advice.title == 1 && (
            <>
              <h3 style={{ marginTop: '-0.1%', color: '#000' }}>{t('eventCalendarDate')}</h3>
              <Divider style={{ marginBottom: 8, marginTop: 0, backgroundColor: '#000' }} />
              <h4 style={{ color: '#000' }}>{appointment?.title}</h4>
            </>
          )}
          {props.advice.title == 2 && (
            <>
              <h3 style={{ marginTop: '-0.1%', color: '#000' }}>
                {t(TitleType[props.advice.title])}
              </h3>
              <Divider style={{ marginBottom: 8, marginTop: 0, backgroundColor: '#000' }} />
              <h4 style={{ color: '#000' }}>{educational?.name}</h4>
            </>
          )}
          {props.advice.title == 3 && (
            <>
              <h3 style={{ marginTop: '-0.1%', color: '#000' }}>
                {t(TitleType[props.advice.title])}
              </h3>
              <Divider style={{ marginBottom: 8, marginTop: 0, backgroundColor: '#000' }} />
              <h4 style={{ color: '#000' }}>{educational?.name}</h4>
            </>
          )}
          {props.advice.title == 4 && (
            <>
              <h3 style={{ marginTop: '-0.1%', color: '#000' }}>
                {t(TitleType[props.advice.title])}
              </h3>
              <Divider style={{ marginBottom: 8, marginTop: 0, backgroundColor: '#000' }} />
              <h4 style={{ color: '#000' }}>{questionnaire?.title}</h4>
            </>
          )}
          {props.advice.title == 5 && (
            <>
              <h3 style={{ marginTop: '-0.1%', color: '#000' }}>
                {t(TitleType[props.advice.title])}
              </h3>
              <Divider style={{ marginBottom: 8, marginTop: 0, backgroundColor: '#000' }} />
              <h4 style={{ color: '#000' }}>{t(TitleType[props.advice.title])}</h4>
            </>
          )}
          {props.advice.title == 6 && (
            <>
              <h3 style={{ marginTop: '-0.1%', color: '#000' }}>
                {t(TitleType[props.advice.title])}
              </h3>
              <Divider style={{ marginBottom: 8, marginTop: 0, backgroundColor: '#000' }} />
              <h4 style={{ color: '#000' }}>{t(TitleType[props.advice.title])}</h4>
            </>
          )}

          <Grid container spacing={0} columns={10}>
            <Grid item xs={1}>
              <p style={{ color: '#000' }}>{t('date')}:</p>
            </Grid>
            <Grid item xs={8}>
              <p style={{ color: '#000' }}>
                {meses[new Date(props.advice.sendAt).getMonth()] +
                  ' ' +
                  props.advice.sendAt.getDate() +
                  ', ' +
                  props.advice.sendAt.getFullYear().toString()}
              </p>
            </Grid>
            <Grid item xs={1}>
              <Box style={{ backgroundColor: '#1796ff', borderRadius: 5, width: '80px' }}>
                <p
                  style={{
                    marginLeft: 13.5,
                    fontSize: 15,
                    padding: 3,
                    fontWeight: 'bold',
                    color: 'white',
                    cursor: 'pointer',
                  }}
                >
                  {deliveryStatuses()[props.advice.deliveryStatus]}
                </p>
              </Box>
            </Grid>
          </Grid>

          {props.advice.title == 1 && (
            <Grid container spacing={0} columns={10} style={{ marginTop: '-2.5%' }}>
              <Grid item xs={1}>
                <p style={{ color: '#000' }}>{t('time')}:</p>
              </Grid>
              <Grid item xs={8}>
                <p style={{ color: '#000' }}>
                  {appointment?.date &&
                    new Date(appointment.date)
                      .toLocaleTimeString()
                      .substring(
                        0,
                        new Date(appointment.date).toLocaleTimeString().length - 3
                      )}{' '}
                  h
                </p>
              </Grid>
            </Grid>
          )}

          {props.advice.image != '' ? (
            <Grid container spacing={0} columns={10} style={{ marginTop: '-1%' }}>
              <Grid item xs={1}>
                <Avatar
                  src={`data:image/jpeg;base64,${props.advice.image}`}
                  style={{ marginTop: '10%' }}
                  sx={{ width: 35, height: 35 }}
                  alt="PatientImage"
                />
              </Grid>
              <Grid item xs={8}>
                <p
                  style={{
                    marginTop: '2.5%',
                    marginBottom: '0%',
                    fontSize: 16,
                    marginLeft: '-4%',
                    color: '#000',
                    fontWeight: 400,
                  }}
                >
                  {t(props.advice.patient)}
                </p>
              </Grid>
            </Grid>
          ) : (
            <p
              style={{
                marginBottom: '0%',
                color: '#000',
                fontSize: 16,
                marginTop: '-0.5%',
                fontWeight: 400,
              }}
            >
              {t(props.advice.patient)}
            </p>
          )}
        </Box>
      )}
    </>
  )
}
