import { Educational } from './Educational'
import { v4 as uuidv4 } from 'uuid'
import { Lesson } from './Lesson'

export interface EducationalDTO {
  id: string
  name: string
  lessons: Lesson[]
  gender: string
}

export function emptyEducationalDTO(creator: string | undefined): EducationalDTO {
  return {
    id: uuidv4(),
    name: '',
    lessons: [],
    gender: '',
  }
}

export function fromModel(f: Educational): EducationalDTO {
  return {
    id: f.id,
    name: f.name,
    lessons: f.lessons,
    gender: f.gender,
  }
}

export function toModel(d: EducationalDTO): Educational {
  return new Educational(d)
}
