import { createContext, useContext } from 'react'

export type GlobalContent = {
  dataImage: string
  setDataImage: (c: string) => void
  messagesForMe: any[]
  setMessagesForMe: ([]: any) => void
  messagesHeader: any[]
  setMessagesHeader: ([]: any) => void
  allMessages: any[]
  setAllMessages: ([]: any) => void
  bellNotif: any
  setBellNotif: (a: any) => void
}

export const MyGlobalContext = createContext<GlobalContent>({
  dataImage: '',
  setDataImage: () => {},
  messagesForMe: [],
  setMessagesForMe: () => {},
  messagesHeader: [],
  setMessagesHeader: () => {},
  allMessages: [],
  setAllMessages: () => {},
  bellNotif: '',
  setBellNotif: () => {},
})

export const useGlobalContext = () => useContext(MyGlobalContext)
