import { Container, ContainerItem } from '../common/container/Container'
import { ContentProps } from '../modules/content/container'
import { getAppContainer } from './app'
import { moduleURLs } from '../config/api'
import {
  QUESTIONNAIRE_API_KEY,
  QUESTIONNAIRE_PATIENT_ANSWER_API_KEY,
  QUESTIONNAIRE_PATIENT_ANSWER_SERVICE_KEY,
  QUESTIONNAIRE_SERVICE_KEY,
  QUESTIONNAIRES_MODULE,
} from '../modules/questionnaires/container'
import { QuestionnaireApi } from '../modules/questionnaires/api/QuestionnaireApi'
import { QuestionnairesService } from '../modules/questionnaires/services/QuestionnaireService'
import { QuestionnairePatientAnswerApi } from '../modules/questionnaires/api/QuestionairePatientAnswerApi'
import { QuestionnairesPatientAnswerService } from '../modules/questionnaires/services/QuestionnairePatientAnswerService'

let container: Container

function init(p: ContentProps) {
  const items = new Map<symbol, ContainerItem>()

  items.set(QUESTIONNAIRE_API_KEY, new ContainerItem({ instance: new QuestionnaireApi() }))

  items.set(
    QUESTIONNAIRE_SERVICE_KEY,
    new ContainerItem({
      instance: new QuestionnairesService({ apiKey: QUESTIONNAIRE_API_KEY }),
    })
  )

  items.set(
    QUESTIONNAIRE_PATIENT_ANSWER_API_KEY,
    new ContainerItem({ instance: new QuestionnairePatientAnswerApi() })
  )

  items.set(
    QUESTIONNAIRE_PATIENT_ANSWER_SERVICE_KEY,
    new ContainerItem({
      instance: new QuestionnairesPatientAnswerService({
        apiKey: QUESTIONNAIRE_PATIENT_ANSWER_API_KEY,
      }),
    })
  )

  container = new Container(items, p.parentContainer, p.config)
}

export function getQuestionnaireContainer(): Container {
  if (!container) {
    init({
      parentContainer: getAppContainer(),
      config: {
        moduleFullUrl: moduleURLs[QUESTIONNAIRES_MODULE],
      },
    })
  }

  return container
}
