import React, { useEffect, useState } from 'react'
import { AppTable } from '../../components/table'
import { Patient, PatientQuery } from '../../modules/patients/models/Patient'
import { Actions, Field, Pager, Search, SearchValue, Sort } from '../../components/table/types'
import { useTranslation } from 'react-i18next'
import { PatientService } from 'modules/patients/services/PatientService'
import { getPatientContainer } from 'container/patient-module'
import { PATIENT_SERVICE_KEY } from 'modules/patients'
import { Query, QueryParam, QueryParamN, SortParam } from '../../common/api/Query'
import seeIcon from '../../assets/higea/View-icon-Copy-2.svg'
import deleteIcon from '../../assets/table_icons/ico_eliminar_copy.svg'
import { ROUTE_CREATE, ROUTE_PATIENTS, ROUTE_PATIENTS_ID } from '../../routes/routes-constants'
import { navigate } from '@reach/router'
import {
  Box,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@material-ui/core'
import generic from '../../assets/generic.module.css'
import { AppButton, ButtonTheme } from '../../components/app-button/AppButton'
import style from './Patients.module.css'
import AddIcon from '../../assets/higea/icon_anadir_copy_white.svg'
import { getUserContainer } from '../../container/user-module'
import { IUserService, USER_SERVICE_KEY } from '../../modules/users'
import { Dialog } from '@mui/material'
import { getNotificationContainer } from 'container/notification-module'
import { NotificationService } from '../../modules/notifications/services/NotificationService'
import { NOTIFICATION_SERVICE_KEY } from '../../modules/notifications/container'
import { forkJoin, Observable } from 'rxjs'
import bellOn from '../../assets/higea/Ico_campana_on_copy.svg'
import bellOff from '../../assets/higea/Ico_campana_off_copy.svg'
import { LoggedUserService } from '../../modules/users/services/LoggedUserService'
import { LOGGED_USER_SERVICE_KEY } from '../../modules/users/container'
import { Conversation } from 'modules/messenger/models/Conversation'
import { getMessengerContainer } from 'container/messenger-module'
import { CONVERSATION_SERVICE_KEY } from 'modules/messenger'
import { ConversationService } from 'modules/messenger/services/ConversationService'
import { lastActivity } from '../../modules/patients/enums/LastActivity'

const patientService = getPatientContainer().get<PatientService>(PATIENT_SERVICE_KEY)
const userContainer = getUserContainer()
const userService = userContainer.get<IUserService>(USER_SERVICE_KEY)
const notificationService =
  getNotificationContainer().get<NotificationService>(NOTIFICATION_SERVICE_KEY)
const loggedUserContainer = getUserContainer()
const loggedUserService = loggedUserContainer.get<LoggedUserService>(LOGGED_USER_SERVICE_KEY)
const conversationService =
  getMessengerContainer().get<ConversationService>(CONVERSATION_SERVICE_KEY)
const searcherQuery = (
  svs: SearchValue<PatientQuery>[]
): QueryParam<PatientQuery>[] | QueryParamN<PatientQuery>[] =>
  svs.filter((sv) => sv.value).map((sv) => ({ name: sv.name, value: sv.value as string }))

interface PatientTableParams {
  actions: Actions<Patient>
  search: Search<PatientQuery>
  items: Patient[]
  pager: Pager | undefined
  fields: Field<Patient>[]
  sort: Sort<Patient>
}

const PatientTable = (props: PatientTableParams) => {
  return (
    <Box className={style.tableContainer}>
      <AppTable
        actions={props.actions}
        search={props.search}
        items={props.items}
        pager={props.pager}
        fields={props.fields}
        rowKeyField={'id'}
        sort={props.sort}
        searchWithIcon={true}
        groupBy={undefined}
      />
    </Box>
  )
}

export const Table = () => {
  const { t } = useTranslation()
  const loggedUser = loggedUserService.get()
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [items, setItems] = useState<Patient[]>([])
  const [pager, setPager] = useState<Pager>()
  const [page, setPage] = useState<number>(0)
  const [count, setCount] = useState<number>(0)
  const [rowsPerPage, setRowsPerPage] = useState<number>(10)
  const [sort, setSort] = useState<SortParam<Patient>>({
    field: 'firstName',
    desc: false,
  })
  const [searcher, setSearcher] = useState<SearchValue<PatientQuery>[]>([
    {
      name: 'firstName',
      label: t('search') + ' ... ',
    },
  ])
  const [openConfirm, setOpenConfirm] = useState<boolean>(false)
  const [patientToDelete, setPatientToDelete] = useState<Patient | undefined>()
  const [patientsByFirstName, setPatientsByFirstName] = useState<string[]>([])
  const [conversations, setConversations] = useState<Conversation[]>([])

  const getNotifications = (patientIDs: string[]): Observable<(number | undefined)[]> =>
    forkJoin(patientIDs.map((id) => notificationService.getPendingNotificationsByPatient(id)))

  useEffect(() => {
    conversationService.getFilteredItems(new Query({})).subscribe((res) => {
      setConversations(res)
    })
  }, [])

  useEffect(() => {
    if (!isLoading) {
      return
    }
    patientService
      .getFilteredList(
        new Query({
          pager: { offset: page * rowsPerPage, limit: rowsPerPage },
          query: [
            ...searcherQuery(searcher),
            new QueryParam<PatientQuery>('createdBy', loggedUser?.id || ''),
            new QueryParam<PatientQuery>('ids', [...patientsByFirstName]),
          ],
          sort: [{ field: sort.field, desc: sort.desc }],
        })
      )
      .subscribe((res) => {
        setItems(res.items)
        setCount(res.count)
        getNotifications(res.items.map((n) => n.id)).subscribe((resn) => {
          resn
            .filter((rn) => rn)
            .forEach((rn, index) => {
              res.items[index].notifications = rn
            })
        })
        setIsLoading(!isLoading)
      })
  }, [isLoading, patientsByFirstName, pager])

  useEffect(() => {
    const searchTerms = [...searcherQuery(searcher)]
    const name = searchTerms.find((s) => s.name === 'firstName')

    if (name) {
      patientService
        .getFilteredList(
          new Query({
            pager: { offset: page * rowsPerPage, limit: rowsPerPage },
            query: [new QueryParam<PatientQuery>('firstName', name.value)],
          })
        )
        .subscribe((res) => {
          if (!res) return
          setPatientsByFirstName(res.items.map((patient) => patient.firstName || ''))
          setIsLoading(!isLoading)
        })
    } else {
      setPatientsByFirstName([])
      setIsLoading(!isLoading)
    }
  }, [searcher])

  useEffect(() => {
    setIsLoading(!isLoading)
    setPager({
      page,
      count,
      handleChangePage,
      rowsPerPage,
      handleChangeRowsPerPage,
    })
  }, [page, count, rowsPerPage])

  const handleChangePage = (event: unknown, value: number) => setPage(value)

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) =>
    setRowsPerPage(Number.parseInt(event.target.value) || 10)

  const fields: Field<Patient>[] = [
    {
      searchable: true,
      sortable: true,
      label: t('firstName'),
      name: 'firstName',
      renderFunc: (f, i) => (i.lastName ? i.firstName + ' ' + i.lastName : i.firstName || ''),
    },
    {
      searchable: true,
      sortable: true,
      label: t('Email'),
      name: 'email',
      renderFunc: (f, i) => i.email || '',
    },
    {
      searchable: true,
      sortable: true,
      label: t('dischargeDate'),
      name: 'createdAt',
      renderFunc: (f, i) =>
        loggedUserService.get()?.language == 1
          ? (new Date(i.createdAt).getDate() <= 9 ? '0' : '') +
            new Date(i.createdAt).getDate() +
            '/' +
            (new Date(i.createdAt).getMonth() <= 9 ? '0' : '') +
            (new Date(i.createdAt).getMonth() + 1) +
            '/' +
            new Date(i.createdAt).getFullYear().toString()
          : new Date(i.createdAt).toLocaleDateString(),
    },
    {
      searchable: true,
      sortable: true,
      label: t('notifications'),
      name: 'notifications',
      renderFunc: (f, i) => notificationCountElem(i),
    },
    {
      searchable: true,
      sortable: true,
      label: t('lastActivity'),
      name: 'lastActivity',
      renderFunc: (f, i) => {
        if (i.lastActivity === undefined) return <span></span>
        return <span>{lastActivity()[i.lastActivity]}</span>
      },
    },
  ]

  const notificationCountElem = (p: Patient) => {
    let conversFiltered = conversations.filter((c) => c.user == p.userID || c.user2 == p.userID)
    let count = 0

    conversFiltered.forEach((c) => {
      count += c.messages.filter((m) => m.authorID != p.userID && m.status == 0).length
    })

    if (count && count != 0) {
      return (
        <Box className={style.countNotificationsContainer}>
          <Box>
            <img src={bellOn} alt="notifications" />
          </Box>
          <Box className={style.countNotifications}>{count}</Box>
        </Box>
      )
    } else {
      return (
        <Box className={style.countNotificationsContainer}>
          <Box>
            <img src={bellOff} alt="notifications" />
          </Box>
        </Box>
      )
    }
  }

  const search: Search<PatientQuery> = {
    searchValues: searcher,
    handleSearch: (svs: SearchValue<PatientQuery>[]) => {
      const result: SearchValue<PatientQuery>[] = svs.map((s) => {
        if (s.value && s.value != '') {
          const auxiliar = Array.from(s.value)
          auxiliar[0] = auxiliar[0].toUpperCase()
          s.value = auxiliar.join('')
        } else {
          s.value = ''
        }
        return s
      })
      setSearcher(result)
    },
  }

  const sortable: Sort<Patient> = {
    name: sort.field,
    direction: sort.desc ? 'desc' : 'asc',
    handleSort: (field) => {
      setSort({ field: field, desc: sort.field === field ? !sort.desc : true })
      setIsLoading(true)
    },
  }

  const viewPatient = (p: Patient) => {
    navigate(ROUTE_PATIENTS_ID.replace(':id', `${p.id}`))
  }

  const deletePatientConfirm = (p: Patient) => {
    setPatientToDelete(p)
    setOpenConfirm(true)
  }

  const deletePatient = () => {
    patientToDelete &&
      userService.delete(patientToDelete.userID || '').subscribe((res) => {
        setIsLoading(true)
      })

    setPatientToDelete(undefined)
    setOpenConfirm(false)
  }

  const createPatient = () => navigate(ROUTE_PATIENTS + '/' + ROUTE_CREATE)

  const actions: Actions<Patient> = {
    actionsColumn: t('Actions'),
    items: [
      {
        handler: viewPatient,
        icon: seeIcon,
        label: t('show'),
      },
      {
        handler: deletePatientConfirm,
        icon: deleteIcon,
        label: t('delete'),
      },
    ],
  }

  const handleCloseConfirm = () => {
    setPatientToDelete(undefined)
    setOpenConfirm(false)
  }

  return (
    <>
      <Box className={generic.pageContainer}>
        <Dialog open={openConfirm} onClose={handleCloseConfirm}>
          <DialogTitle
            disableTypography={true}
            color={'#000'}
            style={{
              color: '#000',
              fontFamily: 'Open-sans, sans-serif',
              fontWeight: 'bold',
              fontSize: '18px',
            }}
          >
            {t('confirmDeleteTitle')}
          </DialogTitle>
          <DialogContent>
            <DialogContentText style={{ color: '#000', fontFamily: 'Open-sans, sans-serif' }}>
              {t('confirmDeleteText')}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <AppButton
              theme={ButtonTheme.NewSecondary}
              type={'button'}
              label={t('cancel')}
              handler={handleCloseConfirm}
            />
            <AppButton
              theme={ButtonTheme.NewPrimaryLight}
              type={'button'}
              label={t('accept')}
              handler={deletePatient}
            />
          </DialogActions>
        </Dialog>

        <Box className={style.buttonContainer}>
          <AppButton
            theme={ButtonTheme.NewPrimaryLight}
            type={'button'}
            label={t('new')}
            handler={createPatient}
            startIcon={AddIcon}
          />
        </Box>

        <PatientTable
          actions={actions}
          search={search}
          items={items}
          pager={pager}
          fields={fields}
          sort={sortable}
        />
      </Box>
    </>
  )
}
