import React, { useState } from 'react'
import { Box } from '@mui/material'
import generic from '../../../../assets/generic.module.css'
import image from './perfilHembra.png'
import image2 from './vejigaNoText.png'

export const CVS234 = () => {
  const [openModal, setOpenModal] = useState<boolean>(false)
  const { innerWidth } = window

  const handleOpenModal = () => setOpenModal(true)

  const handleCloseModal = () => setOpenModal(false)
  return (
    <>
      <Box className={generic.pageContainer}>
        <Box className={generic.pageContainer}>
          <h2>
            <b>2.3. Función de la vejiga y ciclo miccional</b>
          </h2>
        </Box>
        <Box style={{ display: 'flex' }}>
          <Box>
            <h1 style={{ color: 'dodgerblue' }}>
              <b>Cómo afectan las enfermedades neurológicas al sistema urinario</b>
            </h1>
            <div>
              &nbsp;&nbsp;&nbsp;&nbsp;•&nbsp;&nbsp;&nbsp;&nbsp; Las lesiones pueden provocar la
              interrupción de la transmisión nerviosa
              <br />
              <br />
              &nbsp;&nbsp;&nbsp;&nbsp;•&nbsp;&nbsp;&nbsp;&nbsp; Vías destruidas: las señales
              nerviosas no llegan a su destino
              <br />
              <br />
              &nbsp;&nbsp;&nbsp;&nbsp;•&nbsp;&nbsp;&nbsp;&nbsp; Un menor control cerebral conduce a
              una sobreactividad del detrusor
              <br />
              <br />
              &nbsp;&nbsp;&nbsp;&nbsp;•&nbsp;&nbsp;&nbsp;&nbsp; Las lesiones en la médula espinal
              provocan una disenergia del esfínter del detrusor
              <br />
              <br />
              &nbsp;&nbsp;&nbsp;&nbsp;•&nbsp;&nbsp;&nbsp;&nbsp; Las lesiones bajas provocan una
              hipoactividad del detrusor
              <br />
              <br />
            </div>
          </Box>
          <Box>
            <img
              src={image}
              onClick={handleOpenModal}
              style={{
                width: innerWidth < 900 ? '40%' : '20%',
                marginLeft: '50%',
                justifyContent: 'center',
                display: 'flex',
              }}
            />
          </Box>
        </Box>
        <Box style={{ display: 'flex' }}>
          <Box>
            <h1 style={{ color: 'dodgerblue' }}>
              <b>Efectos en la vejiga</b>
            </h1>
            <div>
              &nbsp;&nbsp;&nbsp;&nbsp;•&nbsp;&nbsp;&nbsp;&nbsp; <b>Hiperactividad del detrusor</b>
              <br />
              <br />
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;-&nbsp;&nbsp;&nbsp;&nbsp; Urgencia,
              con o sin incontienencia, con o sin orina residual
              <br />
              <br />
              &nbsp;&nbsp;&nbsp;&nbsp;•&nbsp;&nbsp;&nbsp;&nbsp;{' '}
              <b>Disinergia del esfínter del detrusor</b>
              <br />
              <br />
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;-&nbsp;&nbsp;&nbsp;&nbsp;
              Incontinencia de urgencia, orina residual, daño renal e infección del tracto urinario
              <br />
              <br />
              &nbsp;&nbsp;&nbsp;&nbsp;•&nbsp;&nbsp;&nbsp;&nbsp; <b>Hipoactividad del detrusor</b>
              <br />
              <br />
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;-&nbsp;&nbsp;&nbsp;&nbsp; Retención,
              incontinencia por rebosamiento, orina residual e infección del tracto urinario
              <br />
              <br />
            </div>
          </Box>
          <Box>
            <img
              src={image2}
              onClick={handleOpenModal}
              style={{
                marginTop: '25%',
                width: innerWidth < 900 ? '70%' : '60%',
                marginLeft: innerWidth < 900 ? '30%' : '50%',
                justifyContent: 'center',
                display: 'flex',
              }}
            />
          </Box>
        </Box>
      </Box>
    </>
  )
}
