import generic from '../../../../assets/generic.module.css'
import { Box } from '@mui/material'
import React from 'react'

export const CVS120 = () => {
  return (
    <>
      <Box className={generic.pageContainer} style={{ backgroundColor: 'white' }}>
        <Box className={generic.pageContainer}>
          <h2>
            <b>1.2 Incontinencia</b>
          </h2>
          <div>
            La incontinencia urinaria, según la definición de la International Continence Society
            (ICS) es la queja de cualquier pérdida involuntaria de orina.
            <br />
            <br />
            La incontinencia urinaria es un problema habitual y, a menudo, puede tener un impacto
            negativo en la calidad de vida.
            <br />
            <br />
          </div>
        </Box>
        <Box>
          <h1>
            <b>DISTINTOS TIPOS DE INCONTINENCIA URINARIA</b>
          </h1>

          <h1 style={{ color: 'dodgerblue' }}>
            <b>Incontinencia urinaria de esfuerzo (IUE)</b>
          </h1>
        </Box>
        <Box className={generic.pageContainer}>
          <div>
            La IUE es una pérdida involuntaria por esfuerzo, es decir, al estornudar o toser (Abrams
            et al, 2002).
            <br />
            <br />
            Es habitual sufrir incontinencia de esfuerzo tras el embarazo y el parto. El embarazo
            debilita el suelo pélvico, y resulta más difícil apretar los músculos y esfínteres del
            fondo de la vejiga.
            <br />
            <br />
            <b>Causa</b>
            <br />
            <br />
            Músculos del suelo pélvico debilitados o dañados, cuyas causas más comunes suelen ser:
            <br />
            <br />
          </div>
        </Box>
        <Box style={{ display: 'flex' }}>
          <Box style={{ marginLeft: '2%' }}>
            &nbsp;&nbsp;&nbsp;&nbsp;•&nbsp;&nbsp;&nbsp;&nbsp; Embarazo/parto vaginal
            <br />
            <br />
            &nbsp;&nbsp;&nbsp;&nbsp;•&nbsp;&nbsp;&nbsp;&nbsp; Factores genéricos
            <br />
            <br />
            &nbsp;&nbsp;&nbsp;&nbsp;•&nbsp;&nbsp;&nbsp;&nbsp; Menopausia/deficiencia de estrógeno
            <br />
            <br />
            &nbsp;&nbsp;&nbsp;&nbsp;•&nbsp;&nbsp;&nbsp;&nbsp; Tos crónica
            <br />
            <br />
          </Box>
          <Box style={{ marginLeft: '20%' }}>
            &nbsp;&nbsp;&nbsp;&nbsp;•&nbsp;&nbsp;&nbsp;&nbsp; Cirugía pélvica o de próstata
            <br />
            <br />
            &nbsp;&nbsp;&nbsp;&nbsp;•&nbsp;&nbsp;&nbsp;&nbsp; Obesidad
            <br />
            <br />
            &nbsp;&nbsp;&nbsp;&nbsp;•&nbsp;&nbsp;&nbsp;&nbsp; Fumar
            <br />
            <br />
          </Box>
          <Box style={{ marginLeft: '20%' }}>
            &nbsp;&nbsp;&nbsp;&nbsp;•&nbsp;&nbsp;&nbsp;&nbsp; Estreñimiento crónico
            <br />
            <br />
            &nbsp;&nbsp;&nbsp;&nbsp;•&nbsp;&nbsp;&nbsp;&nbsp; Actividad física extenuante
            <br />
            <br />
            &nbsp;&nbsp;&nbsp;&nbsp;•&nbsp;&nbsp;&nbsp;&nbsp; Levantamiento de cargas pesadas a
            largo plazo
            <br />
            <br />
          </Box>
        </Box>
        <Box className={generic.pageContainer}>
          <div>
            <b>Tratamiento</b>
            <br />
            <br />
            El enfoque de tratamiento de primera línea será fortalecer los músculos del piso pélvico
            y, además, entre las opciones de estilo de vida se incluyen pérdida de peso, dejar de
            fumar, abordar la ingesta de líquidos y resolver el estreñimiento. Se puede optar por
            cirugía en casos más graves.
            <br />
            <br />
          </div>
        </Box>
      </Box>
    </>
  )
}
