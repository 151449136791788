import { Container, IInit } from '../../../common/container/Container'
import { PatientContainerConfig } from '../container'
import { Observable, of } from 'rxjs'
import { catchError, map } from 'rxjs/operators'
import { Query } from '../../../common/api/Query'
import { HTTP_CLIENT_KEY, IHTTPClient } from '../../../common/api/HTTPClient'
import { IStatusService } from '../../../common/status/StatusService'
import { STATUS_SERVICE_KEY } from '../../../container/app'
import { emptyList, ItemList } from 'common/models/ItemList'
import { PatientSymptom } from '../models/PatientSymptom'
import { PatientSymptomDTO } from '../models/PatientSymptomDTO'
import { PatientFile } from '../models/PatientFile'
import { PatientFileDTO, toModel } from '../models/PatientFileDTO'
import { FileDTO, toModel as toModelFile } from '../../files/models/FileDTO'
import { File } from '../../files/models/File'

export interface IPatientFileApi extends IInit {
  getByID(id: string | null): Observable<PatientFile | undefined>

  getFilteredItems(q: Query<PatientFile>): Observable<ItemList<PatientFile>>

  add(e: PatientFileDTO): Observable<PatientFile | undefined>

  update(e: PatientFileDTO): Observable<PatientFile | undefined>

  delete(id: string): Observable<boolean>

  getPatientFilesByPatientID(patientID: string): Observable<ItemList<PatientFile>>

  getPatientFile(patientID: string): Observable<File | undefined>
}

export class PatientFileApi implements IPatientFileApi {
  private _container!: Container
  private _httpClient!: IHTTPClient
  private _url!: string
  private _statusService!: IStatusService

  init(c: Container) {
    this._container = c
    this._httpClient = this._container.get<IHTTPClient>(HTTP_CLIENT_KEY)
    this._statusService = this._container.get<IStatusService>(STATUS_SERVICE_KEY)
    this._url = (this._container.config as PatientContainerConfig).moduleFullUrl
  }

  getByID(id: string): Observable<PatientFile | undefined> {
    return this._httpClient
      .get<PatientFile>({ url: this._url + '/' + id })
      .pipe(
        map<PatientFileDTO, PatientFile>((d) => toModel(d)),
        catchError((err) => {
          this._statusService.sendStatus({ variant: 'error', error: err })
          return of(undefined)
        })
      )
  }

  getFilteredItems(q: Query<PatientFile>): Observable<ItemList<PatientFile>> {
    return this._httpClient
      .post<ItemList<PatientFile>>({
        url: this._url + '/patientfile/paginatedList',
        body: q,
      })
      .pipe(
        map<ItemList<PatientFileDTO>, ItemList<PatientFile>>((dto) => {
          const itemList = emptyList<PatientFile>()
          itemList.items = dto.items.map((d) => toModel(d))
          itemList.count = dto.count
          return itemList
        }),
        catchError((err) => {
          this._statusService.sendStatus({ variant: 'error', error: err })
          return of(emptyList<PatientFile>())
        })
      )
  }

  add(e: PatientFileDTO): Observable<PatientFile | undefined> {
    return this._httpClient
      .post<PatientFile>({ url: this._url + '/patientfile', body: e })
      .pipe(
        map<PatientFileDTO, PatientFile>((d) => {
          this._statusService.sendStatus({ variant: 'success' })
          return toModel(d)
        }),
        catchError((err) => {
          this._statusService.sendStatus({ variant: 'error', error: err })
          return of(undefined)
        })
      )
  }

  update(e: PatientFileDTO): Observable<PatientFile | undefined> {
    return this._httpClient
      .put<PatientFile>({ url: this._url + '/patientfile', body: e })
      .pipe(
        map<PatientFileDTO, PatientFile>((d) => {
          this._statusService.sendStatus({ variant: 'success' })
          return toModel(d)
        }),
        catchError((err) => {
          this._statusService.sendStatus({ variant: 'error', error: err })
          return of(undefined)
        })
      )
  }

  delete(id: string): Observable<boolean> {
    return this._httpClient.delete({ url: this._url + '/patientfile/' + id }).pipe(
      catchError((err) => {
        this._statusService.sendStatus({ variant: 'error', error: err })
        return of(false)
      })
    )
  }

  getPatientFilesByPatientID(patientID: string): Observable<ItemList<PatientFile>> {
    return this._httpClient
      .get<ItemList<PatientFile>>({ url: this._url + '/patientfileList/' + patientID })
      .pipe(
        map<ItemList<PatientFileDTO>, ItemList<PatientFile>>((dto) => {
          const itemList = emptyList<PatientFile>()
          itemList.items = dto.items.map((d) => toModel(d))
          itemList.count = dto.count
          return itemList
        }),
        catchError((err) => {
          this._statusService.sendStatus({ variant: 'error', error: err })
          return of(emptyList<PatientFile>())
        })
      )
  }

  getPatientFile(patientID: string): Observable<File | undefined> {
    return this._httpClient
      .get<File>({ url: `${this._url}/patientfilebypatient/${patientID}` })
      .pipe(
        map<FileDTO, File>((d) => toModelFile(d)),
        catchError((err) => {
          this._statusService.sendStatus({ variant: 'error', error: err })
          return of(undefined)
        })
      )
  }
}
