import { Alert, AppBar, Box, SelectChangeEvent, Tab, Tabs, Modal } from '@mui/material'
import generic from '../../assets/generic.module.css'
import style from '../statistics/Stats.module.css'
import { ChangeEvent, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { GeneralView } from './GeneralView'
import { MedicalView } from './MedicalView'
import { NotesView } from './Notes'
import { emptyPatientDTO, PatientDTO } from '../../modules/patients/models/PatientDTO'
import { emptyUserDTO, UserDTO } from '../../modules/users/models/User'
import { getPatientContainer } from '../../container/patient-module'
import { PatientService } from '../../modules/patients/services/PatientService'
import {
  DIAGNOSTIC_SERVICE_KEY,
  INTERVENTION_SERVICE_KEY,
  PATIENT_DIAGNOSTIC_SERVICE_KEY,
  PATIENT_NOTES_SERVICE_KEY,
  PATIENT_SERVICE_KEY,
  PATIENT_TREATMENT_SERVICE_KEY,
  TREATMENT_SERVICE_KEY,
} from '../../modules/patients'
import { getUserContainer } from '../../container/user-module'
import { LoggedUserService } from '../../modules/users/services/LoggedUserService'
import { IUserService, LOGGED_USER_SERVICE_KEY, USER_SERVICE_KEY } from '../../modules/users'
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date'
import { navigate } from '@reach/router'
import { ROUTE_PATIENTS } from '../../routes/routes-constants'
import { UserGender } from '../../modules/users/enums/UserGender'
import { emptyNoteDTO, NoteDTO } from '../../modules/patients/models/NoteDTO'
import { PatientDiagnosticService } from '../../modules/patients/services/PatientDiagnosticService'
import { PatientTreatmentService } from '../../modules/patients/services/PatientTreatmentService'
import { Diagnostic, DiagnosticQuery } from '../../modules/patients/models/Diagnostic'
import { Intervention, InterventionQuery } from '../../modules/patients/models/Intervention'
import { Query, QueryParam } from '../../common/api/Query'
import { DiagnosticService } from '../../modules/patients/services/DiagnosticService'
import { InterventionService } from '../../modules/patients/services/InterventionService'
import { TreatmentService } from '../../modules/patients/services/TreatmentService'
import { v4 as uuidv4 } from 'uuid'
import { PatientNoteService } from 'modules/patients/services/PatientNoteService'
import { ProblemService } from 'modules/patients/services/ProblemService'
import { PATIENT_PROBLEM_SERVICE_KEY, PROBLEM_SERVICE_KEY } from 'modules/patients/container'
import { PatientProblemService } from 'modules/patients/services/PatientProblemService'
import { fromModel as fromModelProblem } from 'modules/patients/models/PatientProblemDTO'
import { PatientProblem } from 'modules/patients/models/PatientProblem'
import { PatientTreatment } from 'modules/patients/models/PatientTreatment'
import { fromModel as fromModelTreatment } from 'modules/patients/models/PatientTreatmentDTO'
import { AppButton, ButtonTheme } from '../../components/app-button/AppButton'
import style2 from './Patients.module.css'
import { PatientDiagnostic } from 'modules/patients/models/PatientDiagnostic'
import { fromModel as fromModelPT } from 'modules/patients/models/PatientDiagnosticDTO'
import { LegalTermsModal } from '../../components/modal/LegalTermsModal'
import { useSnackbar } from 'notistack'

const patientService = getPatientContainer().get<PatientService>(PATIENT_SERVICE_KEY)
const loggedUserService = getUserContainer().get<LoggedUserService>(LOGGED_USER_SERVICE_KEY)
const userContainer = getUserContainer()
const userService = userContainer.get<IUserService>(USER_SERVICE_KEY)
const patientDiagnosticsService = getPatientContainer().get<PatientDiagnosticService>(
  PATIENT_DIAGNOSTIC_SERVICE_KEY
)
const patientTreatmentService = getPatientContainer().get<PatientTreatmentService>(
  PATIENT_TREATMENT_SERVICE_KEY
)
const patientNoteService = getPatientContainer().get<PatientNoteService>(PATIENT_NOTES_SERVICE_KEY)
const diagnosticService = getPatientContainer().get<DiagnosticService>(DIAGNOSTIC_SERVICE_KEY)
const interventionService = getPatientContainer().get<InterventionService>(INTERVENTION_SERVICE_KEY)
const treatmentService = getPatientContainer().get<TreatmentService>(TREATMENT_SERVICE_KEY)
const problemService = getPatientContainer().get<ProblemService>(PROBLEM_SERVICE_KEY)
const patientProblemService = getPatientContainer().get<PatientProblemService>(
  PATIENT_PROBLEM_SERVICE_KEY
)

enum View {
  generalData = 1,
  medicalData,
  notes,
}

interface PatientTreatmentInterface {
  id: string
  patientID: string
  treatmentID: string
  brand: string
  name: string
  checked: boolean
}

interface PatientDiagnosticInterface {
  diagnosticID: string
  patientID: string
  name: string
  text: string
  date: Date
}

interface PatientProblemInterface {
  id: string
  problemID: string
  patientID: string
  name: string
  checked: boolean
}

export const Editor = () => {
  const { t } = useTranslation()
  const loggedUser = loggedUserService.get()
  const [interventionName, setInterventionName] = useState<string>('')
  const [tabValue, setTabValue] = useState<number>(0)
  const [view, setView] = useState<View>(View.generalData)
  const [tabsView, setTabsView] = useState<JSX.Element>(<></>)
  const [patient, setPatient] = useState<PatientDTO>(emptyPatientDTO())
  const [user, setUser] = useState<UserDTO>(emptyUserDTO)
  const [notes, setNotes] = useState<NoteDTO>(emptyNoteDTO)
  const [diagnostics, setDiagnostics] = useState<Diagnostic[]>([])
  const [interventions, setInterventions] = useState<Intervention[]>([])
  const [errorMessage, setErrorMessage] = useState<string>('')
  const [firstTime, setFirstTime] = useState<boolean>(true)
  const [problemsInterface, setProblemsInterface] = useState<PatientProblemInterface[]>([])
  const [modalOpened, setModalOpened] = useState<boolean>(false)
  const [legalTermsAccepted, setLegalTermsAccepted] = useState<boolean>(false)
  const [patientTreatmentsInter, setPatientTreatmentsInter] = useState<PatientTreatmentInterface[]>(
    []
  )
  const [patientDiagnostic, setPatientDiagnostic] = useState<PatientDiagnosticInterface>({
    diagnosticID: '',
    patientID: '',
    name: '',
    text: '',
    date: new Date(),
  })
  const { enqueueSnackbar } = useSnackbar()

  useEffect(() => {
    if (patient && firstTime) {
      problemService.getFilteredList(new Query({})).subscribe((res) => {
        let problemsAux: PatientProblemInterface[] = []
        res.items.forEach((item) => {
          problemsAux.push({
            id: uuidv4(),
            problemID: item.id,
            patientID: patient.id,
            name: item.name,
            checked: false,
          })
        })
        setProblemsInterface(problemsAux)
      })
    }
  }, [patient])

  useEffect(() => {
    if (patient && firstTime) {
      let auxPatientDiagnostic = patientDiagnostic
      if (auxPatientDiagnostic) {
        auxPatientDiagnostic.patientID = patient.id
      }
      setPatientDiagnostic(auxPatientDiagnostic)

      treatmentService.getFilteredList(new Query({})).subscribe((res) => {
        let ptInterAux: PatientTreatmentInterface[] = []

        res.items.forEach((item) => {
          ptInterAux.push({
            id: uuidv4(),
            patientID: patient.id,
            treatmentID: item.id,
            brand: '',
            name: item.name,
            checked: false,
          })
        })

        setPatientTreatmentsInter(ptInterAux)
        setFirstTime(false)
      })
    }
  }, [patient])

  const handleConfirm = () => {
    if (legalTermsAccepted) {
      setModalOpened(false)
      legalTermsAccepted && handleSave()
    }
  }

  const handleChangeDiagnostic = (d: PatientDiagnosticInterface) => {
    setPatientDiagnostic(d)
  }

  const handleChangePatientTreatment = (ts: PatientTreatmentInterface[]) => {
    setPatientTreatmentsInter(ts)
  }

  useEffect(() => {
    const query: QueryParam<DiagnosticQuery>[] = []
    diagnosticService
      .getFilteredList(
        new Query({
          query,
          sort: [{ field: 'name' }],
        })
      )
      .subscribe((res) => {
        setDiagnostics(res.items)
      })
  }, [])

  useEffect(() => {
    const query: QueryParam<InterventionQuery>[] = []
    interventionService
      .getFilteredList(
        new Query({
          query,
          sort: [{ field: 'name' }],
        })
      )
      .subscribe((res) => {
        setInterventions(res.items)
      })
  }, [])

  useEffect(() => {
    if (!loggedUser?.id) return
    setPatient(Object.assign({ ...patient }, { ['carerID']: loggedUser.id }))
  }, [])

  useEffect(() => {
    setTabsView(getView(view))
  }, [view, patient, user, notes, interventionName])

  useEffect(() => {
    if (user.email != '') {
      setPatient(Object.assign({ ...patient }, { ['userID']: user.id }))
    }
  }, [user])

  const handleInput = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    if (e.target.name == 'firstName') {
      setUser(Object.assign({ ...user }, { firstName: e.target.value }))
    } else if (e.target.name == 'lastName') {
      setUser(Object.assign({ ...user }, { lastName: e.target.value }))
    }
    setPatient(Object.assign({ ...patient }, { [e.target.name]: e.target.value }))
  }

  const handleUserInput = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setUser(Object.assign({ ...user }, { [e.target.name]: e.target.value }))
  }

  const handleNoteInput = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setNotes(Object.assign({ ...notes }, { [e.target.name]: e.target.value }))
  }

  const handleDate = (e: MaterialUiPickersDate, name: string) => {
    if (e && name == 'registerDate')
      setPatient(Object.assign({ ...patient }, { ['createdAt']: new Date(e.toDate()) }))
    if (e && name == 'birthDate')
      setPatient(Object.assign({ ...patient }, { ['dob']: new Date(e.toDate()) }))
    if (e && name == 'diagnosisDate')
      setPatient(Object.assign({ ...patient }, { ['diagnosisDate']: new Date(e.toDate()) }))
    if (e && name == 'procedureDate')
      setPatient(Object.assign({ ...patient }, { ['procedureDate']: new Date(e.toDate()) }))
    if (e && name == 'noteDate')
      setNotes(Object.assign({ ...notes }, { ['date']: new Date(e.toDate()) }))
  }

  const handleSelect = (e: SelectChangeEvent) => {
    const { name, value } = e.target

    switch (name) {
      case 'gender':
        const genderOptions = {
          [t('male')]: UserGender.Male,
          [t('female')]: UserGender.Female,
          [t('noneOfTheAbove')]: UserGender.NoneOfTheAbove,
        }
        const selectedGender = genderOptions[t(value)]
        setPatient({ ...patient, gender: selectedGender })
        break
      case 'intervention':
        setInterventionName(value)
        break
    }
  }

  const handleCancel = () => navigate(ROUTE_PATIENTS).then()

  const validate = () => {
    let unFilled = ''

    if (!patient.email) {
      let space = unFilled == '' ? ': ' : ', '
      unFilled = unFilled + space + t('email')
    }

    if (!patient.firstName) {
      let space = unFilled == '' ? ': ' : ', '
      unFilled = unFilled + space + t('firstName')
    }

    if (!patient.lastName) {
      let space = unFilled == '' ? ': ' : ', '
      unFilled = unFilled + space + t('lastName')
    }

    if (patientDiagnostic.diagnosticID == '') {
      let space = unFilled == '' ? ': ' : ', '
      unFilled = unFilled + space + t('condition')
    }

    let problemsChecked = problemsInterface.filter((p) => p.checked)
    if (problemsChecked.length == 0) {
      let space = unFilled == '' ? ': ' : ', '
      unFilled = unFilled + space + t('problem')
    }

    let treatChecked = patientTreatmentsInter.filter((p) => p.checked)
    if (treatChecked.length == 0) {
      let space = unFilled == '' ? ': ' : ', '
      unFilled = unFilled + space + t('treatment')
    }

    if (unFilled != '') {
      setErrorMessage(unFilled)
    } else {
      setErrorMessage('')
      setModalOpened(true)
    }
  }

  const handleSave = () => {
    userService
      .getFilteredList(
        new Query({
          query: [new QueryParam('email', user.email)],
        })
      )
      .subscribe((res) => {
        if (res.items.length > 0) {
          enqueueSnackbar(t('userAlreadyRegistered'), { variant: 'error' })
          setUser(Object.assign({ ...user }, { email: '' }))
          setPatient(Object.assign({ ...patient }, { email: '' }))
        } else {
          let newUser = Object.assign({ ...user }, { ['roleID']: 4 })

          userService.add(newUser).subscribe((res) => {
            patient.createdBy = loggedUser?.id ?? ''
            patientService.add(patient).subscribe((res) => {
              let patientProChecked: PatientProblemInterface[] = problemsInterface.filter(
                (prob) => prob.checked
              )
              patientProChecked.forEach((pro) => {
                patientProblemService.add(
                  fromModelProblem(
                    new PatientProblem({
                      id: pro.id,
                      createdAt: new Date(),
                      problemID: pro.problemID,
                      patientID: pro.patientID,
                    })
                  )
                )
              })

              if (patientDiagnostic.diagnosticID) {
                patientDiagnosticsService.add(
                  fromModelPT(
                    new PatientDiagnostic({
                      id: uuidv4(),
                      createdAt: patientDiagnostic.date,
                      diagnosticID: patientDiagnostic.diagnosticID,
                      patientID: patientDiagnostic.patientID,
                      text: patientDiagnostic.text,
                    })
                  )
                )
              }

              let patientTreatmentsChecked: PatientTreatmentInterface[] =
                patientTreatmentsInter.filter((treat) => treat.checked)

              patientTreatmentsChecked.forEach((pt) => {
                patientTreatmentService.add(
                  fromModelTreatment(
                    new PatientTreatment({
                      id: pt.id,
                      createdAt: new Date(),
                      treatmentID: pt.treatmentID,
                      brand: pt.brand,
                      patientID: pt.patientID,
                    })
                  )
                )
              })

              res && navigate(ROUTE_PATIENTS).then()
            })

            if (notes.content != '') {
              patientNoteService.add({
                id: uuidv4(),
                patientID: patient.id,
                content: notes.content,
                date: new Date(),
              })
            }
          })
        }
      })
  }

  const handleChangeProblems = (newProblems: PatientProblemInterface[]) => {
    setProblemsInterface(newProblems)
  }

  const getView = (view: View): JSX.Element => {
    switch (view) {
      case View.generalData:
        return (
          <GeneralView
            patient={patient}
            user={user}
            handlePatientInput={handleInput}
            handleUserInput={handleUserInput}
            handleSelect={handleSelect}
            handleDate={handleDate}
            handleCancel={handleCancel}
            handleSave={handleSave}
            handleChangeCommunity={(com) => {
              setPatient(Object.assign({ ...patient }, { autonomousCommunity: com }))
              setUser(Object.assign({ ...user }, { autonomousCommunity: com }))
            }}
            handleChangeProvince={(prov) => {
              setPatient(Object.assign({ ...patient }, { province: prov }))
              setUser(Object.assign({ ...user }, { province: prov }))
            }}
            handleChangeProvinceCommunity={(prov, com) => {
              let newUs = Object.assign({ ...user }, { province: prov })
              let newUs2 = Object.assign({ ...newUs }, { autonomousCommunity: com })
              setUser(newUs2)
              let newPat = Object.assign({ ...patient }, { province: prov })
              let newPat2 = Object.assign({ ...newPat }, { autonomousCommunity: com })
              setPatient(newPat2)
            }}
          />
        )
      case View.medicalData:
        return (
          <MedicalView
            patient={patient}
            diagnostics={diagnostics}
            interventions={interventions}
            patientDiagnostic={patientDiagnostic}
            patientIntervention={interventionName}
            handlePatientInput={handleInput}
            handleChangePatientTreatment={handleChangePatientTreatment}
            allPatientTreatmentsInter={patientTreatmentsInter}
            problemsInterface={problemsInterface}
            handleChangeDiagnostic={handleChangeDiagnostic}
            handleChangeProblems={handleChangeProblems}
          />
        )
      case View.notes:
        return <NotesView note={notes} handleNoteInput={handleNoteInput} handleDate={handleDate} />
    }
  }

  const handleTabChange = (event: React.ChangeEvent<{}>, tabNumber: number) => {
    setTabValue(tabNumber)
    switch (tabNumber) {
      case 0:
        setView(View.generalData)
        break
      case 1:
        setView(View.medicalData)
        break
      case 2:
        setView(View.notes)
        break
      default:
        break
    }
  }

  return (
    <>
      <Box className={generic.pageContainer}>
        {errorMessage && (
          <Box mb={3}>
            <Alert severity="warning" key="errorMessage" id="errorMessage">
              {t('unfilledFields')}
              {errorMessage}
            </Alert>
          </Box>
        )}
        <Box className={style.tabContainer}>
          <AppBar position="static" className={style.caltabs}>
            <Tabs value={tabValue} onChange={handleTabChange} className={style.caltabs}>
              <Tab
                label={t('generalData')}
                className={tabValue === 0 ? style.parsetabsActive : style.parsetabs}
              />
              <Tab
                label={t('medicalData')}
                className={tabValue === 1 ? style.parsetabsActive : style.parsetabs}
              />
              <Tab
                label={t('notes')}
                className={tabValue === 2 ? style.parsetabsActive : style.parsetabs}
              />
            </Tabs>
          </AppBar>
          {tabsView}
          <Box className={style2.buttons} style={{ padding: '25px', marginTop: '10px' }}>
            <Box className={style2.button}>
              <AppButton
                theme={ButtonTheme.BasicTransparentBlue}
                type="button"
                label={t('cancel')}
                handler={handleCancel}
              />
            </Box>
            <Box className={style2.button}>
              <AppButton
                theme={ButtonTheme.NewPrimaryLight}
                type="button"
                label={t('save')}
                handler={validate}
              />
            </Box>
          </Box>
        </Box>
        <Modal
          open={modalOpened}
          onClose={() => {
            setLegalTermsAccepted(false)
            setModalOpened(false)
          }}
        >
          <LegalTermsModal
            handleChangeCheck={() => setLegalTermsAccepted(!legalTermsAccepted)}
            title={t('privacyPoliticPatientRegister')}
            warningText={t('patientRegisterCheck')}
            handleClose={() => {
              setLegalTermsAccepted(false)
              setModalOpened(false)
            }}
            handleSave={handleConfirm}
          />
        </Modal>
      </Box>
    </>
  )
}
