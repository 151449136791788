import { IInit, Container } from '../../../common/container/Container'
import { Observable } from 'rxjs'
import { IStatusService } from '../../../common/status/StatusService'
import { STATUS_SERVICE_KEY } from '../../../container/app'
import { ItemList } from '../../../common/models/ItemList'
import { Query } from '../../../common/api/Query'
import {
  QuestionnairePatientAnswer,
  QuestionnairePatientAnswerQuery,
} from '../models/QuestionnariePatientAnswer'
import { IQuestionnairePatientAnswerApi } from '../api/QuestionairePatientAnswerApi'
import { QuestionnairePatientAnswerDTO } from '../models/QuestionnariePatientAnswerDTO'

type Props = {
  apiKey: symbol
}

export interface IQuestionnairePatientAnswer extends IInit {
  getList(
    q: Query<QuestionnairePatientAnswerQuery>
  ): Observable<ItemList<QuestionnairePatientAnswer>>

  add(quest: QuestionnairePatientAnswerDTO): Observable<QuestionnairePatientAnswer | undefined>

  getByID(id: string): Observable<QuestionnairePatientAnswer | undefined>

  delete(id: string): Observable<boolean>

  getPdfFromAnswers(id: string, language: string): Observable<BinaryType | undefined>
}

export class QuestionnairesPatientAnswerService implements IQuestionnairePatientAnswer {
  private readonly _apiKey: symbol
  private _container!: Container
  private _api!: IQuestionnairePatientAnswerApi
  private _statusService!: IStatusService

  constructor(p: Props) {
    this._apiKey = p.apiKey
  }

  init(c: Container) {
    this._container = c
    this._api = this._container.get<IQuestionnairePatientAnswerApi>(this._apiKey)
    this._statusService = this._container.get<IStatusService>(STATUS_SERVICE_KEY)
  }

  getList(
    q: Query<QuestionnairePatientAnswerQuery>
  ): Observable<ItemList<QuestionnairePatientAnswer>> {
    return this._api.getList(q)
  }

  add(quest: QuestionnairePatientAnswerDTO): Observable<QuestionnairePatientAnswer | undefined> {
    return this._api.add(quest)
  }

  getByID(id: string): Observable<QuestionnairePatientAnswer | undefined> {
    return this._api.getByID(id)
  }

  delete(id: string): Observable<boolean> {
    return this._api.delete(id)
  }

  getPdfFromAnswers(id: string, language: string): Observable<BinaryType | undefined> {
    return this._api.getPdfFromAnswers(id, language)
  }
}
