import generic from '../../../../assets/generic.module.css'
import { Box, Button, Divider, Modal } from '@mui/material'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import image from './intestineFunction.png'

export const CIS112 = () => {
  const { t } = useTranslation()
  const [openModal, setOpenModal] = useState<boolean>(false)
  const { innerWidth } = window
  const handleOpenModal = () => setOpenModal(true)
  const handleCloseModal = () => setOpenModal(false)

  return (
    <>
      <Box className={generic.pageContainer} style={{ backgroundColor: 'white' }}>
        <Box className={generic.pageContainer} display="flex">
          <h2>
            <b>1.1 La función del intestino</b>
          </h2>
        </Box>
        <Box>
          <h1 style={{ color: 'dodgerblue' }}>
            <b>Control neurológico</b>
          </h1>
        </Box>

        <Box display="flex" className={generic.pageContainer}>
          <div>
            El sistema nervioso central (SNC) est&aacute; formado por el cerebro y la m&eacute;dula
            espinal. El sistema nervioso perif&eacute;rico (SNP)
            <br />
            se refiere a todos los dem&aacute;s nervios de nuestro cuerpo adem&aacute;s del SNC.
            <br />
            <br />
            El SNP se divide a su vez en 2 sistemas:
            <br />
            <br />
            &nbsp;&nbsp;&nbsp;&nbsp;&bull;&nbsp;&nbsp;&nbsp;&nbsp; Sistema nervioso aut&oacute;nomo
            <br />
            <br />
            &nbsp;&nbsp;&nbsp;&nbsp;&bull;&nbsp;&nbsp;&nbsp;&nbsp; Sistema nervioso som&aacute;tico
            <br />
            <br />
            El sistema nervioso aut&oacute;nomo se ocupa de las funciones de nuestro cuerpo que no
            est&aacute;n bajo control voluntario. Por
            <br />
            ejemplo, funciones en el coraz&oacute;n, el h&iacute;gado y el intestino. El sistema
            nervioso aut&oacute;nomo se puede dividir en 2 sistemas
            <br />
            m&aacute;s:
            <br />
            <br />
            &nbsp;&nbsp;&nbsp;&nbsp;&bull;&nbsp;&nbsp;&nbsp;&nbsp; Sistema nervioso simp&aacute;tico
            <br />
            <br />
            &nbsp;&nbsp;&nbsp;&nbsp;&bull;&nbsp;&nbsp;&nbsp;&nbsp; Sistema nervioso
            parasimp&aacute;tico
            <br />
            <br />
          </div>
          <img
            src={image}
            onClick={handleOpenModal}
            style={{
              width: innerWidth < 900 ? '50%' : '28%',
              height: '15%',
              marginLeft: innerWidth < 900 ? '3%' : '10%',
              justifyContent: 'center',
              display: 'flex',
              borderStyle: 'ridge',
              borderColor: 'dodgerblue',
            }}
          />
        </Box>
        <Box className={generic.pageContainer} style={{ marginTop: '2%', marginRight: '12%' }}>
          <div>
            El <b>sistema nervioso simpático</b>se activa cuando necesitamos una respuesta de "lucha
            o huida", por ejemplo, cuando nos exponemos a una gran cantidad de estrés o nos
            encontramos con un peligro. El estrés y un estilo de vida frenético a menudo sobrecargan
            nuestro sistema nervioso simpático, lo que contribuye a sufrir problemas digestivos.
            <br />
            <br />
            El <b>sistema nervioso parasimpático</b>es lo opuesto al sistema nervioso simpático. Es
            el estado de "reposo y digestión" del cuerpo. Tenemos cierto control voluntario sobre
            este sistema, por ejemplo, al orinar y al defecar. <br />
            <br />
            El <b>sistema nervioso somático</b>se ocupa de las funciones que están bajo control
            voluntario: los nervios de los músculos controlados por la voluntad de cada persona, la
            piel y los órganos sensoriales.
            <br />
            <br />
            Algunos libros de texto exponen que el sistema nervioso autónomo tiene un tercer
            sistema: el <b>sistema nervioso entérico.</b>Son los nervios hacia/desde el intestino.
            También se denomina "segundo cerebro" y puede funcionar por sí solo o junto con el
            sistema nervioso simpático y parasimpático.
            <br />
            <br />
          </div>
        </Box>

        <Modal open={openModal} onClose={handleCloseModal}>
          <Box
            style={{
              backgroundColor: 'white',
              justifyContent: 'center',
              transform: 'translate(0%, 20%)',
              marginLeft: '30%',
              marginRight: '30%',
              padding: '20px',
            }}>
            <h1 style={{ marginLeft: '25%' }}>
              <b>¿Qué es el intestino y como funciona?</b>
            </h1>
            <Divider variant={'middle'} />
            <img
              src={image}
              style={{
                width: '80%',
                height: '25%',
                marginTop: '1%',
                marginLeft: '10%',
                justifyContent: 'center',
                display: 'flex',
              }}
            />
            <Button
              variant={'outlined'}
              style={{ marginLeft: '45%', marginTop: '1%', justifyContent: 'end', display: 'flex' }}
              onClick={handleCloseModal}>
              {t('close')}
            </Button>
          </Box>
        </Modal>
      </Box>
    </>
  )
}
