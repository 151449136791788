import { RouteProps } from '../../routes/AppRouter'
import { CurrentNavHeader } from '../../components/current-nav-header/CurrentNavHeader'
import { Configuration as C } from '../../features/configuration'
import React from 'react'

export function Configuration(props: RouteProps) {
  const title = props.title || ''

  return (
    <>
      <CurrentNavHeader title={title} />
      <C />
    </>
  )
}
