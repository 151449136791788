import { ViewProps } from './Statistics'
import { Box } from '@mui/material'
import { GenericChart } from '../../components/generic-chart/GenericChart'
import { useTranslation } from 'react-i18next'
import style from './Stats.module.css'
import { useEffect, useState } from 'react'
import { Urination } from 'modules/urination/models/Urination'
import { getUserContainer } from 'container/user-module'
import { ILoggedUserService } from 'modules/users/services/LoggedUserService'
import { LOGGED_USER_SERVICE_KEY } from 'modules/users'
import UrinationStatisticsTable from './UrinationStatisticsTable'

export type Data = {
  x: string
  y: number | null
}

export type DataType = {
  borderColor?: string
  borderWidth?: number
  data: Data[]
  label: string
  pointRadius?: number
  backgroundColor?: string[]
  type?: string
  tension?: number
}

const loggedUserService = getUserContainer().get<ILoggedUserService>(LOGGED_USER_SERVICE_KEY)
const DRINK_QUANTITIES = [150, 200, 330, 500]
export const MiccionalView = (props: ViewProps) => {
  const { t } = useTranslation()
  const [drinkQuantity, setDrinkQuantity] = useState<DataType[]>([])
  const [liquidDrunk, setLiquidDrunk] = useState<DataType[]>([])
  const [diuresisQuantity, setDiuresisQuantity] = useState<DataType[]>([])
  const [isLoading, setIsLoading] = useState<boolean>(true)
  const { innerWidth } = window

  const getDrinkQuantity = (ur: Urination) => {
    const sumDrinkQuantity = ur.drinkQuantity
      .split(',')
      .filter((quantity) => quantity !== '')
      .reduce((sum, q) => sum + DRINK_QUANTITIES[Number(q)], 0)

    return Number(ur.foodType) != -1 ? sumDrinkQuantity + ur.waterQuantity : sumDrinkQuantity
  }

  const getTotalExcreted = (ur: Urination) => {
    return ur.emptyingQuantity.split(',').reduce((sum, q) => sum + Number(q), 0)
  }

  const getDrinkQuantityOnly = (ur: Urination) => {
    const arrayQuantity = ur.drinkQuantity
      .split(',')
      .filter((quantity) => quantity !== '')
      .map(Number)
    const drinkQuantities = [150, 200, 330, 500]
    return arrayQuantity.reduce((sum, q) => sum + drinkQuantities[q], 0)
  }

  useEffect(() => {
    const hours = Array.from({ length: 19 }, (_, i) => (i + 5).toString().padStart(2, '0') + ':00')
    let accumulatedSum = 0
    let accumulatedSumExcreted = 0

    const initializeDataArray = (initialValue: number | null) =>
      hours.map((hour) => ({ x: hour, y: initialValue }))
    let urinationFiltered = initializeDataArray(null)
    let totalExcreted = initializeDataArray(null)
    let liquidDrunkFiltered = initializeDataArray(0)
    let waterFoodFiltered = initializeDataArray(0)
    let axisDiuVol = initializeDataArray(0)
    let axisDiuEsp = initializeDataArray(0)

    const updateDataArray = (dataArray: Data[], index: number, value: number) => {
      if (dataArray[index].y === null) dataArray[index].y = 0
      // @ts-ignore
      dataArray[index].y += value
    }

    props.urination?.sort(
      (a, b) => new Date(a.drinkDate).getTime() - new Date(b.drinkDate).getTime()
    )

    // Agrupa los datos por hora
    const groupedData = (props?.urination || []).reduce((acc, ur) => {
      const d = new Date(ur.drinkDate)
      if (new Date(d.getFullYear(), d.getMonth(), d.getDate()) >= props.startDate) {
        const hour = d.getHours().toString().padStart(2, '0') + ':00'
        if (!acc[hour]) {
          acc[hour] = []
        }
        acc[hour].push(ur)
      }
      return acc
    }, {} as Record<string, Urination[]>)

    Object.entries(groupedData).forEach(([hour, data]) => {
      const index = hours.indexOf(hour)
      if (index !== -1) {
        data.forEach((ur) => {
          //Liquid drunk
          const drinkQuantity = getDrinkQuantity(ur)
          accumulatedSum += drinkQuantity
          // @ts-ignore
          urinationFiltered[index].y = accumulatedSum
          liquidDrunkFiltered[index].y = getDrinkQuantityOnly(ur)
          waterFoodFiltered[index].y = ur.waterQuantity

          //Diuresis
          const emptyingQuantity = getTotalExcreted(ur)
          accumulatedSumExcreted += emptyingQuantity
          totalExcreted[index].y = accumulatedSumExcreted

          const emptyings = ur.emptying.split(',')
          const emptyingsQuantities = ur.emptyingQuantity.split(',')
          if (emptyings.includes('1')) {
            // @ts-ignore
            axisDiuVol[index].y += Number(emptyingsQuantities[0])
          }
          if (emptyings.includes('2')) {
            emptyings.length === 1
              ? // @ts-ignore
                (axisDiuEsp[index].y += Number(emptyingsQuantities[0]))
              : // @ts-ignore
                (axisDiuEsp[index].y += Number(emptyingsQuantities[1]))
          }
        })
      }
    })

    urinationFiltered = maintainLastValueUntilNext(urinationFiltered)
    totalExcreted = maintainLastValueUntilNext(totalExcreted)

    setDrinkQuantity([
      {
        label: t('accumulated'),
        data: urinationFiltered,
        borderWidth: 3,
        borderColor: '#f0007d',
        pointRadius: 0,
        type: 'line',
        tension: 0,
      },
      {
        label: t('liquidDrunk'),
        data: liquidDrunkFiltered,
        backgroundColor: ['#68B3E0'],
        pointRadius: 0,
      },
      {
        label: t('waterFood'),
        data: waterFoodFiltered,
        backgroundColor: ['#f88831'],
        pointRadius: 0,
      },
    ])

    setDiuresisQuantity([
      {
        label: t('totalExcreted'),
        data: totalExcreted,
        borderWidth: 3,
        borderColor: '#f0007d',
        pointRadius: 0,
        type: 'line',
        tension: 0,
      },
      { label: t('urinated'), data: axisDiuVol, backgroundColor: ['#68B3E0'], pointRadius: 2 },
      { label: t('sounded'), data: axisDiuEsp, backgroundColor: ['#f88831'], pointRadius: 2 },
    ])

    setIsLoading(false)
  }, [props.urination])

  // Función para mantener el último valor registrado hasta el siguiente valor
  function maintainLastValueUntilNext(data: Data[]): Data[] {
    let lastNonNullValue: number | null = null

    for (let i = 0; i < data.length; i++) {
      if (data[i].y !== null) {
        lastNonNullValue = data[i].y
      } else if (lastNonNullValue !== null) {
        data[i].y = lastNonNullValue
      }
    }

    return data
  }

  const extractMonthsFromChart = (chartPercentile: DataType[]): string[] => {
    let set: Set<string> = new Set()

    chartPercentile.forEach((cps) =>
      cps.data.forEach((cp: Data) => {
        set.add(cp.x)
      })
    )
    return Array.from(set).sort((a, b) => new Date(a).getTime() - new Date(b).getTime())
  }

  return (
    <>
      <Box className={style.chartsContainer}>
        {!isLoading && (
          <>
            <Box
              display="flex"
              justifyContent="center"
              maxWidth={innerWidth < 900 ? '700px' : '800px'}
            >
              <GenericChart
                title={t('fluidIntake')}
                type="bar"
                data={drinkQuantity}
                labelX={['']}
                xAxisLabel={''}
                yAxisLabel={'ml '}
                showLegend={true}
                showTitle={true}
                showTooltip={true}
              />
            </Box>
            <Box
              display="flex"
              justifyContent="center"
              maxWidth={innerWidth < 900 ? '700px' : '800px'}
            >
              <GenericChart
                title={t('diuresis')}
                type="bar"
                data={diuresisQuantity}
                labelX={extractMonthsFromChart(diuresisQuantity)}
                xAxisLabel={''}
                yAxisLabel={'ml '}
                showLegend={true}
                showTitle={true}
                showTooltip={true}
              />
            </Box>
          </>
        )}
        {/* <Box ml={4} mt={8}>
        <UrinationStatisticsTable />
          </Box>*/}
      </Box>
    </>
  )
}
