import i18n from '../../../i18n'

export enum ArticleType {
  News = 1,
  Faqs,
}

export const articleTypes = (): Record<ArticleType, string> => ({
  [ArticleType.News]: i18n.t('news'),
  [ArticleType.Faqs]: i18n.t('faqs'),
})
