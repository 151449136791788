import { RouteProps } from '../../routes/AppRouter'
import { CurrentNavHeader } from '../../components/current-nav-header/CurrentNavHeader'
import { Statistics as S } from '../../features/statistics/Statistics'
import React, { useState, useEffect } from 'react'
import { getPatientContainer } from '../../container/patient-module'
import { PatientService } from '../../modules/patients/services/PatientService'
import { PATIENT_SERVICE_KEY } from '../../modules/patients'
import { useTranslation } from 'react-i18next'

const patientService = getPatientContainer().get<PatientService>(PATIENT_SERVICE_KEY)

export function Statistics(props: RouteProps) {
  const [title, setTitle] = useState<string>('')
  const { t } = useTranslation()

  useEffect(() => {
    patientService.getByID(props.id || '').subscribe((res) => {
      setTitle(`${t('patient')} ${' '} ${res.firstName} ${' '}  ${res.lastName}`)
    })
  }, [])

  return (
    <>
      <CurrentNavHeader title={title} />
      <S id={props.id || ''} />
    </>
  )
}
