import { HTTP_CLIENT_KEY, IHTTPClient } from 'common/api/HTTPClient'
import { Container, IInit } from 'common/container/Container'
import { emptyList, ItemList } from 'common/models/ItemList'
import { IStatusService } from 'common/status/StatusService'
import { STATUS_SERVICE_KEY } from 'container/app'
import { Observable } from 'rxjs'
import { catchError, map } from 'rxjs/operators'
import { PatientContainerConfig } from '../container'
import { Note } from '../models/Note'
import { NoteDTO, toModel } from '../models/NoteDTO'

export interface IPatientNoteApi extends IInit {
  add(n: NoteDTO): Observable<Note | undefined>
  getNotesByPatientID(id: string): Observable<Note[]>
  update(e: NoteDTO): Observable<Note | undefined>
}

export class PatientNoteApi implements IPatientNoteApi {
  private _container!: Container
  private _httpClient!: IHTTPClient
  private _url!: string
  private _statusService!: IStatusService

  init(c: Container) {
    this._container = c
    this._httpClient = this._container.get<IHTTPClient>(HTTP_CLIENT_KEY)
    this._statusService = this._container.get<IStatusService>(STATUS_SERVICE_KEY)
    this._url = (this._container.config as PatientContainerConfig).moduleFullUrl
  }

  add(n: NoteDTO): Observable<Note | undefined> {
    return this._httpClient
      .post<Note>({ url: `${this._url}/notes`, body: n })
      .pipe(
        map<NoteDTO, Note>((d) => {
          this._statusService.sendStatus({ variant: 'success' })
          return toModel(d)
        })
      )
  }

  getNotesByPatientID(id: string): Observable<Note[]> {
    return this._httpClient
      .get<Note[]>({ url: `${this._url}/notes/${id}` })
      .pipe(
        map<NoteDTO[], Note[]>((dto) => dto.map((d) => toModel(d)))
      )
  }

  update(e: NoteDTO): Observable<Note | undefined> {
    return this._httpClient
      .put<Note>({ url: `${this._url}/notes`, body: e })
      .pipe(
        map<NoteDTO, Note>((d) => {
          this._statusService.sendStatus({ variant: 'success' })
          return toModel(d)
        })
      )
  }
}
