import generic from '../../../../assets/generic.module.css'
import { Box } from '@mui/material'
import React from 'react'
import style from './Table.module.css'

export const CVS310 = () => {
  return (
    <>
      <Box className={generic.pageContainer} style={{ backgroundColor: 'white' }}>
        <Box className={generic.pageContainer} display="flex">
          <h2>
            <b>3.1 Lesión Medular - Función Vesical y síntomas</b>
          </h2>
        </Box>

        <Box className={generic.pageContainer}>
          <div>
            <b>Función Vesical</b>
            <br />
            <br />
            La mayoría de las personas con LM experimentan síntomas significativos relacionados con
            la disfunción intestinal neurógena, por ejemplo, el 42 % experimenta estreñimiento y
            hasta el 77 % experimenta incontinencia fecal.
            <br />
            <br />
            Una lesión medular (LM) se puede producir en cualquier nivel de la médula espinal. Puede
            ser una lesión completa, con una pérdida total de la sensibilidad y la función muscular,
            o bien incompleta, lo que significa que puede quedar algo de control y sensación
            muscular. Una LM por lo general afecta al control de la vejiga y el intestino pero,
            según el nivel y la integridad de la lesión, se presentan diferentes síntomas. Hay tres
            nervios importantes para controlar la función de la vejiga y el intestino.
            <br />
            <br />
            <Box style={{ display: 'flex', justifyContent: 'center' }}>
              <table className={style.table}>
                <tr className={style.tr}>
                  <th>Nervio</th>
                  <th>Función vejiga - intestino</th>
                  <th>Espinal - Vejiga</th>
                  <th>Espinal - Intestino</th>
                </tr>
                <tr className={style.td}>
                  <td>Nervio hipogástrico</td>
                  <td>Continencia</td>
                  <td>Th10-L2</td>
                  <td>Th12-L3</td>
                </tr>
                <tr className={style.td}>
                  <td>Nervio pélvico</td>
                  <td>Micción/Defecación</td>
                  <td>S2-S4</td>
                  <td>S2-S4</td>
                </tr>
                <tr className={style.td}>
                  <td>Nervio pudendo</td>
                  <td>Uretral externo/ Esfínter anal</td>
                  <td>S2-S4</td>
                  <td>S2-S4</td>
                </tr>
              </table>
            </Box>
            <br />
            <br />
            <br />
            Las personas con LM experimentan problemas intestinales neurógenos, que aumentan con la
            edad y la duración.
            <br />
            <br />
            Las lesiones más altas producirán tiempos de tránsito del colon más lentos, de hasta el
            doble del tránsito "normal sano". Esto provocará estreñimiento y retención/impactación
            fecal.
            <br />
            <br />
          </div>
        </Box>
      </Box>
    </>
  )
}
