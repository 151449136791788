import { PatientTreatmentDTO } from './PatientTreatmentDTO'

export class PatientTreatment {
  private readonly _id: string
  private _createdAt: Date
  private _treatmentID: string
  private _brand: string
  private _patientID: string

  constructor(p: PatientTreatmentDTO) {
    this._id = p.id
    this._createdAt = p.createdAt
    this._treatmentID = p.treatmentID
    this._brand = p.brand
    this._patientID = p.patientID
  }

  get id(): string {
    return this._id
  }

  get createdAt(): Date {
    return this._createdAt
  }

  get treatmentID(): string {
    return this._treatmentID
  }

  get patientID(): string {
    return this._patientID
  }

  get brand() : string {
    return this._brand
  }
}

export interface PatientTreatmentQuery {
  id: string
  createdAt: Date
  symptomID: string
  patientID: string
}
