import { Box } from '@material-ui/core'
import { FormControlLabel } from '@mui/material'
import { Header } from 'components/header/Header'
import style from './Questionnaires.module.css'
import close from '../../assets/table_icons/ico-eliminar.svg'
import { getQuestionnaireContainer } from '../../container/questionnaire-module'
import { QuestionnairesService } from '../../modules/questionnaires/services/QuestionnaireService'
import { QUESTIONNAIRE_SERVICE_KEY } from '../../modules/questionnaires/container'
import { useEffect, ChangeEvent } from 'react'
import { useState } from 'react'
import { Questionnaire } from '../../modules/questionnaires/models/Questionnaire'
import { Checkbox } from '@mui/material'
import { AppButton, ButtonTheme } from 'components/app-button/AppButton'
import { useTranslation } from 'react-i18next'
import { Query } from 'common/api/Query'

type questionnarieProps = {
  title: string
  handleClose: () => void
  handleSave: (ids: string[]) => void
  patientID: string
}

const questionnaireService =
  getQuestionnaireContainer().get<QuestionnairesService>(QUESTIONNAIRE_SERVICE_KEY)

export function QuestionnairesModal(props: questionnarieProps) {
  const { t } = useTranslation()
  const [items, setItems] = useState<Questionnaire[]>([])

  useEffect(() => {
    questionnaireService
      .getPatientQuestionnaireList(new Query({}), props.patientID)
      .subscribe((res0) => {
        let assignedQuests = res0.items.map((q) => q.questionnaireID)
        questionnaireService.getList().subscribe((res) => {
          let aux = res.items[0]
          setItems(res.items)
        })
      })
  }, [])

  const handleChangeCheck = (e: ChangeEvent<HTMLInputElement>, index: number) => {
    const updatedItems = [...items]
    updatedItems[index].checked = e.target.checked
    setItems(updatedItems)
  }

  const assignQuestionnaire = () => {
    let itemsSelected = items.filter((item) => item.checked).map((x) => x.id)

    props.handleSave(itemsSelected)
  }

  return (
    <div>
      <div
        className={style.questionnaireContainer}
        style={{ marginTop: items.length > 5 ? '-10%' : '-2%' }}
      >
        <Box className={style.containerInside}>
          <Header label={props.title} icon={close} onClick={props.handleClose} />
          <Box className={style.containerContent}>
            {items.map(function (quest, index) {
              return (
                <FormControlLabel
                  sx={{
                    '.css-ahj2mt-MuiTypography-root': {
                      fontFamily: 'Open-sans, sans-serif',
                    },
                    '.css-170jmt1-MuiFormControlLabel-root': {
                      fontFamily: 'Open-sans, sans-serif',
                    },
                  }}
                  style={{ fontFamily: 'Open-sans, sans-serif' }}
                  control={
                    <Checkbox
                      key={quest.id}
                      value={quest.id}
                      onChange={(e) => handleChangeCheck(e, index)}
                      checked={quest.checked}
                    />
                  }
                  label={<span style={{ fontFamily: 'Open-sans, sans-serif' }}>{quest.title}</span>}
                />
              )
            })}
          </Box>
          <Box className={style.buttons}>
            <AppButton
              theme={ButtonTheme.NewPrimaryLight}
              type={'button'}
              label={t('submit')}
              handler={assignQuestionnaire}
            />
          </Box>
        </Box>
      </div>
    </div>
  )
}
