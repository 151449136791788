import { RouteProps } from '../../routes/AppRouter'
import { CurrentNavHeader } from '../../components/current-nav-header/CurrentNavHeader'
import React, { useEffect, useState } from 'react'
import { FecalDiary as F } from '../../features/fecal'
import { getPatientContainer } from 'container/patient-module'
import { PatientService } from 'modules/patients/services/PatientService'
import { PATIENT_SERVICE_KEY } from 'modules/patients'
import { useTranslation } from 'react-i18next'

const patientService = getPatientContainer().get<PatientService>(PATIENT_SERVICE_KEY)

export function Fecal(props: RouteProps) {
  const { t } = useTranslation()
  const [title, setTitle] = useState<string>('')

  useEffect(() => {
    patientService.getByID(props.id || '').subscribe((res) => {
      setTitle(`${t('patient')} ${' '} ${res.firstName} ${' '}  ${res.lastName}`)
    })
  }, [])

  return (
    <>
      <CurrentNavHeader title={title} />
      <F id={props.id || ''} />
    </>
  )
}
