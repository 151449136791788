import { HTTP_CLIENT_KEY, IHTTPClient } from 'common/api/HTTPClient'
import { IStatusService } from 'common/status/StatusService'
import { STATUS_SERVICE_KEY } from 'container/app'
import { Container, IInit } from '../../../common/container/Container'
import { ContentContainerConfig } from '../../goals/container'
import { PatientGoalDTO, toModel } from '../models/PatientGoalDTO'
import { Observable, of } from 'rxjs'
import { PatientGoal, PatientGoalQuery, toModel as toModelPatientGoal } from '../models/PatientGoal'
import { catchError, map } from 'rxjs/operators'
import { Query } from 'common/api/Query'
import { ItemList, emptyList } from '../../../common/models/ItemList'
import { prepareURL } from '../../../common/api/http-helpers'

export interface IPatientGoalApi extends IInit {
  add(p: PatientGoalDTO): Observable<PatientGoal | undefined>
  getListByID(id: string): Observable<ItemList<PatientGoal>>
  delete(id: string): Observable<boolean>
  update(e: PatientGoalDTO): Observable<PatientGoal | undefined>
  getFilteredList(q: Query<PatientGoalQuery>): Observable<ItemList<PatientGoal>>
}

export class PatientGoalApi implements IPatientGoalApi {
  private _container!: Container
  private _httpClient!: IHTTPClient
  private _url!: string
  private _statusService!: IStatusService

  init(c: Container) {
    this._container = c
    this._httpClient = this._container.get<IHTTPClient>(HTTP_CLIENT_KEY)
    this._statusService = this._container.get<IStatusService>(STATUS_SERVICE_KEY)
    this._url = (this._container.config as ContentContainerConfig).moduleFullUrl
  }

  add(p: PatientGoalDTO): Observable<PatientGoal | undefined> {
    return this._httpClient
      .post<PatientGoal>({ url: `${this._url}/goals`, body: p })
      .pipe(
        map<PatientGoalDTO, PatientGoal>((d) => {
          this._statusService.sendStatus({ variant: 'success' })
          return toModel(d)
        }),
        catchError((err) => {
          this._statusService.sendStatus({ variant: 'error', error: err })
          return of(undefined)
        })
      )
  }

  getListByID(id: string): Observable<ItemList<PatientGoal>> {
    return this._httpClient
      .get<ItemList<PatientGoal>>({ url: `${this._url}/goals/${id}` })
      .pipe(
        map<ItemList<PatientGoalDTO>, ItemList<PatientGoal>>((dto) => {
          const itemList = emptyList<PatientGoal>()
          itemList.count = dto.count
          itemList.items = dto.items.map((d) => toModelPatientGoal(d))
          return itemList
        }),
        catchError((err) => {
          this._statusService.sendStatus({ variant: 'error', error: err })
          return of(emptyList<PatientGoal>())
        })
      )
  }

  delete(id: string): Observable<boolean> {
    return this._httpClient.delete({ url: `${this._url}/goals/${id}` }).pipe(
      catchError((err) => {
        this._statusService.sendStatus({ variant: 'error', error: err })
        return of(false)
      })
    )
  }

  update(e: PatientGoalDTO): Observable<PatientGoal | undefined> {
    return this._httpClient
      .put<PatientGoal>({ url: this._url + '/goals', body: e })
      .pipe(
        map<PatientGoalDTO, PatientGoal>((d) => {
          this._statusService.sendStatus({ variant: 'success' })
          return toModel(d)
        }),
        catchError((err) => {
          this._statusService.sendStatus({ variant: 'error', error: err })
          return of(undefined)
        })
      )
  }

  getFilteredList(q: Query<PatientGoalQuery>): Observable<ItemList<PatientGoal>> {
    return this._httpClient
      .get<ItemList<PatientGoal>>({ url: prepareURL(this._url + '/patientGoals/list', q) })
      .pipe(
        map<ItemList<PatientGoalDTO>, ItemList<PatientGoal>>((dto) => {
          const itemList = emptyList<PatientGoal>()
          itemList.items = dto.items.map((d) => toModel(d))
          itemList.count = dto.count
          return itemList
        }),
        catchError((err) => {
          this._statusService.sendStatus({ variant: 'error', error: err })
          return of(emptyList<PatientGoal>())
        })
      )
  }
}
