import { Box, List, ListItem } from '@mui/material'
import generic from '../../../../assets/generic.module.css'
import { Avatar, IconButton, ListItemIcon, Typography } from '@material-ui/core'
import moduleActiveIcon from '../../../../assets/higea/modulo-activo.svg'
import viewIcon from '../../../../assets/higea/ico-view.svg'
import React, { useState } from 'react'
import styles from '../../EducationalProfessional.module.css'
import { navigate } from '@reach/router'
import { ROUTE_EDUCATIONALVESICAL51 } from 'routes/routes-constants'

export const Module5Lessons = () => {
  const handleVesical51 = () => navigate(ROUTE_EDUCATIONALVESICAL51).then()

  return (
    <List>
      <ListItem>
        <Box className={styles.lessonItem}>
          <Box display={'flex'} justifyContent={'space-between'} alignItems={'center'}>
            <ListItem id={'idLesson'}>
              <Typography style={{ color: 'blue' }}>5.1 Vídeo</Typography>
            </ListItem>
            <IconButton>
              <Avatar src={viewIcon} style={{ width: 29, height: 25 }} onClick={handleVesical51} />
            </IconButton>
          </Box>
        </Box>
      </ListItem>
    </List>
  )
}
