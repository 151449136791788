import { Container } from '../../common/container/Container'

export type EducationalContainerConfig = {
  moduleFullUrl: string
}

export type EducationalProps = {
  parentContainer: Container
  config: EducationalContainerConfig
}

export const EDUCATIONAL_MODULE = Symbol('EDUCATIONAL_MODULE')

export const EDUCATIONAL_API_KEY = Symbol('EDUCATIONAL_API_KEY')

export const EDUCATIONAL_SERVICE_KEY = Symbol('EDUCATIONAL_SERVICE_KEY')

export const PATIENTLESSON_MODULE = Symbol('PATIENTLESSON_MODULE')

export const PATIENTLESSON_API_KEY = Symbol('PATIENTLESSON_API_KEY')

export const PATIENTLESSON_SERVICE_KEY = Symbol('PATIENTLESSON_SERVICE_KEY')

export const EDUCATIONAL_USER_MODULE = Symbol('EDUCATIONAL_USER_MODULE')

export const EDUCATIONAL_USER_API_KEY = Symbol('EDUCATIONAL_USER_API_KEY')

export const EDUCATIONAL_USER_SERVICE_KEY = Symbol('EDUCATIONAL_USER_SERVICE_KEY')