import { Container, IInit } from '../../../common/container/Container'
import { PatientContainerConfig } from '../container'
import { Observable, of } from 'rxjs'
import { catchError, map } from 'rxjs/operators'
import { Query } from '../../../common/api/Query'
import { HTTP_CLIENT_KEY, IHTTPClient } from '../../../common/api/HTTPClient'
import { IStatusService } from '../../../common/status/StatusService'
import { STATUS_SERVICE_KEY } from '../../../container/app'
import { emptyList, ItemList } from 'common/models/ItemList'
import { PatientTreatmentDTO, toModel } from '../models/PatientTreatmentDTO'
import { PatientTreatment, PatientTreatmentQuery } from '../models/PatientTreatment'

export interface IPatientTreatmentApi extends IInit {
  getByID(id: string | null): Observable<PatientTreatment | undefined>

  getFilteredItems(q: Query<PatientTreatmentQuery>): Observable<ItemList<PatientTreatment>>

  add(e: PatientTreatmentDTO): Observable<PatientTreatment | undefined>

  update(e: PatientTreatmentDTO): Observable<PatientTreatment | undefined>

  delete(id: string): Observable<boolean>

  getPatientTreatmentByPatientID(
    patientID: string
  ): Observable<ItemList<PatientTreatment> | undefined>
}

export class PatientTreatmentApi implements IPatientTreatmentApi {
  private _container!: Container
  private _httpClient!: IHTTPClient
  private _url!: string
  private _statusService!: IStatusService

  init(c: Container) {
    this._container = c
    this._httpClient = this._container.get<IHTTPClient>(HTTP_CLIENT_KEY)
    this._statusService = this._container.get<IStatusService>(STATUS_SERVICE_KEY)
    this._url = (this._container.config as PatientContainerConfig).moduleFullUrl
  }

  getByID(id: string): Observable<PatientTreatment | undefined> {
    return this._httpClient
      .get<PatientTreatment>({ url: this._url + '/' + id })
      .pipe(
        map<PatientTreatmentDTO, PatientTreatment>((d) => toModel(d)),
        catchError((err) => {
          this._statusService.sendStatus({ variant: 'error', error: err })
          return of(undefined)
        })
      )
  }

  getFilteredItems(q: Query<PatientTreatmentQuery>): Observable<ItemList<PatientTreatment>> {
    return this._httpClient
      .post<ItemList<PatientTreatment>>({
        url: this._url + '/patienttreatment/paginatedList',
        body: q,
      })
      .pipe(
        map<ItemList<PatientTreatmentDTO>, ItemList<PatientTreatment>>((dto) => {
          const itemList = emptyList<PatientTreatment>()
          itemList.items = dto.items.map((d) => toModel(d))
          itemList.count = dto.count
          return itemList
        }),
        catchError((err) => {
          this._statusService.sendStatus({ variant: 'error', error: err })
          return of(emptyList<PatientTreatment>())
        })
      )
  }

  add(e: PatientTreatmentDTO): Observable<PatientTreatment | undefined> {
    return this._httpClient
      .post<PatientTreatment>({ url: this._url + '/patienttreatment', body: e })
      .pipe(
        map<PatientTreatmentDTO, PatientTreatment>((d) => {
          this._statusService.sendStatus({ variant: 'success' })
          return toModel(d)
        }),
        catchError((err) => {
          this._statusService.sendStatus({ variant: 'error', error: err })
          return of(undefined)
        })
      )
  }

  update(e: PatientTreatmentDTO): Observable<PatientTreatment | undefined> {
    return this._httpClient
      .put<PatientTreatment>({ url: this._url + '/patienttreatment', body: e })
      .pipe(
        map<PatientTreatmentDTO, PatientTreatment>((d) => {
          this._statusService.sendStatus({ variant: 'success' })
          return toModel(d)
        }),
        catchError((err) => {
          this._statusService.sendStatus({ variant: 'error', error: err })
          return of(undefined)
        })
      )
  }

  delete(id: string): Observable<boolean> {
    return this._httpClient.delete({ url: this._url + '/patienttreatment/' + id }).pipe(
      catchError((err) => {
        this._statusService.sendStatus({ variant: 'error', error: err })
        return of(false)
      })
    )
  }

  getPatientTreatmentByPatientID(patientID: string): Observable<ItemList<PatientTreatment>> {
    return this._httpClient
      .get<ItemList<PatientTreatment>>({ url: this._url + '/patienttreatment2/' + patientID })
      .pipe(
        map<ItemList<PatientTreatmentDTO>, ItemList<PatientTreatment>>((dto) => {
          const itemList = emptyList<PatientTreatment>()
          itemList.items = dto.items.map((d) => toModel(d))
          itemList.count = dto.count
          return itemList
        }),
        catchError((err) => {
          this._statusService.sendStatus({ variant: 'error', error: err })
          return of(emptyList<PatientTreatment>())
        })
      )
  }
}
