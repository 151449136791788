import { Query } from '../../../common/api/Query'
import { Message } from './Message'
import { ConversationStatus } from '../enums/ConversationStatus'
import { ConversationDTO, messageToModel } from './ConversationDTO'

export class Conversation {
  private readonly _id: string | undefined
  private readonly _createdAt: Date
  private _user: string
  private _user2: string
  private _messages: Message[]
  private _updatedAt: Date
  private _status: ConversationStatus

  constructor(p: ConversationDTO) {
    this._id = p.id
    this._user = p.user
    this._user2 = p.user2
    this._messages = p.messages?.map((m) => messageToModel(m)) || []
    this._createdAt = p.createdAt
    this._updatedAt = p.updatedAt
    this._status = p.status
  }

  get id(): string | undefined {
    return this._id
  }

  get user(): string {
    return this._user
  }

  get user2(): string {
    return this._user2
  }

  set users(value: string) {
    this._user = value
  }

  get messages(): Message[] {
    if (this._messages) {
      return [...this._messages]
    }

    return this._messages
  }

  addMessage(value: Message) {
    if (this._messages) {
      this._messages.push(value)
      return
    }

    this._messages = [value]
  }

  removeMessage(id: string) {
    this._messages = this._messages.filter((m) => m.id !== id)
  }

  get createdAt(): Date {
    return this._createdAt
  }

  get updatedAt(): Date {
    return this._updatedAt
  }

  set updatedAt(value: Date) {
    this._updatedAt = value
  }

  get status(): ConversationStatus {
    return this._status
  }

  set status(value: ConversationStatus) {
    this._status = value
  }
}

export interface ConversationQuery {
  user: string
}

export class ConversationParams extends Query<Conversation> {}
