import { Container, ContainerItem } from '../common/container/Container'
import { getAppContainer } from './app'
import { moduleURLs } from '../config/api'
import { ContentProps } from '../modules/goals/container'
import { FECAL_API_KEY, FECAL_MODULE, FECAL_SERVICE_KEY } from '../modules/fecal'
import { FecalApi } from 'modules/fecal/api/FecalApi'
import { FecalService } from 'modules/fecal/services/FecalService'

let container: Container

function init(p: ContentProps) {
  const items = new Map<symbol, ContainerItem>()

  items.set(FECAL_API_KEY, new ContainerItem({ instance: new FecalApi() }))

  items.set(
    FECAL_SERVICE_KEY,
    new ContainerItem({ instance: new FecalService({ apiKey: FECAL_API_KEY }) })
  )

  container = new Container(items, p.parentContainer, p.config)
}

export function getFecalContainer(): Container {
  if (!container) {
    init({
      parentContainer: getAppContainer(),
      config: {
        moduleFullUrl: moduleURLs[FECAL_MODULE],
      },
    })
  }

  return container
}
