import { Actions, Field } from './types'
import React from 'react'
import List from '@material-ui/core/List'
import { Box } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'

export type ListTableProps<T> = {
  readonly fields: Field<T>[]
  readonly items: T[]
  readonly rowKeyField: keyof T
  readonly actions?: Actions<T>
}

export function ListTable<T extends { [key: string]: any }>(props: ListTableProps<T>) {
  const useStyles = makeStyles((_) => ({
    body: {
      fontSize: '14px',
      borderBottom: '1px solid #D1D1D1',
      padding: '0 auto 0 auto',
      fontFamily: 'Open-sans, sans-serif',
    },
    icon: {
      cursor: 'pointer',
      width: '20px',
      height: '100%',
      marginTop: 'auto',
      marginBottom: 'auto',
      marginLeft: '10px',
    },
    listItem: {
      borderBottom: '3px solid #68b3e0',
      fontFamily: 'Open-sans, sans-serif', //--color-light-blue
    },
    key: {
      fontWeight: 'bold',
      marginTop: 'auto',
      marginBottom: 'auto',
    },
    cell: {
      borderBottom: '1px solid #ABB8B8',
      minHeight: '45px',
      fontFamily: 'Open-sans, sans-serif',
    },
    actionsCell: {
      minHeight: '45px',
    },
  }))

  const classes = useStyles()

  return (
    <List>
      <Box className={classes.listItem} />
      {props.items.map((item) => {
        const actionStyle = props.actions?.styleFunc ? props.actions.styleFunc(item) : ''
        return (
          <Box className={classes.listItem} display={'flex'} flexDirection={'column'}>
            {Object.values(props.fields).map((field) => {
              const style = field.styleFunc ? field.styleFunc(field, item) : ''
              return (
                <Box justifyContent={'space-between'} display={'flex'} className={classes.cell}>
                  <p className={classes.key}>{field.label}</p>
                  <p className={style}>
                    {field.renderFunc ? field.renderFunc(field, item) : item[field.name].toString()}
                  </p>
                </Box>
              )
            })}
            {props.actions && props.actions.items?.length > 0 && (
              <Box
                style={{ verticalAlign: 'middle', height: '100%' }}
                className={classes.actionsCell + ' ' + actionStyle}
                display={'flex'}
                flexDirection={'row'}
                justifyContent={'space-between'}>
                <p className={classes.key}>{props.actions.actionsColumn}</p>
                <Box>
                  {props.actions.items.map((a) => {
                    if (a.hidden && a.hidden(item)) {
                      return <></>
                    }
                    return (
                      <img
                        src={a.icon}
                        className={classes.icon}
                        key={item[props.rowKeyField]}
                        onClick={() => a.handler(item)}
                      />
                    )
                  })}
                </Box>
              </Box>
            )}
          </Box>
        )
      })}
    </List>
  )
}
