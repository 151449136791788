import { PatientPoint } from './PatientPoint'
import { Source } from '../enums/Source_Points'

export interface PatientPointDTO {
  id: string
  createdAt: Date
  points: number
  patientID: string
  source: Source
}

export function emptyPatientPointDTO(): PatientPointDTO {
  return {
    id: '',
    createdAt: new Date(),
    points: 0,
    patientID: '',
    source: Source.FAQ,
  }
}

export function toModel(p: PatientPointDTO): PatientPoint {
  return new PatientPoint(p)
}

export function fromModel(p: PatientPoint): PatientPointDTO {
  return {
    id: p.id,
    createdAt: p.createdAt,
    points: p.points,
    patientID: p.patientID,
    source: p.source,
  }
}
